import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vs-signup-venue-page',
  templateUrl: './signup-venue-page.html',
  styleUrls: ['./signup-venue-page.scss']
})
export class SignUpVenuePageComponent {

}
