export const APP_NAV = {
  guest: [
    {
      label: 'NAV.LIST_VENUE',
      link: '/venues'
    }
  ],
  venue_manager: [
    {
      label: 'NAV.MY_BOOKINGS',
      link: '/booking'
    },
    {
      label: 'NAV.MANAGE_VENUE',
      link: '/pro'
    }
  ],
  venue_user: [
    {
      label: 'NAV.MY_BOOKINGS',
      link: '/booking'
    }
  ],
  organization_manager: [
    {
      label: 'NAV.MY_BOOKINGS',
      link: '/booking'
    },
    {
      label: 'NAV.FAVORITE',
      link: '/user/favorites'
    }
  ],
  organization_user: [
    {
      label: 'NAV.MY_BOOKINGS',
      link: '/booking'
    },
    {
      label: 'NAV.FAVORITE',
      link: '/user/favorites'
    }
  ]
};
