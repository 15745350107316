import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';

import {HubSpotService } from '../services';

@Injectable()
export class HubspotGuard implements CanActivate {
  constructor(private hubSpotService: HubSpotService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.hubSpotService.register(state.url);
    return true;
  }
}
