import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { ArrangementService } from './arrangement.service';
import { onVenueSwitch } from './venue-switch.service';
import { environment } from '@env/environment';
import { ArrangementProduct } from '../models';
import { SessionService } from './session.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class UserArrangementsService extends ArrangementService implements onVenueSwitch {
  venueId: number;

  /**
   *
   */
  constructor(http: HttpClient,private sessionService: SessionService) {
    super(http);

    this.sessionService.user$.subscribe(user => {
      this.venueId = (user !== null ? user.venue_id : null);
    });
  }

  /**
   *
   */
  getArrangements(): Observable<ArrangementProduct[]> {
    let requestUrl = `${environment.API_URL}/venues/${this.venueId}/admin/arrangements`;

    return this.http.get<ArrangementProduct[]>(requestUrl);
  }

  /**
   *
   */
  getArrangement(arrangementId: number): Observable<ArrangementProduct> {
    return this.http.get<ArrangementProduct>(`${environment.API_URL}/venues/${this.venueId}/arrangements/${arrangementId}`);
  }

  /**
   *
   */
  getArrangementsByType(type: string, category?: string): Observable<ArrangementProduct[]> {
    return this.getArrangements()
      .pipe(map((arrangements: ArrangementProduct[]) => {
        return arrangements.filter(arrangement => {
          return (category) ? arrangement.type === type && arrangement.category === category : arrangement.type === type;
        });
      }));
  }

  /**
   *
   */
  addArrangement(arrangement: ArrangementProduct|any): Observable<any> {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');

    // arrangement.tax_percentage = 6;

    const body = JSON.stringify(arrangement);

    return this.http.post(`${environment.API_URL}/venues/${this.venueId}/admin/arrangements`,  body, requestOptions);
  }

  /**
   *
   */
  createEmptyArrangement(category: string): Observable<any> {
    const newArrangement = {
      type: 'meeting',
      category: category,
      title: `Untitled ${category} package`,
      activated: false
    };

    return this.addArrangement(newArrangement);
  }

  /**
   *
   */
  updateArrangement(arrangement: ArrangementProduct) {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(arrangement);

    return this.http
      .put(
        `${environment.API_URL}/venues/${this.venueId}/admin/arrangements/${arrangement.id}`,
        body, requestOptions);
  }

  /**
   *
   */
  updateArrangementOrder(arrangements: Array<any>) {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(arrangements);

    return this.http
      .put(
        `${environment.API_URL}/venues/${this.venueId}/admin/arrangements/order`,
        body,
        requestOptions);
  }

  /**
   *
   */
  deleteArrangement(arrangementId: number) {
    return this.http.delete(`${environment.API_URL}/venues/${this.venueId}/admin/arrangements/${arrangementId}`);
  }

  /**
   *
   */
  setVenueId(val: number) {
    this.venueId = val;
  }
}
