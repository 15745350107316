<mdl-dialog #homeVideoDialog [mdl-dialog-config]="dialogConfig">
  <div class="mdl-dialog__content embed-container">
    <iframe src="https://player.vimeo.com/video/298423522?autoplay=true" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen allow="autoplay; fullscreen"></iframe>
  </div>
</mdl-dialog>

<section class="main-cont">
  <div class="mdl-grid mdl-grid--no-spacing">
    <div class="mdl-cell--no-v-spacing mdl-cell--12-col">
      <div class="jumbotron">
      <div class="jumbotron-images">
        <vs-photo-slider [images]="headerImages" [interval]="4000"></vs-photo-slider>
      </div>

      <div class="overlay"></div>
      <section class="content">
        <div class="filler"></div>
        <div class="content-container">
          <div class="mdl-grid mdl-grid--no-spacing">
            <div class="mdl-cell mdl-cell--12-col cell-middle">
              <div class="search_content">
                <h1 class="display-1" [innerHTML]="'ADD_VENUE.HEADLINE' | translate"></h1>
                <p class="tagline">{{ 'ADD_VENUE.TAGLINE' | translate }}</p>
                <div class="jumbo-actions">
      
                  <button (click)="navigateToTarget(venuesignup)" class="mdl-button--xl" mdl-button mdl-button-type="raised" mdl-colored="accent">
                    {{ 'ADD_VENUE.SIGNUP_BTN' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
    </div>
  </div>
</section>

<section #videoblock class="video">
  <div class="mdl-grid mdl-grid--no-spacing">
    <div class="mdl-cell--no-v-spacing mdl-cell--12-col">
      <h2 class="display-2" [innerHTML]="'ADD_VENUE.USPS_TITLE' | translate"></h2>
      <div class="video-wrap">
        <div class="video-container">
          <div class="video-div">
            <img class="video-img" src="https://res.cloudinary.com/venuesuite-com/image/upload/v1602766317/homepage/Video_BG.jpg" width="1280" />
            <img class="video-play" src="../../../../assets/images/marketing/play_icon.svg" (click)="homeVideoDialog.show()" />
          </div>
          <div class="video-container__wrap">
            <div class="video-container__wrap-list">
              <div class="video-container__wrap-list-item">
                <div class="video-container__wrap-list-item-title">
                  <h3>{{ 'ADD_VENUE.USPS.1.NAME' | translate }}</h3>
                </div>
                <div class="video-container__wrap-list-item-description">
                  <p> {{ 'ADD_VENUE.USPS.1.TEXT' | translate }}</p>
                </div>
              </div>
              <div class="video-container__wrap-list-item">
                <div class="video-container__wrap-list-item-title">
                  <h3>{{ 'ADD_VENUE.USPS.2.NAME' | translate }}</h3>
                </div>
                <div class="video-container__wrap-list-item-description">
                  <p>{{ 'ADD_VENUE.USPS.2.TEXT' | translate }}</p>
                </div>
              </div>
              <div class="video-container__wrap-list-item">
                <div class="video-container__wrap-list-item-title">
                  <h3>{{ 'ADD_VENUE.USPS.3.NAME' | translate }}</h3>
                </div>
                <div class="video-container__wrap-list-item-description">
                  <p>{{ 'ADD_VENUE.USPS.3.TEXT' | translate }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="quote">
  <div class="mdl-grid mdl-grid--no-spacing">
    <div class="mdl-cell--no-v-spacing mdl-cell--12-col">
      <span class="quote-mark"></span>
      <span class="quote-txt">{{ 'ADD_VENUE.QUOTE' | translate }}</span>
      <div class="quote-person">
        <img src="https://res.cloudinary.com/venuesuite-com/image/upload/v1603183142/homepage/Liesbeth.jpg" />
        <div class="quote-person__desc">
          <b>Liesbeth Grootvelt</b>
          <p>NBC Congrescentrum</p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="venue-platform-advantage platform-advantage"> 
  <div class="mdl-grid mdl-grid--no-spacing">
    <div class="mdl-cell--no-v-spacing mdl-cell--12-col">
      <section class="feature-benefit s-bg-white s-img-align-left ">
        <div class="feature-benefit__text-wrap align-left">
          <div class="feature-benefit__text ">
            <div class="feature-benefit__desc-wrap ">
              <span>{{ 'ADD_VENUE.ADVANTAGES.1.SECTION' | translate }}</span>
              <h2 class="display-2">{{ 'ADD_VENUE.ADVANTAGES.1.NAME' | translate }}</h2>
              <ul>
                <li>
                  {{ 'ADD_VENUE.ADVANTAGES.1.TEXT_1' | translate }}
                </li>
                <li>
                  {{ 'ADD_VENUE.ADVANTAGES.1.TEXT_2' | translate }}
                </li>
                <li>
                  {{ 'ADD_VENUE.ADVANTAGES.1.TEXT_3' | translate }}
                </li>
              </ul>
              <a (click)="navigateToTarget(venuesignup)" rel="noreferrer" class="mdl-button--xl btn-schedule-demo" mdl-button mdl-button-type="raised" mdl-colored="primary">
                {{ 'ADD_VENUE.REGISTER_LABEL' | translate }} 
              </a>
            </div>
          </div>
        </div>
        <div class="feature-benefit__img-wrap s-img-left">
          <div class="feature-benefit__img-wrap-container">
            <img src="{{ 'ADD_VENUE.ADVANTAGES.1.IMAGE' | translate }}" alt="Products" />
          </div>
        </div>
      </section>
      <section class="feature-benefit s-bg-white s-img-align-right ">
        <div class="feature-benefit__text-wrap align-left">
          <div class="feature-benefit__text ">
            <div class="feature-benefit__desc-wrap ">
              <span>{{ 'ADD_VENUE.ADVANTAGES.2.SECTION' | translate }}</span>
              <h2 class="display-2">{{ 'ADD_VENUE.ADVANTAGES.2.NAME' | translate }}</h2>
              <ul>
                <li>
                  {{ 'ADD_VENUE.ADVANTAGES.2.TEXT_1' | translate }}
                </li>
                <li>
                  {{ 'ADD_VENUE.ADVANTAGES.2.TEXT_2' | translate }}
                </li>
                <li>
                  {{ 'ADD_VENUE.ADVANTAGES.2.TEXT_3' | translate }}
                </li>
              </ul>
              <a (click)="navigateToTarget(venuesignup)" rel="noreferrer" class="mdl-button--xl btn-schedule-demo" mdl-button mdl-button-type="raised" mdl-colored="primary">
                {{ 'ADD_VENUE.REGISTER_LABEL' | translate }} 
              </a>
            </div>
          </div>
        </div>
        <div class="feature-benefit__img-wrap s-img-right">
          <div class="feature-benefit__img-wrap-container">
            <img src="{{ 'ADD_VENUE.ADVANTAGES.2.IMAGE' | translate }}" alt="Chat" />
          </div>
        </div>
      </section>
      <section class="feature-benefit s-bg-white s-img-align-left ">
        <div class="feature-benefit__text-wrap align-left">
          <div class="feature-benefit__text ">
            <div class="feature-benefit__desc-wrap ">
              <span>{{ 'ADD_VENUE.ADVANTAGES.3.SECTION' | translate }}</span>
              <h2 class="display-2">{{ 'ADD_VENUE.ADVANTAGES.3.NAME' | translate }}</h2>
              <ul>
                <li>
                  {{ 'ADD_VENUE.ADVANTAGES.3.TEXT_1' | translate }}
                </li>
                <li>
                  {{ 'ADD_VENUE.ADVANTAGES.3.TEXT_2' | translate }}
                </li>
                <li>
                  {{ 'ADD_VENUE.ADVANTAGES.3.TEXT_3' | translate }}
                </li>
              </ul>
              <a (click)="navigateToTarget(venuesignup)" rel="noreferrer" class="mdl-button--xl btn-schedule-demo" mdl-button mdl-button-type="raised" mdl-colored="primary">
                {{ 'ADD_VENUE.REGISTER_LABEL' | translate }} 
              </a>
            </div>
          </div>
        </div>
        <div class="feature-benefit__img-wrap s-img-left">
          <div class="feature-benefit__img-wrap-container">
            <img src="{{ 'ADD_VENUE.ADVANTAGES.3.IMAGE' | translate }}" alt="Exposure" />
          </div>
        </div>
      </section>
    </div>
  </div>
</section>
<section class="new_way">
  <div class="mdl-grid mdl-grid--no-spacing">
    <div class="mdl-cell--no-v-spacing mdl-cell--12-col">
      <ng-container>
        <div class="red_border"></div>
        <h2 class="display-2">{{ 'ADD_VENUE.SUPPORT_ADVAN.TITLE' | translate }}</h2>

        <div class="new_way_container">
          <div class="new_way_container__wrap">
            <img src="../../../../assets/images/venuepage/bg_image.jpg"/>
          </div>
    
          <div class="new_way_container-list">
            <div class="usp_item">
              <h3 class="display-h3">{{ 'ADD_VENUE.SUPPORT_ADVAN.1.NAME' | translate }}</h3>
              <p>{{ 'ADD_VENUE.SUPPORT_ADVAN.1.TEXT' | translate }}</p>
            </div>
    
            <div class="usp_item">
              <h3 class="display-h3"> {{ 'ADD_VENUE.SUPPORT_ADVAN.2.NAME' | translate }}</h3>
              <p>{{ 'ADD_VENUE.SUPPORT_ADVAN.2.TEXT' | translate }}</p>
            </div>
    
            <div class="usp_item">
              <h3 class="display-h3">{{ 'ADD_VENUE.SUPPORT_ADVAN.3.NAME' | translate }}</h3>
              <p>{{ 'ADD_VENUE.SUPPORT_ADVAN.3.TEXT' | translate }}</p>
            </div>
    
            <div class="usp_item">
              <h3 class="display-h3">{{ 'ADD_VENUE.SUPPORT_ADVAN.4.NAME' | translate }}</h3>
              <p>{{ 'ADD_VENUE.SUPPORT_ADVAN.4.TEXT' | translate }}</p>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</section>

<section #venuesignup class="venue-signup">
  <vs-signup-venue></vs-signup-venue>
</section>

<vs-footer></vs-footer>
