import { Component, Input } from '@angular/core';

export type AlertLevel = 'info'|'error'|'success'|'warning'|'neutral' |'critical';

@Component({
  selector: 'vs-alert',
  templateUrl: './alert.html',
  styleUrls: ['./alert.scss']
})
export class AlertComponent {
  @Input() type: AlertLevel = 'info';
  @Input() text: string;
  @Input() htmlText: string;

  constructor() {}
}
