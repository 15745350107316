import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class ScrollBlockService {
  scrollTop: number;

  constructor(@Inject(DOCUMENT) private document: any) { }

  blockScrolling() {
    this.scrollTop = this.document.documentElement.scrollTop;
    this.document.body.style.marginTop = (this.scrollTop * -1) + 'px';
    this.document.body.classList.add('scrollblock');
  }

  enableScrolling() {
    this.document.body.style.marginTop = '0px';
    this.document.body.classList.remove('scrollblock');
    window.scrollTo(0, this.scrollTop);
    this.document.body.scrollTop = this.scrollTop;
  }
}
