import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AccomodationProduct } from '../models';

import { environment } from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable()
export class AccomodationService {
  venueId : number = 0;

  constructor(private http: HttpClient) { }

  /**
   * 
   */
  setVenueId(val: number) {
    this.venueId = val;
  }

  /**
   * 
   */
  getVenueId(venueId?:number) {
      return venueId ? venueId : this.venueId;
  }

  /**
   * Get All
   */
  getAll(venueId?: number): Observable<AccomodationProduct[]> {
    const auxVenueId = this.getVenueId(venueId);
    let requestUrl = `${environment.API_URL}/venues/${auxVenueId}/accomodations`;
    return this.http.get<AccomodationProduct[]>(requestUrl);
  }

  /**
   * Save
   */
  save(accomodation: AccomodationProduct): Observable<AccomodationProduct> {
    if (accomodation.id) {
        return this.update(accomodation);
    }
    return this.create(accomodation);
  }

  /**
   * Create
   */
  create(accomodation: AccomodationProduct): Observable<AccomodationProduct> {
    const auxVenueId = this.getVenueId();
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(accomodation);
    return this.http.post<AccomodationProduct>(`${environment.API_URL}/venues/${auxVenueId}/accomodations`, body, requestOptions);
  }

  /**
   * Update
   */
  update(accomodation: AccomodationProduct): Observable<AccomodationProduct> {
    const auxVenueId = this.getVenueId();
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(accomodation);
    return this.http.patch<AccomodationProduct>(`${environment.API_URL}/venues/${auxVenueId}/accomodations/${accomodation.id}`, body, requestOptions);
  }

  /**
   * Delete
   */
  delete(id: number): Observable<any> {
    const auxVenueId = this.getVenueId();
    const requestOptions = {
      headers: new HttpHeaders()
    };

    return this.http.delete(`${environment.API_URL}/venues/${auxVenueId}/accomodations/${id}`, requestOptions);
  }
}
