import {type} from "../util";
import { Action } from '@ngrx/store';
import {Venue} from "@core/models";

export class ActionTypes {
  static readonly ADD_VENUE = type('[Compare venue] Add venue to compare');
  static readonly RM_VENUE = type('[Compare venue] Rm venue from comparison');
  static readonly LOAD_VENUES = type('[Compare venue] Load venues');
  static readonly VENUES_LOADED = type('[Compare venue] Venues loaded');
}

export class AddVenueAction implements Action {
  readonly type = ActionTypes.ADD_VENUE;

  constructor(public payload: Venue) { }
}

export class RmVenueAction implements Action {
  readonly type = ActionTypes.RM_VENUE;

  constructor(public payload: Venue) { }
}

export class LoadVenuesAction implements Action {
  readonly type = ActionTypes.LOAD_VENUES;
}

export class VenuesLoadedAction implements Action {
  readonly type = ActionTypes.VENUES_LOADED;

  constructor(public payload: Venue[]) {}
}

export type Actions
  = AddVenueAction
  | RmVenueAction
  | LoadVenuesAction
  | VenuesLoadedAction;
