import { Component, Input, ViewEncapsulation } from '@angular/core';
import { SetupIconPipe } from './../../pipes/setup-icon';

@Component({
  selector: 'vs-space-setups',
  template: `
    <div class="vs-space-setups" [class.hide-tooltip]="!showTooltip">
      <div *ngFor="let setup of setups" class="space-setup" [mdl-tooltip]="'SETUPS.'+setup.label.toUpperCase() | translate" mdl-tooltip-position="bottom">
        <i class="icon" [ngClass]="getSetupIcon(setup.label)"></i>
        <span *ngIf="showLabel" class="space-setup-name">{{ ('SETUPS.'+setup.label.toUpperCase()) | translate }}</span>
        <span class="space-setup-number">{{ setup.max }}</span>
      </div>
    </div>
  `,
  styleUrls: ['./space-setups.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SpaceSetupsComponent {
  @Input() showLabel: boolean;
  @Input() showTooltip: boolean = false;
  @Input() setups: Array<any>;
  setupIconPipe: SetupIconPipe;

  /**
   * 
   */
  constructor() {
    this.setupIconPipe = new SetupIconPipe();
  }

  /**
   * 
   */
  getSetupIcon(label: string) {
    return this.setupIconPipe.transform(label);
  }
}
