import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MdlDialogReference, MdlDialogService } from '@angular-mdl/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { filter, withLatestFrom } from 'rxjs/operators';

import { environment } from '@env/environment';
import { HubSpotService, NavService, SessionService, EventTrackerService, VenueSwitchService } from '@core/services';
import { User } from '@core/models/user';
import * as fromRoot from '@core/reducers';
import * as userActions from '@core/actions/user';
import { APP_NAV } from './main/data/app-main-nav';
import { AuthDialogComponent } from './main/containers';
import { INITIAL_STATE } from './main/containers/auth-dialog/auth-dialog';
import * as moment from 'moment';
import { MarketingNavService } from './core/services';
import { Title } from "@angular/platform-browser";
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'vs-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']

})
export class AppComponent implements OnInit {
  isAuthenticated = false;
  user: User;
  latestUrl: string;
  navItems: any;
  userRole: string;
  authDialogReference: MdlDialogReference;
  authDialogVisible: boolean;
  transparentNav: boolean;
  marketingTransparentNav: boolean;
  showNav: boolean = false;
  showUrgent: boolean = false;
  test = 0;
  currentRoute: string = '';
  originalTitle: string;
  sysLanguages: string[] = [];

  /**
   *
   */
  constructor(
    private navService: NavService,
    private marketingNavService: MarketingNavService,
    private hubSpotService: HubSpotService,
    private eventTrackerService: EventTrackerService,
    private translate: TranslateService,
    public router: Router,
    private session: SessionService,
    private switcher: VenueSwitchService,
    private dialogService: MdlDialogService,
    private store: Store<fromRoot.State>,
    private titleService: Title) {
      this.sysLanguages = environment.LANGUAGES.map(lang => lang.value.toLowerCase());
    }

  /**
   *
   */
  ngOnInit() {
    this.originalTitle = this.titleService.getTitle();
    this.setLanguageFromUrl();
    this.initializeTranslationService();

    this.router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd),
        filter((event: any) => event.url.indexOf('#') === -1)
      )
      .subscribe((event) => {
        this.latestUrl = event.url;
        window.scrollTo(0, 0);

        this.showNav = this.latestUrl.indexOf('loginless') === -1;

        this.showUrgent = this.latestUrl === '/';

        this.transparentNav = this.navService.exists(event.url);
        this.marketingTransparentNav = this.marketingNavService.exists(event.url);
        this.hubSpotService.onAppNav(event.url);

        this.eventTrackerService.trackPageView(event.url);
        this.setOriginalTitle();
      });

    this.session
      .isLoggedIn$
      .subscribe(isAuthenticated => {
        this.isAuthenticated = isAuthenticated;

        if ( this.isAuthenticated && this.authDialogVisible ) {
          this.authDialogReference.hide();
        }
      });

    this.session
      .user$
      .subscribe((user) => {
        this.user = user;
        console.log("user", this.user)
        this.userRole = this.user && this.user.role ? this.user.role : '';

        if ( ! user || typeof(APP_NAV[this.userRole]) === 'undefined' ) {
          this.navItems = APP_NAV.guest;
        } else {
          this.navItems = APP_NAV[this.userRole];
        }
        if ( user && user.venue_id ) {
          this.switcher.setActiveVenueId(user.venue_id);
        }
      });

    this.session
      .sessionExpired$
      .subscribe(() => {
        const redirectUrl = this.latestUrl;
        this.dialogService.alert('Please enter your credentials to ensure your identity', 'ok', 'Session expired').subscribe(() => {
          this.router.navigate(['/'], {
            queryParams: {
              'redirectUrl': redirectUrl
            }
          });
          this.showAuthDialog('login');
        });
      });

    this.store.select(fromRoot.getShowAuthDialog)
      .pipe(withLatestFrom(this.store.select(fromRoot.getAuthDialogTab)))
      .subscribe(value => {
        if ( value[0] ) {
          this.showAuthDialog(value[1]);
        } else if ( this.authDialogVisible && this.authDialogReference ) {
          this.authDialogReference.hide();
        }
      });
  }

  /**
   *
   */
  showAuthDialog(initialTab: string) {
    const pDialog = this.dialogService.showCustomDialog({
      animate: true,
      clickOutsideToClose: true,
      component: AuthDialogComponent,
      isModal: true,
      classes: 'mdl-dialog_auth',
      providers: [{
        provide: INITIAL_STATE,
        useValue: initialTab
      }]
    });

    pDialog.subscribe(ref => {
      this.authDialogReference = ref;

      ref.onVisible().subscribe(res => {
        this.authDialogVisible = true;
      });

      ref.onHide().subscribe(res => {
        this.authDialogVisible = false;
        this.store.dispatch(new userActions.HideAuthDialogAction());
      });
    })
  }

  /**
   *
   */
  signOut() {
    this.store.dispatch(new userActions.LogoutAction());
  }

  /**
   *
   */
  initializeTranslationService() {
    const fallBackLang = 'en';

    this.translate.addLangs(this.sysLanguages);
    this.translate.setDefaultLang(fallBackLang); // Fallback lang

    let lang = '';
    if ( localStorage.getItem('lang') ) {
      lang = localStorage.getItem('lang');
    } else {
      const clientLang = window.navigator.language || (window.navigator as any).userLanguage || '';
      lang = clientLang.slice(0, 2);
    }

    const realLang = this.sysLanguages.findIndex(l => l === lang) !== -1 ? lang : fallBackLang;

    console.log("realLang " , realLang, " lang ", lang);

    this.translate.use(realLang);
    moment.locale(realLang);
  }

  /**
   *
   */
  private setLanguageFromUrl() {
    let urlParams = new HttpParams({fromString: window.location.search});
    const selectedLang = urlParams.get('lang') ? urlParams.get('lang') : urlParams.get('?lang');

    console.log(urlParams, "selectedLang", selectedLang);

    if (this.sysLanguages.findIndex(l => l === selectedLang) !== -1) {
      localStorage.setItem('lang', selectedLang);
    }
  }

  /**
   *
   */
  private setOriginalTitle() {
    // set the original title by default for every page. It can be updated later on the app.
    // this will help keeping consistent titles when navigating the app without refresh
    if (this.originalTitle != this.titleService.getTitle()) {
      this.titleService.setTitle(this.originalTitle);
    }
  }
}
