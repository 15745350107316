import { BookingActions, BookingActionTypes } from './booking.actions';
import { INewBooking } from '../models/booking-form-model';

export interface State {
  spaceIds: number[];
  drafts: { [spaceId:number]: INewBooking };
  selectedSpaceId: number | null;
}

export const initialState: State = {
  spaceIds: [],
  drafts: [],
  selectedSpaceId: null
};

export function reducer(state = initialState, action: BookingActions): State {
  switch (action.type) {
    case BookingActionTypes.CREATE_DRAFT: {
      state.drafts[action.spaceId] = Object.assign({}, action.booking);
      return state;
    }
    case BookingActionTypes.SELECT_DRAFT: {
      if ( state.drafts[action.spaceId] === undefined ) {
        return state;
      }

      return {
        ...state,
        selectedSpaceId: action.spaceId
      };
    }
    case BookingActionTypes.UPDATE_DRAFT: {
      const existingDraft = state.drafts[action.spaceId];

      if ( existingDraft ) {
        state.drafts[action.spaceId] = Object.assign(existingDraft, action.changes);
      } else {
        state.drafts[action.spaceId] = Object.assign({}, action.changes);
      }

      return state;
    }
    case BookingActionTypes.INSERT_MANY: {
      (<INewBooking[]>action.drafts).forEach((value, index) => {
        state.drafts[index] = value;
      });

      return state;
    }
    case BookingActionTypes.DELETE_DRAFT: {
      delete state.drafts[action.spaceId];  // delete the hash associated with the action.id

      return {
        selectedSpaceId: state.selectedSpaceId,
        spaceIds: state.spaceIds,
        drafts: state.drafts
      };
    }
    default: {
      return state;
    }
  }
}

export const selectBookingState = (state: State) => state;
export const selectCurrentDraftId = (state: State) => state.selectedSpaceId;
export const selectDraftIds = (state:State) => state.spaceIds;
export const selectDraftEntities = (state: State) => state.drafts;
