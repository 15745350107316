import { Pipe, PipeTransform } from '@angular/core';
import * as Dinero from 'dinero.js/build/cjs/dinero.js';

@Pipe({name: 'currencyCents'})
export class CurrencyCentsPipe implements PipeTransform {
  transform(value: any, valuta: string): string {
    if ( ! value ) {
      return valuta ? '€ 0,00' : '0,00';
    }

    try {
      const price = Dinero({amount: value}).toFormat();
      return valuta ? '€ '+price : price;
    } catch(err){
      console.log(err);
      return '';
    }
  }
}
