<div class="mdl-grid">
  <div class="mdl-cell mdl-cell--4-col"></div>
  <div class="mdl-cell mdl-cell--4-col" *ngIf="!completeUser">
    <vs-reset-password
      (resetPassword)="resetPassword($event)"
      [email]="email"
      [loading]="loading"
      [error]="errorMsg"></vs-reset-password>
  </div>
  <div class="mdl-cell mdl-cell--4-col" *ngIf="completeUser">
    <vs-complete-account
      (resetPassword)="resetPassword($event)"
      [email]="email"
      [loading]="loading"
      [error]="errorMsg"></vs-complete-account>
  </div>
  <div class="mdl-cell mdl-cell--4-col"></div>
</div>
