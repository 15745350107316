import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { LocalStorageRefService } from "./local-storage-ref-service";

@Injectable({ providedIn: "root" })
export class DataTableService {
  private _myData$ = new BehaviorSubject<any>(null);
  public myData$ = this._myData$.asObservable();
  private _localStorage: Storage;

  constructor(_localStorageRefService: LocalStorageRefService) {
    this._localStorage = _localStorageRefService.localStorage
  }

  setInfo(data: any) {
    const jsonData = JSON.stringify(data.value)
    this._localStorage.setItem(data.name, jsonData)
    this._myData$.next(data)
 }

  getInfo(name: string) {
    if ( this._localStorage.getItem(name) ) {
      return  JSON.parse(this._localStorage.getItem(name));
    }
  }

  setAndUpdateDefaults(name: string, value: any): number {
    this.setInfo({name: name, value: value});
    return this.getInfo(name);
  }

}