import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { take, switchMap } from 'rxjs/operators';

import * as fromRoot from '../reducers';
import * as userActions from '../actions/user';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(public store: Store<fromRoot.State>, public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store.select(fromRoot.getUser)
      .pipe(
        take(1),
        switchMap(user => {
          if ( user ) {
            return of(true);
          }

          this.store.dispatch(new userActions.ShowAuthDialogAction('login'));
          this.router.navigate(['/'], {
            queryParams: {
              redirectUrl: state.url
            }
          });

          return of(false);
        })
      );
  }
}
