<div class="form-card">
  <h4>{{ 'LOGIN.ACCOUNT_CREATED_TITLE' | translate }}</h4>
  <p class="subtitle">{{ 'LOGIN.SECURE_PASSWORD_TITLE' | translate }}</p>

  <vs-alert *ngIf="error" type="error" [text]="error"></vs-alert>

  <form (ngSubmit)="onSubmit()" [formGroup]="form" (submit)="$event.preventDefault()">
    <vs-formfield>
      <label>{{ 'LOGIN.USERNAME' | translate }}</label>
      <input type="email" readonly disabled [ngModel]="email" [ngModelOptions]="{standalone: true}" />
    </vs-formfield>
    <vs-formfield [class.is-invalid]="password.invalid && submitAttempt">
      <label>{{ 'LOGIN.PASSWORD' | translate }}</label>
      <input autofocus type="password" formControlName="password" />
      <span dirErrorMsg>{{ 'LOGIN.SET_PASSWORD_ERROR' | translate }}</span>
      <vs-password-strength-indicator [password]="form.get('password').value"></vs-password-strength-indicator>
    </vs-formfield>
    <div class="form-actions">
      <button [disabled]="loading" type="submit" mdl-button mdl-button-type="raised" mdl-colored="accent">
        <ng-container *ngIf="!loading">{{ 'LOGIN.ACCOUNT_GET_ACCESS' | translate }}</ng-container>
        <ng-container *ngIf="loading">{{ 'LOGIN.BUSY' | translate }}</ng-container>
      </button>
    </div>
  </form>
</div>
