export const environment = {
  PRODUCTION: true,
  ORG_ID: 'nbc',
  LOGGING: false,
  LOGGING_SENTRY_URL: '',
  ENABLE_GA: true,
  HUBSPOT_ENABLED: false,
  HUBSPOT_INTEGRATION_KEY: '',
  HUBSPOT_SHOW_DELAY_SECONDS: 30,
  VENUESUITE_AMBASSADOR: 170,
  APP_VERSION: '',
  API_URL: 'https://api-nbc.venuesuite.com',
  TRANSLATION_FILES: '/assets/i18n/',
  GOOGLEMAPS_API_KEY: 'AIzaSyBuIV69KAjCc6uduLy9nzdrgMvYg84a5ow',
  SEARCH_DEFAULT_VIEW: 'map',
  IMG_PLACEHOLDER: 'https://res.cloudinary.com/venuesuite-com/image/upload/placeholder_oqplcb.png',
  MAP_ICON_DEFAULT: 'assets/images/map-marker.svg',
  MAP_ICON_HOVER: 'assets/images/map-marker-hover.svg',
  TRANSPARENT_ICON: 'assets/images/nbc-white.svg',
  MOBILE_LOGO: 'assets/images/nbc.svg',
  DESKTOP_LOGO: 'assets/images/nbc.svg',
  NEW_BOOKING_RESPONSE_TIME: 1,
  LANGUAGES: [
    { label: 'English', value: 'EN' },
    { label: 'Nederlands', value: 'NL' }
  ],
  DAY_PART_START_HOUR: {
    MORNING : 9,
    AFTERNOON : 13,
    EVENING : 18
  },
  DAY_PART_END_HOUR: {
    MORNING : 13,
    AFTERNOON : 17,
    EVENING : 23
  },
  MAP_CENTER : {
    LAT : 52.058929,
    LNG : 5.499008
  },
  MAP_DEFAULT_ZOOM : 8,
  WIDGET_URL: 'https://cdn.qa.onlinebookingwidget.com/widget.js',
  FILTER_VENUE_IDS: [],
  HIDE_FOOTER_LINK: true,
  MONTH_FORMAT: 'MMMM'
};
