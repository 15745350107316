import { ToasterService } from '@core/services/toaster.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'vs-toaster',
  templateUrl: './toaster.html',
  styleUrls: ['./toaster.scss']
})
export class ToasterComponent implements OnInit {
  showToast: boolean;
  message: string;
  type: string;
  dismissable: boolean;

  constructor(private toasterService: ToasterService) { }

  ngOnInit() {
    this.toasterService
      .showToaster$
      .subscribe(toastConfig => {
        this.type = toastConfig.type;
        this.message = toastConfig.message;
        this.dismissable = toastConfig.dismissable;
        this.showToast = true;
      });

    this.toasterService
      .closeToaster$
      .subscribe(() => {
        this.showToast = false;
      });
  }

  dismissToast() {
    this.showToast = false;
  }
}
