import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {MarketingNavService} from '../services';

@Injectable()
export class TransparentMarketingNavGuard implements CanActivate {
  constructor(private navService: MarketingNavService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.navService.registerRoute(state.url);
    return true;
  }
}
