import { Action } from '@ngrx/store';

import { IUser, User, Venue } from '../models';
import { type } from '../util';

export class ActionTypes {
  static readonly LOAD = type('[User] Load user');
  static readonly LOAD_USER_VENUE = type('[User] Load user venue');
  static readonly USER_VENUE_LOADED = type('[User] User venue loaded');
  static readonly USER_VENUES_LOADED = type('[User] User venues loaded');

  static readonly LOGIN = type('[User] Login');
  static readonly TOKEN_LOGIN = type('[User] Token login');
  static readonly LOGIN_LESS_TOKEN_SUCCESS = type('[User] Login less token success');
  static readonly LOGIN_SUCCESS = type('[User] Login success');
  static readonly LOGIN_FAILED = type('[User] Login failed');

  static readonly LOGIN_REQUEST_2FA = type('[User] Request 2FA code');

  static readonly LOGOUT = type('[User] Logout');
  static readonly LOGOUT_SUCCESS = type('[User] Logout Success');

  static readonly UPDATE_USER = type('[User] Update User Info');
  static readonly UPDATE_USER_SUCCESS = type('[User] Update User Info Success');

  static readonly SHOW_AUTH_DIALOG = type('[User] Show Auth Dialog');
  static readonly HIDE_AUTH_DIALOG = type('[User] Hide Auth Dialog');
}

export class LoadAction implements Action {
  readonly type = ActionTypes.LOAD;
}

export class LoadUserVenueAction implements Action {
  readonly type = ActionTypes.LOAD_USER_VENUE;
}

export class UserVenueLoadedAction implements Action {
  readonly type = ActionTypes.USER_VENUE_LOADED;

  constructor(public payload: Venue) { }
}

export class UserVenuesLoadedAction implements Action {
  readonly type = ActionTypes.USER_VENUES_LOADED;

  constructor(public payload: any) {}
}

export class LoginAction implements Action {
  readonly type = ActionTypes.LOGIN;

  constructor(public payload: any) {}
}

export class LoginLessAction implements Action {
  readonly type = ActionTypes.TOKEN_LOGIN;

  constructor(public payload: any) {}
}

export class LoginSuccessAction implements Action {
  readonly type = ActionTypes.LOGIN_SUCCESS;

  constructor(public payload: User) {}
}

export class LoginRequest2FAAction implements Action {
  readonly type = ActionTypes.LOGIN_REQUEST_2FA;

  constructor(public payload: User) {}
}

export class LoginLessSuccessAction implements Action {
  readonly type = ActionTypes.LOGIN_LESS_TOKEN_SUCCESS;

  constructor(public payload: User) {}
}

export class LoginFailedAction implements Action {
  readonly type = ActionTypes.LOGIN_FAILED;

  constructor(public payload: string) {}
}

export class LogoutAction implements Action {
  readonly type = ActionTypes.LOGOUT;
  constructor(public payload: boolean = true) {}
}

export class LogoutSuccessAction implements Action {
  readonly type = ActionTypes.LOGOUT_SUCCESS;
}

export class UpdateUserAction implements Action {
  readonly type = ActionTypes.UPDATE_USER;

  constructor(public payload: IUser) {}
}

export class UpdateUserSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_USER_SUCCESS;
}

export class ShowAuthDialogAction implements Action {
  readonly type = ActionTypes.SHOW_AUTH_DIALOG;
  constructor(public authType: string) {}
}

export class HideAuthDialogAction implements Action {
  readonly type = ActionTypes.HIDE_AUTH_DIALOG;
}

export type Actions
  = LoadAction
  | LoadUserVenueAction
  | UserVenueLoadedAction
  | UserVenuesLoadedAction
  | LoginAction
  | LoginLessAction
  | LoginLessSuccessAction
  | LoginSuccessAction
  | LoginFailedAction
  | LogoutAction
  | LogoutSuccessAction
  | UpdateUserAction
  | UpdateUserSuccessAction
  | ShowAuthDialogAction
  | HideAuthDialogAction
  | LoginRequest2FAAction;
