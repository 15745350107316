import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  AfterViewInit,
  ElementRef,
  OnChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';

import { Venue } from '@core/models';
import { AppRoles} from '@core/roles.enum';
import { UserService, VenueService, EventTrackerService } from '@core/services';

@Component({
  selector: 'vs-venue-preview',
  templateUrl: './venue-preview.html',
  styleUrls: ['./venue-preview.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VenuePreviewComponent implements AfterViewInit, OnChanges {
  @Input() venue: Venue;
  @Input() role: AppRoles;
  @Output() readonly mouseOver = new EventEmitter<any>();
  @Output() readonly mouseOut = new EventEmitter<any>();
  freeParkingFeature: any;

  get comingSoon(): boolean {
    return this.numberOfRooms === 0;
  }

  get id() {
    return this.venue.id;
  }

  get name() {
    return this.venue.name;
  }

  get showFavorite() {
    return !UserService.isVenue(this.role) && !this.comingSoon;
  }

  get numberOfRooms() {
    return this.venue.total_rooms;
  }

  get city() {
    return this.venue.city;
  }

  get province() {
    return this.venue.province;
  }

  get amenities() {
    return this.venue.amenities;
  }

  get hotel_rates() {
    return this.venue.hotel_grade;
  }

  /**
   * 
   */
  constructor(
    private eventTrackerService: EventTrackerService,
    private element: ElementRef,
    private router: Router
  ) { }

  /**
   * 
   */
  ngOnChanges(changes: any) {
    if (changes && changes.venue) {
      this.freeParkingFeature = this.amenities.find(value => value.label === 'Free parking');
    }
  }

  /**
   * 
   */
  ngAfterViewInit() {
    fromEvent(this.element.nativeElement, 'mouseover')
      .pipe(distinctUntilChanged())
      .subscribe((e: MouseEvent) => this.mouseOver.emit(e));

    fromEvent(this.element.nativeElement, 'mouseleave')
      .pipe(distinctUntilChanged())
      .subscribe((e: MouseEvent) => this.mouseOut.emit(e));
  }

  /**
   * 
   */
  navigateToVenue($event) {
    $event.preventDefault();

    if (this.comingSoon) {
      return;
    }

    this.eventTrackerService.trackEvent('reserve-done', 'button', 'venue', this.id);
    window.open(VenueService.buildVenueLink({id: this.id, name: this.name}, this.venue.share_key?this.venue.share_key:''), '_blank');
  }

  ratingsCounter(i: number) {
    return new Array(i);
  }
}
