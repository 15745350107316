import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timediff'
})
export class TimeDiffPipe implements PipeTransform {
  transform(value: string) {
    return moment(value, moment.ISO_8601).toNow(true);
  }
}
