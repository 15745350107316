import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '@env/environment';
import * as moment from 'moment';

@Component({
  selector: 'vs-language-switcher',
  templateUrl: 'language-switcher.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./language-switcher.scss']
})
export class LanguageSwitcherComponent implements OnInit {
  @Input() display: 'flags'|'full' = 'full';

  languages: any[] = environment.LANGUAGES;
  selectedLanguage: any;

  /**
   * 
   */
  constructor(public translate: TranslateService) { }

  /**
   * 
   */
  ngOnInit() {
    const selectedLanguageIndex: number = this.languages.findIndex(v => {
      return (v.value as String).toLowerCase() === this.translate.currentLang;
    });

    if (selectedLanguageIndex !== -1) {
      this.selectedLanguage = this.languages[selectedLanguageIndex];
    }
  }

  /**
   * 
   */
  onValueChange($event) {
    const selectedLang = $event.value.toLowerCase() ;
    this.setLang(selectedLang);
  }

  /**
   * 
   */
  setLang(selectedLang: string) {
    localStorage.setItem('lang', selectedLang);
    let loader = this.translate.use(selectedLang);
    moment.locale(selectedLang);
  }
}
