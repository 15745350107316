import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'paymentStatus'
})
export class PaymentStatusPipe implements PipeTransform {

  return: any;
  color: string;

  constructor(private sanitizer: DomSanitizer) { };

  transform(value: string) {
    if ( value === 'paid' ) {
      this.color = 'green';
    } else if ( value === 'overdue' ) {
      this.color = 'red';
    } else {
      return value;
    }

    this.return = '<span style="color: ' + this.color + '">' + value + '</span>';

    return this.sanitizer.bypassSecurityTrustHtml(this.return);
  }
}
