import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { EquipmentProduct } from '../models';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EquipmentService {
  static readonly EQUIPMENT_LIST = [
    'daylight',
    'tv_screen',
    'projector',
    'projection_and_screen',
    'monitor',
    'speakers',
    'sound_system',
    'microphone',
    'laser_pointer',
    'audio_engineer',
    'laptop',
    'lighting',
    'speakers_desk',
    'wardrobe',
    'video_camera',
    'conference_phone',
    'pens_and_paper',
    'whiteboard',
    'flipchart',
    'headset',
    'stage',
    'beamer'
  ];

  http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  /**
   * 
   */
  getAllVenueEquipment(venueId: number): Observable<EquipmentProduct[]> {
    const requestUrl = `${environment.API_URL}/venues/${venueId}/equipment`;

    return this.http.get<EquipmentProduct[]>(requestUrl);
  }

  /**
   * 
   */
  getVenueEquipment(venueId: number, equipmentId: number): Observable<EquipmentProduct> {
    return this.http.get<EquipmentProduct>(`${environment.API_URL}/venues/${venueId}/equipment/${equipmentId}`);
  }

  /**
   * 
   */
  getVenueEquipmentOffers(
    venueId: number,
    spaceId?: number,
    eventType?: string,
    period?: {start: string, end: string},
    organizationId?: number
    ): Observable<EquipmentProduct[]> {
    let requestUrl = `${environment.API_URL}/venues/${venueId}/equipment?`;

    if ( spaceId ) {
      requestUrl += '&space_id='+spaceId.toString();
    }

    if ( eventType ) {
      requestUrl += '&type='+eventType;
    }

    if ( period && period.start && period.end ) {
      requestUrl += '&period[start]='+encodeURIComponent(period.start)+'&period[end]='+encodeURIComponent(period.end);
    }
    
    if ( organizationId ) {
      requestUrl += '&organization_id='+organizationId;
    }

    return this.http.get<EquipmentProduct[]>(requestUrl);
  }

}
