import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { User } from '@core/models/user';

@Directive({
  selector: '[ifRole]'
})
export class IfRoleDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) { }

  @Input() role: string = '';

  @Input('ifRole') set user(user: User) {
    if ( user && user != null && user.role == this.role ) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
