<ng-select
  class="language"
  [items]="languages"
  [(ngModel)]="selectedLanguage"
  bindLabel="label"
  [clearable]="false"
  [searchable]="false"
  (change)="onValueChange($event)">
  <ng-template ng-label-tmp let-item="item">
    <svg class="icon_flag"><use [attr.xlink:href]="'#icon_flag_'+item.value.toLowerCase()"></use></svg><span *ngIf="display == 'full'"> - {{ item.label }}</span>
  </ng-template>
  <ng-template ng-option-tmp let-item="item">
    <svg class="icon_flag"><use [attr.xlink:href]="'#icon_flag_'+item.value.toLowerCase()"></use></svg><span> - {{ item.label }}</span>
  </ng-template>
  <ng-template ng-option-tmp let-item="item">
    <svg class="icon_flag"><use [attr.xlink:href]="'#icon_flag_'+item.value.toLowerCase()"></use></svg><span> - {{ item.label }}</span>
  </ng-template>
</ng-select>
