import { Component, Input, ViewChild, ElementRef, Output, EventEmitter, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileUploader, FileItem, ParsedResponseHeaders, FileLikeObject } from 'ng2-file-upload';

@Component({
  selector: 'vs-file-uploader',
  templateUrl: './file-uploader.html',
  styleUrls: ['./file-uploader.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: FileUploaderComponent,
    multi: true
  }]
})
export class FileUploaderComponent implements ControlValueAccessor, OnInit {
  @Input() url: string;
  @Input() queueLimit = 10;
  @Input() maxFileSize = 1000000;
  @Input() disabled: boolean;
  @Output() readonly fileUploadSuccess: EventEmitter<any> = new EventEmitter();
  @Output() readonly allDone: EventEmitter<number> = new EventEmitter();
  @ViewChild('fileInput', {static: false}) nativeFileInput: ElementRef;
  files: any[];
  fileUploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  failedUploads: {name: string, reason: string}[] = [];
  multiple: boolean;

  private _onChange: Function = Function.prototype;
  private _onTouched: Function = Function.prototype;

  constructor() {
    this.files = [];
  }

  ngOnInit() {
    this.fileUploader = new FileUploader({
      url: this.url,
      maxFileSize: this.maxFileSize,
      queueLimit: this.queueLimit,
      headers: [{
        name: 'Authorization',
        value: 'Bearer ' + localStorage.getItem('vsuite/apiToken')
      }],
      autoUpload: true
    });

    this.multiple = this.queueLimit > 1;

    this.fileUploader.onBeforeUploadItem = (item) => item.withCredentials = false;
    this.fileUploader.onWhenAddingFileFailed = (fileObject, filter, options) => this.onAddingFileFailed(fileObject, filter, options);
    this.fileUploader.onErrorItem = (item, response, status, headers) => this.onUploadError(item, response, status, headers);
    this.fileUploader.onSuccessItem = (fileItem, response) => this.onUploadSuccess(fileItem, response);
    this.fileUploader.onCompleteAll = () => this.allDone.emit(this.files.length);
  }

  onUploadSuccess(fileItem: FileItem, response) {
    const res = JSON.parse(response);

    this.fileUploadSuccess.emit(res);
    this.files = [...this.files, res];
    this._onChange(this.files);
  }

  onAddingFileFailed(item: FileLikeObject, filter, options) {
    this.failedUploads.push({
      name: item.name,
      reason: 'File is too big!'
    });
  }

  onUploadError(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) {
    this.failedUploads.push({
      name: item.file.name,
      reason: response
    });
  }

  dismissFailedUpload(index: number) {
    this.failedUploads.splice(index, 1);
  }

  cancelUpload($event) {
    this.fileUploader.cancelAll();
  }

  openFileInputDialog($event) {
    this.nativeFileInput.nativeElement.click();
  }

  fileOverBase($event) {
    this.hasBaseDropZoneOver = $event;
  }

  writeValue(value: any): void {
    // ...
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
