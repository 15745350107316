<div class="vs-venue-detail-page">
  <div class="header-image-container">
    <div class="header-img-ar">
      <vs-photo-slider
        #photoSlider
        [images]="photos"
        [allowFullscreen]="true"
        [interval]="0"></vs-photo-slider>

      <div class="header-content">
        <div class="mdl-grid">
          <div class="mdl-cell mdl-cell--9-col">
            <h1 class="venue-title">{{ venue?.name }}</h1>
          </div>
          <!-- <div class="mdl-cell mdl-cell--3-col photoslider-cell mdl-cell--hide-phone">
            <vs-favorite [venue]="venue"></vs-favorite>
            <button
              *ngIf="venue.youtube_url"
              (click)="openVideoDialog()"
              class="btn-open-video hidden-md"
              mdl-button
              mdl-button-type="raised"><i class="material-icons">play_circle_outline</i></button>
            <button
              *ngIf="venue?.images && venue?.images.length > 0"
              (click)="openPhotoSlider(0)"
              class="btn-open-photoslider hidden-md"
              mdl-button
              mdl-button-type="raised">{{ 'VENUE_PAGE.PHOTOS' | translate }}</button>
          </div> -->
        </div>
      </div>
    </div>
  </div>

  <section class="venue-info">
    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--6-col">
        <div class="facilities">
          <vs-venue-amenities [amenities]="venue.amenities" [limit]="100"></vs-venue-amenities>
        </div>
        <div class="venue-description">
          <vs-read-more [content]="venue?.description" [maxLength]="300"></vs-read-more>
        </div>
      </div>
      <div class="mdl-cell mdl-cell--6-col mdl-cell--hide-phone cell-venue-host">
        <vs-venue-host-vcard *ngIf="venue?.ambassador && venue?.ambassador.id !== venuesuiteAmbassador"
                             [venueName]="venue.name"
                             [contactInfo]="venue?.ambassador"
                             [avatarWidth]='200'></vs-venue-host-vcard>
      </div>
    </div>
  </section>

  <section class="section-header">
    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--12-col no-v-spacing">
        <h4 class="section-title">{{ 'VENUE_PAGE.SPACES' | translate }}</h4>
      </div>
    </div>
  </section>

  <section class="venue-spaces">
    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--12-col">
        <div [formGroup]="searchForm" id="space-list-filter" class="space-list-filter mdl-grid mdl-grid--no-spacing">
          <div class="mdl-cell mdl-cell--6-col mdl-cell--12-col-phone">
            <vs-formfield size="lg" *ngIf="!hasAvailability">
              <i (click)="dateTimeInput.openDatetimePicker($event)" dirPrefix class="material-icons">date_range</i>
              <vs-datetime
                #dateTimeInput
                formControlName="period"
                [venueId]="venueId"
                [placeholder]="'BOOKING_DETAILS.DATE_AND_TIME' | translate"></vs-datetime>
              <div dirPostfix class="clickable">
                <i *ngIf="hasDateTime" (click)="dateTimeInput.clearValue($event);" class="material-icons">clear</i>
              </div>
            </vs-formfield>
            <vs-formfield size="lg" *ngIf="hasAvailability">
              <i (click)="datetimeAvailabilityInput.openDatetimePicker($event)" dirPrefix class="material-icons">date_range</i>
              <vs-datetime-availability
                #datetimeAvailabilityInput
                formControlName="period"
                [venueId]="venueId"
                [placeholder]="'BOOKING_DETAILS.DATE_AND_TIME' | translate"></vs-datetime-availability>
              <div dirPostfix class="clickable">
                <i *ngIf="hasDateTime" (click)="datetimeAvailabilityInput.clearValue($event);" class="material-icons">clear</i>
              </div>
            </vs-formfield>
            <div class="formfield-tooltip" [class.visible]="showDateTooltip">
              <div class="formfield-tooltip-content"><b>{{ 'VENUE_PAGE.DATE_TOOLTIP' | translate }}</b></div>
            </div>
          </div>
          <div class="mdl-cell mdl-cell--3-col mdl-cell--12-col-phone">
            <vs-formfield size="lg">
              <ng-select
                formControlName="eventType"
                [placeholder]="'HOME.EVENT_TYPE_PLACEHOLDER' | translate"
                [items]="eventTypeOptions"
                [clearable]="true"
                [searchable]="false">
                <ng-template ng-label-tmp ng-option-tmp let-item="item">
                  {{ ('ACTIVITIES.'+item.toUpperCase()) | translate }}
                </ng-template>
              </ng-select>
            </vs-formfield>
          </div>
          <div class="mdl-cell mdl-cell--3-col mdl-cell--12-col-phone">
            <vs-formfield size="lg">
              <i dirPrefix class="material-icons">group</i>
              <input #guestInput formControlName="guests" [placeholder]="'SEARCH.GUESTS' | translate" />
              <div dirPostfix class="clickable">
                <i class="material-icons" (click)="incrementGuests($event)">add_circle_outline</i>
                <i class="material-icons" (click)="decrementGuests($event)">remove_circle_outline</i>
              </div>
              <div dirPostfix class="clickable">
                <i *ngIf="hasGuestNum" (click)="clearNumOfPeople($event)" class="material-icons">clear</i>
              </div>
            </vs-formfield>
            <div class="formfield-tooltip" [class.visible]="showGuestsTooltip">
              <div class="formfield-tooltip-content"><b>{{ 'VENUE_PAGE.GUEST_TOOLTIP' | translate }}</b></div>
            </div>
          </div>
        </div>
      </div>

      <ng-container *ngIf="isLoadingSpaces">
        <div class="mdl-cell mdl-cell--12-col space-list-loading hidden-md" *ngFor="let n of [1,2,3,4]">
          <div class="fake-sk-ph block-1"></div>
          <div class="block-group">
            <div class="fake-sk-ph block-2"></div>
            <div class="fake-sk-ph block-3"></div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="noResults">
        <div class="mdl-cell mdl-cell--12-col">
          <vs-alert type="info">{{ 'VENUE_PAGE.NO_RESULT' | translate }}</vs-alert>
        </div>
      </ng-container>

      <ng-container *ngIf="!isLoadingSpaces">
        <div class="mdl-cell mdl-cell--12-col space-list-preview" *ngFor="let space of spaces | activeProduct; let i = index">
          <div class="space-list-preview-image" (click)="openSpaceModal($event, space)">
            <vs-carousel [images]="space?.images" [transformation]="'4:3'" [width]="'600'"></vs-carousel>
          </div>
          <div class="space-list-preview-content">
            <div class="space-details" (click)="openSpaceModal($event, space)">
              <h5>{{ space.name }}
                <small
                  class="capacity"
                  [mdl-tooltip-large]="'COMMON.CAPACITY' | translate"
                  mdl-tooltip-position="top">
                  <i class="material-icons">group</i>{{ space.capacity.min }} - {{ space.capacity.max }}
                </small>
              </h5>
              <div class="space-equipment-included">
                <div *ngIf="space.equipment.length > 0">
                  <span *ngFor="let equipmentItem of space.equipment; let i = index">
                    {{ 'EQUIPMENT.'+equipmentItem.title.toUpperCase() | translate }}
                    <ng-container *ngIf="i < space.equipment.length - 1"><span class="middot">&middot;</span></ng-container>
                  </span>
                </div>
                <a (click)="openSpaceModal($event, space)" class="more-info-link mdl-cell--hide-phone">> {{ 'COMMON.MORE_INFO' | translate }}</a>
              </div>
              <div class="space-setups hidden-md">
                <vs-space-setups [showTooltip]="true" [setups]="space.setups"></vs-space-setups>
              </div>
            </div>
            <div class="space-equipment"><div class="line"></div></div>
            <div class="space-select">
              <span>{{ 'VENUE_PAGE.RESERVE' | translate }}</span>
              <h4 class="price" *ngIf="space.from_price.price">
                {{ +space.from_price.price | currencyCents:'EUR':'symbol' }}
                <small>{{ 'COMMON.PRICES_FROM.'+space.from_price.type | translate }}</small>
              </h4>
              <h4 class="price" *ngIf="!space.from_price.price">{{ 'COMMON.PRICE_ON_REQUEST' | translate }}</h4>

              <button
                (click)="navigateToCheckout(space)"
                mdl-button
                mdl-button-type="raised"
                mdl-colored="accent"
                [mdl-tooltip]="tt">
                {{ 'VENUE_PAGE.INSTANT_PRICE' | translate }}
              </button>
              <mdl-tooltip #tt="mdlTooltip">
                <ul class="tooltip-list" [innerHTML]="'VENUE_PAGE.PRICE_TOOLTIP' | translate"></ul>
              </mdl-tooltip>
            </div>
          </div>
        </div>
      </ng-container>

    </div>
  </section>

  <section class="venue-features">
    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--12-col section-title-container">
        <h4 class="section-title">{{ 'VENUE_PAGE.VENUE_FEATURES' | translate }}</h4>
      </div>
    </div>
    <div class="mdl-grid no-v-spacing">
      <div class="mdl-cell mdl-cell--12-col">
        <div class="venue-features-list">
          <div *ngFor="let amenity of venue.amenities" class="item">
            <span class="item-content" [mdl-tooltip]="amenityTooltip(amenity)" mdl-tooltip-position="top">
              <span class="icon" [ngClass]="amenity.label | venueFeatureIcon"></span> {{ amenityTr(amenity) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="venue-location">
    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--12-col no-v-spacing"><h4 class="section-title">{{ 'VENUE_PAGE.LOCATION' | translate }}</h4></div>
    </div>
    <div class="venue-map-container">
      <agm-map [latitude]="venueLocationMarker.lat" [longitude]="venueLocationMarker.lng" [zoom]="11" [scrollwheel]="false" [zoomControl]="true" [streetViewControl]="false">
        <agm-marker iconUrl="{{ICON_DEFAULT}}" [title]="venue.name" [latitude]="venueLocationMarker.lat" [longitude]="venueLocationMarker.lng"></agm-marker>
      </agm-map>
    </div>
  </section>

  <section class="venue-photos">
    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--12-col">
        <h4 class="section-title">{{ 'VENUE_PAGE.IMPRESSION' | translate }}</h4>
      </div>
      <div class="p-relative">
        <div class="photo_grid">
          <a *ngFor="let image of photos; let i = index" (click)="openPhotoSlider(i)">
            <span><img [src]="image | imageTransform:1200:900:true" alt=""></span>
          </a>
        </div>
      </div>
    </div>
  </section>

</div>
<vs-footer></vs-footer>
