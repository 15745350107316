<div class="p-relative">
  <i class="close-dialog material-icons" (click)="closeDialog()" (keydown.enter)="closeDialog()" role="button" tabindex="0">close</i>
  <div class="dialog-content">
    <ng-container *ngIf="dialogState === 'login'">
      <h4>{{ 'LOGIN.HEADER' | translate }}</h4>
      <vs-alert
        *ngIf="signupSuccess"
        type="success"
        [text]="'SIGNUP.SUCCESS' | translate"></vs-alert>

      <form [formGroup]="loginForm" (ngSubmit)="attemptLogin()" (submit)="$event.preventDefault()">
        <p *ngIf="errorMsg" class="mdl-color-text--red">{{ errorMsg }}</p>

        <div class="formfield-row">
          <vs-formfield [class.is-invalid]="formControlIsInvalid('login', 'username')">
            <label for="username-input">{{ 'LOGIN.USERNAME' | translate }}</label>
            <input id="username-input" formControlName="username" type="text" />
          </vs-formfield>
        </div>

        <div class="formfield-row">
          <vs-formfield [class.is-invalid]="formControlIsInvalid('login', 'password')">
            <label for="password-input">{{ 'LOGIN.PASSWORD' | translate }}</label>
            <input id="password-input" formControlName="password" type="password" />
          </vs-formfield>
        </div>

        <a class="pull-right" target="_blank" href="/forgot-password">{{ 'LOGIN.FORGOT_PASS' | translate }}</a>

        <button
          mdl-button
          mdl-button-type="raised"
          mdl-colored="accent"
          class="mdl-button--full-width"
          [disabled]="isLoading"
          (click)="attemptLogin()">{{ (isLoading ? 'LOGIN.BUSY' : 'LOGIN.SEND') | translate }}</button>

        <p class="text-center">
          {{ 'LOGIN.NEW_USER' | translate }} &nbsp; <a class="signup-link" (click)="goToSignUp()">{{ 'LOGIN.SIGNUP_LINK' | translate }}</a>
        </p>
      </form>
    </ng-container>
    <ng-container *ngIf="dialogState === 'login2fa'">
      <h4>{{ 'LOGIN_2FA.TITLE' | translate }}</h4>

      <p>{{ 'LOGIN_2FA.DESCRIPTION' | translate }}</p>

      <form [formGroup]="login2FAForm">
        <p *ngIf="errorMsg" class="mdl-color-text--red">{{ errorMsg }}</p>

        <div class="formfield-row">
          <vs-formfield [class.is-invalid]="formControlIsInvalid('login2FAForm', 'code')">
            <label for="2fa-code-input">{{ 'LOGIN_2FA.CODE' | translate }}</label>
            <input id="2fa-code-input" formControlName="code" type="text" />
          </vs-formfield>
        </div>

        <button
                mdl-button
                mdl-button-type="raised"
                mdl-colored="accent"
                class="mdl-button--full-width"
                [disabled]="isLoading"
                (click)="checkAuthCode()">{{ (isLoading ? 'LOGIN_2FA.BUSY' : 'LOGIN_2FA.SEND') | translate }}</button>

        <p class="text-center">
          {{ 'LOGIN_2FA.GO_BACK' | translate }}&nbsp;<a class="signup-link" (click)="goToLogin()">{{ 'LOGIN.TITLE' | translate }}</a>
        </p>
      </form>
    </ng-container>
    <ng-container *ngIf="dialogState === 'signup'">
      <h4>{{ 'SIGNUP.TITLE' | translate }}</h4>
      <p *ngIf="errorMsgSignup" class="mdl-color-text--red">{{ errorMsgSignup }}</p>
      <form [formGroup]="signupForm" (ngSubmit)="attemptSignup()" (submit)="$event.preventDefault()">
        <div class="formfield-row">
          <vs-formfield [class.is-invalid]="formControlIsInvalid('signup', 'fullname')">
            <label>{{ 'NEW_ACCOUNT_FORM.FULLNAME_PLACEHOLDER' | translate }}</label>
            <input id="signup-fullname" formControlName="fullname" type="text"/>
            <span dirErrorMsg>{{ 'NEW_ACCOUNT_FORM.FULLNAME_ERROR' | translate }}</span>
          </vs-formfield>
        </div>
        <div class="formfield-row">
          <vs-formfield [class.is-invalid]="formControlIsInvalid('signup', 'username')">
            <label>{{ 'LOGIN.USERNAME' | translate }}</label>
            <input id="signup-username" formControlName="username" type="text"/>
            <span *ngIf="this.signupForm.get('username').hasError('emailNotTrusted')" dirErrorMsg>
              {{ 'NEW_ACCOUNT_FORM.EMAIL_NOT_TRUSTED_ERROR' | translate }}
            </span>
            <span *ngIf="this.signupForm.get('username').hasError('email')" dirErrorMsg>
              {{ 'NEW_ACCOUNT_FORM.EMAIL_FORMAT_ERROR' | translate }}
            </span>
          </vs-formfield>
        </div>
        <div class="formfield-row">
          <vs-formfield>
            <label>{{ 'NEW_ACCOUNT_FORM.ORGANIZATION_PLACEHOLDER' | translate }}</label>
            <input id="signup-organization" formControlName="organization" type="text"/>
          </vs-formfield>
        </div>
        <div class="user-agree">
          <span [innerHTML]="'SIGNUP.TERMS' | translate:tosAndPp"></span>
        </div>
        <button
          [disabled]="isLoading"
          (click)="attemptSignup()"
          mdl-button
          mdl-button-type="raised"
          mdl-colored="accent"
          class="mdl-button--full-width">{{ (isLoading ? 'SENDING...' : 'SIGNUP.TITLE') | translate }}</button>
        <p class="text-center">{{ 'NEW_ACCOUNT_FORM.RETURNING' | translate }} <a class="signup-link" (click)="goToLogin()">{{ 'NEW_ACCOUNT_FORM.LOGIN_LINK' | translate }}</a></p>
      </form>
    </ng-container>
  </div>
</div>
