<input
  [disabled]="disabled"
  [readonly]="true"
  [placeholder]="placeholder ? placeholder : ''"
  [attr.id]="'dateTimeAvailabilityInput'"
  [value]="text"
  (blur)="onBlur($event)"
  (focus)="onFocus($event)"
  (click)="openDatetimeAvailabilityPicker($event)"
  (keydown.enter)="openDatetimeAvailabilityPicker($event)"
  (change)="$event.stopPropagation()">
