import { isPresent } from '@core/util';
import { LoadingBarEvent, LoadingBarEventType, LoadingBarService } from '@core/services';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'vs-loading-bar',
  template: `
    <div class="loading-bar">
      <div class="loading-bar-progress" [style.opacity]="show ? '1' : '0'" [style.width]="progress + '%'"></div>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./loading-bar.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class LoadingBarComponent implements OnInit, OnDestroy {
  progress = '0';
  show = false;
  private loadingBarEventSubs : Subscription;

  /**
   * 
   */
  constructor(private loadingBarService: LoadingBarService, private _cdRef: ChangeDetectorRef) { }

  /**
   * 
   */
  ngOnInit() {
    this.loadingBarEventSubs = this.loadingBarService.events.subscribe((event: LoadingBarEvent) => {
      if (event.type === LoadingBarEventType.PROGRESS && isPresent(event.value)) {
        this.progress = event.value;
      } else if (event.type === LoadingBarEventType.VISIBLE) {
        this.show = event.value;
      }
      this._cdRef.detectChanges();
    });
  }

  /**
   * 
   */
  ngOnDestroy() {
    this._cdRef.detach();
    this.loadingBarEventSubs.unsubscribe();
  }
}
