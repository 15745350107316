import {Venue} from "@core/models";
import * as fromActions from '../actions/compare-venue.actions';

export interface State {
  venues: Map<number, Venue>;
}

const initialState: State = {
  venues: new Map()
};

export function reducer(state = initialState, action: fromActions.Actions): State {
  switch (action.type) {
    case fromActions.ActionTypes.ADD_VENUE:
      const venueToAdd = action.payload;
      return {
        venues: new Map(state.venues.set(venueToAdd.id, venueToAdd))
      };
    case fromActions.ActionTypes.RM_VENUE:

      state.venues.delete(action.payload.id);
      return {
        venues: new Map(state.venues)
      };
    case fromActions.ActionTypes.VENUES_LOADED:
      // TODO: Add payload to state
      return state;
    default:
      return state;
  }
}

export const getVenues = (state: State) => state.venues;
export const getCountVenuesSelected = (state: State) => state.venues.size;
