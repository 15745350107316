import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

export interface IDepartmentEntry {
  id: number;
  name: string;
  cost_center: string;
}

@Injectable()
export class DepartmentManageService {

  constructor(private http: HttpClient) { }

  /**
   * 
   */
  getAll(): Observable<IDepartmentEntry[]> {
    let requestUrl = `${environment.API_URL}/departments`;
    return this.http.get<IDepartmentEntry[]>(requestUrl);
  }

  /**
   * 
   */
  create(department: IDepartmentEntry): Observable<IDepartmentEntry> {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(department);
    return this.http.post<IDepartmentEntry>(`${environment.API_URL}/departments`, body, requestOptions);
  }

  /**
   * 
   */
  update(id: number, department: IDepartmentEntry): Observable<IDepartmentEntry> {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(department);
    return this.http.patch<IDepartmentEntry>(`${environment.API_URL}/departments/${id}`, body, requestOptions);
  }

  /**
   * 
   */
  delete(id: number): Observable<any> {

    const requestOptions = {
      headers: new HttpHeaders()
    };

    return this.http.delete(`${environment.API_URL}/departments/${id}`, requestOptions);
  }
}
