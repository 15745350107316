import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'changelogIcon'
})
export class ChangelogIconPipe implements PipeTransform {
  changelogIconMap: {[label: string]: string};

  constructor() {
    this.changelogIconMap = {};
    this.changelogIconMap['attach'] = 'icon-invoice';
    this.changelogIconMap['status'] = 'icon-check-circle';
    this.changelogIconMap['create'] = 'icon-check-circle';
    this.changelogIconMap['delete'] = 'icon-close-circle';
    this.changelogIconMap['update'] = 'icon-edit';
  }

  transform(value: string) {
    return this.changelogIconMap[value] || 'icon-check-circle';
  }
}
