import { FormArray } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';

export function unmaskCurrency(rawValue: string): number {
  if ( !rawValue || rawValue === '' ) {
    return null;
  }

  let value = rawValue || '0';

  if ( typeof(value) !== 'string' ) {
    value = String(value);
  }

  value = value.replace(new RegExp('\\.', 'g'), '');
  value = value.replace(',', '.');

  return parseFloat(value);
}

export function maskCurrency(value: number|string, displaySimbol = false): string {
  if ( ! value || value === '' ) {
    return null;
  }

  const masked = new CurrencyPipe('nl-NL').transform(value, 'EUR', displaySimbol ? "symbol" : "code");
  return masked.replace('EUR', '').trim();
}

const typeCache: { [label: string]: boolean } = {};
export function type<T>(label: T | ''): T {
  if (typeCache[<string>label]) {
    throw new Error(`Action type "${label}" is not unique"`);
  }

  typeCache[<string>label] = true;

  return <T>label;
}

export function decodeToken(token: string): any {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

export function strEnum<T extends string>(o: Array<T>): {[K in T]: K} {
  return o.reduce((res, key) => {
    res[key] = key;
    return res;
  }, Object.create(null));
}

export function objHasPropertiesWithValue(obj: any, properties: string[]) {
  return obj && properties.every((value) => {
    if ( value.indexOf('.') >= 0 ) {
      const split = value.split('.');
      return objHasPropertiesWithValue(obj[split[0]], [split[1]]);
    }

    return obj.hasOwnProperty(value) && obj[value];
  });
}

export function randomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function isPresent(obj: any) {
  return obj !== undefined && obj !== null;
}

export function uniqBy(originalArray, objKey: string) {
  const trimmedArray = [];
  const values = [];
  let value;

  for (let i = 0; i < originalArray.length; i++) {
    value = originalArray[i][objKey];

    if ( values.indexOf(value) === -1 ) {
      trimmedArray.push(originalArray[i]);
      values.push(value);
    }
  }

  return trimmedArray;
}

export function slugify(value: string): string {
  return value.toString().toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^\w\-]+/g, '')
    .replace(/\-\-+/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '');
}

  /**
   * 
   */
  export function toTitleCase(value: string): string  {
    return value.toLowerCase()
    .split('-')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join('-');
  }

export function isEmptyObject(obj) {
  let name;
  for ( name in obj ) {
    if ( name ) {
      return false;
    }
  }
  return true;
}

export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function clearFormArray(formArray: FormArray) {
  while (formArray.length !== 0) {
    formArray.removeAt(0);
  }
}

export function randomId() {
  const S4 = () => (((1+Math.random())*0x10000)|0).toString(16).substring(1);
  return (S4()+S4());
}

export function formatBytes(bytes: number, decimals = 2): string {
  if ( bytes === 0 ) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const s = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

  return s + ' ' + sizes[i];
}

export function enumToIterable(e: any): {key: string, value: string}[] {
  let iterable: {key: string, value: string}[] = [];
  Object.keys(e).forEach(k => {
    iterable.push({
      key: k,
      value: e[k]
    });
  });
  return iterable;
}