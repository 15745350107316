import { Component, InjectionToken, Inject, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MdlDialogReference } from '@angular-mdl/core';
import { TranslateService } from '@ngx-translate/core';

import {OrganizationService, EventTrackerService, UserService} from '@core/services';
import * as fromRoot from '@core/reducers/index';
import * as userActions from '@core/actions/user';

export const INITIAL_STATE = new InjectionToken<string>('initialState');

@Component({
  selector: 'vs-auth-dialog',
  templateUrl: './auth-dialog.html',
  styleUrls: ['./auth-dialog.scss']
})
export class AuthDialogComponent implements OnInit, OnDestroy {
  dialogState = 'signup';
  isLoading: boolean;
  errorMsg: string;
  tosAndPp: object;
  errorMsgSignup: string;
  loginForm: FormGroup;
  signupForm: FormGroup;
  login2FAForm: FormGroup;
  auth2FAToken: string = '';
  signupSuccess: boolean;
  submitAttempt: boolean;
  private _destroyed$: Subject<void> = new Subject();

  constructor(
    @Inject(INITIAL_STATE) initialState: string,
    private actions: Actions,
    private userService: UserService,
    private dialogRef: MdlDialogReference,
    private translation: TranslateService,
    private formBuilder: FormBuilder,
    private organizationService: OrganizationService,
    private eventTrackerService: EventTrackerService,
    private store: Store<fromRoot.State>) {
    this.dialogState = initialState;
    this.tosAndPp = { tos: translation.instant('FOOTER.TERMS_LINK'), privacy: translation.instant('FOOTER.PRIVACY_LINK') };
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.login2FAForm = this.formBuilder.group({
      code: ['', Validators.required]
    });

    this.signupForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      fullname: ['', Validators.required],
      organization: ['', Validators.required]
    });

    this.store.select(fromRoot.getUserLoading)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(loading => {
        this.isLoading = loading;

        if ( loading ) {
          this.errorMsg = '';
        }
      });

    this.actions.pipe(ofType(userActions.ActionTypes.LOGIN_FAILED))
      .pipe(
        takeUntil(this._destroyed$),
        map((res: any) => res.payload)
      )
      .subscribe(error => this.errorMsg = 'Invalid e-mail and/or password');

    this.store.select(fromRoot.getAuthRequest2FA)
        .pipe(takeUntil(this._destroyed$))
        .subscribe((token: any) => {
          if (!token) {
            return;
          }

          this.auth2FAToken = token.auth_2fa_token;
          this.dialogState = 'login2fa';
          this.isLoading = false;
          this.submitAttempt = false;
        });
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  attemptLogin() {
    this.submitAttempt = true;

    if ( this.loginForm.valid ) {
      this.store.dispatch(new userActions.LoginAction(this.loginForm.value));
    }
  }

  formControlIsInvalid(form: string, key: string) {
    if ( ! this.submitAttempt ) {
      return false;
    }

    if (form === 'login2FAForm') {
      return this.login2FAForm.get(key).invalid;
    } else if (form === 'login') {
      return this.loginForm.get(key).invalid;
    } else {
      return this.signupForm.get(key).invalid;
    }
  }

  attemptSignup() {
    this.submitAttempt = true;
    this.errorMsgSignup = '';
    this.signupSuccess = false;

    if ( this.signupForm.valid ) {
      this.isLoading = true;

      this.organizationService
        .register(this.signupForm.value)
        .subscribe(res => {
          this.isLoading = false;
          this.goToLogin();
          this.signupSuccess = true;
        }, res => {
          this.isLoading = false;
          this.errorMsgSignup = 'Sorry, but this e-mail address is already registered or deemed invalid.';
        });
    }

    this.eventTrackerService.trackEvent('signup', 'button');
  }

  goToLogin() {
    this.dialogState = 'login';
    this.submitAttempt = false;
  }

  goToSignUp() {
    this.dialogState = 'signup';
    this.submitAttempt = false;
  }

  closeDialog() {
    this.dialogRef.hide();
  }

  checkAuthCode() {
    this.submitAttempt = true;

    if (this.login2FAForm.valid) {
      this.userService
          .check2FA(this.login2FAForm.value.code, this.auth2FAToken)
          .subscribe((user) => {
            this.store.dispatch(new userActions.LoginSuccessAction(user));
          }, res => {
            this.isLoading = false;
          });
    }
  }

  /**
   *
   */
  @HostListener('window:keydown', ['$event']) keyEvent(event: KeyboardEvent) {
    // escape
    if ( event.keyCode === 27 ) {
      this.closeDialog();
    }
  }
}
