import { Pipe, PipeTransform } from '@angular/core';
import { formatBytes } from '@core/util';

@Pipe({
  name: 'formatBytes'
})
export class FormatBytesPipe implements PipeTransform {
  transform(bytes: number): string {
    return formatBytes(bytes);
  }
}
