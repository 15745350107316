export interface BaseProduct {
  id?: number;
  title: string;
  category: ProductCategories;
  description: string;
  activated?: boolean;
  component?: string;
  images?: string[];
  pricing?: ProductPrice;

  // Soon to be deprecated pricing fields:
  price?: any;
  tax_percentage?: number;

  dayparts?: any;
}

export interface ProductPrice {
  excluded: number;
  included: number;
  tax_breakdown: {
    high?: number,
    low?: number
  };
  unit: ProductPriceUnit;
}

export enum ProductPriceUnit {
  PER_UNIT = 'perUnit',
  PER_DAYPART = 'perDaypart',
  PER_HOUR = 'perHour'
}

export enum ProductCategories {
  VENUE = 'venue',
  SPACE = 'space',
  FOOD = 'food',
  BEVERAGE = 'beverages',
  EQUIPMENT = 'equipment',
  PACKAGE = 'package',
  HOTEL = 'hotel',
  OTHER = 'extra',
  AMENITY = 'amenity'
}
