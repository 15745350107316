import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

export type IconTypeList = 'vs'|'material'|'no';

@Component({
  selector: 'vs-empty-state-message',
  templateUrl: './empty-state-message.html',
  styleUrls: ['./empty-state-message.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyStateMessageComponent {
  @Input() icon: string = 'info-circle';
  @Input() iconType: IconTypeList = 'vs';
  @Input() title: string;
  @Input() text: string;

  constructor() {}
}
