import { Component, Input, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { ProductPrice } from '@core/models';
import { PriceSelectorPipe } from '@shared/pipes';

@Component({
  selector: 'vs-pricing',
  templateUrl: './pricing.html',
  styleUrls: ['./pricing.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class PricingComponent {
  @Input() pricing: ProductPrice;
  @Input() showUnit: boolean = true;

  private priceSelector :PriceSelectorPipe;

  /**
   * 
   */
  get price() {
    return this.priceSelector.transform(this.pricing);
  }

  /**
   * 
   */
  get unit() {
    return this.pricing.unit;
  }

  /**
   * 
   */
  constructor() { 
    this.priceSelector = new PriceSelectorPipe();
  }
}
