import { Routes } from '@angular/router';

import { VenueResolver } from '@core/resolvers/venue';
import {
  AddVenuePageComponent,
  ForgotPasswordPageComponent,
  // HomePageComponent,
  NotFoundPageComponent,
  ResetPasswordPageComponent,
  VenueDetailPageComponent,
  SignUpVenuePageComponent,
  CompareVenuePageComponent,
  MarketingHomePageComponent,
  WhiteLabelPageComponent,
  FastBookingComponent
} from './main/containers';
import { AuthenticationGuard } from '@core/guards/authentication';
import { HasVenueManagerRoleGuard } from '@core/guards/has-venue-manager-role';
import { NotInProductionGuard } from '@core/guards/not-in-production';
import { LogoutGuard } from '@core/guards/logout';
import { TransparentNavGuard } from '@core/guards/transparent-nav.guard';
import { HubspotGuard } from '@core/guards/hubspot.guard';
import { HasOrganizationManagerRoleGuard } from '@core/guards';
import { TransparentMarketingNavGuard } from '@core/guards';

export const routes: Routes = [
  {
    path: '',
    data: { 
      name: 'home', 
      roles: ['default']
    },
    component: MarketingHomePageComponent,
    pathMatch: 'full',
    canActivate: [ TransparentMarketingNavGuard, HubspotGuard ]
  },
  {
    path: '',
    data: {
      name: 'home',
      roles: ['aristo', 'sok', 'ucb', 'bcn']
    },
    pathMatch: 'full',
    loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
    canActivate: [ HubspotGuard ]
  },
  {
    path: '', 
    redirectTo: '/venues/nbc-congrescentrum/1/details',
    data: {
      name: 'home',
      roles: ['nbc']
    }, 
    canActivate: [ TransparentNavGuard, HubspotGuard ],
    pathMatch: 'full'
  },
  {
    path: '', 
    redirectTo: '/venues/room-mate-hotels/1/details',
    data: {
      name: 'home',
      roles: ['roommate']
    }, 
    canActivate: [ TransparentNavGuard, HubspotGuard ],
    pathMatch: 'full'
  },
  {
    path: '', 
    redirectTo: '/venues/ahoy-rotterdam/1/details',
    data: {
      name: 'home',
      roles: ['ahoy']
    }, 
    canActivate: [ ],
    pathMatch: 'full'
  },
  {
    path: 'venues',
    data: {
      roles: ['default']
    }, 
    component: AddVenuePageComponent,
    canActivate: [ TransparentMarketingNavGuard, HubspotGuard ]
  },
  {
    path: 'whitelabel',
    data: {
      roles: ['default']
    }, 
    component: WhiteLabelPageComponent,
    canActivate: [ TransparentMarketingNavGuard, HubspotGuard ]
  },
  {
    path: 'fast-booking',
    data: {
      roles: ['default']
    }, 
    component: FastBookingComponent,
    canActivate: [ TransparentMarketingNavGuard, HubspotGuard ]
  },
  {
    path: 'signup-venue',
    data: {
      roles: ['default']
    }, 
    component: SignUpVenuePageComponent,
    canActivate: [ HubspotGuard ]
  },
  {
    path: 'venues/:venue_slug/:venue_id/details',
    component: VenueDetailPageComponent,
    data: {
      roles: ['nbc', 'roommate', 'ahoy', 'all']
    }, 
    canActivate: [ TransparentNavGuard, HubspotGuard ],
    resolve: {
      data: VenueResolver
    },
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'venues/:venue_slug/:venue_id/details/:share_key',
    component: VenueDetailPageComponent,
    data: {
      roles: ['all']
    }, 
    canActivate: [ TransparentNavGuard ],
    resolve: {
      data: VenueResolver
    }
  },
  {
    path: 'compare',
    data: {
      roles: ['all']
    }, 
    component: CompareVenuePageComponent
  },
  {
    path: 'forgot-password',
    data: {
      roles: ['all']
    }, 
    component: ForgotPasswordPageComponent
  },
  {
    path: 'reset-password/:token/:email',
    data: {
      roles: ['all']
    }, 
    component: ResetPasswordPageComponent,
    canActivate: [ LogoutGuard ]
  },
  {
    path: 'complete-account/:token/:email',
    data: {
      roles: ['all']
    }, 
    component: ResetPasswordPageComponent,
    canActivate: [ LogoutGuard ]
  },
  {
    path: 'search',
    data: {
      roles: ['default', 'aristo', 'ucb', 'bcn', 'sok']
    }, 
    loadChildren: () => import('./search/search.module').then(m => m.SearchModule)
  },
  {
    path: 'booking',
    data: {
      roles: ['all']
    }, 
    loadChildren: () => import('./booking/booking.module').then(m => m.BookingModule)
  },
  {
    path: 'user',
    data: {
      roles: ['all']
    }, 
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
    canActivate: [ AuthenticationGuard ]
  },
  {
    path: 'company',
    data: {
      roles: ['all']
    }, 
    loadChildren: () => import('./company/company.module').then(m => m.CompanyModule),
    canActivate: [ AuthenticationGuard, HasOrganizationManagerRoleGuard]
  },
  {
    path: 'pro',
    data: {
      roles: ['all']
    }, 
    loadChildren: () => import('./pro/pro.module').then(m => m.ProModule),
    canActivate: [ HasVenueManagerRoleGuard ],
  },
  {
    path: 'demo',
    data: {
      roles: ['default']
    }, 
    loadChildren: () => import('./demo/demo.module').then(m => m.DemoModule),
    canActivate: [ NotInProductionGuard ]
  },
  {
    path: '**',
    data: {
      roles: ['all']
    }, 
    component: NotFoundPageComponent
  }
];
