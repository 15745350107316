import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHeaders,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { SessionService } from './session.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { environment } from '@env/environment';

@Injectable()
export class VSHttpInterceptor implements HttpInterceptor {
  isAuthenticated = false;
  hasLoginlessToken = false;
  contentLanguage = 'en';
  customReq: HttpRequest<any>;

  constructor(
    private sessionService: SessionService,
    private translate: TranslateService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const jwt = localStorage.getItem('vsuite/apiToken');
    const loginless_jwt = localStorage.getItem('vsuite/refreshToken');

    this.sessionService
    .isLoggedIn$
    .subscribe(isAuthenticated => {
      this.isAuthenticated = isAuthenticated;
    });

    this.sessionService
    .hasLoginlessToken$
    .subscribe(hasLoginlessToken => {
      this.hasLoginlessToken = hasLoginlessToken;
    });

    if (request.url.indexOf(environment.TRANSLATION_FILES) === 0 ) {
      return next.handle(request);
    }

    if ( loginless_jwt && this.hasLoginlessToken ) {
      this.customReq  = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${loginless_jwt}`)
      }); 
    } 
    else if ( jwt && this.isAuthenticated ) {
      this.customReq = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${jwt}`)
      }); 
    } 
    else {
      this.customReq = request.clone({headers: request.headers});
    }

    this.customReq = this.customReq.clone({
      headers: this.customReq.headers.set('Configured-Language', `${this.translate.currentLang}`)
    });

    // pass on the modified request object
    return next
    .handle(this.customReq)
    .pipe(
    catchError(error => {
      if (error.status === 401 || error.status === 403) {
        localStorage.removeItem('vsuite/apiToken');
        localStorage.removeItem('vsuite/refreshToken');

        // Session expired will redirect the user to the login page
        if (this.isAuthenticated) {
          this.sessionService.sessionExpired();
        }

      }
      return throwError(error);
    }))
  }

}
