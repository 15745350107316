<footer [class.footer-relative]="inline">
  <div class="mdl-grid mdl-grid--no-spacing">
    <div class="mdl-cell--no-v-spacing mdl-cell--12-col footer-container">
      <div class="footer-col">
        <h4>{{ 'FOOTER.SITE' | translate }}</h4>
        <ul>
          <li><a [routerLink]="['/']">Home</a></li>
          <li><a href="{{ 'FOOTER.ABOUT_LINK' | translate }}" rel="noreferrer" target="_blank">{{ 'FOOTER.ABOUT' | translate }}</a></li>
        </ul>
      </div>
      <div class="footer-col">
        <h4>{{ 'FOOTER.LEGAL' | translate }}</h4>
        <ul>
          <li>
            <a rel="noreferrer" [href]="'FOOTER.TERMS_LINK' | translate" target="_blank">
              {{ 'NAV.FOOTER_TERMS' | translate }}
            </a>
          </li>
          <li>
            <a rel="noreferrer" [href]="'FOOTER.PRIVACY_LINK' | translate" target="_blank">
              {{ 'NAV.FOOTER_PRIVACY' | translate }}
            </a>
          </li>
        </ul>
      </div>
      <div class="footer-col">
        <h4>{{ 'FOOTER.SUPPORT' | translate }}</h4>
        <ul>
          <li><a href="https://nbccongrescentrum.nl/veelgestelde-vragen/">{{ 'FOOTER.FAQ' | translate }}</a></li>
          <li><a href="{{ 'FOOTER.CONTACT_LINK' | translate }}" rel="noreferrer" target="_blank">{{ 'FOOTER.CONTACT' | translate }}</a></li>
        </ul>
      </div>
      <div class="footer-col">
        <h4>{{ 'NAV.FOOTER_FOLLOW_US' | translate }}</h4>
        <a class="social-icon social-icon--fb" rel="noreferrer" href="https://www.facebook.com/NBCcongrescentrum" target="_blank" aria-label="Facebook">
          <span class="icon icon-fb_square"></span>
        </a>
        <a class="social-icon social-icon--li" rel="noreferrer" href="https://www.linkedin.com/company/2736261" target="_blank" aria-label="Linkedin">
          <span class="icon icon-linkedin"></span>
        </a>
        <a class="social-icon social-icon--tw" rel="noreferrer" href="https://twitter.com/NBCongrescenter" target="_blank" aria-label="Twitter">
          <span class="icon icon-twitter_square"></span>
        </a>
      </div>
    </div>
  </div>
  <div class="mdl-grid mdl-grid--no-spacing">
    <div class="mdl-cell--no-v-spacing mdl-cell--12-col">
      <hr />
      <p class="copy-right">&copy; {{ 'FOOTER.COPY_RIGHT' | translate }} {{ appVersion }} <vs-language-switcher *ngIf="!(isLoggedIn$ | async)"></vs-language-switcher></p>
    </div>
  </div>
</footer>
