import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';
import { Store } from '@ngrx/store';
import { withLatestFrom } from 'rxjs/operators';

import { VenueSwitchService, ISwitchableVenue, SessionService } from '@core/services';
import * as fromRoot from '@core/reducers';
import { User } from '@core/models';

@Component({
  selector: 'vs-venue-switcher',
  templateUrl: './venue-switcher.html',
  styleUrls: ['./venue-switcher.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VenueSwitcherComponent implements OnInit {
  userVenues: ISwitchableVenue[] = [];
  filteredVenues: ISwitchableVenue[] = [];
  selectedVenueId: number;
  isVisible = 1;
  storedVenueId: any;

  constructor(
    private store: Store<fromRoot.State>,
    private sessionService: SessionService,
    private venueSwitchService: VenueSwitchService,
    private cref: ChangeDetectorRef) { }

  ngOnInit() {
    this.getStoredVenueId();

    this.store
      .select(fromRoot.getUserVenues)
      .pipe(withLatestFrom(this.sessionService.user$))
      .subscribe((results: [ISwitchableVenue[], User]) => {
        const user = results[1];

        this.userVenues = results[0] || [];
        this.filteredVenues = results[0] || [];

        this.isVisible = this.userVenues.length;
        const activeVenueId = this.venueSwitchService.getActiveVenueId();

        if ( this.userVenues.length > 0 && user.venue_id ) {

          let lastActiveVenue = this.userVenues.filter((userVenue) => this.storedVenueId === userVenue.id);

          if ( this.storedVenueId && lastActiveVenue[0].id === this.storedVenueId ) {
            this.selectedVenueId = this.storedVenueId;
          } else if ( activeVenueId !== undefined ) {
            this.selectedVenueId = activeVenueId;
          } else {
            this.selectedVenueId = user.venue_id;
          }

          this.setStoredVenueId();
          this.venueSwitchService.setActiveVenueId(this.selectedVenueId);
        }

        this.cref.markForCheck();
      });

      this.venueSwitchService.onChange.subscribe(
        (venueId: number) => {
          // Only check when is different, to avoid circular reference
          if (this.selectedVenueId == venueId) {
            return;
          }
          this.selectedVenueId = venueId;
          this.cref.markForCheck();
        }
      );
  }

  /**
   * 
   */
  updateSelectedVenue(index: number) {
    this.setStoredVenueId();
    this.venueSwitchService.switchActiveVenue(this.userVenues.find(venue => venue.id === this.selectedVenueId));
    this.cref.markForCheck();
  }

  /**
   * 
   */
  private getStoredVenueId() {
    this.storedVenueId = +sessionStorage.getItem('lastActiveVenueId');
  }

  /**
   * 
   */
  private setStoredVenueId() {
    sessionStorage.setItem("lastActiveVenueId", this.selectedVenueId.toString());
  }
}
