import {
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from "@angular/core";
import {Observable, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

import {CompareVenuesFacade} from "@core/facades/compare-venues.facade";
import {Venue} from "@core/models";

@Component({
  selector: 'vs-compare-venue-checkbox',
  template: `
    <mdl-checkbox [disabled]="disabled" [value]="compareActive" (click)="toggleCompare($event)">
      {{ 'COMPARE.CB_LABEL' | translate }} <ng-container *ngIf="selected">( {{ selected }} / {{ maxItems }} )</ng-container>
    </mdl-checkbox>
    <a *ngIf="showLink && compareActive" [routerLink]="['/compare']">{{ 'COMPARE.CHECK' | translate }}</a>
    <a *ngIf="disabled" [routerLink]="['/compare']">{{ 'COMPARE.VIEW_ITEMS' | translate }}</a>
  `,
  styleUrls: ['./compare-venue-checkbox.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompareVenueCheckboxComponent implements OnInit, OnDestroy {
  @Input() venue: Venue;
  showLink: boolean;
  compareActive: boolean;
  disabled: boolean;
  selected: number = 1;
  maxItems: number = CompareVenuesFacade.MAX_COMPARE;
  disabled$: Observable<boolean>;
  private destroyed$ = new Subject<void>();

  constructor(
    private compareVenueFacade: CompareVenuesFacade,
    private cref: ChangeDetectorRef) { }

  ngOnInit() {
    this.compareVenueFacade
      .isDisabledFor$(this.venue.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(disabled => {
        this.disabled = disabled;
        this.cref.markForCheck();
      });

    this.compareVenueFacade
      .venues$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(venues => {
        this.showLink = venues.size > 1;
        this.selected = venues.size;
        this.cref.markForCheck();
      });

    this.compareVenueFacade
      .selected$(this.venue.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(selected => {
        this.compareActive = selected;
        this.cref.markForCheck();
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  toggleCompare($event) {
    $event.stopPropagation();

    if (this.disabled) {
        return false;
    }

    this.compareActive = (this.compareActive) ? false : true;

    if (this.compareActive) {
      this.compareVenueFacade.addVenue(this.venue);
    } else {
      this.compareVenueFacade.removeVenue(this.venue);
    }

    return false;
  }
}
