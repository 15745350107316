<div class="mdl-dialog_scroll-area" dirIsolateScrolling>
  <div class="space-carousel">
    <i (click)="close()" class="close-modal material-icons">close</i>
    <img [src]="visibleImageUrl| imageRatio:imageTransform:imageWidth" />
    <ng-container *ngIf="displayCarousel">
      <div class="slider-nav slider-nav--left">
        <button (click)="prevItem($event)">
          <i class="material-icons">chevron_left</i>
        </button>
      </div>
      <div class="slider-nav slider-nav--right">
        <button (click)="nextItem($event)">
          <i class="material-icons">chevron_right</i>
        </button>
      </div>
    </ng-container>
  </div>
  <section class="space-details">
    <div class="space-info">
      <h3>{{ space.name }}</h3>
      <vs-read-more [content]="space.description" [maxLength]="170"></vs-read-more>
    </div>
    <div class="space-price">
      <h4 class="price" *ngIf="space.from_price.price">
        {{ space.from_price.price | currencyCents:'EUR':'symbol' }}
        <small>{{ 'COMMON.PRICES_FROM.'+space.from_price.type | translate }}</small>
      </h4>
      <h4 class="price" *ngIf="!space.from_price.price">{{ 'COMMON.PRICE_ON_REQUEST' | translate }}</h4>
      <button
        (click)="navigateToCheckout()"
        mdl-button
        mdl-button-type="raised"
        mdl-colored="accent">{{ (space.from_price.price ? 'VENUE_PAGE.INSTANT_PRICE' : 'COMMON.PRICE_ON_REQUEST') | translate }}</button>
    </div>
  </section>
  <section class="space-capacity" *ngIf="space.setups && space.setups.length > 0">
    <h4>{{ 'COMMON.CAPACITY' | translate }}</h4>
    <div class="space-setups">
      <vs-space-setups [showLabel]="true" [setups]="space.setups"></vs-space-setups>
    </div>
  </section>
  <section class="space-equipment">
    <h4>{{ 'VENUE_PAGE.INCLUDED' | translate }}</h4>
    <div class="included-equipment-container" *ngIf="space.equipment.length > 0">
      <div class="included-equipment" *ngFor="let equipmentItem of space.equipment">
        <span class="icon" [ngClass]="equipmentItem.title | equipmentIcon"></span> {{ 'EQUIPMENT.'+equipmentItem.title.toUpperCase() | translate }}
      </div>
    </div>
    <p *ngIf="space.equipment.length === 0">
      <i>{{ 'VENUE_PAGE.NO_EQUIPMENT' | translate }}</i>
    </p>
  </section>
</div>
