import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ViewChild, HostListener, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { take, takeUntil, distinctUntilChanged, debounceTime, switchMap } from 'rxjs/operators';
import { Observable, timer, Subject } from 'rxjs';
import { ShufflePipe } from 'ngx-pipes';

import { VenueService, SearchCriteriaService, BookingService } from '@core/services';
import { NgSelectComponent } from '@ng-select/ng-select';
import { EventTypes } from '@core/spaces.enum';

const MAIN_HEADER_MEDIA = [
  'https://res.cloudinary.com/venuesuite-com/image/upload/w_1200,q_60,ar_16:9/ni5qojx8v6d8ychsfiem.jpg',
  'https://res.cloudinary.com/venuesuite-com/image/upload/w_1280,q_60,ar_16:9/j25qpduxtsb4gpswabe1.jpg',
  'https://res.cloudinary.com/venuesuite-com/image/upload/w_1280,q_60,ar_16:9/tcvnvsdcqghceqsxywoy.jpg',
  'https://res.cloudinary.com/venuesuite-com/image/upload/w_1280,q_60,ar_16:9/h88ha5w0xl5fjhefocrq.jpg',
  'https://res.cloudinary.com/venuesuite-com/image/upload/w_1280,q_60,ar_16:9/vegxvootgg8ktlgkmulo.jpg',
  'https://res.cloudinary.com/venuesuite-com/image/upload/w_1280,q_60,ar_16:9/fren7kamktvoosrt317j.jpg'
];

const POPULAIR_CITIES = [
  { name: 'Amsterdam',  photo: 'https://res.cloudinary.com/venuesuite-com/image/upload/w_750,h_500,c_thumb/v1500857784/01-amsterdam_mzw9it.jpg'},
  { name: 'Utrecht',    photo: 'https://res.cloudinary.com/venuesuite-com/image/upload/w_750,h_500,c_thumb/v1500857787/02-utrecht_hpz0gj.jpg' },
  { name: 'Den Haag',   photo: 'https://res.cloudinary.com/venuesuite-com/image/upload/w_750,h_500,c_thumb/v1500857783/03-denhaag_c8ou46.jpg' },
  { name: 'Rotterdam',  photo: 'https://res.cloudinary.com/venuesuite-com/image/upload/w_750,h_500,c_thumb/v1500857784/04-rotterdam_e33wc5.jpg' },
  { name: 'Eindhoven',  photo: 'https://res.cloudinary.com/venuesuite-com/image/upload/w_750,h_500,c_thumb/v1500857785/05-eindhoven_yzthgl.jpg' },
  { name: 'Amersfoort', photo: 'https://res.cloudinary.com/venuesuite-com/image/upload/w_750,h_500,c_thumb/v1519114722/Amersfoort_nblht0.jpg' }
]

enum KeyCode {
    Tab = 9,
    Enter = 13,
    Esc = 27,
    Space = 32,
    ArrowUp = 38,
    ArrowDown = 40,
    Backspace = 8
}

@Component({
  selector: 'vs-home-page',
  templateUrl: './home-page.html',
  styleUrls: ['./home-page.scss'],
  providers: [ShufflePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomePageComponent implements OnInit, OnDestroy {
  @ViewChild('eventTypeControl', {static: false}) eventTypeControl: NgSelectComponent;
  eventTypeOptions = Object.values(EventTypes);
  searchForm: FormGroup;
  autocomplete$: Observable<any[]>;
  autocompleteInput$ = new Subject<string>();
  autocompleteLoading: boolean;
  shuffleCities: any;
  cities = POPULAIR_CITIES;
  headerImages = MAIN_HEADER_MEDIA;
  dialogConfig = {
    classes: 'mdl-dialog_no-padding mdl-dialog_video',
    clickOutsideToClose: true,
    isModal:true,
    animate: false,
    enterTransitionDuration: 0,
    leaveTransitionDuration: 0
  };

  // Progressive loading of content blocks
  showHowItWorks = false;
  showContactUs = false;
  showCities = false;
  showMission = false;
  showForVenues = false;
  showGetStarted = false;

  searchTerm: string = "";
  searchResults: any[] = [];
  isOpen: boolean = false;
  query: FormControl = new FormControl();

  private _destroyed$: Subject<void> = new Subject();

  /**
   * 
   */
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private venueService: VenueService,
    private searchCriteriaService: SearchCriteriaService,
    private cref: ChangeDetectorRef,
    private shuffleCityArray: ShufflePipe) { }

  /**
   * 
   */
  ngOnInit() {
    this.searchForm = this.formBuilder.group({
      q: [null, Validators.required],
      query: null,
      eventType: [null, Validators.required]
    });

    this.query
      .valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this._destroyed$),
        switchMap(term => {
          this.searchTerm = term;
          return this.venueService.autocomplete(term, 10);
        })
      ).subscribe(
        results => this.searchItems(results),
        err => console.log(err),
        () => console.log("complete")
    );

    this.shuffleCities = this.shuffleCityArray.transform(this.cities);
  }

  /**
   * Implemented OnDestroy interface
   */
  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  /**
   * 
   */
  onSelect($event) {
    $event.preventDefault();
    if ($event.target.dataset.key) {
      this.router.navigate([VenueService.buildVenueLink({id:$event.target.dataset.key, name:$event.target.innerText})]);
    } else {
      const search = $event.target.innerText.trim();
      this.searchForm.patchValue({q: search});
    }
    this.isOpen = false;
    this.eventTypeControl.open();
  }

  /**
   * 
   */
  onBlur() {
    timer(200)
      .pipe(take(1))
      .pipe(takeUntil(this._destroyed$))
      .subscribe(() => {
        this.isOpen = false;
        this.cref.detectChanges();
      });
  }

  /**
   * 
   */
  private searchItems(results: any) {
    this.searchResults = [];
    for (let key in results) {
      this.searchResults.push(results[key]);
    }
    this.isOpen = !!this.searchResults.length;
    this.cref.markForCheck();
  }

  /**
   * 
   */
  @HostListener('keydown', ['$event']) handleKeyDown($event: KeyboardEvent) {
    if (KeyCode[$event.which]) {
      switch ($event.which) {
        case KeyCode.Enter:
          this.searchForm.patchValue({q: this.query.value});
          this.isOpen = false;
          this.eventTypeControl.open();
          break;
        case KeyCode.Esc:
          this.isOpen = false;
          break;
      }
      this.cref.detectChanges();
    }
  }

  /**
   * 
   */
  eventTypeControlChanged($event) {
    this.navigateToSearch(null);
  }

  /**
   * 
   */
  selectCity(city: string) {
    this.searchCriteriaService.update({q: city});
    this.router.navigate(['/search']);
  }

  /**
   * 
   */
  navigateToSearch($event) {
    const q = this.searchForm.get('q').value || null;
    const eventType = this.searchForm.get('eventType').value || null;

    const term = q || this.searchTerm;
    
    timer(200).pipe(take(1)).subscribe(() => {
      let routeCommands = ['/search'];
      let routeExtras = {};

      if ( term ) {
        routeCommands.push(term);
      }

      if ( eventType ) {
        routeExtras['queryParams'] = {
          'eventType': eventType
        };
      }

      this.router.navigate(routeCommands, routeExtras);
    });
  }
}
