import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { Coupon } from '../models/coupon';
import { SessionService } from './session.service';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PricingCouponService {

  static readonly PRICE_EXCLUDE_DAY = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"]

  venueId: number = 0;

  constructor(private sessionService: SessionService, private http: HttpClient) {
    this.sessionService.user$.subscribe(user => {
      this.venueId = (user !== null ? user.venue_id : null);
    });
  }

  /**
   * 
   */
  setVenueId(val: number) {
    this.venueId = val;
  }

  /**
   * 
   */
  getVenueId(venueId?: number) {
    return venueId ? venueId : this.venueId;
  }

  /**
   * Get All
   */
  getAll(venueId?: number): Observable<Coupon[]> {
    const auxVenueId = this.getVenueId(venueId);
    let requestUrl = `${environment.API_URL}/venues/${auxVenueId}/coupon`;
    return this.http.get<Coupon[]>(requestUrl);
  }
}