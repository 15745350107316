import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { Store } from '@ngrx/store';

import { LogoutAction } from '../actions/user';
import * as fromRoot from '../reducers';
import { User } from '../models/user';

/**
 * Expose the logged in user from the state as observables
 */
@Injectable()
export class SessionService {
  isLoggedIn$: Observable<boolean>;
  hasLoginlessToken$: Observable<boolean>;
  user$: Observable<User>;
  error$: Observable<any>;
  sessionExpired$: Observable<any>;
  private _user: BehaviorSubject<User|null>;
  private _isLoggedIn: BehaviorSubject<boolean>;
  private _hasLoginlessToken: BehaviorSubject<boolean>;
  private _sessionExpired: Subject<any>;
  private _error: Subject<any>;

  constructor(private store: Store<fromRoot.State>) {
    this._user = new BehaviorSubject<User>(null);
    this._isLoggedIn = new BehaviorSubject(false);
    this._hasLoginlessToken = new BehaviorSubject(false);
    this._sessionExpired = new Subject();
    this._error = new Subject();
    this.isLoggedIn$ = this._isLoggedIn.asObservable();
    this.hasLoginlessToken$ = this._hasLoginlessToken.asObservable();
    this.user$ = this._user.asObservable();
    this.error$ = this._error.asObservable();
    this.sessionExpired$ = this._sessionExpired.asObservable();

    this.store.select(fromRoot.getUserIsLoggedIn).subscribe(isLoggedIn => {
      this._isLoggedIn.next(isLoggedIn);
    });
    this.store.select(fromRoot.getUserHasLoginlessToken).subscribe(hasLoginlessToken => {
      this._hasLoginlessToken.next(hasLoginlessToken);
    });
    this.store.select(fromRoot.getUser).subscribe(user => this._user.next(user));
  }

  sessionExpired() {
    this.store.dispatch(new LogoutAction());
    this._sessionExpired.next();
  }

  serverError(error) {
    this._error.next(error);
  }
}
