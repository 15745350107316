import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { Booking, BookingListItem } from './../models';
import { SessionService } from './session.service';
import { INewBooking } from '../../booking/models/booking-form-model';
import { BookingStatus } from '../booking-status.enum';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()
export class BookingService {
  http: HttpClient;
  venueId: number;

  constructor(http: HttpClient, private sessionService: SessionService) {
    this.http = http;

    this.sessionService.user$.subscribe(user => {
      this.venueId = (user !== null ? user.venue_id : null);
    });
  }

  setVenueId(val: number) {
    this.venueId = val;
  }

  /**
   * Find all bookings for the currently authenticated user
   */
  findAll(filterBy?: BookingStatus[]): Observable<BookingListItem[]> {
    let resourceUrl = '';

    if ( ! this.venueId ) {
      resourceUrl = environment.API_URL + '/bookings';
    } else {
      resourceUrl = `${environment.API_URL}/venues/${this.venueId}/bookings`
    }

    let urlSearchParams = new HttpParams();

    if ( filterBy instanceof Array ) {
      urlSearchParams = urlSearchParams.set('status', filterBy.join(','));
    }

    const options = {
      params: urlSearchParams
    };

    return this.http.get<BookingListItem[]>(resourceUrl, options);
  }

  /**
   * Find bookings by searchkey for the currently authenticated user
   */
  findBookings(searchKey?: any, status?: any): Observable<BookingListItem[]> {
    let resourceUrl = '';
    if ( status === 'all' || status === 'active' ){
      let statusList = [BookingStatus.REQUEST, BookingStatus.REQUIRED, BookingStatus.RESERVATION, BookingStatus.BOOKED];
      if ( statusList instanceof Array ) {
        status = statusList.join(',');
      }
    }

    if ( status === BookingStatus.REQUEST){
      let statusList = [BookingStatus.REQUEST, BookingStatus.REQUIRED];
      status = statusList.join(',');
    }

    if ( ! this.venueId ) {
      resourceUrl = searchKey ? `${environment.API_URL}/bookings?q=${searchKey}&status=${status}` : `${environment.API_URL}/bookings?status=${status}`;
    } else {
      resourceUrl = searchKey ? `${environment.API_URL}/venues/${this.venueId}/bookings?status=${status}&q=${searchKey}` : `${environment.API_URL}/venues/${this.venueId}/bookings?status=${status}`;
    }
  
    return this.http.get<BookingListItem[]>(resourceUrl);
  }
  

  /**
   * Get all active bookings
   */
  findActiveBookings(): Observable<BookingListItem[]> {
    return this.findAll([BookingStatus.REQUEST, BookingStatus.REQUIRED, BookingStatus.RESERVATION, BookingStatus.BOOKED]);
  }

  /**
   * Get bookings that are cancelled
   */
  findCancelledBookings(): Observable<BookingListItem[]> {
    return this.findAll([BookingStatus.CANCELLED]);
  }

  /**
   * Get all the bookings that are done
   */
  findDoneBookings(): Observable<BookingListItem[]> {
    return this.findAll([BookingStatus.DONE]);
  }

  /**
   * Get specific booking by id
   *
   * @param bookingId number
   * @param include string
   */
  findById(bookingId: number, include: string = ''): Observable<Booking> {
    let requestOptions = {params: new HttpParams()};
    if (include) {
      requestOptions.params = requestOptions.params.set('include', include);
    }

    return this.http.get<Booking>(`${environment.API_URL}/bookings/${bookingId}`, requestOptions);
  }

  /**
   * Get the payments of booking, this will only return the created payments that can
   * either be paid or are already paid. Payment dates (aka future payments) are
   * required through the invoice endpoint
   *
   * @param bookingId
   */
  getBookingPayments(bookingId: number): Observable<any> {
    return this.http.get(`${environment.API_URL}/bookings/${bookingId}/payments`);
  }

  /**
   * Get the price breakdown based on the provided booking
   *
   * @param booking
   */
  getInvoice(booking: any): Observable<any> {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');

    const body = JSON.stringify(booking);

    return this.http.post(`${environment.API_URL}/invoice`, body, requestOptions);
  }

  /**
   * Create a new booking
   *
   * @param booking
   */
  createBooking(booking: INewBooking, username: string = ''): Observable<any> {
    let requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    if (username.length) {
      requestOptions.headers = requestOptions.headers.append("Authorization", "Basic " + btoa(username + ":"));
    }

    if ( booking.repeats instanceof Array && booking.repeats.length > 1 ) {
      const body = JSON.stringify(booking);
      if (booking.isDirect) {
        return this.http.post(`${environment.API_URL}/bookings/repeat-direct`, body, requestOptions);
      } else {
        return this.http.post(`${environment.API_URL}/bookings/repeat`, body, requestOptions);
      }
    }

    const body = JSON.stringify(booking);
    const url  = booking.isDirect ? `${environment.API_URL}/bookings/direct` : `${environment.API_URL}/bookings`;

    return this.http.post(url, body, requestOptions);
  }

  /**
   * Get instances of a group booking
   *
   * @param bookingId
   */
  getGroupBookingInstances(bookingId: number): Observable<any> {
    return this.http.get(`${environment.API_URL}/booking_groups/${bookingId}/bookings`);
  }

  /**
   * Updates the booking details
   *
   * @param booking
   */
  updateBooking(booking: Booking, include: string = ''): Observable<{data: Booking}> {
    const requestOptions = {headers: new HttpHeaders(), params: new HttpParams()};
    requestOptions.headers.set('Content-Type', 'application/json');

    if (include) {
      requestOptions.params = requestOptions.params.set('include', include);
    }
    const body = JSON.stringify(booking);

    return this.http.put<{data: Booking}>(`${environment.API_URL}/bookings/${booking.id}`, body, requestOptions);
  }

  /**
   * Cancel a booking
   *
   * @param bookingId
   */
  cancel(bookingId: number): Observable<any> {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');

    return this.http
      .delete(`${environment.API_URL}/bookings/${bookingId}`, requestOptions);
  }

  /**
   * Change all the booking status of a given group
   *
   * @param booking
   * @param action The action that will make the status change
   */
  changeGroupStatus(booking: Booking, action: string): Observable<any> {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');

    const body = JSON.stringify({
      action: action
    });

    return this.http
        .patch(`${environment.API_URL}/booking_groups/${booking.group_id}/bookings`, body, requestOptions);
  }

  /**
   * Approves the booking with the option to approve on the alternative
   * date and the option to allow the booker/client to pay afterwards. This
   * will not select pay afterwards but will only give the client the possibility
   *
   * @param bookingId
   * @param alternative
   * @param allowPayAfterwards
   */
  acceptBookingRequest(bookingId: number) {

    const action = 'approve';
    const payload = null;

    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify({
      action: action,
      payload: payload
    });

    return this.http.patch(`${environment.API_URL}/bookings/${bookingId}`, body, requestOptions);
  }

  /**
   * Moves a confirmed booking to done
   *
   * @param bookingId
   */
  moveBookingToDone(bookingId: number) {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify({
      action: 'done'
    });

    return this.http.patch(`${environment.API_URL}/bookings/${bookingId}`, body, requestOptions);
  }

  /**
   * Decline a booking with an optional alternative suggestion
   *
   * @param bookingId
   * @param alternativeSuggestion
   */
  declineBookingRequest(bookingId: number,
    alternativeSuggestion: {start: string, end: string, space_id: number} = null) {

      const requestOptions = {headers: new HttpHeaders()};
      requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify({
      action: 'decline',
      payload: alternativeSuggestion
    });
    return this.http.patch(`${environment.API_URL}/bookings/${bookingId}`, body, requestOptions);
  }

  /**
   * Redeem a coupon
   *
   * @param venueId
   * @param couponCode
   */
  redeemCouponRequest(venueId: number, couponCode: string) {

    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify({
      coupon_code: couponCode
    });
    return this.http.post(`${environment.API_URL}/venues/${venueId}/coupon/redeem`, body, requestOptions);
  }

  /**
   * Get specific booking by id
   *
   * @param bookingId
   * @param format can be pdf or html
   */
  getQuote(bookingId: number, format: string = 'pdf'): Observable<Blob> {
    let requestParams = new HttpParams();
    requestParams = requestParams.set('format', format);

    return this.http.get<Blob>(`${environment.API_URL}/bookings/${bookingId}/quote`, {
      params: requestParams,
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }
}
