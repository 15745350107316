import { Directive, OnChanges, ElementRef, Renderer2, Input } from '@angular/core';
import { interval } from 'rxjs';
import { AnimationPlayer, AnimationMetadata, style, animate, AnimationBuilder } from '@angular/animations';

@Directive({
  selector: '[dirRotatingTxt]'
})
export class RotatingTxtDirective implements OnChanges {
  @Input() dirRotatingTxt: string;
  words: string[];
  index: number;
  animationPlayer: AnimationPlayer;

  constructor(
    private elementRef: ElementRef,
    private builder: AnimationBuilder,
    private renderer: Renderer2) {

  }

  ngOnChanges(changes) {
    if ( changes.dirRotatingTxt && this.dirRotatingTxt.length > 0 ) {
      this.words = this.dirRotatingTxt.split(',');
      this.index = 0;
      this.animate();
      this.start();
    }
  }

  animate() {
    if ( this.animationPlayer ) {
      this.animationPlayer.destroy();
    }

    // Enter element
    this.renderer.setProperty(this.elementRef.nativeElement, 'innerText', this.words[this.index]);
    const factory = this.builder.build(this.fadeIn());
    const player = factory.create(this.elementRef.nativeElement);
    player.play();

    this.index = this.index + 1 >= this.words.length ? 0 : this.index + 1;
  }

  start() {
    interval(4000).subscribe(() => this.animate());
  }

  private fadeIn(): AnimationMetadata[] {
    return [
      style({ opacity: 0 }),
      animate('1s ease-in', style({ opacity: 1 })),
    ];
  }

  private fadeOut(): AnimationMetadata[] {
    return [
      style({ opacity: '*' }),
      animate('900ms ease-in', style({ opacity: 0 })),
    ];
  }
}
