<div class="signup">
  <div class="mdl-grid mdl-grid--no-spacing">
    <div class="mdl-cell--no-v-spacing mdl-cell--12-col">
      <div class="signup-container">
        <div class="signup-container-title">
          <h1 class="display-2">{{ 'LIST_VENUE.TITLE' | translate }}</h1>
          <p class="subtitle">{{ 'LIST_VENUE.TAGLINE' | translate }}</p>    
        </div>
        <div class="signup-container-form">
          <vs-alert
          *ngIf="signUpComplete"
          type="success"
          [text]="'LIST_VENUE.SUCCESS' | translate"></vs-alert>
        <vs-alert
          *ngIf="signUpFailed"
          type="error"
          [text]="signUpError"></vs-alert>
        <form [formGroup]="signUpForm" class="form-signup-venue">
          <vs-formfield [class.is-invalid]="formControlIsInvalid('fullname')">
            <label>{{ 'LIST_VENUE.INPUT_NAME' | translate }}</label>
            <input formControlName="fullname" type="text" />
            <span dirErrorMsg>Please enter your name</span>
          </vs-formfield>
          <vs-formfield [class.is-invalid]="formControlIsInvalid('username')">
            <label>{{ 'LIST_VENUE.INPUT_EMAIL' | translate }}</label>
            <input formControlName="username" type="text" />
            <span dirErrorMsg>Please enter a valid e-mail address</span>
          </vs-formfield>
          <vs-formfield [class.is-invalid]="formControlIsInvalid('venue')">
            <label>{{ 'LIST_VENUE.INPUT_VENUE' | translate }}</label>
            <input formControlName="venue" type="text" />
            <span dirErrorMsg>Please enter the name of your venue</span>
          </vs-formfield>
  
          <div class="jumbo-actions">
            <button
            (click)="signUp($event)"
            [disabled]="isSubmitting"
            type="button"
            class="mdl-button--xl" 
            mdl-button 
            mdl-button-type="raised" 
            mdl-colored="accent">{{ 'ADD_VENUE.SIGNUP_BTN' | translate }}</button>
            <a href="https://meetings.hubspot.com/niels53" rel="noreferrer" target="_blank" class="mdl-button--xl btn-schedule-demo" mdl-button mdl-button-type="raised" mdl-colored="primary">
              {{ 'HOME.DEMO' | translate }}
            </a>
          </div>
        </form>
        </div>
      </div>
    </div>
  </div>
</div>
