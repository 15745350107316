<div #filler class="nav-scroll-filler"></div>
<nav
  #navElement
  class="nav-primary"
  [class.nav-primary--transparent]="transparentNav">

  <div class="nav-primary__inner">

    <div class="nav-title">
      <a class="nav-link--logo" aria-label="Home" routerLink="/">
        <img class="nav-link--logo" src={{mobileLogo}} alt="logo" class="mobile"/>
        <img [src]="deskTopLogo" alt="logo" class="desktop"/>
        <img [src]="transparentLogo " alt="logo" class="transparent"/>
      </a>
    </div>

    <div class="nav-primary__mobile" (click)="toggleMobileMenu($event)">
      <i class="material-icons">menu</i>
    </div>

    <div
      class="nav-links"
      [class.nav-links--show]="showMobileMenu">

      <ng-container *ngIf="isAuthenticated">
        <div class="layout-spacer"></div>
        <ng-container *ngIf="isOrganization">
          <a class="nav-link"
          routerLinkActive="nav-link--active"
          *ngFor="let navItem of navItems | slice:0:navItems.length-1"
          [routerLink]="navItem.link">{{ navItem.label | translate }}</a>
        </ng-container>
        <ng-container *ngIf="!isOrganization">
          <a class="nav-link"
          routerLinkActive="nav-link--active"
          *ngFor="let navItem of navItems"
          [routerLink]="navItem.link">{{ navItem.label | translate }}</a>
        </ng-container>
        <vs-venue-switcher></vs-venue-switcher>
        <a class="nav-link nav-link--profile" #btnProfile (click)="vsDropdown.toggle($event, btnProfile)">
          <img alt="Profile picture" [src]="userAvatar | imageTransform:45:45:false:true" />
          <div class="greeting"><b>{{ userName }}</b></div>
          <i class="material-icons">arrow_drop_down</i>
        </a>
        <vs-dropdown #vsDropdown>
          <a class="dropdown-item has-icon" routerLink="/user">
            <i class="icon icon-account"></i>
            <div>{{ 'NAV.PROFILE_TITLE' | translate }}<span>{{ 'NAV.PROFILE_DESC' | translate }}</span></div>
          </a>
          <a *ngIf="isOrganizationManager" class="dropdown-item has-icon" routerLink="/company">
            <i class="icon icon-venue"></i>
            <div>{{ 'NAV.COMPANY_TITLE' | translate }}<span>{{ 'NAV.COMPANY_DESC' | translate }}</span></div>
          </a>
          <a class="dropdown-item has-icon dropdown-item--divider" (click)="signOut.emit()">
            <i class="icon icon-close-circle"></i>
            <div>{{ 'NAV.SIGN_OUT' | translate }}</div>
          </a>
        </vs-dropdown>
      </ng-container>

      <ng-container *ngIf="!isAuthenticated">
        <a class="nav-link" (click)="showAuthDialog($event, 'signup')">{{ 'NAV.SIGN_UP' | translate }}</a>
        <a class="nav-link" (click)="showAuthDialog($event, 'login')">{{ 'NAV.LOG_IN' | translate }}</a>
      </ng-container>
    </div>
  </div>
</nav>
