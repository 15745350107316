import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'vs-data-actions',
  templateUrl: './data-actions.html',
  styleUrls: ['./data-actions.scss']
})
export class DataActionsComponent {
  @Input() hasResults: boolean;
  @Input() label: string;
  @Input() newProductCategories: Array<{value: string, label: string}>;
  @Input() disableActions: boolean;
  @Input() showDeleteAction = true;
  @Input() showDuplicateAction = true;
  @Output() readonly onAdd: EventEmitter<string> = new EventEmitter();
  @Output() readonly onDelete: EventEmitter<any> = new EventEmitter();
  @Output() readonly onDuplicate: EventEmitter<any> = new EventEmitter();

  showProductCategoryTooltip: boolean;

  constructor() {
    this.label = 'product';
  }

  onAddClick(event: MouseEvent) {
    if ( this.showProductCategoryTooltip ) {
      this.showProductCategoryTooltip = false;
      return;
    }

    if ( this.newProductCategories && this.newProductCategories.length > 0 ) {
      this.showProductCategoryTooltip = true;
    } else {
      this.onAdd.emit('');
    }
  }

  confirmAdd(selectedCategory: string) {
    this.showProductCategoryTooltip = false;
    this.onAdd.emit(selectedCategory);
  }
}
