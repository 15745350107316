import {AfterContentInit, Directive, ElementRef, Input, OnDestroy, Renderer2} from '@angular/core';

/**
 * ProgImgLoaderDirective loads a second in the background and replaces the main image
 * when the second one is loaded
 */
@Directive({
  selector: '[dirProgImgLoad]'
})
export class ProgImgLoaderDirective implements AfterContentInit, OnDestroy {
  @Input() dirProgImgLoad: string;
  cancelOnLoad: Function;
  nativeElement: HTMLElement;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  ngAfterContentInit() {
    this.nativeElement = this.elementRef.nativeElement;
    this.renderer.addClass(this.nativeElement, 'vs-prog-img');
    this.renderer.addClass(this.nativeElement, 'vs-img-not-loaded');
    this.registerEvents();
  }

  ngOnDestroy() {
    if ( this.cancelOnLoad ) {
      this.cancelOnLoad();
    }
  }

  loadLargeImg() {
    let largeImg = new Image();
    largeImg.src = this.dirProgImgLoad;
    largeImg.onload = () => {
      this.renderer.removeClass(this.nativeElement, 'vs-img-not-loaded');
      this.renderer.addClass(this.nativeElement, 'vs-img-loaded');
      this.renderer.setAttribute(this.nativeElement, 'src', this.dirProgImgLoad);
    }
  }

  registerEvents() {
    this.cancelOnLoad = this.renderer.listen(this.nativeElement, 'load', () => {
      this.loadLargeImg();
    });
  }
}
