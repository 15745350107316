export enum BookingStatus {

  /**
   * The booking has been sent to the venue, and the
   * venue has to approve the request.
   */
  REQUEST = 'request',

  /**
   * The booking has been sent to the venue, and the
   * venue has to approve the request but not extra
   * confirmation from the booker takes place.
   */
  REQUIRED = 'required',

  /**
   * The booking has been approved by the venue. But not
   * yet by the organization
   */
  RESERVATION = 'reservation',

  /**
   * Both the organization and the venue have confirmed
   * the booking.
   */
  BOOKED = 'booked',

  /**
   * The booking was cancelled by either the venue
   * or the organization
   */
  CANCELLED = 'cancelled',

  /**
   * The booking has been manually set to done
   */
  DONE = 'done'
}
