import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WidgetSettings } from '../models';

import { environment } from '@env/environment';
import { SessionService } from './session.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Injectable()
export class WidgetService {
  venueId : number = 0;

  /**
   * 
   */
  constructor( private sessionService: SessionService, 
    private http: HttpClient) {
    this.sessionService.user$.subscribe(user => {
      this.venueId = (user !== null ? user.venue_id : null);
    });
  }

  /**
   * 
   */
  setVenueId(val: number) {
    this.venueId = val;
  }

  /**
   * 
   */
  getVenueId(venueId?:number) {
    return venueId ? venueId : this.venueId;
  }

  /**
   * Get widget settings
   */
  getWidgetDetails(venueId?: number): Observable<WidgetSettings[]> {
    const auxVenueId = this.getVenueId(venueId);
    let requestUrl = `${environment.API_URL}/venues/${auxVenueId}/widget`;
    return this.http.get<WidgetSettings[]>(requestUrl);
  }

  /**
   * Save
   */
  save(widgetSettings: WidgetSettings): Observable<WidgetSettings> {
    if (widgetSettings.id) {
      return this.update(widgetSettings);
    }
    return this.create(widgetSettings);
  }

  /**
   * Create
   */
  create(settings: WidgetSettings): Observable<WidgetSettings> {
    const auxVenueId = this.getVenueId();
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(settings);

    return this.http.post<WidgetSettings>(`${environment.API_URL}/venues/${auxVenueId}/widget`, body, requestOptions);
  }

  /**
   * Update
   */
  update(settings: WidgetSettings): Observable<WidgetSettings> {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(settings);

    return this.http.put<WidgetSettings>(`${environment.API_URL}/widget/${settings.id}`, body, requestOptions);
  }

  /**
   * Delete
   */
  delete(id: string): Observable<any> {
    const requestOptions = {
      headers: new HttpHeaders()
    };
    return this.http.delete(`${environment.API_URL}/widget/${id}`, requestOptions);
  }

}
