import { Observable, BehaviorSubject } from 'rxjs';
import * as moment from 'moment';

export interface ICalendarDay {
  dateIso: string;
  dayOfMonth?: string;
  isToday: boolean;
  isPast: boolean;
  isSelected: boolean;
  isCurrentMonth: boolean;
  isAvailable: boolean;
}

export interface ICalendarWeek {
  weekNr: string;
  days: ICalendarDay[];
}

export class CalendarService {
  calendarDataChange$: Observable<ICalendarWeek[]>;
  allowMultipleDates: boolean;

  private _calendarData: BehaviorSubject<ICalendarWeek[]>;
  private _selectedDates: moment.Moment[] = [];

  /**
   *
   */
  get selectedDates() {
    return this._selectedDates;
  }

  /**
   *
   */
  get selectedDate() {
    return this._selectedDates[0] !== undefined ? this._selectedDates[0] : null;
  }

  /**
   *
   */
  private _currentDate: moment.Moment;

  /**
   *
   */
  private _datePointer: moment.Moment;

  /**
   *
   */
  get datePointer() {
    return this._datePointer;
  }

  /**
   *
   */
  set datePointer(date: moment.Moment) {
    if (!date.isValid()) {
        return;
    }
    this._datePointer = date;
    this._generateCalendarData();
  }

  /**
   *
   */
  constructor() {
    this._currentDate = moment();
    this._datePointer = moment();

    this._calendarData = new BehaviorSubject(null);
    this.calendarDataChange$ = this._calendarData.asObservable();

    this._generateCalendarData();
  }

  /**
   *
   */
  add(amount: any, unit: string = 'month') {
    this._datePointer.add(amount, unit);
    this._generateCalendarData();
  }

  /**
   *
   */
  substract(amount: any, unit: string = 'month') {
    this._datePointer.subtract(amount, unit);
    this._generateCalendarData();
  }

  /**
   *
   */
  selectDate(day: string) {
    const momentSelectedDate = moment(day);
    if ( ! this.allowMultipleDates && this._selectedDates[0] ) {

      if ( momentSelectedDate.isSame(this._selectedDates[0], 'day') ) {
        this._selectedDates.splice(0, 1);
      } else {
        this._selectedDates.splice(0, 1, momentSelectedDate);
      }
    } else {
      const dateAlreadySelectedIndex = this._selectedDates.findIndex(value => value.isSame(momentSelectedDate, 'day'));

      if ( dateAlreadySelectedIndex !== -1 ) {
        this._selectedDates.splice(dateAlreadySelectedIndex, 1);
      } else {
        this._selectedDates.push(momentSelectedDate);
      }
    }

    this._generateCalendarData();
  }

  /**
   *
   */
  deselectDate(index: number) {
    this._selectedDates.splice(index, 1);
    this._generateCalendarData();
  }

  /**
   *
   */
  navigateToDate(date: moment.Moment) {
    this._datePointer = date.clone();
    this._generateCalendarData();
  }

  /**
   *
   */
  refresh() {
    this._generateCalendarData();
  }

  /**
   *
   */
  private _generateCalendarData() {
    let firstWeekOfMonth = this._datePointer.clone().startOf('month');
    let lastWeekOfMonth = this._datePointer.clone().endOf('month');

    let calendarData = [];

    let week: moment.Moment = firstWeekOfMonth.clone();

    while ( ! week.isAfter(lastWeekOfMonth, 'isoWeek') ) {

      let weekObject = {
        weekNr: week.format('W'),
        days: []
      };

      let weekDay = week.clone().startOf('isoWeek');
      let endOfWeek = week.clone().endOf('isoWeek');

      while ( ! weekDay.isAfter(endOfWeek, 'day') ) {

        let day: ICalendarDay = {
          dateIso: weekDay.toISOString(true),
          dayOfMonth: weekDay.format('D'),
          isSelected: this._isDaySelected(weekDay),
          isToday: weekDay.isSame(this._currentDate, 'day'),
          isCurrentMonth: weekDay.isSame(this._datePointer, 'month'),
          isPast: weekDay.isBefore(this._currentDate, 'day'),
          isAvailable: true
        };

        weekObject.days.push(day);

        weekDay.add(1, 'day');
      }

      calendarData.push(weekObject);

      week.add(1, 'week');
    }

    this._calendarData.next(calendarData);
  }

  /**
   *
   */
  private _isDaySelected(weekDay) {
    if ( this.allowMultipleDates ) {
      return this._selectedDates.findIndex(date => weekDay.isSame(date, 'day')) !== -1
    }
    return this._selectedDates[0] && weekDay.isSame(this._selectedDates[0], 'day');
  }
}
