import { NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { LoadingBarComponent } from '@shared/components';
import { UserEffects } from './effects/user';
import { BookingEffects } from '../booking/state/booking.effects';
import { reducers, metaReducers } from './reducers';
import { VenueResolver } from './resolvers/venue';
import { SpaceVenueResolver } from './resolvers/space-venue';
import {
  AccomodationService,
  ArrangementService,
  BookingService,
  CateringService,
  AuditService,
  DocumentService,
  MessageService,
  SessionService,
  SpaceService,
  UserArrangementsService,
  ToasterService,
  MapsService,
  UserCateringService,
  UserService,
  OrganizationService,
  UserSpaceService,
  HubSpotService,
  EventTrackerService,
  UserVenueService,
  ScrollBlockService,
  VenueService,
  StatisticsService,
  ProductService,
  GlobalErrorHandlerService,
  NavService,
  SearchCriteriaService,
  UserManageService,
  UserTranslationService,
  DepartmentManageService,
  VenueSwitchService,
  LoadingBarService,
  EquipmentService,
  UserEquipmentService,
  FavoritesService,
  PriceRuleService,
  AvailabilityService,
  MarketingNavService,
  WidgetPreviewService,
  WidgetService,
  PricingCouponService
} from './services';
import {
  PendingChangesGuard,
  AuthenticationGuard,
  HasVenueManagerRoleGuard,
  NotInProductionGuard,
  HasOrganizationManagerRoleGuard,
  LogoutGuard,
  TransparentNavGuard,
  HubspotGuard,
  TransparentMarketingNavGuard
} from './guards';
import {FavoriteEffects} from './effects/favorite.effects';
import {FavoritesFacade} from './facades/favorites.facade';
import {CompareVenuesFacade} from "@core/facades/compare-venues.facade";
import {CompareVenueEffects} from "@core/effects/compare-venue.effects";

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([FavoriteEffects, UserEffects, BookingEffects, CompareVenueEffects])
  ],
  declarations: [],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    ToasterService,
    SessionService,
    UserService,
    MarketingNavService,
    NavService,
    TransparentNavGuard,
    TransparentMarketingNavGuard,
    AuthenticationGuard,
    NotInProductionGuard,
    PendingChangesGuard,
    HubspotGuard,
    HasVenueManagerRoleGuard,
    HasOrganizationManagerRoleGuard,
    LogoutGuard,
    AccomodationService,
    PriceRuleService,
    ArrangementService,
    UserManageService,
    UserTranslationService,
    DepartmentManageService,
    StatisticsService,
    ScrollBlockService,
    SpaceService,
    HubSpotService,
    EventTrackerService,
    VenueService,
    LoadingBarComponent,
    ProductService,
    OrganizationService,
    UserArrangementsService,
    UserSpaceService,
    UserVenueService,
    LoadingBarService,
    FavoritesService,
    AuditService,
    DocumentService,
    MessageService,
    CateringService,
    UserCateringService,
    VenueResolver,
    MapsService,
    SpaceVenueResolver,
    VenueSwitchService,
    EquipmentService,
    SearchCriteriaService,
    UserEquipmentService,
    BookingService,
    FavoritesFacade,
    CompareVenuesFacade,
    AvailabilityService,
    WidgetPreviewService,
    WidgetService,
    PricingCouponService
  ]
})
export class CoreModule {
  constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
    if ( parentModule ) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only!');
    }
  }
}
