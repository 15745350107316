import {
  CalendarAvailabilityService,
  ICalendarAvailabilityDay,
  ICalendarAvailabilityWeek,
  ICalendarAvailabilityDayPart,
  ICalendarAvailabilityHour,
  ICalendarSelectedDates,
  DAY_PART
} from './calendar-availability.service';
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  InjectionToken,
  Inject,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import * as moment from 'moment';
import { MdlDialogReference } from '@angular-mdl/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AvailabilityService } from '@core/services';
import {environment as env} from "@env/environment";

export const DATETIMEAVAILABILITYPICKER_CONF = new InjectionToken<IDatetimeAvailabilityPickerConfiguration>('dateTimeAvailabilityPickerConf');

export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
  ESC = 27
}

export function isDateRange(object: any): object is IDateRange {
  return object && object.hasOwnProperty('start') && object.hasOwnProperty('end');
}

export interface IDateRange {
  start: string;
  end: string;
}

export interface SelectedTime {
  day: string;
  dayPart: string;
  label: string;
}

export type DatetimeAvailabilityPickerValue = IDateRange|IDateRange[];

export interface IDatetimeAvailabilityPickerConfiguration {
  value?: DatetimeAvailabilityPickerValue;
  spaceId?: number,
  venueId?: number,
  allowMultiple?: boolean;
  dateOnly?: boolean;
  selectTimeRange?: boolean;
  calendarOnly?: boolean;
  openView?: DatetimeView;
}

export type DatetimeView = 'perhour'|'perdaypart';

@Component({
  selector: 'vs-datetime-availability-picker',
  templateUrl: `./datetime-availability-picker.html`,
  styleUrls: ['./datetime-availability-picker.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatetimeAvailabilityPickerComponent implements OnInit, OnDestroy, AfterViewInit {
  activeView: DatetimeView;
  isValid: boolean;
  value: DatetimeAvailabilityPickerValue;
  venueId: number = 0;
  spaceId: number = 0;
  calendarOnly: boolean = false;
  allowMultiple: boolean = false;
  dateOnly: boolean;
  calendarData: ICalendarAvailabilityWeek[];
  availabilityData: any[];
  selectedDayParts: {day: string, dayParts: string[], label: string}[];
  selectedHours: {day: string, hours: number[], label: string}[];
  dialogVisible = false;
  @ViewChild('container', {static: false}) containerElement: ElementRef;
  private _calendarService: CalendarAvailabilityService;
  private _destroyed$: Subject<any> = new Subject();
  private _monthChanged : boolean = true;

  get activeMonth(): string {
    return this._calendarService.datePointer.format(env.MONTH_FORMAT);
  }

  get activeYear(): string {
    return this._calendarService.datePointer.format('YYYY');
  }

  get selectedDates(): ICalendarSelectedDates[] {
    return this._calendarService.selectedDates;
  }

  get hourRange(): number[] {
    let hours = [];
    const endHours = (env.DAY_PART_END_HOUR.EVENING.valueOf() == 0) ? 24 : env.DAY_PART_END_HOUR.EVENING.valueOf();
    const startHours = env.DAY_PART_START_HOUR.MORNING.valueOf();
    for (let i = startHours; i <= endHours; i++) {
      hours.push(i);
    }

    return hours;
  }

  /**
   *
   */
  constructor(
    @Inject(DATETIMEAVAILABILITYPICKER_CONF) private configuration: IDatetimeAvailabilityPickerConfiguration,
    private dialogReference: MdlDialogReference,
    private cdr: ChangeDetectorRef,
    private availabilityService: AvailabilityService) {

    this._calendarService = new CalendarAvailabilityService();
    this.selectedDayParts = [];
    this.selectedHours = [];
    this.calendarData = [];
    this.availabilityData = [];

    // Apply configuration to component
    this.value = this.configuration.value;
    this.venueId = this.configuration.venueId;
    this.spaceId = this.configuration.spaceId;
    this.dateOnly = this.configuration.dateOnly;
    this.calendarOnly = this.configuration.calendarOnly;
    this.activeView = this.configuration.openView;
    this.allowMultiple = this.configuration.allowMultiple;
    
    this._calendarService.allowMultiple(this.allowMultiple);
  }

  /**
   *
   */
  ngOnInit() {
    this.dialogReference.onVisible().subscribe(res => {
      this.dialogVisible = true;
    });

    this._parseDate();
    if (this.activeView == 'perdaypart') {
      this.formatSelectedTimes();
    } else {
      this.formatSelectedHours();
    }

    this._calendarService
      .calendarDataChange$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(calendarData => this.setCalendar(calendarData));
  }

  /**
   *
   */
  ngAfterViewInit() {
    let day = moment().format('D');
    const htmlRow = document.getElementById("day-number-" + day);
    if (htmlRow) {
      htmlRow.focus();
    }
  }

  /**
   *
   */
  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  /**
   *
   */
  @HostListener('window:keyup', ['$event']) keyEvent(event: KeyboardEvent) {
    if ( event.keyCode === KEY_CODE.RIGHT_ARROW ) {
      this.nextMonth();
    } else if ( event.keyCode === KEY_CODE.LEFT_ARROW ) {
      this.prevMonth();
    } else if ( event.keyCode === KEY_CODE.ESC ) {
      this.hide(event);
    }
  }

  /**
   *
   */
  nextMonth() {
    this._monthChanged = true;
    this._calendarService.add(1, 'month');
  }

  /**
   *
   */
  prevMonth() {
    this._monthChanged = true;
    this._calendarService.substract(1, 'month');
  }

  /**
   *
   */
  nextYear() {
    this._monthChanged = true;
    this._calendarService.add(1, 'year');
  }

  /**
   *
   */
  prevYear() {
    this._monthChanged = true;
    this._calendarService.substract(1, 'year');
  }

  /**
   *
   */
  removeSelectedTime($event, day: string) {
    $event.stopPropagation();
    this._calendarService.deselectDate(day);
    this.formatSelectedTimes();
  }

  /**
   *
   */
  removeSelectedHour($event, day: string) {
    $event.stopPropagation();
    this._calendarService.deselectDate(day);
    this.formatSelectedHours();
  }

  /**
   *
   */
  selectDate($event, day: ICalendarAvailabilityDay, dayPart: ICalendarAvailabilityDayPart) {
    $event.stopPropagation();
    if (this.selectedHours.length > 0) {
      this.selectedHours = [];
      this._calendarService.removeSelectedDates();
    }
    
    this._calendarService.selectDate(day.dateIso, dayPart.dayPart);
    this.formatSelectedTimes();
  }

  /**
   *
   */
  selectHour($event, day: ICalendarAvailabilityDay, dayHour: ICalendarAvailabilityHour) {
    $event.stopPropagation();
    if (this.selectedDayParts.length > 0) {
      this.selectedDayParts = [];
      this._calendarService.removeSelectedDates();
    }
    this._calendarService.selectHour(day.dateIso, dayHour.hour);
    this.formatSelectedHours();
  }

  /**
   *
   */
  formatSelectedTimes() {
    this.selectedDayParts = [];
    if (this.selectedDates.length > 0 ) {

      let sortedTimes = this.selectedDates.sort((a, b) => a.date.valueOf() - b.date.valueOf());

      sortedTimes.forEach(selectedDateItem => {
        let day: string = selectedDateItem.date.clone().hour(0).format();
        let datesPart = {
          day: day,
          dayParts: selectedDateItem.dayparts,
          label: this._buildDayPartsLabel(selectedDateItem.date, selectedDateItem.dayparts)
        };

        this.selectedDayParts.push(datesPart);
      }
      );
    }
    this.isValid = !!this.selectedDates.length;
  }

  /**
   *
   */
  formatSelectedHours() {
    this.selectedHours = [];
    if (this.selectedDates.length > 0 ) {

      let sortedTimes = this.selectedDates.sort((a, b) => a.date.valueOf() - b.date.valueOf());

      sortedTimes.forEach(selectedDateItem => {
          let day: string = selectedDateItem.date.clone().hour(0).format();
          let hours = {
            day: day,
            hours: selectedDateItem.hours,
            label: this._buildHoursLabel(selectedDateItem.date, selectedDateItem.hours)
          };

          this.selectedHours.push(hours);
        }
      );
    }
    this.isValid = !!this.selectedDates.length;
  }

  /**
   *
   */
  hide($event: MouseEvent|KeyboardEvent) {
    this.dialogReference.hide();
  }

  /**
   *
   */
  confirm($event: MouseEvent) {
    const formattedDates: IDateRange[] = [];
    this.selectedDates.forEach(date => {
      formattedDates.push(this._formatDateAndTimeToRange(date));
    });
    
    const returnDates = (this.allowMultiple) ? formattedDates : formattedDates.shift();
    
    this.dialogReference.hide({selectedDates: returnDates, lastView: this.activeView});
  }

  /**
   *
   */
  navigateCalendarTo(date: moment.Moment) {
    this._calendarService.navigateToDate(date);
  }

  /**
   *
   */
  showActiveView($event) {
    if (this.activeView !== $event) {
      this.activeView = $event;
      this._calendarService.refresh();
    }
  }

  /**
   *
   */
  setCalendar(calendarData: ICalendarAvailabilityWeek[]) {
    this.calendarData = calendarData;

    if (!this.spaceId && !this.venueId) {
      this.cdr.markForCheck();
      return;
    }
    if (!this._monthChanged) {
      this.mergeCalendarData();
      this.cdr.markForCheck();
      return;
    }
    this._monthChanged = false;

    if (this.spaceId) {
      this.availabilityService
        .getSpaceAvailability(this.spaceId, this._calendarService.datePointer.format("YYYY-MM-DD"))
        .pipe(takeUntil(this._destroyed$))
        .subscribe((result) => {this.availabilityData = result; this.mergeCalendarData(); this.cdr.markForCheck();},
                 (error) => {if (error.status != 404) console.error(error);}
      );
        
    } else {
      this.availabilityService
        .getVenueAvailability(this.venueId, this._calendarService.datePointer.format("YYYY-MM-DD"))
        .pipe(takeUntil(this._destroyed$))
        .subscribe((result) => {this.availabilityData = result; this.mergeCalendarData(); this.cdr.markForCheck();},
                   (error) => { if (error.status != 404) console.error(error);}
      );
    }
  }

  /**
   *
   */
  mergeCalendarData() {
    this.calendarData =
    this.calendarData.map(
      (weekObj) => {
        weekObj.days.map(
          (day) => {

            let availabilityDate = this.availabilityData.find(value => value.date === day.dateIso.slice(0,10));
            if (availabilityDate) {
              let dayPartAvailability = availabilityDate.availability
                .map(daypart => Object.values(daypart).shift());

              day.dayParts[0].isAvailable = !!dayPartAvailability[0];
              day.dayParts[0].availability = dayPartAvailability[0];
              day.dayParts[1].isAvailable = !!dayPartAvailability[1];
              day.dayParts[1].availability = dayPartAvailability[1];
              day.dayParts[2].isAvailable = !!dayPartAvailability[2];
              day.dayParts[2].availability = dayPartAvailability[2];

              day.hours.forEach(hour => {
                var isAvailable = false;
                if (hour.hour >= env.DAY_PART_START_HOUR.MORNING && hour.hour <= env.DAY_PART_END_HOUR.MORNING) {
                  isAvailable = day.dayParts[0].isAvailable;
                }
                if (hour.hour >= env.DAY_PART_START_HOUR.AFTERNOON && hour.hour <= env.DAY_PART_END_HOUR.AFTERNOON && !isAvailable) {
                  isAvailable = day.dayParts[1].isAvailable;
                }
                if (hour.hour >= env.DAY_PART_START_HOUR.EVENING && hour.hour <= env.DAY_PART_END_HOUR.EVENING && !isAvailable) {
                  isAvailable = day.dayParts[2].isAvailable;
                }
                hour.isAvailable = isAvailable;
              });
            }
            return day;
          }
        );
        return weekObj;
      }
    );
  }

  /**
   *
   */
  private _parseDate() {
    let primaryDate: IDateRange;

    if ( Array.isArray(this.value) && this.value.length ) {
      primaryDate = this.value[0];

      if (this.activeView == 'perdaypart') {
        this.value.forEach(dateRange => {
            const dayparts = this._calendarService.dayPartsFromDateRange(dateRange);
            dayparts.forEach(daypart => this._calendarService.selectDate(dateRange.start, daypart));
          }
        );
      } else {
        this.value.forEach(dateRange => {
            const hours = this._calendarService.hoursFromDateRange(dateRange);
            hours.forEach(hour => this._calendarService.selectHour(dateRange.start, hour));
          }
        );
      }
    } else {
      primaryDate = this.value as IDateRange;
    }

    this._calendarService.datePointer = moment(primaryDate.start, moment.ISO_8601);
  }

  /**
   *
   */
  private _buildDayPartsLabel(daySelected: moment.Moment, dayParts: string[]): string {
    let endLabel = (dayParts.length == 3) ? 'all-day' : dayParts.join(' - ');
    return daySelected.format('D') + ' ' + daySelected.format('MMM') + ' ' + endLabel;
  }

  /**
   *
   */
  private _buildHoursLabel(daySelected: moment.Moment, hours: number[]): string {
    let endLabel = (hours.length == 1) ? hours[0] : Math.min.apply(Math, hours).toString() + ' - '+ Math.max.apply(Math, hours).toString();
    return daySelected.format('D') + ' ' + daySelected.format('MMM') + ' ' + endLabel;
  }

  /**
   *
   */
  private _formatDateAndTimeToRange(selectedDate: ICalendarSelectedDates): IDateRange {

    const dateStart = selectedDate.date.clone();
    const dateEnd = selectedDate.date.clone();

    if (selectedDate.dayparts.indexOf(DAY_PART.MORNING) !== -1) {
      dateStart.set('hour', env.DAY_PART_START_HOUR.MORNING);
      dateEnd.set('hour', env.DAY_PART_END_HOUR.MORNING);
      if (selectedDate.dayparts.indexOf(DAY_PART.AFTERNOON) !== -1) {
        dateEnd.set('hour', env.DAY_PART_END_HOUR.AFTERNOON);
        if (selectedDate.dayparts.indexOf(DAY_PART.EVENING) !== -1) {
          dateEnd.set('hour', env.DAY_PART_END_HOUR.EVENING);
        }
      }
    } else if (selectedDate.dayparts.indexOf(DAY_PART.AFTERNOON) !== -1) {
      dateStart.set('hour', env.DAY_PART_START_HOUR.AFTERNOON);
      dateEnd.set('hour', env.DAY_PART_END_HOUR.AFTERNOON);
      if (selectedDate.dayparts.indexOf(DAY_PART.EVENING) !== -1) {
        dateEnd.set('hour', env.DAY_PART_END_HOUR.EVENING);
      }
    } else if (selectedDate.dayparts.indexOf(DAY_PART.EVENING) !== -1) {
      dateStart.set('hour', env.DAY_PART_START_HOUR.EVENING);
      dateEnd.set('hour', env.DAY_PART_END_HOUR.EVENING);
    } else {

      dateStart.set('hour', Math.min.apply(Math, selectedDate.hours));
      dateEnd.set('hour', Math.max.apply(Math, selectedDate.hours));
    }

    return {
      start: dateStart.format(),
      end: dateEnd.format()
    }
  }
}
