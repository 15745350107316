export enum EventTypes {
  ET_MEETING = 'meeting',
  ET_TRAINING = 'training',
  ET_WORKSHOP = 'workshop',
  ET_EVENT = 'event',
//  ET_CONGRESS = 'congress',
//  ET_CONFERENCE = 'conference',
//  ET_EXPOSITIE = 'expositie',
  ET_DINNER = 'dinner',
};

export enum SpaceSetups {
    SS_BOARDROOM = 'Boardroom',
    SS_CABARET = 'Cabaret',
    SS_CARRE = 'Carre',
    SS_CIRCLE = 'Circle',
    SS_DINNER = 'Dinner',
    SS_EXAM = 'Exam',
    SS_PARTY = 'Party',
    SS_SCHOOL = 'School',
    SS_STANDING = 'Standing',
    SS_THEATER = 'Theater',
    SS_USHAPE = 'U-Shape',
};