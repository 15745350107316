import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';

import * as fromRoot from '../reducers/index';
import * as favoriteActions from '../actions/favorite.actions';
import {Venue} from '../models';
import {map} from 'rxjs/operators';

@Injectable()
export class FavoritesFacade {
  favoriteVeneus$ = this.store.select(fromRoot.selectFavoriteVenues);
  isFavorited$ = (id: number) => this.favoriteVeneus$.pipe(map(venues => venues.has(id)));

  constructor(private store: Store<fromRoot.State>) {}

  addFavorite(venue: Venue) {
    this.store.dispatch(new favoriteActions.AddVenueAction(venue));
  }

  removeFavorite(venue: Venue) {
    this.store.dispatch(new favoriteActions.RemoveVenueAction(venue));
  }
}
