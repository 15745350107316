import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'timeago'
})
export class TimeAgoPipe implements PipeTransform {
  transform(value: string) {
    return moment(value, moment.ISO_8601).fromNow();
  }
}
