<vs-toaster></vs-toaster>

<vs-marketing-nav
  *ngIf="showNav && marketingTransparentNav"
  [isAuthenticated]="isAuthenticated"
  [user]="user"
  [navItems]="navItems"
  [marketingTransparentNav]="marketingTransparentNav"
  [latestUrl]= "latestUrl"
  (signOut)="signOut()"></vs-marketing-nav>

  <vs-nav
  *ngIf="showNav && !marketingTransparentNav"
  [isAuthenticated]="isAuthenticated"
  [user]="user"
  [navItems]="navItems"
  [transparentNav]="transparentNav"
  (signOut)="signOut()"></vs-nav>

<div
  class="main-content"
  [class.main-content--no-margin]="transparentNav"
  [class.main-content--no-margin]="marketingTransparentNav">

  <router-outlet></router-outlet>
</div>
