import { ActionReducer, ActionReducerMap, MetaReducer, createFeatureSelector } from '@ngrx/store';
import { combineReducers } from '@ngrx/store';
import { createSelector } from 'reselect';

import * as fromUser from './user';
import * as fromFavorites from './favorite.reducer';
import * as fromBooking from '../../booking/state/booking.reducer';
import * as fromCompareVenues from './compare-venue.reducer';
import { environment } from '@env/environment';

/**
 * We treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface State {
  user: fromUser.State;
  booking: fromBooking.State;
  favorites: fromFavorites.State;
  compareVenues: fromCompareVenues.State;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const reducers: ActionReducerMap<State> = {
  user: fromUser.reducer,
  booking: fromBooking.reducer,
  favorites: fromFavorites.reducer,
  compareVenues: fromCompareVenues.reducer
};

/**
 * Log all store actions. Automaticaly disabled when app is in production mode
 *
 * @param reducer
 */
export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return function(state: State, action: any): State {
    console.group('STATE CHANGE');
    console.log("state", state);
    console.log("action", action);
    console.groupEnd();
    return reducer(state, action);
  };
}

/**
 * Our main production reducer which combines all the reducers. The reducers is passed
 * through the meta reducers which are defined below.
 */
const productionReducer: ActionReducer<State> = combineReducers(reducers);
export function reducer(state: any, action: any) {
  return productionReducer(state, action);
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<State>[] = environment.APP_VERSION === 'dev'
  ? [logger]
  : [];

/**
 * Define all the selectors for accessing the user part of the state
 */
export const getUserState = (state: State) => state.user;
export const getUserLoading = createSelector(getUserState, fromUser.getLoading);
export const getUserIsLoggedIn = createSelector(getUserState, fromUser.getIsLoggedIn);
export const getUserHasLoginlessToken = createSelector(getUserState, fromUser.getHasLoginLessToken);
export const getUser = createSelector(getUserState, fromUser.getUser);
export const getUserVenues = createSelector(getUserState, fromUser.getUserVenues);
export const getShowAuthDialog = createSelector(getUserState, fromUser.getShowAuthDialog);
export const getAuthDialogTab = createSelector(getUserState, fromUser.getAuthDialogTab);
export const getAuthRequest2FA = createSelector(getUserState, fromUser.getAuthRequest2FA);

/**
 * Selectors for the booking part of the state
 */
export const selectBookingState = createFeatureSelector<fromBooking.State>('booking');
export const selectDraftIds = createSelector(selectBookingState, fromBooking.selectDraftIds);
export const selectDraftEntities = createSelector(selectBookingState, fromBooking.selectDraftEntities);
export const selectCurrentDraftId = createSelector(selectBookingState, fromBooking.selectCurrentDraftId);
export const selectCurrentDraft = createSelector(selectDraftEntities, selectCurrentDraftId, (draftEntities, spaceId) => draftEntities[spaceId]);

/**
 * Selectors for favorites state
 */
export const selectFavoriteState = (state: State) => state.favorites;
export const selectFavoriteVenues = createSelector(selectFavoriteState, fromFavorites.getVenues);
export const selectFavoritesLoading = createSelector(selectFavoriteState, fromFavorites.getLoading);

/**
 * Selectors for compare venues
 */
export const getCompareVenuesState = (state: State) => state.compareVenues;
export const getCompareVenues = createSelector(getCompareVenuesState, fromCompareVenues.getVenues);
export const getCompareVenuesCount = createSelector(getCompareVenuesState, fromCompareVenues.getCountVenuesSelected);
