import { Component, Input } from '@angular/core';

@Component({
  selector: 'vs-password-strength-indicator',
  template: `
    <div>
      <span class="password-strength" [ngClass]="strengthLabel">
        {{ strengthLabel | ucfirst }}
      </span>
    </div>
  `,
  styleUrls: ['./password-strength-indicator.scss']
})
export class PasswordStrengthIndicatorComponent {
  @Input()
  set password(value: string) {
    this._password = value;
    this.determinePasswordStrength();
  }

  strengthLabel: string;

  private _password;

  determinePasswordStrength() {
    const score = this.scorePassword(this._password);

    if ( score > 80 ) {
      this.strengthLabel = 'strong';
    } else if ( score > 50 ) {
      this.strengthLabel = 'good';
    } else if ( score > 10 ) {
      this.strengthLabel = 'weak';
    } else {
      this.strengthLabel = '';
    }
  }

  private scorePassword(pass): number {
    let score = 0;

    if ( ! pass ) {
      return score;
    }

    // Award every unique letter until 5 repetitions
    const letters = [];
    for ( let i = 0; i < pass.length; i++ ) {
      letters[pass[i]] = (letters[pass[i]] || 0) + 1;
      score += 5.0 / letters[pass[i]];
    }

    // bonus points for mixing it up
    const variations = {
      digits: /\d/.test(pass),
      lower: /[a-z]/.test(pass),
      upper: /[A-Z]/.test(pass),
      nonWords: /\W/.test(pass),
    };

    let variationCount = 0;

    for ( const key in variations ) {
      if ( variations[key] ) {
        variationCount += (variations[key] === true) ? 1 : 0;
      }
    }

    score += (variationCount - 1) * 10;

    return score;
  }
}
