import { Venue } from './venue';
import {AppRoles} from '@core/roles.enum';

export interface IUser {
  id: number;
  email: string;
  sex: string;
  first_name: string;
  preposition: string;
  last_name: string;
  company_name: string;
  avatar: string;
  auth_config?: { type?: string, method?: string };
  role: AppRoles;
  phone?: string;
  job_title?: string;
  organization_id?: number;
  auth_2fa_token?: string;

  // Venue is only present for users of the role venue_ambassador
  venue_id?: number;
  venue?: Venue;
  venues?: any;
}

export class User implements IUser {
  id: number;
  email: string;
  sex: string;
  first_name: string;
  preposition: string;
  last_name: string;
  lang: string;
  company_name: string;
  avatar: string;
  auth_config?: { type?: string, method?: string };
  role: AppRoles;
  phone: string;
  job_title: string;
  organization_id: number;
  venue_id: number;
  venue: Venue;
  venues: any;
  auth_2fa_token: string;
  activated_on: string;

  /**
   * 
   */
  constructor(defaultValues: IUser) {
    if ( ! defaultValues ) return;

    const keys = Object.keys(defaultValues);
    for ( let i = 0; i < keys.length; i++ ) {
      const propName = keys[i];

      try {
        this[propName] = defaultValues[propName];
      } catch ( error ) {
        console.log(error);
      }
    }
  }

  /**
   * 
   */
  salutation(): string {
    return this.first_name;
  }

  /**
   * 
   */
  fullName(): string {
    return this.first_name + this.getPreposition() + ' ' + this.last_name;
  }

  /**
   * 
   */
  getPreposition(): string {
    return (this.preposition && this.preposition.length > 0) ? ' ' + this.preposition : '';
  }

  /**
   * 
   */
  hasMultiVenues(): boolean {
    return this.venues && this.venues.length > 1;
  }

  /**
   * 
   */
  isVenue(): boolean {
    return this.venue_id > 0;
  }

  /**
   * 
   */
  isOrganization(): boolean {
    return this.organization_id > 0;
  }
}
