import { Action } from '@ngrx/store';
import { INewBooking } from '../models/booking-form-model';

export enum BookingActionTypes {
  CREATE_DRAFT = '[Booking] Create a new draft',
  SELECT_DRAFT = '[Booking] Select booking draft',
  UPDATE_DRAFT = '[Booking] Update booking draft',
  DELETE_DRAFT = '[Booking] Delete booking draft',
  GET_ALL = '[Booking] Get all drafts',
  INSERT_MANY = '[Booking] Insert many',
  LOAD_DRAFTS = '[Booking] Load drafts'
}

export class CreateDraftAction implements Action {
  readonly type = BookingActionTypes.CREATE_DRAFT;

  constructor(
    public spaceId: number,
    public booking: INewBooking) {}
}

export class SelectBookingDraftAction implements Action {
  readonly type = BookingActionTypes.SELECT_DRAFT;

  constructor(public spaceId: number) {}
}

export class UpdateDraftAction implements Action {
  readonly type = BookingActionTypes.UPDATE_DRAFT;

  constructor(
    public spaceId: number,
    public changes: Partial<INewBooking>) {}
}

export class DeleteDraftAction implements Action {
  readonly type = BookingActionTypes.DELETE_DRAFT;

  constructor(public spaceId: number) {}
}

export class GetAllAction implements Action {
  readonly type = BookingActionTypes.GET_ALL;
  constructor(public bookings: INewBooking[]) {}
}

export class LoadDraftsAction implements Action {
  readonly type = BookingActionTypes.LOAD_DRAFTS;
}

export class InsertManyAction implements Action {
  readonly type = BookingActionTypes.INSERT_MANY;
  constructor(public drafts: { [spaceId:number]: INewBooking }) {}
}

export type BookingActions
  = CreateDraftAction
  | SelectBookingDraftAction
  | UpdateDraftAction
  | DeleteDraftAction
  | LoadDraftsAction
  | InsertManyAction;
