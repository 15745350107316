
import { Router } from '@angular/router';
import { Injector } from '@angular/core';
import { routes } from './app.routes';
import { environment } from '../environments/environment';

export function initApp(injector: Injector) {
  return () => {
    return new Promise((resolve) => {
      const orgId = environment.ORG_ID;
      const filteredRoutes = routes.filter(r => {
        return r.data.roles.indexOf('all') !== -1 || r.data.roles.indexOf(orgId) !== -1;
      });
      const router: Router = injector.get(Router);
      router.resetConfig(filteredRoutes);
      resolve();
    });
  };
}