import { Directive, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { Subject, fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[dirIsolateScrolling]'
})
export class IsolateScrollingDirective implements OnInit, OnDestroy {
  element: HTMLElement;
  private destroyed: Subject<void> = new Subject();

  constructor(private elementRef: ElementRef) { }

  ngOnInit() {
    this.element = this.elementRef.nativeElement;

    fromEvent(this.element, 'DOMMouseScroll')
      .pipe(takeUntil(this.destroyed))
      .subscribe((event: WheelEvent) => this.preventScrollPropagation(event));

    fromEvent(this.element, 'mousewheel')
      .pipe(takeUntil(this.destroyed))
      .subscribe((event: WheelEvent) => this.preventScrollPropagation(event));
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }

  preventScrollPropagation(event: any) {
    const scrollTop = this.element.scrollTop;
    const scrollHeight = this.element.scrollHeight;
    const height = parseFloat(window.getComputedStyle(this.element, null).getPropertyValue('height'))
    const delta = event.type == 'DOMMouseScroll' ? (event.detail * -40) : event.wheelDelta
    const up = delta > 0

    const prevent = () => {
      event.stopPropagation()
      event.preventDefault()
      event.returnValue = false
      return false
    }

    // Scrolling down, but this will take us past the bottom.
    if (!up && -delta > scrollHeight - height - scrollTop) {
      this.element.scrollTop = scrollHeight
      return prevent()

    // Scrolling up, but this will take us past the top.
    } else if (up && delta > scrollTop) {
      this.element.scrollTop = 0
      return prevent()
    }
  }
}
