import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';

import * as fromRoot from '../reducers/index';
import * as compareActions from '../actions/compare-venue.actions';
import {Venue} from '../models';
import {map} from 'rxjs/operators';

@Injectable()
export class CompareVenuesFacade {
  static readonly MAX_COMPARE = 3;

  venues$ = this.store.select(fromRoot.getCompareVenues);
  selected$ = (id: number) => this.venues$.pipe(map(venues => venues.has(id)));
  isDisabledFor$ = (id: number) => this.venues$.pipe(map(venues => !venues.has(id) && venues.size >= CompareVenuesFacade.MAX_COMPARE));

  constructor(private store: Store<fromRoot.State>) {}

  addVenue(venue: Venue) {
    this.store.dispatch(new compareActions.AddVenueAction(venue));
  }

  removeVenue(venue: Venue) {
    this.store.dispatch(new compareActions.RmVenueAction(venue));
  }
}
