import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CateringService } from './catering.service';
import { environment } from '@env/environment';
import { CateringProduct } from '../models';
import { SessionService } from './session.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class UserCateringService extends CateringService {
  venueId: number;

  constructor(http: HttpClient, sessionService: SessionService) {
    super(http);

    // Get the venue id from the currently authenticated user
    sessionService.user$.subscribe(user => {
      this.venueId = (user !== null ? user.venue_id : null);
    });
  }

  /**
   * 
   */
  setVenueId(val: number) {
    this.venueId = val;
  }

  /**
   * 
   */
  getCateringOffers(): Observable<CateringProduct[]> {
    return this.http.get<CateringProduct[]>(`${environment.API_URL}/venues/${this.venueId}/admin/catering`);
  }

  /**
   * 
   */
  getOffersByCategory(category: string, component?: string): Observable<CateringProduct[]> {
    return this.getCateringOffers()
      .pipe(
        map((venueCateringOffers: CateringProduct[]) => {
          return venueCateringOffers.filter(offer => {
            return (component) ? offer.category === category && offer.component === component : offer.category === category;
          });
        })
      );
  }

  /**
   * 
   */
  getCateringOffer(id): Observable<CateringProduct> {
    return this.http.get<CateringProduct>(`${environment.API_URL}/venues/${this.venueId}/admin/catering/${id}`);
  }

  /**
   * 
   */
  addCateringOffer(cateringOffer: Partial<CateringProduct>) {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');

    const body = JSON.stringify(cateringOffer);

    return this.http.post(`${environment.API_URL}/venues/${this.venueId}/admin/catering`,  body, requestOptions);
  }

  /**
   * 
   */
  updateCateringOffer(cateringOffer: CateringProduct) {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(cateringOffer);

    return this.http.put(`${environment.API_URL}/venues/${this.venueId}/admin/catering/${cateringOffer.id}`,  body, requestOptions);
  }

  /**
   * 
   */
  deleteCateringOffer(cateringOfferId: number) {
    return this.http.delete(`${environment.API_URL}/venues/${this.venueId}/admin/catering/${cateringOfferId}`);
  }
}
