import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env/environment';

@Pipe({name: 'imageTransform'})
export class ImageTransformPipe implements PipeTransform {

  placeholderImage = environment.IMG_PLACEHOLDER;

  /**
   * Image pipe for CLOUDINARY images,
   *
   * @param value
   * @param width
   * @param height
   * @param crop
   * @param face
   * @param scale
   * @param fit
   */
  transform(
    value: string,
    width: number,
    height: number,
    crop: boolean = false,
    face: boolean = false,
    scale: boolean = false,
    fit: boolean = false): string {

    if ( !value ) {
      return this.placeholderImage;
    }

    if ( value.indexOf('cloudinary') === -1 ) {
      return value;
    }

    if ( typeof(value) === 'object') {
      value = value[0];
    }

    const urlParts = value.split('/');

    let transformUrlSegment = '';

    if ( width ) {
      transformUrlSegment += 'w_' + width;
    }

    if ( height && width ) {
      transformUrlSegment += ',h_' + height;
    }  else if (  height && !width  ) {
      transformUrlSegment += 'h_' + height;
    }

    if ( crop )  {
      transformUrlSegment += ',c_thumb';
    }

    if ( face ) {
      transformUrlSegment += ',g_face,c_thumb';
    }

    if ( scale ) {
      transformUrlSegment += ',c_scale';
    }

    if ( fit ) {
      transformUrlSegment += ',c_fit';
    }

    if ( transformUrlSegment.length > 0 ) {
      urlParts.splice(6, 0, transformUrlSegment);
    }

    return urlParts.join('/');
  }
}
