import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { onVenueSwitch } from './venue-switch.service';
import { environment } from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

export interface IUserEntry {
  id: number;
  organization: number;
  venue: number;
  last_login: string;
  role: string;
  department?: number;
  username: string;
  full_name: string;
}

@Injectable()
export class UserManageService implements onVenueSwitch {
  private venueId: number;

  constructor(private http: HttpClient) { }

  /**
   * 
   */
  setVenueId(venueId: number) {
    this.venueId = venueId;
  }

  /**
   * 
   */
  getAll(forActiveVenue = true): Observable<IUserEntry[]> {
    let requestUrl = `${environment.API_URL}/users`;

    if (forActiveVenue && this.venueId) {
      requestUrl += '?venue_id=' + this.venueId;
    }

    return this.http.get<IUserEntry[]>(requestUrl);
  }

  /**
   * 
   */
  create(user: IUserEntry): Observable<IUserEntry> {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(user);

    return this.http.post<IUserEntry>(`${environment.API_URL}/users`,  body, requestOptions);
  }

  /**
   * 
   */
  update(id: number, user: IUserEntry): Observable<IUserEntry> {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(user);
    return this.http.patch<IUserEntry>(`${environment.API_URL}/users/${id}`, body, requestOptions);
  }

  /**
   * 
   */
  delete(id: number): Observable<any> {
    const requestBody = JSON.stringify({
      venue: this.venueId
    });

    const requestOptions = {
      headers: new HttpHeaders(),
      body: requestBody
    };

    return this.http.delete(`${environment.API_URL}/users/${id}`, requestOptions);
  }
}
