import {Injectable} from '@angular/core';

import {environment} from '@env/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class StatisticsService {
  private venueId: number;

  setVenueId(venueId: number) {
    this.venueId = venueId;
  }

  constructor(private http: HttpClient) { }

  getInsights(forActiveVenue: boolean, forYear?: number, type?: string, department?: string) {
    let urlSearchParams = new HttpParams();
    urlSearchParams = urlSearchParams.set('year', forYear.toString());
    urlSearchParams = urlSearchParams.set('meeting_type', type ? type : '');
    urlSearchParams = urlSearchParams.set('department',  department ? department : '');
    urlSearchParams = urlSearchParams.set('type', 'booking_price');
    urlSearchParams = urlSearchParams.set('group', 'month');

    if ( forActiveVenue && this.venueId ) {
      urlSearchParams = urlSearchParams.set('venue', this.venueId.toString());
    }

    const requestOptionsArgs = {
      params: urlSearchParams
    };

    return this.http.get(environment.API_URL+'/statistics', requestOptionsArgs);
  }

  getCatgoryInsights(forActiveVenue: boolean, forYear?: number, type?: string, department?: string) {
    let urlSearchParams = new HttpParams();
    urlSearchParams = urlSearchParams.set('year', forYear.toString());
    urlSearchParams = urlSearchParams.set('meeting_type', type ? type : '');
    urlSearchParams = urlSearchParams.set('department', department ? department : '');
    urlSearchParams = urlSearchParams.set('type', 'booking_category');
    urlSearchParams = urlSearchParams.set('group', 'year');

    if ( forActiveVenue && this.venueId ) {
      urlSearchParams = urlSearchParams.set('venue', this.venueId.toString());
    }

    const requestOptionsArgs = {
      params: urlSearchParams
    };

    return this.http.get(environment.API_URL+'/statistics', requestOptionsArgs);
  }

  downloadInsights(
      forActiveVenue: boolean, 
      forYear?: number, 
      type?: string, 
      department?: string): Observable<Blob>  {
        
    let urlSearchParams = new HttpParams();
    urlSearchParams = urlSearchParams.set('year', forYear.toString());
    urlSearchParams = urlSearchParams.set('meeting_type', type ? type : '');
    urlSearchParams = urlSearchParams.set('department', department ? department : '');
    urlSearchParams = urlSearchParams.set('type', 'booking_price');
    urlSearchParams = urlSearchParams.set('group', 'month');

    if ( forActiveVenue && this.venueId ) {
      urlSearchParams = urlSearchParams.set('venue', this.venueId.toString());
    }

    return this.http.get<Blob>(environment.API_URL+'/statistics/download', {
      params: urlSearchParams,
      observe: 'body',
      responseType: 'blob' as 'json'
    });
  }
}
