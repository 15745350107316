import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MdlDialogService } from '@angular-mdl/core';
import { ScrollBlockService } from '@core/services';
import { switchMap, tap } from 'rxjs/operators';
import * as moment from 'moment';


import {
  DatetimeAvailabilityPickerComponent,
  DATETIMEAVAILABILITYPICKER_CONF,
  IDatetimeAvailabilityPickerConfiguration,
  DatetimeAvailabilityPickerValue,
  IDateRange,
  DatetimeView
} from './datetime-availability-picker';
import {environment} from "@env/environment";

@Component({
  selector: 'vs-datetime-availability',
  templateUrl: './datetime-availability.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: DatetimeAvailabilityComponent,
    multi: true
  }]
})
export class DatetimeAvailabilityComponent implements ControlValueAccessor {
  @Input() dateOnly = false;
  @Input() selectStartEndTime = true;
  @Input() calendarOnly = false;
  @Input() dateFormat = 'D MMM YYYY';
  @Input() dateTimeSeparator = '/';
  @Input() timeFormat = 'HH:mm';
  @Input() timeSeparator = '-';
  @Input() multipleDates = false;
  @Input() disabled = false;
  @Input() label = '';
  @Input() placeholder = '';
  @Input() spaceId = 0;
  @Input() venueId = 0;
  isDialogVisible: boolean;
  focused = false;
  text = '';
  lastView: string = '';
  private value: IDateRange[];
  private onChange: Function = () => {};
  private onTouched: Function = () => {};

  /**
   *
   */
  constructor(
    private changeDetectionRef: ChangeDetectorRef,
    private dialogService: MdlDialogService,
    private scrollBlocker: ScrollBlockService) {}

  /**
   *
   */
  writeValue(value: any) {
    if (!value) {
      this.value = [];
    } else if (Array.isArray(value)) {
      this.value = value;
    } else {
      this.value = [value];
    }
    this.renderValue(this.value);
  }

  /**
   *
   */
  clearValue($event) {
    this.writeValue(null);
    this.onChange(null);
  }

  /**
   *
   */
  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  /**
   *
   */
  registerOnTouched(fn: Function) {
    this.onTouched = fn;
  }

  /**
   *
   */
  onBlur($event) {
    this.focused = false;
    this.onTouched();
  }

  /**
   *
   */
  onFocus($event) {
    // Disabled for now because the animation adds more lag
    // this.focused = true;
  }

  /**
   *
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   *
   */
  renderValue(value: any) {
    this.text = this.stringifyValue(value);
    this.changeDetectionRef.detectChanges();
  }

  /**
   *
   */
  stringifyValue(dateRanges: DatetimeAvailabilityPickerValue): string {
    if (!Array.isArray(dateRanges)) {
      return '';
    }

    if (dateRanges.length === 0) {
      return '';
    }

    if (dateRanges.length === 1) {
      const start = moment(dateRanges[0].start, moment.ISO_8601);
      const end = moment(dateRanges[0].end, moment.ISO_8601);
      const dateFormatted = start.format(this.dateFormat);

      return `${dateFormatted} ${this.dateTimeSeparator} ${start.format(this.timeFormat)} ${this.timeSeparator} ${end.format(this.timeFormat)}`;
    }

    return `${dateRanges.length} days selected`;
  }

  /**
   *
   */
  openDatetimeAvailabilityPicker($event: MouseEvent) {
    if ( ! this.disabled && ! this.isDialogVisible ) {
      this.isDialogVisible = true;
      this.onTouched();

      let lastView = (this.lastView !== '' ? this.lastView : this.deductView()) as DatetimeView;

      const dateTimePickerConfiguration: IDatetimeAvailabilityPickerConfiguration = {
        value: this.value,
        dateOnly: this.dateOnly,
        allowMultiple: this.multipleDates,
        calendarOnly: this.calendarOnly,
        spaceId: this.spaceId,
        venueId: this.venueId,
        openView: lastView
      };

      this.scrollBlocker.blockScrolling();
      const pDialog = this.dialogService.showCustomDialog({
        component: DatetimeAvailabilityPickerComponent,
        providers: [{provide: DATETIMEAVAILABILITYPICKER_CONF, useValue: dateTimePickerConfiguration}],
        isModal: true,
        openFrom: $event,
        styles:  {'width': '38.5rem', 'max-width': '100vw'},
        classes: 'vs-datepicker-dialog',
        clickOutsideToClose: true,
        enterTransitionDuration: 400,
        leaveTransitionDuration: 400
      });

      pDialog.pipe(switchMap(ref => ref.onHide()),
        tap(() => this.scrollBlocker.enableScrolling())
        ).subscribe(data => {
          this.isDialogVisible = false;
          this.dateTimePickerCallback(data)
        }
      );
    }
  }

  /**
   *
   */
  deductView(): string {
    let isDayPart = true;

    if (Array.isArray(this.value) && this.value.length > 0) {
      this.value.forEach(date => {
        isDayPart = isDayPart && this.isDayPart(date);
      });
    }

    return isDayPart ? 'perdaypart' : 'perhour';
  }

  /**
   *
   */
  isDayPart (date): boolean {
    if (moment(date.start).hour() !== environment.DAY_PART_START_HOUR.MORNING
        && moment(date.start).hour() !== environment.DAY_PART_START_HOUR.AFTERNOON
        && moment(date.start).hour() !== environment.DAY_PART_START_HOUR.EVENING) {
      return false;
    }

    if (moment(date.end).hour() !== environment.DAY_PART_END_HOUR.MORNING
        && moment(date.end).hour() !== environment.DAY_PART_END_HOUR.AFTERNOON
        && moment(date.end).hour() !== environment.DAY_PART_END_HOUR.EVENING) {
      return false;
    }

    return true;
  }

  /**
   *
   */
  dateTimePickerCallback(data) {
    if ( data ) {
      this.onChange(data.selectedDates);
      this.writeValue(data.selectedDates);
      this.lastView = data.lastView;
    }
  }
}
