import { Component, HostBinding, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';

import { environment } from '@env/environment';
import {SessionService} from '@core/services';

@Component({
  selector: 'vs-footer',
  templateUrl: './footer.html',
  styleUrls: ['./footer.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  @HostBinding('class.vs-footer') hostClass = true;
  @Input() inline = false;
  appVersion = environment.APP_VERSION;
  isLoggedIn$ = this.sessionService.isLoggedIn$;

  constructor(private sessionService: SessionService) { }
}
