import {type} from '../util';
import {Action} from '@ngrx/store';
import {Venue, User} from '../models';

export class ActionTypes {
  static readonly LOAD_VENUES = type('[Favorite] Load venues');
  static readonly VENUES_LOADED = type('[Favorite] Venues loaded');
  static readonly ADD_VENUE = type('[Favorite] Add venue to favorites');
  static readonly VENUE_ADDED = type('[Favorite] Venue added to favorites');
  static readonly REMOVE_VENUE = type('[Favorite] Remove venue from favorites');
  static readonly VENUE_REMOVED = type('[Favorite] Venue removed from favorites');
  static readonly FAVORITE_FAILED = type('[Favorite] Favorite add or remove failed');
}

export class LoadVenuesAction implements Action {
  readonly type = ActionTypes.LOAD_VENUES;

  constructor(public payload: User) {}
}

export class VenuesLoadedAction implements Action {
  readonly type = ActionTypes.VENUES_LOADED;

  constructor(public payload: Venue[]) { }
}

export class AddVenueAction implements Action {
  readonly type = ActionTypes.ADD_VENUE;

  constructor(public payload: Venue) { }
}

export class VenueAddedAction implements Action {
  readonly type = ActionTypes.VENUE_ADDED;

  constructor(public payload: Venue) { }
}

export class RemoveVenueAction implements Action {
  readonly type = ActionTypes.REMOVE_VENUE;

  constructor(public payload: Venue) { }
}

export class VenueRemovedAction implements Action {
  readonly type = ActionTypes.VENUE_REMOVED;

  constructor(public payload: Venue) { }
}

export class FavoriteFailedAction implements Action {
  readonly type = ActionTypes.FAVORITE_FAILED;
}

export type Actions
  = LoadVenuesAction
  | VenuesLoadedAction
  | AddVenueAction
  | VenueAddedAction
  | RemoveVenueAction
  | VenueRemovedAction
  | FavoriteFailedAction;



