import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { BookingPeriod } from '../models';
import { BookingService } from './booking.service';

export interface ISearchCriteria {
  q: string;
  eventType: string;
  spaceCategory: string;
  guests: number;
  period: BookingPeriod|BookingPeriod[];
  amenities: string;
  atmosphere: string;
  category: string;
  venueIds: number[];
  order: string;
}

/**
 * Persistence for search criteria by using sessionStorage API
 */
@Injectable()
export class SearchCriteriaService {
  private _defaultSearchCriteria: Readonly<ISearchCriteria>;

  searchCriteria$: Observable<ISearchCriteria>;
  private _searchCritera$: BehaviorSubject<ISearchCriteria>;

  /**
   * 
   */
  constructor() {
    this._defaultSearchCriteria = this.emptySearch();

    if ( sessionStorage.getItem('vsuite/searchCriteria') ) {
      this._defaultSearchCriteria = JSON.parse(sessionStorage.getItem('vsuite/searchCriteria'));
    }

    this._searchCritera$ = new BehaviorSubject(this._defaultSearchCriteria);
    this.searchCriteria$ = this._searchCritera$.asObservable();
  }

  /**
   * 
   */
  emptySearch() : ISearchCriteria {
    return {
      q: null,
      eventType: null,
      spaceCategory: null,
      guests: null,
      period: null,
      amenities: '',
      atmosphere: '',
      category: '',
      venueIds: [],
      order: 'random'
    };
  }

  /**
   * 
   */
  update(next: Partial<ISearchCriteria>) {
    this.searchCriteria$.pipe(take(1)).subscribe(latest => {

      if ( next.eventType ) {
        next.spaceCategory = next.eventType;
      } else {
        next.spaceCategory = null;
      }

      const newVal = Object.assign({}, this._defaultSearchCriteria, latest, next);

      sessionStorage.setItem('vsuite/searchCriteria', JSON.stringify(newVal));
      this._searchCritera$.next(newVal);
    });
  }
}
