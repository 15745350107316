<div class="vs-datetime-availability-picker" #container>
  <div class="vs-datetime-availability-picker_calendar-section">
    <div class="vs-datetime-availability-picker_actions">
      <div class="vs-datetime-availability-picker_actions-button"
        [class.vs-datetime-availability-picker_actions-button--active]="activeView === 'perdaypart'"
           (click)="showActiveView('perdaypart')">
        {{ 'DATE_TIME.PER_DAYPART' | translate }}
      </div>
      <div class="vs-datetime-availability-picker_actions-button"
        [class.vs-datetime-availability-picker_actions-button--active]="activeView === 'perhour'"
           (click)="showActiveView('perhour')">
        {{ 'DATE_TIME.PER_HOUR' | translate }}
      </div>
    </div>

    <div class="vs-datetime-availability-picker_mini-cal_navigator">
      <i class="material-icons" (click)="prevMonth()">arrow_left</i>
      {{ activeMonth }} {{ activeYear }}
      <i class="material-icons" (click)="nextMonth()">arrow_right</i>
    </div>

    <div class="vs-datetime-availability-picker_mini-cal_header">
      <table *ngIf="activeView == 'perdaypart'">
        <thead>
        <tr>
          <th class="calendar-first-column"></th>
          <th>{{ 'DATE_TIME.MORNING' | translate }}</th>
          <th>{{ 'DATE_TIME.AFTERNOON' | translate }}</th>
          <th>{{ 'DATE_TIME.EVENING' | translate }}</th>
        </tr>
        </thead>
      </table>
      <table *ngIf="activeView == 'perhour'">
        <thead>
        <tr>
          <th class="calendar-first-column"></th>
          <th *ngFor='let number of  hourRange;'>
            {{ number % 2 !== 0 ? '' : number }}
          </th>
        </tr>
        </thead>
      </table>
    </div>

    <div class="vs-datetime-availability-picker_mini-cal_data">
      <table class="scroll-data" *ngIf="activeView == 'perdaypart'">
        <tbody>
        <ng-template ngFor let-w [ngForOf]="calendarData">
          <ng-template ngFor let-d [ngForOf]="w.days">
          <tr *ngIf="d.isCurrentMonth">
            <th class="calendar-first-column">
              <div class="day-short-small">{{ d.dayShortCode | translate }}</div>
              <div tabindex="{{d.dayOfMonth}}" id="day-number-{{d.dayOfMonth}}" class="day-number">{{ d.dayOfMonth }}</div>
            </th>
            <td *ngFor="let dp of d.dayParts"
                class="dayPartCell"
                [class.selected]="dp.isSelected"
                [class.is-past]="d.isPast"
                [class.not-selectable]="d.isPast || d.isToday || !dp.isAvailable"
                [class.outside-current]="!d.isCurrentMonth"
                (click)="!(d.isPast || d.isToday || !dp.isAvailable) && selectDate($event, d, dp)"
                data-iso="d.dateIso"
                data-av="dp.availability">
            </td>

            <td *ngIf="d.dayParts.length == 0"></td>
            <td *ngIf="d.dayParts.length == 0"></td>
            <td *ngIf="d.dayParts.length == 0"></td>
          </tr>
          </ng-template>
        </ng-template>
        </tbody>
      </table>

      <table class="scroll-data" *ngIf="activeView == 'perhour'">
        <tbody>
        <ng-template ngFor let-w [ngForOf]="calendarData">
          <ng-template ngFor let-d [ngForOf]="w.days">
            <tr *ngIf="d.isCurrentMonth">
              <th class="calendar-first-column">
                <div class="day-short-small">{{ d.dayShortCode | translate }}</div>
                <div tabindex="{{d.dayOfMonth}}" id="day-number-{{d.dayOfMonth}}" class="day-number">{{ d.dayOfMonth }}</div>
              </th>
              <td *ngFor="let h of d.hours"
                  [class.selected]="h.isSelected"
                  [class.is-past]="d.isPast"
                  [class.not-selectable]="d.isPast || d.isToday || !h.isAvailable"
                  [class.outside-current]="!d.isCurrentMonth"
                  (click)="!(d.isPast || d.isToday || !h.isAvailable) && selectHour($event, d, h)"
                  data-iso="d.dateIso"
                  data-av="dp.availability"></td>
            </tr>
          </ng-template>
        </ng-template>
        </tbody>
      </table>

    </div>
    <div class="vs-datetime-availability-picker_selected_times" *ngIf="activeView == 'perdaypart'">
      <span class="vs-datetime-availability-picker_selected_times_label" *ngFor="let dp of selectedDayParts">
        {{ dp.label }}
        <i class="material-icons vs-datetime-availability-picker_selected_times_label_remove" (click)="removeSelectedTime($event, dp.day)">clear</i>
      </span>
    </div>

    <div class="vs-datetime-availability-picker_selected_times" *ngIf="activeView == 'perhour'">
      <span class="vs-datetime-availability-picker_selected_times_label" *ngFor="let h of selectedHours">
        {{ h.label }}
        <i class="material-icons vs-datetime-availability-picker_selected_times_label_remove" (click)="removeSelectedHour($event, h.day)">clear</i>
      </span>
    </div>
    <div class="vs-datetime-availability-picker_buttons">
      <button (click)="confirm($event)"
              [disabled]="!isValid"
              mdl-button
              mdl-button-type="raised"
              mdl-colored="accent">
        {{ 'COMMON.APPLY' | translate }}
      </button>
    </div>
    <div class="vs-datetime-picker_buttons-placeholder"></div>
  </div>
</div>
