import {Injectable} from '@angular/core';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {catchError, exhaustMap, map, switchMap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';

import * as userActions from '../actions/user';
import * as favoriteActions from '../actions/favorite.actions';
import * as fromRoot from '../reducers/index';
import {User, Venue} from '../models';
import {FavoritesService, ToasterService, UserService} from '../services';

@Injectable()
export class FavoriteEffects {
  /**
   * Load users saved venues after he logged in
   */
  @Effect() login$ = this.actions$
    .pipe(ofType(userActions.ActionTypes.LOGIN_SUCCESS))
    .pipe(
      map((action: any) => action.payload),
      switchMap((user: User) => of(new favoriteActions.LoadVenuesAction(user)))
    );

  /**
   * Fetch saved venues from API
   */
  @Effect() loadFavoriteVenues$ = this.actions$
    .pipe(ofType(favoriteActions.ActionTypes.LOAD_VENUES))
    .pipe(
      map((action: any) => action.payload),
      switchMap((user: User) => {
        if (UserService.isVenue(user.role)) {
          return of([]);
        }
        return this.favoriteService.getFavoriteVenues().pipe(
          catchError(() => of(new favoriteActions.FavoriteFailedAction()))
        )
      }),
      switchMap((venues: Venue[]) => of(new favoriteActions.VenuesLoadedAction(venues)))
    );

  /**
   * Clear the favorites venues store on logout
   */
  @Effect() logout$ = this.actions$
    .pipe(ofType(userActions.ActionTypes.LOGOUT_SUCCESS))
    .pipe(
      switchMap((action: any) => of(new favoriteActions.VenuesLoadedAction([])))
    );

  /**
   * Save the venue to the API or show the auth dialog based
   * on whether or not the user is authenticated
   */
  @Effect() addFavoriteVenue$ = this.actions$
    .pipe(ofType(favoriteActions.ActionTypes.ADD_VENUE))
    .pipe(
      withLatestFrom(this.store.select(fromRoot.getUserIsLoggedIn)),
      exhaustMap(([action, loggedIn]: [any, boolean]) => {
        if ( ! loggedIn ) {
          // Save venue in local storage?
          this.store.dispatch(new userActions.ShowAuthDialogAction('login'));
          return of({type: 'NOOP'});
        }

        return this.favoriteService
          .addFavoriteVenue(action.payload.id)
          .pipe(
            switchMap(venue => of(new favoriteActions.VenueAddedAction(venue))),
            catchError(() => of(new favoriteActions.FavoriteFailedAction()))
          );
      })
    );

  @Effect() removeFavoriteVenue$ = this.actions$
    .pipe(ofType(favoriteActions.ActionTypes.REMOVE_VENUE))
    .pipe(
      map((action: any) => action.payload),
      switchMap((payload: any) => {
        return this.favoriteService
          .deleteFavoriteVenue(payload.id)
          .pipe(
            switchMap((venue: Venue) => of(new favoriteActions.VenueRemovedAction(venue))),
            catchError(() => of(new favoriteActions.FavoriteFailedAction()))
        );
      })
    );

  @Effect() favoriteFailed$ = this.actions$
    .pipe(
      ofType(favoriteActions.ActionTypes.FAVORITE_FAILED)
    )
    .pipe(
      switchMap(() => {
        this.toastService.show({
          message: this.transService.instant('FAVORITE.GENERIC_ERROR'),
          type: 'danger'
        });

        return of({type: 'NOOP'});
      })
    );

  constructor(
    private actions$: Actions,
    private favoriteService: FavoritesService,
    private store: Store<fromRoot.State>,
    private transService: TranslateService,
    private toastService: ToasterService) { }
}
