import { Component, OnChanges, Input, ViewEncapsulation, ChangeDetectionStrategy, HostBinding } from '@angular/core';

@Component({
  selector: 'vs-read-more',
  template: `
    <div class="read-more-text" [innerHTML]="visibleContent"></div>
    <a *ngIf="showToggle" class="more-info-link" (click)="toggleContent($event)">
      {{ (isCollapsed ? 'COMMON.READ_MORE' : 'COMMON.READ_LESS') | translate }}
    </a>
  `,
  styles: [`
    .read-more-text {
      white-space: pre-wrap;
    }
  `],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReadMoreComponent implements OnChanges {
  @HostBinding('class.vs-readmore') hostClass = true;
  @Input() content: string;
  @Input() maxLength: number = 200;

  visibleContent: string;
  isCollapsed: boolean = true;
  showToggle: boolean;

  ngOnChanges() {
    this.determineView();
  }

  determineView() {
    if ( !this.content || this.content.length <= this.maxLength ) {
      this.visibleContent = this.content;
      this.showToggle = false;
      return;
    }

    this.showToggle = true;

    if ( this.isCollapsed ) {
      this.visibleContent = this.content.substring(0, this.maxLength) + '...';
    } else {
      this.visibleContent = this.content;
    }
  }

  toggleContent($event) {
    $event.preventDefault();

    this.isCollapsed = !this.isCollapsed;
    this.determineView();
  }
}
