import { Component } from '@angular/core';
import { UserService } from '@core/services';
import { MdlDialogService } from '@angular-mdl/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'vs-forgot-password-page',
  templateUrl: './forgot-password-page.html'
})
export class ForgotPasswordPageComponent {
  loading: boolean = false;

  /**
   * 
   */
  constructor(
    private userService: UserService,
    private dialogService: MdlDialogService,
    private transService: TranslateService
  ) { }

  /**
   * 
   */
  sendInstructions(email: string) {
    this.loading = true;

    this.userService.forgotPassword(email)
      .subscribe((res) => {
        this.loading = false;
        this.dialogService.alert(this.transService.instant('FORGOT_PASS.SENDING_OK_TEXT'), 'OK', this.transService.instant('FORGOT_PASS.SENDING_OK_TITLE'));
      }, (res) => {
        this.loading = false;
        const body = res || {message: 'unknown'};
        this.dialogService.alert('Failed to reset password. Reason: ' + body.message, 'OK', 'Oops!');
      });
  }
}
