<section class="main-content">
  <div class="content-container">
    <div class="search_content">
      <h1 class="display-1" >{{ 'WHITE_LABEL.HEADLINE' | translate }}</h1>
      <p class="tagline">{{ 'WHITE_LABEL.TAGLINE' | translate }}</p>
      <div class="jumbo-actions">

        <button onclick="window.open('https://meetings.hubspot.com/niels53', '_blank')" class="mdl-button--xl" mdl-button mdl-button-type="raised" mdl-colored="accent">
          {{ 'WHITE_LABEL.PLAN_DEMO' | translate }}
        </button>
        <a (click)="navigateToTarget(platformadvantages)" rel="noreferrer" class="mdl-button--xl btn-schedule-demo" mdl-button mdl-button-type="raised" mdl-colored="primary">
          {{ 'HOME.DEMO' | translate }}
        </a>
      </div>
    </div>
    <div class="feature-img">
      <img class="feature-img__wrap" src="{{ 'WHITE_LABEL.HERO_IMAGE' | translate }}" width="1030" alt="Products" />
    </div>
  </div>
</section>
<section class="platform-advantage" #platformadvantages> 
  <div class="mdl-grid mdl-grid--no-spacing">
    <div class="mdl-cell--no-v-spacing mdl-cell--12-col">
      <section class="feature-benefit s-bg-white s-img-align-left ">
        <div class="feature-benefit__text-wrap align-left">
          <div class="feature-benefit__text ">
            <div class="feature-benefit__desc-wrap ">
              <span>{{ 'WHITE_LABEL.ADVANTAGES.1.SECTION' | translate }}</span>
              <h2 class="display-2">{{ 'WHITE_LABEL.ADVANTAGES.1.NAME' | translate }}</h2>
              <ul>
                <li>
                  {{ 'WHITE_LABEL.ADVANTAGES.1.TEXT_1' | translate }}
                </li>
                <li>
                  {{ 'WHITE_LABEL.ADVANTAGES.1.TEXT_2' | translate }}
                </li>
                <li>
                  {{ 'WHITE_LABEL.ADVANTAGES.1.TEXT_3' | translate }}
                </li>
              </ul>
              <a href="https://share.hsforms.com/1W5kQreImTL-nwOt8u8mr5w2g8h5" target="_blank" rel="noreferrer" class="mdl-button--xl btn-schedule-demo" mdl-button mdl-button-type="raised" mdl-colored="primary">
                {{ 'WHITE_LABEL.REGISTER_LABEL' | translate }} 
              </a>
            </div>
          </div>
        </div>
        <div class="feature-benefit__img-wrap s-img-left">
          <div class="feature-benefit__img-wrap-container">
            <img src="{{ 'WHITE_LABEL.ADVANTAGES.1.IMAGE' | translate }}" alt="Products" />
          </div>
        </div>
      </section>
      <section class="feature-benefit s-bg-white s-img-align-right ">
        <div class="feature-benefit__text-wrap align-left">
          <div class="feature-benefit__text ">
            <div class="feature-benefit__desc-wrap ">
              <span>{{ 'WHITE_LABEL.ADVANTAGES.2.SECTION' | translate }}</span>
              <h2 class="display-2">{{ 'WHITE_LABEL.ADVANTAGES.2.NAME' | translate }}</h2>
              <ul>
                <li>
                  {{ 'WHITE_LABEL.ADVANTAGES.2.TEXT_1' | translate }}
                </li>
                <li>
                  {{ 'WHITE_LABEL.ADVANTAGES.2.TEXT_2' | translate }}                </li>
                <li>
                  {{ 'WHITE_LABEL.ADVANTAGES.2.TEXT_3' | translate }}                </li>
              </ul>
              <a href="https://share.hsforms.com/1W5kQreImTL-nwOt8u8mr5w2g8h5" target="_blank" rel="noreferrer" class="mdl-button--xl btn-schedule-demo" mdl-button mdl-button-type="raised" mdl-colored="primary">
                {{ 'WHITE_LABEL.REGISTER_LABEL' | translate }} 
              </a>
            </div>
          </div>
        </div>
        <div class="feature-benefit__img-wrap s-img-right">
          <div class="feature-benefit__img-wrap-container">
            <img src="{{ 'WHITE_LABEL.ADVANTAGES.2.IMAGE' | translate }} " alt="Chat" />
          </div>
        </div>
      </section>
    </div>
  </div>
</section>
<!-- section class="quote">
  <div class="mdl-grid mdl-grid--no-spacing">
    <div class="mdl-cell--no-v-spacing mdl-cell--12-col">
      <span class="quote-mark"></span>
      <span class="quote-txt">{{ 'WHITE_LABEL.QUOTE' | translate }}</span>
      <div class="quote-person">
        <img src="https://res.cloudinary.com/venuesuite-com/image/upload/v1547481425/marketing/nbc_partner.png" />
        <div class="quote-person__desc">
          <b>Rene Steenbakkers</b>
          <p>Aristo Meeting Center</p>
        </div>
      </div>
    </div>
  </div>
</section -->
<section id="content" class="platform-advantage"> 
  <div class="mdl-grid mdl-grid--no-spacing">
    <div class="mdl-cell--no-v-spacing mdl-cell--12-col">
      <section class="feature-benefit s-bg-white s-img-align-left ">
        <div class="feature-benefit__text-wrap align-left">
          <div class="feature-benefit__text ">
            <div class="feature-benefit__desc-wrap ">
              <span>{{ 'WHITE_LABEL.ADVANTAGES.3.SECTION' | translate }}</span>
              <h2 class="display-2">{{ 'WHITE_LABEL.ADVANTAGES.3.NAME' | translate }}</h2>
              <ul>
                <li>
                  {{ 'WHITE_LABEL.ADVANTAGES.3.TEXT_1' | translate }}
                </li>
                <li>
                  {{ 'WHITE_LABEL.ADVANTAGES.3.TEXT_2' | translate }}
                </li>
                <li>
                  {{ 'WHITE_LABEL.ADVANTAGES.3.TEXT_3' | translate }}
                </li>
              </ul>
              <a href="https://share.hsforms.com/1W5kQreImTL-nwOt8u8mr5w2g8h5" target="_blank" rel="noreferrer" class="mdl-button--xl btn-schedule-demo" mdl-button mdl-button-type="raised" mdl-colored="primary">
                {{ 'WHITE_LABEL.REGISTER_LABEL' | translate }} 
              </a>
            </div>
          </div>
        </div>
        <div class="feature-benefit__img-wrap s-img-left">
          <div class="feature-benefit__img-wrap-container">
            <img src="{{ 'WHITE_LABEL.ADVANTAGES.3.IMAGE' | translate }} " alt="Exposure" />
          </div>
        </div>
      </section>
      <section class="feature-benefit s-bg-white s-img-align-right ">
        <div class="feature-benefit__text-wrap align-left">
          <div class="feature-benefit__text ">
            <div class="feature-benefit__desc-wrap ">
              <span>{{ 'WHITE_LABEL.ADVANTAGES.4.SECTION' | translate }}</span>
              <h2 class="display-2">{{ 'WHITE_LABEL.ADVANTAGES.4.NAME' | translate }}</h2>
              <ul>
                <li>
                  {{ 'WHITE_LABEL.ADVANTAGES.4.TEXT_1' | translate }}
                </li>
                <li>
                  {{ 'WHITE_LABEL.ADVANTAGES.4.TEXT_2' | translate }}                </li>
                <li>
                  {{ 'WHITE_LABEL.ADVANTAGES.4.TEXT_3' | translate }}                </li>
              </ul>
              <a href="https://share.hsforms.com/1W5kQreImTL-nwOt8u8mr5w2g8h5" target="_blank" rel="noreferrer" class="mdl-button--xl btn-schedule-demo" mdl-button mdl-button-type="raised" mdl-colored="primary">
                {{ 'WHITE_LABEL.REGISTER_LABEL' | translate }} 
              </a>
            </div>
          </div>
        </div>
        <div class="feature-benefit__img-wrap s-img-right">
          <div class="feature-benefit__img-wrap-container">
            <img src="{{ 'WHITE_LABEL.ADVANTAGES.4.IMAGE' | translate }} " alt="Chat" />
          </div>
        </div>
      </section>
      <section class="feature-benefit s-bg-white s-img-align-left ">
        <div class="feature-benefit__text-wrap align-left">
          <div class="feature-benefit__text ">
            <div class="feature-benefit__desc-wrap ">
              <span>{{ 'WHITE_LABEL.ADVANTAGES.5.SECTION' | translate }}</span>
              <h2 class="display-2">{{ 'WHITE_LABEL.ADVANTAGES.5.NAME' | translate }}</h2>
              <ul>
                <li>
                  {{ 'WHITE_LABEL.ADVANTAGES.5.TEXT_1' | translate }}
                </li>
                <li>
                  {{ 'WHITE_LABEL.ADVANTAGES.5.TEXT_2' | translate }}
                </li>
                <li>
                  {{ 'WHITE_LABEL.ADVANTAGES.5.TEXT_3' | translate }}
                </li>
              </ul>
              <a href="https://share.hsforms.com/1W5kQreImTL-nwOt8u8mr5w2g8h5" target="_blank" rel="noreferrer" class="mdl-button--xl btn-schedule-demo" mdl-button mdl-button-type="raised" mdl-colored="primary">
                {{ 'WHITE_LABEL.REGISTER_LABEL' | translate }} 
              </a>
            </div>
          </div>
        </div>
        <div class="feature-benefit__img-wrap s-img-left">
          <div class="feature-benefit__img-wrap-container">
            <img src="{{ 'ADD_VENUE.ADVANTAGES.3.IMAGE' | translate }}" alt="Exposure" />
          </div>
        </div>
      </section>
    </div>
  </div>
</section>
<section class="new_way">
  <div class="mdl-grid mdl-grid--no-spacing">
    <div class="mdl-cell--no-v-spacing mdl-cell--12-col">
      <ng-container>
        <div class="new_way_container">
          <div class="new_way_container__wrap">
            <div class="new_way_container__wrap-text ">
              <div class="new_way_container__wrap-text-desc-wrap ">
                <h2 class="display-2">{{ 'WHITE_LABEL.USP_TITLE' | translate }}</h2>
              </div>
            </div>
          </div>
    
          <div class="new_way_container-list">
            <div class="usp_item">
              <h3 class="display-3">{{ 'WHITE_LABEL.USP_1_TITLE' | translate }}</h3>
              <p>{{ 'WHITE_LABEL.USP_1_DESC' | translate }}</p>
            </div>
    
            <div class="usp_item">
              <h3 class="display-3"> {{ 'WHITE_LABEL.USP_2_TITLE' | translate }}</h3>
              <p>{{ 'WHITE_LABEL.USP_2_DESC' | translate }}</p>
            </div>
    
            <div class="usp_item">
              <h3 class="display-3">{{ 'WHITE_LABEL.USP_3_TITLE' | translate }}</h3>
              <p>{{ 'WHITE_LABEL.USP_3_DESC' | translate }}</p>
            </div>
    
            <div class="usp_item">
              <h3 class="display-3">{{ 'WHITE_LABEL.USP_4_TITLE' | translate }}</h3>
              <p>{{ 'WHITE_LABEL.USP_4_DESC' | translate }}</p>
            </div>

          </div>
        </div>
      </ng-container>
    </div>
  </div>
</section>
<section class="main-content" id="register">
  <div class="content-container">
    <div class="mdl-grid mdl-grid--no-spacing">
      <div class="mdl-cell mdl-cell--12-col cell-middle">
        <div class="search_content">
          <h2 class="display-2" >{{ 'WHITE_LABEL.CONTACT_US_TITLE' | translate }}</h2>
          <div class="jumbo-actions">
            <button onclick="window.open('https://meetings.hubspot.com/niels53', '_blank')" class="mdl-button--xl" mdl-button mdl-button-type="raised" mdl-colored="accent">
              {{ 'WHITE_LABEL.REGISTER_LABEL' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<vs-footer></vs-footer>