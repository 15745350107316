import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'venueFeatureIcon'
})
export class VenueFeatureIconPipe implements PipeTransform {
  iconMap: {[label: string]: string};

  constructor() {
    this.iconMap = {};
    this.iconMap['social mobility employer'] = 'icon-check-circle';
    this.iconMap['social distance protocol'] = 'icon-covid';
    this.iconMap['Free parking'] = 'icon-free-parking';
    this.iconMap['hybrid meeting'] = 'icon-hybrid-meetings';
    this.iconMap['Public transport'] = 'icon-train';
    this.iconMap['By car'] = 'icon-car';
    this.iconMap['Paid parking'] = 'icon-paid-parking';
    this.iconMap['Elevator'] = 'icon-elevator';
    this.iconMap['Own kitchen'] = 'icon-inhouse-catering';
    this.iconMap['Eco-friendly'] = 'icon-eco-friendly';
    this.iconMap['Wheelchair access'] = 'icon-wheelchair';
    this.iconMap['Airconditioning'] = 'icon-air-conditioning';
    this.iconMap['Restaurant'] = 'icon-restaurant';
    this.iconMap['WIFI'] = 'icon-wifi';
  }

  transform(value: string) {
    return this.iconMap[value] || 'icon-check-circle';
  }
}
