import { RouterModule } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { routes } from './app.routes';
import { environment } from '@env/environment';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.TRANSLATION_FILES);
}

export const APP_IMPORTS = [
  RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }),
  CoreModule,
  SharedModule,
  HttpClientModule,
  ScrollToModule.forRoot(),
  AgmCoreModule.forRoot({
    apiKey: environment.GOOGLEMAPS_API_KEY
  }),
  TranslateModule.forRoot({
    loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
    }
  })
];
