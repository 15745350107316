export const mapStyling = [
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "on",
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "on",
        "lightness": 50
      }
    ]
  }
];
