import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { EquipmentProduct } from '../models';
import { SessionService } from './session.service';
import { EquipmentService } from './equipment.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class UserEquipmentService extends EquipmentService {
  venueId: number;

  constructor(http: HttpClient, private sessionService: SessionService) {
    super(http);

    this.sessionService.user$.subscribe(user => {
      this.venueId = (user !== null ? user.venue_id : null);
    });
  }

  /**
   * 
   */
  setVenueId(val: number) {
    this.venueId = val;
  }

  /**
   * 
   */
  readAll(): Observable<any> {
    const requestUrl = `${environment.API_URL}/venues/${this.venueId}/admin/equipment`;
    return this.http.get<EquipmentProduct[]>(requestUrl);
  }

  /**
   * 
   */
  read(equipmentId: number): Observable<EquipmentProduct> {
    return this.http.get<EquipmentProduct>(`${environment.API_URL}/venues/${this.venueId}/admin/equipment/${equipmentId}`);
  }

  /**
   * 
   */
  create(equipment: Partial<EquipmentProduct>): Observable<any> {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');

    const body = JSON.stringify(equipment);

    return this.http.post(`${environment.API_URL}/venues/${this.venueId}/admin/equipment`,  body, requestOptions);
  }

  /**
   * 
   */
  update(equipment: EquipmentProduct) {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(equipment);

    return this.http
      .put(`${environment.API_URL}/venues/${this.venueId}/admin/equipment/${equipment.id}`,  body, requestOptions);
  }

  /**
   * 
   */
  delete(equipmentId: number) {
    return this.http.delete(`${environment.API_URL}/venues/${this.venueId}/admin/equipment/${equipmentId}`);
  }
}
