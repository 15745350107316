import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'vs-white-label',
  templateUrl: './white-label-page.html',
  styleUrls: ['./white-label-page.scss']
})
export class WhiteLabelPageComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private translate: TranslateService) { }

  ngOnInit() {
    this.setupSEO();
  }

  public setupSEO() {
    this.translate.get(['PAGES.WHITELABEL.TITLE', 'PAGES.WHITELABEL.META'])
      .subscribe(translations => {
        this.titleService.setTitle(translations['PAGES.WHITELABEL.TITLE']);
        this.metaService.updateTag(
          {name: 'description', content: translations['PAGES.WHITELABEL.META']}
        );
      });
  }

  /**
   *
   */
  navigateToTarget($element: any) {
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
}
