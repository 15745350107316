import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { CateringProduct } from '../models';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CateringService {

  static readonly FOOD_CATEGORY_COMPONENTS = [
    {label: 'Breakfast', value: 'breakfast'},
    {label: 'Lunch', value: 'lunch'},
    {label: 'Dinner', value: 'dinner'},
    {label: 'Snacks', value: 'snacks'},
  ];

  static readonly FOOD_CATEGORY = [
    'breakfast',
    'lunch',
    'dinner',
    'snacks',
  ];

  static readonly BEVERAGE_CATEGORY_COMPONENTS = [
    {label: 'Coffee & Tea', value: 'coffee_and_tea'},
    {label: 'Softdrinks', value: 'softdrinks'},
    {label: 'Water', value: 'water'},
    {label: 'Alcoholic beverages', value: 'alcoholic'}
  ];

  static readonly BEVERAGE_CATEGORY = [
    'coffee_and_tea',
    'softdrinks',
    'water',
    'alcoholic'
  ];

  http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  /**
   * 
   */
  getVenueCateringOffers(
    venueId: number,
    spaceId?: number,
    eventType?: string,
    period?: {start: string, end: string},
    organizationId?: number
    ): Observable<CateringProduct[]> {

    let requestUrl = `${environment.API_URL}/venues/${venueId}/catering?`;

    if ( spaceId ) {
      requestUrl += '&space_id='+spaceId.toString();
    }

    if ( eventType ) {
      requestUrl += '&type='+eventType;
    }

    if ( period && period.start && period.end ) {
      requestUrl += '&period[start]='+encodeURIComponent(period.start)+'&period[end]='+encodeURIComponent(period.end);
    }

    if ( organizationId ) {
      requestUrl += '&organization_id='+organizationId;
    }

    return this.http.get<CateringProduct[]>(requestUrl);
  }

  /**
   * 
   */
  getVenueCateringOffer(venueId: number, cateringOfferId: number): Observable<CateringProduct> {
    return this.http
      .get<CateringProduct>(`${environment.API_URL}/venues/${venueId}/catering/${cateringOfferId}`);
  }
}
