<div class="vs-photo-slider">
  <ng-container *ngIf="interval > 0">
    <ng-container *ngFor="let imageSrc of images; let i = index">
      <img
        class="slider-img"
        [@imageState]="i === visibleImageIndex ? 'active' : 'inactive'"
        [alt]="'Image ' + i"
        [src]="imageSrc|imageRatio: imageTransform:imageWidth"
        (click)="imageClick($event, i)" />
    </ng-container>
  </ng-container>
  <ng-container *ngIf="interval === 0">
    <img (click)="imageClick($event, 0)" alt="Slider image" class="slider-img slider-img-fullscreen" [src]="images[0]|imageRatio: imageTransform:imageWidth" />
  </ng-container>
</div>
<div class="vs-photo-slider-modal" *ngIf="isFullScreenMode" (click)="closeFullScreen()">
  <div class="modal-header">
    <button (click)="closeFullScreen()"><i class="material-icons">close</i></button>
  </div>
  <div class="modal-body">
    <div class="slider-nav">
      <button (click)="prevItem($event)"><i class="material-icons">chevron_left</i></button>
    </div>
    <div class="active-image">
      <img [src]="images[visibleImageIndex]" (click)="nextItem($event)" />
    </div>
    <div class="slider-nav">
      <button (click)="nextItem($event)"><i class="material-icons">chevron_right</i></button>
    </div>
  </div>
  <div class="modal-footer"></div>
</div>
