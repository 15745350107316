import { Component, InjectionToken, Inject, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, ViewEncapsulation, HostBinding } from '@angular/core';
import { Space } from '@core/models';
import { MdlDialogReference } from '@angular-mdl/core';
import { ImageRatioPipe } from '@shared/pipes';

export const SPACE_VALUE_PROVIDER = new InjectionToken<Space>('space');

@Component({
  selector: 'vs-space-detail-modal',
  templateUrl: './space-detail-modal.html',
  styleUrls: ['./space-detail-modal.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class SpaceDetailModalComponent implements OnInit {

  @HostBinding('class.vs-space-detail-modal') hostClass = true;
  visibleImageIndex: number;
  visibleImageUrl: string;
  displayCarousel: boolean = false;
  space: Space;

  imageTransform: string = '4:3'
  imageWidth: string = '800'
  imageRatio: ImageRatioPipe;

  constructor(
    private cref: ChangeDetectorRef,
    private dialogRef: MdlDialogReference,
    @Inject(SPACE_VALUE_PROVIDER) space: Space) {

    this.space = space;
    this.imageRatio = new ImageRatioPipe();
  }

  ngOnInit() {
    if ( this.space.images.length > 1 ) {
      this.displayCarousel = true;
    }

    this.setVisibleImage(0);
  }

  /**
   * 
   */
  setVisibleImage(index) {
    this.visibleImageIndex = index;
    this.visibleImageUrl = this.space.images[index];
    this.cref.detectChanges();
  }

  /**
   * 
   */
  prevItem($event) {
    $event.stopPropagation();
    this.setVisibleImage(this.getPrevIndex(this.visibleImageIndex));
    this.preloadImage(this.getPrevIndex(this.visibleImageIndex));
  }

  /**
   * 
   */
  nextItem($event) {
    $event.stopPropagation();
    this.setVisibleImage(this.getNextIndex(this.visibleImageIndex));
    this.preloadImage(this.getNextIndex(this.visibleImageIndex));
  }

  /**
   * 
   */
  close() {
    this.dialogRef.hide();
  }

  /**
   * 
   */
  navigateToCheckout() {
    this.dialogRef.hide(true);
  }

  /**
   * 
   */
  preloadImage(index) {
    const trImage = this.imageRatio.transform(this.space.images[index], this.imageTransform, this.imageWidth);
    let image = new Image();
    setTimeout(() => { image.src = trImage;}, 0);
  }
  
  /**
   * 
   */
  getNextIndex(index : number) : number {
    if ( (index + 1 ) >= this.space.images.length ) {
      return 0;
    }
    return index + 1;
  }

  /**
   * 
   */
  getPrevIndex(index) {
    if ( (index - 1 ) < 0 ) {
      return this.space.images.length - 1;
    }
    return index - 1;
  }
  
}
