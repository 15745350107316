import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {Venue} from '@core/models';
import {EventTrackerService} from '@core/services';
import {FavoritesFacade} from '@core/facades/favorites.facade';

@Component({
  selector: 'vs-favorite',
  templateUrl: './favorite.html',
  styleUrls: ['./favorite.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavoriteComponent implements OnInit, OnDestroy {
  @Input() venue: Venue;
  @HostBinding('class.vs-favorite') hostClass = true;
  destroyed$ = new Subject<void>();
  selected: boolean;

  /**
   * 
   */
  constructor(
    private favoriteFacade: FavoritesFacade, 
    private eventTrackerService: EventTrackerService,
    private cref: ChangeDetectorRef
    ) { }

  /**
   * 
   */
  ngOnInit() {
    this.favoriteFacade
      .isFavorited$(this.venue.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(selected => {
        if ( this.selected === selected ) {
          return;
        }

        this.selected = selected;
        this.cref.markForCheck();
      });
  }

  /**
   * 
   */
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * 
   */
  toggleFavorite($event) {
    $event.stopPropagation();

    this.cref.markForCheck();

    if ( this.selected ) {
      this.favoriteFacade.removeFavorite(this.venue);
      this.eventTrackerService.trackEvent('delFavourite', 'button');
    } else {
      this.favoriteFacade.addFavorite(this.venue);
      this.eventTrackerService.trackEvent('addFavourite', 'button');
    }

    return false;
  }
}
