import { Injectable, Inject } from '@angular/core';

import { environment } from '@env/environment';
import { DOCUMENT } from '@angular/common';


@Injectable()
export class WidgetPreviewService {

  /**
   * 
   */
  constructor(@Inject(DOCUMENT) private document: any) { }

  /**
   * Widget Preview
   */
  getWidgetPreview(formValue, venueId:number) {

    this.removeIframe();

    window['venuesuiteSettings'] = {
      position: 'bottom-'+formValue.position, // Can be bottom-right, bottom-left or bottom-center
      color: formValue.colour, // defaults to #ed5052
      textColor: formValue.textColor, // defaults to #ffffff
      language: formValue.language.value.toLowerCase(), // must be an ISO 639 language code
      venueId: venueId, // Venue id
      eventType: formValue.event_types, // Venue event types
      spaces: formValue.spaces, // Venue spaces
      arrangements: formValue.arrangements, // Venue products
      defaultGuests: formValue.default_guests,
      defaultDuration: formValue.start_time +'-'+ formValue.end_time,
      activated: true,
      show_tax: formValue.show_tax === 'true'
    }

    if (!window.hasOwnProperty('vsbwBuildWidget')) {
        var script = this.document.createElement('script');
        var body = this.document.getElementsByTagName('body')[0];

        script.async = true;
        script.defer = true;
        script.src = environment.WIDGET_URL;

        body.appendChild(script);
    }
    else {
        window['vsbwBuildWidget']();
    }
  }

  /**
   * Remove Iframe if exists
   */
  removeIframe() {
    var venuesuiteButton = this.document.getElementsByClassName("venuesuiteButton");
    var venuesuiteContainer = this.document.getElementsByClassName("venuesuiteContainer");
    if ( venuesuiteButton.length > 0 ||venuesuiteContainer.length > 0 ) {
      venuesuiteContainer[0].parentNode.removeChild(venuesuiteContainer[0]);
      venuesuiteButton[0].parentNode.removeChild(venuesuiteButton[0]);
    }
  }
}
