import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { isPresent } from '../util';

export enum LoadingBarEventType {
  PROGRESS,
  HEIGHT,
  COLOR,
  VISIBLE,
  COMPLETED
}

export class LoadingBarEvent {
  constructor(public type: LoadingBarEventType, public value: any) {}
}

@Injectable()
export class LoadingBarService {
  interval = 500; // in milliseconds
  private _progress = 0;
  private _intervalCounterId: any = 0;
  private _visible: boolean;
  private eventSource: Subject<LoadingBarEvent> = new Subject<LoadingBarEvent>();
  public events: Observable<LoadingBarEvent> = this.eventSource.asObservable();

  constructor() {}

  set progress(value: number) {
    if ( isPresent(value) ) {
      if (value > 0) {
        this.visible = true;
      }

      this._progress = value;
      this.emitEvent(new LoadingBarEvent(LoadingBarEventType.PROGRESS, this._progress));
    }
  }

  get progress(): number {
    return this._progress;
  }

  get visible(): boolean {
    return this._visible;
  }

  set visible(value: boolean) {
    if (isPresent(value)) {
      this._visible = value;
      this.emitEvent(new LoadingBarEvent(LoadingBarEventType.VISIBLE, this._visible));
    }
  }

  private emitEvent(event: LoadingBarEvent) {
    if (this.eventSource) {
      // Push up a new event
      this.eventSource.next(event);
    }
  }

  start() {
    this.stop();
    this.visible = true;
    this._intervalCounterId = setInterval(() => {
      this.progress++;
      if (this.progress === 100) {
          this.complete();
      }
    }, this.interval);
  }

  stop() {
    if (this._intervalCounterId) {
      clearInterval(this._intervalCounterId);
      this._intervalCounterId = null;
    }
  }

  complete() {
    this.progress = 100;

    this.stop();

    setTimeout(() => {
      this.visible = false;
      setTimeout(() => {
        this.progress = 0;
        this.emitEvent(new LoadingBarEvent(LoadingBarEventType.COMPLETED, 100));
      }, 250);
    }, 250);
  }
}
