import { PipeTransform, Pipe } from '@angular/core';

import { environment } from '@env/environment';

@Pipe({name: 'imageRatio'})
export class ImageRatioPipe implements PipeTransform {
  transform(imageUrl: string, ratio = '4:3', width = '960', quality = '60'): string {
    if ( !imageUrl ) {
      return environment.IMG_PLACEHOLDER;
    }

    if (imageUrl.indexOf('cloudinary') === -1) {
      return imageUrl;
    }

    const urlParts = imageUrl.split('/');
    let transformUrlSegment = `w_${width},q_${quality},c_fill`;
    if (ratio) {
        transformUrlSegment += `,ar_${ratio}`;
    }
    urlParts.splice(6, 0, transformUrlSegment);
    return urlParts.join('/');
  }
}
