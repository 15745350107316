import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';

import { ArrangementService } from './arrangement.service';
import { CateringService } from './catering.service';
import { EquipmentService } from './equipment.service';
import { AccomodationService } from './accomodation.service';
import { CateringProduct, ArrangementProduct, EquipmentProduct, AccomodationProduct } from '../models';

@Injectable()
export class ProductService {
  constructor(
    private arrangementService: ArrangementService,
    private cateringService: CateringService,
    private equipmentService: EquipmentService,
    private accomodationService: AccomodationService) { }

  getVenueProducts(venueId: number, spaceId?: number): Observable<[CateringProduct[], ArrangementProduct[], EquipmentProduct[], AccomodationProduct[]]> {
    return forkJoin([
      this.cateringService.getVenueCateringOffers(venueId),
      this.arrangementService.getVenueArrangements(venueId, spaceId),
      this.equipmentService.getAllVenueEquipment(venueId),
      this.accomodationService.getAll(venueId)
    ]);
  }
}
