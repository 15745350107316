import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import * as Dinero from 'dinero.js/build/cjs/dinero.js';

@Directive({
  selector: 'input[dirMoney]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MoneyDirective,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: MoneyDirective,
      multi: true
    }
  ]
})
export class MoneyDirective implements ControlValueAccessor, Validator {
  private _modelValue: any;
  set modelValue(value: any) {
    this._modelValue = value;
  }

  get modelValue() {
    return this._modelValue;
  }

  isValid = false;
  onChange: Function = () => {};
  onTouched: Function = () => {};

  constructor(private element: ElementRef, private renderer: Renderer2) { }

  @HostListener('input', ['$event']) onInputChange($event) {
    const rawInputValue: string = this.element.nativeElement.value;

    if ( ! rawInputValue ) {
      this.isValid = true;
      this.modelValue = null;
      this.onChange(0);
      return;
    }

    const numVal: number = +(rawInputValue.replace(/,/g, '.'));
    if ( ! isNaN(numVal) ) {
      try {
        this.isValid = true;
        const cents: number = +Math.round(numVal * 100).toFixed();
        this.modelValue = Dinero({amount: cents});
        this.onChange(this.modelValue.getAmount());
        return;
      } catch (err) {
        console.log(err);
      }
    }

    // Set invalid state
    this.isValid = false;
    this.modelValue = null;
    this.onChange(0);
  }

  @HostListener('blur', ['$event']) onBlur($event) {
    this.render();
  }

  /**
   * Render the model value
   */
  render() {
    if ( this.modelValue ) {
      this.renderer.setProperty(this.element.nativeElement, 'value', this.modelValue.toFormat());
    } else {
      this.renderer.setProperty(this.element.nativeElement, 'value', '');
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Recieve decimal value (still to be divided by 100)
   *
   * @param value
   */
  writeValue(value: any): void {
    if ( value ) {
      this.modelValue = this._parseCents(value);
    } else {
      this.isValid = true;
      this.modelValue = null;
    }

    this.render();
  }

  validate(c: AbstractControl): ValidationErrors {
    if ( this.isValid ) {
      return null;
    }

    return {
      validateAmount: false
    };
  }

  private _parseCents(value: any): any {
    let modelValue: any;

    try {
      const cents = +value;

      if ( isNaN(cents) ) {
        throw new Error('Not a number');
      }

      modelValue = Dinero({amount: cents});
    } catch (err) {
      modelValue = null;
    }

    this.isValid = modelValue ? true : false;
    return modelValue;
  }
}
