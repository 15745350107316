import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input, OnInit, OnDestroy, ChangeDetectorRef, HostListener } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Subject, interval } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ScrollBlockService } from '@core/services';

@Component({
  selector: 'vs-photo-slider',
  templateUrl: './photo-slider.html',
  styleUrls: ['./photo-slider.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('imageState', [
      state('inactive', style({opacity: 0})),
      state('active',   style({opacity: 1})),
      transition('inactive <=> active', animate('1s ease-in-out'))
    ])
  ]
})
export class PhotoSliderComponent implements OnInit, OnDestroy {

  /**
   * The media to show in the slider
   */
  @Input() images: string[] = [];

  /**
   * Whether or not to auto rotate the media.
   */
  @Input() interval = 5000;

  /**
   * Start the media slider with a random image
   */
  @Input() startWithRandomImage = false;

  /**
   * Whether or not to open the media in a full screen modal
   */
  @Input() allowFullscreen = false;

  @Input() imageTransform: string = '4:3'
  @Input() imageWidth: string = '1920'

  isFullScreenMode = false;
  visibleImageIndex = 0;
  private _destroyed$: Subject<void> = new Subject<void>();

  constructor(
    private cref: ChangeDetectorRef,
    private scrollBlockService: ScrollBlockService) { }

  ngOnInit() {
    if ( this.startWithRandomImage && this.images.length > 1 ) {
      this.visibleImageIndex = Math.floor(Math.random() * (this.images.length - 1));
    }

    if ( this.interval ) {
      interval(this.interval)
        .pipe(takeUntil(this._destroyed$))
        .subscribe(() => this.nextItem(null));
    }
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  imageClick($event, index: number) {
    if ( ! this.allowFullscreen ) {
      return;
    }

    if ( ! this.isFullScreenMode ) {
      this.openFullScreen();
    } else {
      this.closeFullScreen();
    }
  }

  openFullScreen(imageIndex?: number) {
    if ( imageIndex >= 0 && this.images[imageIndex] !== undefined) {
      this.visibleImageIndex = imageIndex;
    }

    this.scrollBlockService.blockScrolling();
    this.isFullScreenMode = true;
    this.cref.detectChanges();
  }

  closeFullScreen() {
    this.scrollBlockService.enableScrolling();
    this.isFullScreenMode = false;
    this.cref.detectChanges();
  }

  nextItem($event) {
    if ( $event ) {
      $event.stopPropagation();
    }

    if ( this.visibleImageIndex >= this.images.length - 1 ) {
      this.visibleImageIndex = 0;
    } else {
      this.visibleImageIndex++;
    }

    this.cref.detectChanges();
  }

  prevItem($event) {
    if ( $event ) {
      $event.stopPropagation();
    }

    if ( this.visibleImageIndex === 0 ) {
      this.visibleImageIndex = this.images.length - 1;
    } else {
      this.visibleImageIndex--;
    }

    this.cref.detectChanges();
  }

  @HostListener('window:keyup', ['$event']) keyEvent(event: KeyboardEvent) {
    if ( this.isFullScreenMode ) {
      if ( event.keyCode === 27 ) {
        this.closeFullScreen();
      } else if ( event.keyCode === 39 ) {
        this.nextItem(null);
      } else if ( event.keyCode === 37 ) {
        this.prevItem(null);
      }
    }
  }
}
