import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'setupIcon'
})
export class SetupIconPipe implements PipeTransform {
  setupIconMap: {[label: string]: string};

  constructor() {
    this.setupIconMap = {};
    this.setupIconMap['Standing'] = 'icon-standing';
    this.setupIconMap['Cabaret'] = 'icon-cabaret';
    this.setupIconMap['Carre'] = 'icon-carre';
    this.setupIconMap['Circle'] = 'icon-circle';
    this.setupIconMap['Exam'] = 'icon-examen';
    this.setupIconMap['School'] = 'icon-school';
    this.setupIconMap['Theater'] = 'icon-theater';
    this.setupIconMap['U-Shape'] = 'icon-u-form';
    this.setupIconMap['Party'] = 'icon-party';
    this.setupIconMap['Dinner'] = 'icon-dinner';
    this.setupIconMap['Boardroom'] = 'icon-boardroom';
  }

  transform(value: string) {
    return this.setupIconMap[value];
  }
}
