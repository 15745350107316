import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

import { User } from '@core/models';
import * as fromRoot from '@core/reducers/index';
import * as userActions from '@core/actions/user';
import { AppRoles } from '@core/roles.enum';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'vs-nav',
  templateUrl: './nav.html',
  styleUrls: ['./nav.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class NavComponent implements OnChanges, OnInit {
  @Input() navItems: Array<any>;
  @Input() transparentNav: boolean;
  @Input() isAuthenticated: boolean;
  @Input() user: User;

  @Output() readonly signOut = new EventEmitter();
  @Output() readonly currentVenue = new EventEmitter();

  
  userName: string;
  userAvatar: string;
  showMobileMenu: boolean;
  isOrganizationManager: boolean;
  isOrganization: boolean;

  transparentLogo = environment.TRANSPARENT_ICON;
  mobileLogo = environment.MOBILE_LOGO;
  deskTopLogo = environment.DESKTOP_LOGO;

    constructor(
    private router: Router,
    private store: Store<fromRoot.State>) { }

  /**
   * 
   */
  ngOnInit() {
    this.router
      .events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationEnd) => {
        this.showMobileMenu = false;
      });

  }

  /**
   * 
   */
  ngOnChanges(changes) {
    this.userName = this.isAuthenticated ? this.user.first_name : '';
    this.userAvatar = this.isAuthenticated ? this.user.avatar : '';
    this.isOrganizationManager = this.isAuthenticated ? this.user.role == AppRoles.ORGANIZATION_MANAGER : false;
    this.isOrganization = this.user && this.user.organization_id && this.user.organization_id > 0;
  }

  /**
   * 
   */
  showAuthDialog($event, tab: string) {
    $event.preventDefault();
    this.store.dispatch(new userActions.ShowAuthDialogAction(tab));
  }

  /**
   * 
   */
  toggleMobileMenu($event) {
    this.showMobileMenu = !this.showMobileMenu;
  }
}
