<div *ngIf="isVisible > 1" class="vs-venue-switcher">
  <vs-formfield size="sm">
    <ng-select
      #venueSwitcher
      [clearable]="false"
      [(ngModel)]="selectedVenueId"
      (ngModelChange)="updateSelectedVenue($event)"
      [items]="filteredVenues"
      bindLabel="name"
      bindValue="id">
      <ng-template ng-header-tmp>
        <input
          [placeholder]="'Search venues ('+userVenues.length+')'"
          style="width: 100%;"
          type="text"
          (input)="venueSwitcher.filter($event.target.value)" />
      </ng-template>
    </ng-select>
  </vs-formfield>
</div>
