import { Component, Input, ViewEncapsulation, HostBinding } from '@angular/core';
import { VenueFeatureIconPipe } from '../../pipes';
import { TranslateService } from '@ngx-translate/core';
import { slugify } from '@core/util';

@Component({
  selector: 'vs-venue-amenities',
  template: `
    <i *ngFor="let amenity of amenitiesFiltered"
      class="icon venue-amenity-icon"
      [mdl-tooltip]="amenityTooltip(amenity)"
      mdl-tooltip-position="top"
      [ngClass]="amenityIcon(amenity)"></i>
  `,
  encapsulation: ViewEncapsulation.None
})
export class VenueAmenitiesComponent {
  @HostBinding('class.vs-venue-amenities') hostClass = true;
  @Input() amenities: Array<any>;
  @Input() limit = 4;
  venueFeatureIconPipe: VenueFeatureIconPipe;

  /**
   * 
   */
  get amenitiesFiltered() {
    return this.amenities.slice(0, this.limit);
  }

  /**
   * 
   */
  constructor(private translate: TranslateService) {
    this.venueFeatureIconPipe = new VenueFeatureIconPipe();
  }

  /**
   * 
   */
  amenityIcon(amenity: any): string|boolean {
    return this.venueFeatureIconPipe.transform(amenity.label);
  }

  /**
   * 
   */
  amenityTooltip(amenity: any): string {
    return (amenity.description || this.translate.instant('FEATURES.' + slugify(amenity.label).toUpperCase()));
  }
}
