import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { MdlDialogService } from '@angular-mdl/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { take, distinctUntilChanged, debounceTime, takeUntil, switchMap, withLatestFrom } from 'rxjs/operators';

import { Venue, Space } from '@core/models';
import { SpaceService, SearchCriteriaService, EventTrackerService } from '@core/services';
import { EventTypes } from '@core/spaces.enum';
import { SpaceDetailModalComponent } from '../../components';
import { SPACE_VALUE_PROVIDER } from '../../components/space-detail-modal/space-detail-modal';
import { PhotoSliderComponent, VideoDialogComponent } from '@shared/components';
import { VIDEO_URL } from '@shared/components/video-dialog/video-dialog';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { slugify } from '@core/util';
import { ISearchCriteria } from '@core/services/search-criteria.service';
import * as moment from 'moment';
import { Meta, Title } from "@angular/platform-browser";
import { mapStyling } from './map-styling';

export interface VenueAvailability {
  period?: { start: string, end: string },
  eventType?: string,
  guests?: number,
  category?: boolean|string,
  repeats?: { start: string, end: string }[],
}

@Component({
  selector: 'vs-venue-detail-page',
  templateUrl: './venue-detail-page.html',
  styleUrls: ['./venue-detail-page.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VenueDetailPageComponent implements OnInit, OnDestroy {
  @ViewChild('scrollIntoView', {static: false}) scrollIntoView: ElementRef;
  @ViewChild('guestInput', {static: false}) guestInputElement: ElementRef;
  @ViewChild('photoSlider', {static: false}) photoSliderComponent: PhotoSliderComponent;

  venue: Venue;
  venueId: number = 0;
  spaces: Space[] = [];
  cachedSpaces: Space[] = [];
  photos: string[];
  venueLocationMarker: { lat: number, lng: number };
  period: { start: string, end: string} = { start: null, end: null };
  shareKey: string;

  isLoadingSpaces: boolean = false;
  noResults: boolean = false;
  showDateTooltip: boolean = false;
  showGuestsTooltip: boolean = false;
  hasAvailability: boolean = false;

  searchForm: FormGroup;
  eventTypeOptions: string[] = Object.values(EventTypes);

  readonly venuesuiteAmbassador: number = environment.VENUESUITE_AMBASSADOR;
  readonly ICON_DEFAULT = environment.MAP_ICON_DEFAULT;
  readonly mapStyles = mapStyling;

  private _destroyed$: Subject<void> = new Subject<void>();

  /**
   */
  get guestsControl(): FormControl {
    return this.searchForm.get('guests') as FormControl;
  }

  /**
   */
  get hasDateTime(): boolean {
    return this.searchForm.get('period').value !== null ? true : false;
  }

  /**
   */
  get hasGuestNum() {
    return this.searchForm.get('guests').value >  0 ? true : false;
  }

  /**
   *
   */
  constructor(
    private cref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private searchCriteriaService: SearchCriteriaService,
    private eventTrackerService: EventTrackerService,
    private formBuilder: FormBuilder,
    private dialogService: MdlDialogService,
    private spaceService: SpaceService,
    private titleService: Title,
    private metaService: Meta) {

    this.createSearchForm();
  }

  /**
   *
   */
  ngOnInit() {
    this.shareKey = this.route.snapshot.params['share_key'] || null;

    this.route.data
      .pipe(takeUntil(this._destroyed$))
      .subscribe((result: {data: Venue}) => {
        this.venue = result.data;
        this.venueId = this.venue.id;
        this.photos = this.venue.images;
        this.venueLocationMarker = {
          lat: +this.venue.lat,
          lng: +this.venue.lng
        };
        this.hasAvailability = this.venue.features.includes("availability");

        this.titleService.setTitle(this.venue.name);
        this.translate.get(['PAGES.VENUE_DETAILS.META'])
          .subscribe(translations => {
            this.metaService.updateTag(
              { 
                name: 'description',
                content: translations['PAGES.VENUE_DETAILS.META'] 
              }
            );
        });

        this.cref.detectChanges();
      }
    );

    this.translate.onLangChange
      .subscribe(() => {
        this.router.navigate([], { skipLocationChange: true });
        this.searchForm.updateValueAndValidity({onlySelf: true});
      }
    );

    this.searchForm
      .valueChanges
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        takeUntil(this._destroyed$)
      )
      .subscribe(formValue => {

        const filters = this.formAvailabilityModel(formValue);
        this.loadSpaces(filters);

        // Save search to local storage.
        this.searchCriteriaService.update(
          {
            period: formValue.period,
            guests: formValue.guests,
            eventType: formValue.eventType
          }
        );

        this.cref.detectChanges();
    });

    this.searchCriteriaService
      .searchCriteria$
      .pipe(
        takeUntil(this._destroyed$),
        take(1),
        withLatestFrom(
          this.route.queryParams
        )
      )
      .subscribe((value: [
        ISearchCriteria,
        Params
      ]) => {
        const searchCriteria = Object.assign({}, value[0]);
        const query = value[1];

        if ( query.periodStart ) {
          this.period.start = query.periodStart;
        }

        if ( query.periodEnd ) {
          this.period.end = query.periodEnd;
        }

        if ( query.timeStart ) {
          this.period.start = this.period.start && `${this.period.start}${'T'}${query.timeStart}`;
        }

        if ( query.timeEnd ) {
          this.period.end = this.period.end && `${this.period.end}${'T'}${query.timeEnd}`;
        }

        if ( query.guests ) {
          searchCriteria.guests = query.guests;
        }

        if ( query.eventType ) {
          searchCriteria.eventType = query.eventType;
        }

        if ( this.period.start !== null && this.period.end!== null ) {
          if ( this.period.start && moment(this.period.start, moment.ISO_8601).isValid() &&
          this.period.end && moment(this.period.end, moment.ISO_8601).isValid() ) {
            searchCriteria.period = this.period;
          }
        }

        this.searchForm.patchValue(
          {
            period: searchCriteria.period,
            eventType: searchCriteria.eventType,
            guests: searchCriteria.guests
          }
        );
      });
  }

  /**
   *
   */
  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  /**
   *
   */
  formAvailabilityModel(formValue: any): VenueAvailability {
    return <VenueAvailability> {
      guests: formValue.guests || null,
      eventType: formValue.eventType  || null,
      period: formValue.period instanceof Array ? formValue.period[0] : formValue.period,
      repeats: formValue.period instanceof Array ? formValue.period : null,
      category: formValue.spaceCategory || null
    }
  }

  /**
   *
   */
  loadSpaces(filters: any) {
    if (this.isLoadingSpaces) {
      return;
    }
    this.isLoadingSpaces = true;
    this.spaceService
      .getSpaces(this.venueId, filters, this.shareKey)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(spaces => {
        this.noResults = spaces.length === 0;
        this.isLoadingSpaces = false;

        if (this.noResults) {
          this.loadAllSpaces();
          return;
        }

        this.setSpaces(spaces);
        this.cref.detectChanges();
      });
  }

  /**
   *
   */
  loadAllSpaces() {
    if (this.isLoadingSpaces) {
      return;
    }
    this.isLoadingSpaces = true;
    this.spaceService
      .getSpaces(this.venueId, null, this.shareKey)
      .pipe(takeUntil(this._destroyed$))
      .subscribe(spaces => {
        this.isLoadingSpaces = false;
        this.setSpaces(spaces);
        this.cref.detectChanges();
      });
  }

  /**
   * 
   */
  setSpaces(spaces: Space[]) {
    this.spaces = spaces;

    const evenTypes = spaces.map(space => { return space.category.split(',');})
      .reduce((a,b) => { return a.concat(b);}, [])
      .filter((elem, index, self) => index === self.indexOf(elem));

    this.setEventTypes(evenTypes);
    return this;
  }

  /**
   * 
   */
  setEventTypes(eventTypes: string[]) {
    this.eventTypeOptions = eventTypes;
    return this;
  }

  /**
   *
   */
  navigateToCheckout(space: Space) {
    this.eventTrackerService.trackEvent('showprices', 'button', 'space', space.id);
    this.router.navigate(['/booking/new/'+space.id]);
  }

  /**
   *
   */
  showFilterTooltips() {
    this.showDateTooltip = this.searchForm.get('period').invalid;
    this.showGuestsTooltip = this.searchForm.get('guests').invalid;

    if ( ! this.showDateTooltip && this.showGuestsTooltip ) {
      this.guestInputElement.nativeElement.focus();
    }
  }

  /**
   *
   */
  openSpaceModal($event, space) {
    $event.stopPropagation();

    const dialogRef = this.dialogService.showCustomDialog({
      component: SpaceDetailModalComponent,
      providers: [{ provide: SPACE_VALUE_PROVIDER, useValue: space}],
      styles: { width: '738px'},
      classes: 'mdl-dialog_no-padding mdl-dialog_scroll',
      animate: false,
      isModal: true,
      clickOutsideToClose: true
    });

    dialogRef.pipe(switchMap(dialogRef => dialogRef.onHide())).subscribe(navToCheckout => {
      if ( navToCheckout ) {
        this.navigateToCheckout(space);
      }
    });
  }

  /**
   *
   */
  openPhotoSlider(index = 0) {
    this.photoSliderComponent.openFullScreen(index);
  }

  /**
   *
   */
  openVideoDialog() {
    this.dialogService.showCustomDialog({
      component: VideoDialogComponent,
      providers: [{provide: VIDEO_URL, useValue: this.venue.youtube_url}],
      animate: false,
      styles: { width: '760px' },
      classes: 'mdl-dialog_no-padding',
      isModal: true,
      clickOutsideToClose: true
    });
  }

  /**
   *
   */
  incrementGuests($event) {
    $event.preventDefault();
    const value = +this.guestsControl.value;
    this.guestsControl.patchValue(value + 1);
  }

  /**
   *
   */
  decrementGuests($event) {
    $event.preventDefault();
    const value = +this.guestsControl.value;
    if ( value === 1 ) {
      return;
    }
    this.guestsControl.patchValue(value - 1);
  }

  /**
   *
   */
  private createSearchForm() {
    this.searchForm = this.formBuilder.group({
      period: [null, Validators.required],
      eventType: null,
      guests: [null, Validators.required]
    });
  }

  /**
   *
   */
  amenityTooltip(amenity: any): string {
    return (amenity.description || this.amenityTr(amenity));
  }

  /**
   *
   */
  amenityTr(amenity: any): string {
    return this.translate.instant('FEATURES.' + slugify(amenity.label).toUpperCase());
  }

  /**
   *
   */
  clearNumOfPeople($event) {
    if (this.searchForm.get('guests').value !== null) {
      this.searchForm.controls['guests'].setValue(null);
    }
  }

}
