import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { UserVenueService } from './user-venue.service';
import { UserArrangementsService } from './user-arrangement.service';
import { AccomodationService } from './accomodation.service';
import { UserSpaceService } from './user-space.service';
import { UserCateringService } from './user-catering.service';
import { BookingService } from './booking.service';
import { UserEquipmentService } from './user-equipment.service';
import { UserManageService } from './user-manage.service';
import { UserTranslationService } from './user-translation.service';
import { StatisticsService } from './statistics.service';
import { PriceRuleService } from './price-rule.service';
import { PricingCouponService } from './pricing-coupon.service';
import { WidgetService } from './widget.service';

export interface ISwitchableVenue {
  id: number; name: string;
}

export interface onVenueSwitch {
  setVenueId(venueId: number): void;
}

/**
 * The VenueSwitchService manages what venue a user is currently
 * working on. It does this in two ways:
 *
 * 1: Whenever the active venue changes, it updates the venue_id of
 * all the data services in the DI tree. This is a sub-optimal solution
 * as when a new data service is introduced it has to be added here
 * which can easily be forgotten. It also assumes that the data service
 * has a setVenueId method
 *
 * 2: By exposing a onSwitch observable to which other parts of the
 * application can subscribe to and take responsibilty themselves. In
 * this way the responsibily is delegated to other parts of the app.
 */
@Injectable()
export class VenueSwitchService {
  private venueId : number = 0;
  onSwitch: Subject<number>;
  onChange: Subject<number>;

  constructor(
    private userVenueService: UserVenueService,
    private userArrangementService: UserArrangementsService,
    private accomodationService: AccomodationService,
    private userSpaceService: UserSpaceService,
    private userCateringService: UserCateringService,
    private bookingService: BookingService,
    private userEquipmentService: UserEquipmentService,
    private userManageService: UserManageService,
    private userTranslationService: UserTranslationService,
    private statisticsService: StatisticsService,
    private priceRuleService: PriceRuleService,
    private pricingCouponService: PricingCouponService,
    private widgetService: WidgetService) {

    this.onSwitch = new Subject<number>();
    this.onChange = new Subject<number>();
  }

  /**
   * 
   */
  changeActiveVenue(venue: ISwitchableVenue) {
    if (venue.id == this.getActiveVenueId()) {
      return;
    }
    this.setActiveVenueId(venue.id);
    this.onChange.next(venue.id);
  }

  /**
   * 
   */
  switchActiveVenue(venue: ISwitchableVenue) {
    if (venue.id == this.getActiveVenueId()) {
      return;
    }
    this.changeActiveVenue(venue);
    this.onSwitch.next(venue.id);
  }

  /**
   * 
   */
  getActiveVenueId() {
    return this.venueId;
  }

  /**
   * 
   */
  setActiveVenueId(venueId: number) {
    if (this.venueId == venueId) {
      return;
    }
    this.venueId = venueId;
    this.updateVenueDataServices(venueId);
  }

  /**
   * 
   */
  private updateVenueDataServices(nextVenueId: number) {
    this.userVenueService.setVenueId(nextVenueId);
    this.userArrangementService.setVenueId(nextVenueId);
    this.accomodationService.setVenueId(nextVenueId);
    this.userSpaceService.setVenueId(nextVenueId);
    this.userCateringService.setVenueId(nextVenueId);
    this.bookingService.setVenueId(nextVenueId);
    this.userEquipmentService.setVenueId(nextVenueId);
    this.userManageService.setVenueId(nextVenueId);
    this.userTranslationService.setVenueId(nextVenueId);
    this.statisticsService.setVenueId(nextVenueId);
    this.priceRuleService.setVenueId(nextVenueId);
    this.pricingCouponService.setVenueId(nextVenueId);
    this.widgetService.setVenueId(nextVenueId);
  }
}
