import { Injectable } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Action, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { map, switchMap, withLatestFrom, startWith, catchError } from 'rxjs/operators';

import * as fromRoot from '../reducers';
import * as fromUser from '../actions/user';
import { User } from '../models/user';
import { UserService, VenueService } from '../services';

@Injectable()
export class UserEffects {

  constructor(
    private actions$: Actions,
    private store$: Store<fromRoot.State>,
    private userService: UserService,
    private venueService: VenueService,
    private route: ActivatedRoute,
    private router: Router) { }

  @Effect() login$: Observable<Action> = this.actions$
    .pipe(
      ofType(fromUser.ActionTypes.LOGIN)
    )
    .pipe(
      map((res: any) => res.payload),
      switchMap((credentials: any) => {
        return this.userService
          .login(credentials.username, credentials.password)
          .pipe(
            switchMap((user: User) => {

                if (user.auth_2fa_token) {
                    return of(new fromUser.LoginRequest2FAAction(user));
                }

                return of(new fromUser.LoginSuccessAction(user));
            }),
            catchError(err => of(new fromUser.LoginFailedAction(err.message)))
          );
      })
    );
    
  @Effect() tokenLogin$: Observable<Action> = this.actions$
    .pipe(
      ofType(fromUser.ActionTypes.TOKEN_LOGIN)
    )
    .pipe(
      map((res: any) => res.payload),
      switchMap((token: any) => {
        return this.userService
          .tokenLogin(token)
          .pipe(
            switchMap((user) => of(new fromUser.LoginLessSuccessAction(user))),
            catchError(err => of({type: 'USER_NO_AUTH_TOKEN'}))
          );
      })
    );

  @Effect() loginSuccess$: Observable<Action> = this.actions$
    .pipe(ofType(fromUser.ActionTypes.LOGIN_SUCCESS))
    .pipe(
      map((res: any) => res.payload),
      switchMap((user: User) => {
        const redirectAfterLogin = this.route.snapshot.queryParams['redirectUrl'];

        if ( redirectAfterLogin ) {
          this.router.navigateByUrl(redirectAfterLogin);
        }

        // If user is venue ambassador, we also trigger a venue load
        if ( UserService.isVenue(user.role) && user.venue_id ) {
          return of(new fromUser.LoadUserVenueAction());
        }

        return of({type: 'NOOP'});
      })
    );
  
  @Effect() loginLessSuccess$: Observable<Action> = this.actions$
    .pipe(ofType(fromUser.ActionTypes.LOGIN_LESS_TOKEN_SUCCESS))
    .pipe(
      map((res: any) => res.payload),
      switchMap((user: User) => {
        const redirectAfterLogin = this.route.snapshot.queryParams['redirectUrl'];

        if ( redirectAfterLogin ) {
          this.router.navigateByUrl(redirectAfterLogin);
        }

        // If user is venue ambassador, we also trigger a venue load
        if (UserService.isVenue(user.role) && user.venue_id ) {
          return of(new fromUser.LoadUserVenueAction());
        }

        return of({type: 'NOOP'});
      })
    );

  @Effect() loadUserVenues$ = this.actions$
    .pipe(ofType(fromUser.ActionTypes.LOAD_USER_VENUE))
    .pipe(
      switchMap((action) => {
        return this.userService
          .getVenues()
          .pipe(switchMap(venues => of(new fromUser.UserVenuesLoadedAction(venues))));
      })
    );

  @Effect() loadUserVenue$ = this.actions$
    .pipe(ofType(fromUser.ActionTypes.LOAD_USER_VENUE))
    .pipe(
      withLatestFrom(this.store$.select(fromRoot.getUser)),
      switchMap(([action, user]) => {
        return this.venueService
          .getVenue(user.venue_id)
          .pipe(switchMap(venue => of(new fromUser.UserVenueLoadedAction(venue))));
      })
    );

  /**
   * On the logout action we want to produce the following side effects:
   * - Destroy the JWT token and sesssion
   * - Redirect the user back to the home page
   */
  @Effect() logout$: Observable<Action> = this.actions$
    .pipe(ofType(fromUser.ActionTypes.LOGOUT))
    .pipe(
      map((res: any) => res.payload),
      switchMap((redirectAfterLogout: boolean = true) => {
        this.userService.logout();

        if ( redirectAfterLogout ) {
          this.router.navigate(['/']);
        }

        return of(new fromUser.LogoutSuccessAction());
      })
    );

  /**
   * This effect makes use of the `startWith` operator to trigger
   * the effect immediately on app startup. In the switchMap it checks
   * if there is an authToken stored in the localStorage of the client.
   */
  @Effect() loadUser$: Observable<Action> = this.actions$
    .pipe(ofType(fromUser.ActionTypes.LOAD))
    .pipe(
      startWith(new fromUser.LoadAction()),
      switchMap(() => {
        if ( this.userService.clientBrowserToken() !== null ) {
          return of(new fromUser.LoginSuccessAction(this.userService.convertTokenToUser(this.userService.clientBrowserToken())));
        } else {
          return of({type: 'USER_NO_AUTH_TOKEN'});
        }
      })
    );
}
