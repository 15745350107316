import { Injectable } from '@angular/core';

@Injectable()
export class NavService {
  routesMap: {[url: string]: boolean} = {};

  registerRoute(url: string) {
    if ( ! this.routesMap[url] ) {
      this.routesMap[url] = true;
    }
  }

  exists(url: string): boolean {
    return this.routesMap[url];
  }
}
