import { 
  CalendarService,
  ICalendarDay,
  ICalendarWeek
} from './calendar.service';
import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  InjectionToken,
  Inject,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  AfterViewInit
} from '@angular/core';
import * as moment from 'moment';
import { MdlDialogReference } from '@angular-mdl/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AvailabilityService } from '@core/services';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';

export const DATETIMEPICKER_CONF = new InjectionToken<IDatetimePickerConfiguration>('dateTimePickerConf');

export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37,
  ESC = 27
}

export function isDateRange(object: any): object is IDateRange {
  return object && object.hasOwnProperty('start') && object.hasOwnProperty('end');
}

export interface IDateRange {
  start: string;
  end: string;
}

export type DatetimePickerValue = IDateRange|IDateRange[]|string;

export interface IDatetimePickerConfiguration {
  value?: DatetimePickerValue;
  spaceId?: number,
  venueId?: number,
  allowMultiple?: boolean;
  dateOnly?: boolean;
  selectTimeRange?: boolean;
  calendarOnly?: boolean;
}

type DatetimeView = 'single'|'multiple';

@Component({
  selector: 'vs-datetime-picker',
  templateUrl: `./datetime-picker.html`,
  styleUrls: ['./datetime-picker.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatetimePickerComponent implements OnInit, OnDestroy {
  activeView: DatetimeView = 'single';
  value: DatetimePickerValue;

  venueId: number = 0;
  spaceId: number = 0;

  isValid: boolean;
  allowMultipleDates: boolean;
  calendarOnly: boolean = false;
  allowTimeSelection: boolean;
  dateOnly: boolean;
  timeSectionVisible: boolean;
  dialogVisible: boolean = false;
  
  calendarData: ICalendarWeek[];
  availabilityData: any[];
  selectedDayParts: string[];
  dayParts: {[dayPart: string]: {selected: boolean, start: string, end: string}};
  startTimeOptions: {label: string, value: string, disabled?: boolean}[];
  endTimeOptions: {label: string, value: string, disabled?: boolean}[];
  startTime: string;
  endTime: string;
  dayPartStartTime: any = null;
  dayPartEndTime: any = null;

  @ViewChild('container', {static: false}) containerElement: ElementRef;

  private _calendarService: CalendarService;
  private _destroyed$: Subject<any> = new Subject();
  private _monthChanged : boolean = true;

  get activeMonth(): string {
    return this._calendarService.datePointer.format(environment.MONTH_FORMAT);
  }

  get activeYear(): string {
    return this._calendarService.datePointer.format('YYYY');
  }

  get selectedDates(): moment.Moment[] {
    return this._calendarService.selectedDates;
  }

  get selectedDate(): moment.Moment {
    return this._calendarService.selectedDate;
  }

  /**
   *
   */
  constructor(
    @Inject(DATETIMEPICKER_CONF) private configuration: IDatetimePickerConfiguration,
    private dialogReference: MdlDialogReference,
    private cdr: ChangeDetectorRef,
    private availabilityService: AvailabilityService,
    private translateService: TranslateService) {

    this._calendarService = new CalendarService();
    this.selectedDayParts = [];
    this.calendarData = [];
    this.availabilityData = [];
    this.timeSectionVisible = false;
    this.startTime = '';
    this.endTime = '';

    // Apply configuration to component
    this.value = this.configuration.value;
    this.venueId = this.configuration.venueId;
    this.spaceId = this.configuration.spaceId;
    this.dateOnly = this.configuration.dateOnly;
    this.allowTimeSelection = this.configuration.selectTimeRange;
    this.allowMultipleDates = this.configuration.allowMultiple;
    this.calendarOnly = this.configuration.calendarOnly;

    // this._initDayparts();
    this._initTimeSelectOptions();
  }

  /**
   *
   */
  ngOnInit() {
    this.dialogReference.onVisible().subscribe(res => {
      this.dialogVisible = true;
    });

    this._parseDate();
    this._checkDateIsValid();

    this._calendarService
      .calendarDataChange$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(calendarData => this.setCalendar(calendarData));
  }

  /**
   *
   */
  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  /**
   *
   */
  @HostListener('window:keydown', ['$event']) keyEvent(event: KeyboardEvent) {
    if ( event.keyCode === KEY_CODE.ESC ) {
      this.hide(event);
    }
  }

  /**
   *
   */
  nextMonth() {
    this._monthChanged = true;
    this._calendarService.add(1, 'month');
  }

  /**
   *
   */
  prevMonth() {
    this._monthChanged = true;
    this._calendarService.substract(1, 'month');
  }

  /**
   *
   */
  nextYear() {
    this._monthChanged = true;
    this._calendarService.add(1, 'year');
  }

  /**
   *
   */
  prevYear() {
    this._monthChanged = true;
    this._calendarService.substract(1, 'year');
  }

  /**
   *
   */
  selectDate($event, day: ICalendarDay) {
      
    if (day.isPast || day.isToday || !day.isAvailable || !day.isCurrentMonth) {
        return false;
    }

    $event.stopPropagation();
    this._calendarService.selectDate(day.dateIso);
    this._checkDateIsValid();
    this.calendarOnly ? this.confirm($event) : '';
  }

  /**
   *
   */
  hide($event: MouseEvent|KeyboardEvent) {
    $event.stopImmediatePropagation();
    $event.preventDefault();
    this.dialogReference.hide();
  }

  /**
   *
   */
  setFocus(elementId: string, elementIdDisabled: string = '') {
    const element = document.getElementById(elementId);
    if (elementIdDisabled !== '' && element.getAttribute('disabled') !== null) {
        document.getElementById(elementIdDisabled).focus();
    } else {
        document.getElementById(elementId).focus();
    }
  }

  /**
   *
   */
  confirm($event: MouseEvent) {
    if ( this.activeView !== 'single' && this.allowMultipleDates && this.selectedDates.length > 1 ) {
      const formattedDates: IDateRange[] = [];
      this.selectedDates.forEach(date => {
        formattedDates.push(this._formatDateAndTimeToRange(
          date,
          this.startTime,
          this.endTime
        ));
      });

      this.dialogReference.hide(formattedDates);
    } else if ( this.allowTimeSelection ) {
      this.dialogReference.hide(this._formatDateAndTimeToRange(
        this.selectedDate,
        this.startTime,
        this.endTime
      ));
    } else {
      this.dialogReference.hide(this.selectedDate.format());
    }
  }

  /**
   *
   */
  navigateCalendarTo(date: moment.Moment) {
    this._calendarService.navigateToDate(date);
  }

  /**
   *
   */
  deselectDay($event, index: number) {
    $event.stopPropagation();
    this._calendarService.deselectDate(index);
  }

  /**
   *
   */
  showActiveView($event) {
    this.activeView = $event;

    this._calendarService.allowMultipleDates = this.activeView === 'multiple';
    this._calendarService.refresh();
  }

  /**
   *
   */
  setCalendar(calendarData: ICalendarWeek[]) {
    this.calendarData = calendarData;

    if (!this.spaceId && !this.venueId) {
      this.cdr.markForCheck();
      return;
    }
    if (!this._monthChanged) {
      this.mergeCalendarData();
      this.cdr.markForCheck();
      return;
    }
    this._monthChanged = false;

    if (this.spaceId) {
      this.availabilityService
        .getSpaceAvailability(this.spaceId, this._calendarService.datePointer.format("YYYY-MM-DD"))
        .pipe(takeUntil(this._destroyed$))
        .subscribe((result) => {this.availabilityData = result; this.mergeCalendarData(); this.cdr.markForCheck();},
                   (error) => {if (error.status != 404) console.error(error);}
        );
        
    } else {
      this.availabilityService
        .getVenueAvailability(this.venueId, this._calendarService.datePointer.format("YYYY-MM-DD"))
        .pipe(takeUntil(this._destroyed$))
        .subscribe((result) => {this.availabilityData = result; this.mergeCalendarData(); this.cdr.markForCheck();},
                   (error) => { if (error.status != 404) console.error(error);}
        );
    }
  }

  /**
   *
   */
  mergeCalendarData() {
    this.calendarData =
    this.calendarData.map(
      (weekObj) => {
        weekObj.days.map(
          (day) => {
            let availabilityDate = this.availabilityData.find(value => value.date === day.dateIso.slice(0,10));
            if (availabilityDate) {
              let isAvailable = availabilityDate.availability
                .map(daypart => Object.values(daypart).shift())
                .reduce((prev,cur,ind) => {return prev + cur;});
              day.isAvailable = !!isAvailable;
            }
            return day;
          }
        );
        return weekObj;
      }
    );
  }

  /**
   *
   */
  isDaypartSelected(dayPart: string): boolean {
    return this.dayParts[dayPart].selected;
  }

  /**
   *
   */
  onStartTimeChange(selectedStartTime: {value: string}) {
    this._checkDateIsValid();
    const startTimeMoment = moment(selectedStartTime.value, 'HH:mm');

    this.endTimeOptions = this.endTimeOptions.map(timeOption => {
      const isDisabled = timeOption.value <= selectedStartTime.value;
      const endTimeMoment = moment(timeOption.value, 'HH:mm');
      const diff = endTimeMoment.diff(startTimeMoment, 'hours', true);
      const labelTr = this.translateService.instant("COMMON.HOURS");
      const newLabel = timeOption.value + ' (+' + diff.toString() + ' ' + labelTr + ')';

      return {
        disabled: isDisabled,
        label: newLabel,
        value: timeOption.value
      }
    });
  }

  /**
   *
   */
  private _checkDateIsValid() {
    if ( this.dateOnly && this.selectedDate ) {
      this.isValid = true;
    } else if ( this.selectedDate && this.startTime !== '' && this.endTime !== '' ) {
      this.isValid = true;
    } else {
      this.isValid = false;
    }
  }

  /**
   *
   */
  private _parseDate() {
    let primaryDate: IDateRange;

    if ( this.value instanceof Array ) {
      primaryDate = this.value[0];
      this.activeView = 'multiple';
      this._calendarService.allowMultipleDates = true;
      this.value.forEach(dateRange => this._calendarService.selectDate(dateRange.start));
    } else if ( isDateRange(this.value) ) {
      primaryDate = this.value;
      this._calendarService.selectDate(primaryDate.start);
    } else {
      primaryDate = {
        start: this.value,
        end: this.value
      }
    }

    this._calendarService.datePointer = moment(primaryDate.start, moment.ISO_8601);

    if ( this.allowTimeSelection ) {
      if ( primaryDate.start ) {
        this.startTime = moment(primaryDate.start, moment.ISO_8601).format('HH:mm');
        this.endTime = moment(primaryDate.end, moment.ISO_8601).format('HH:mm');
      } else {
        this.startTime = moment('09:00', 'HH:mm').format('HH:mm');
        this.endTime = moment('17:00', 'HH:mm').format('HH:mm');
      }
    }
  }

  /**
   *
   */
  private _formatDateAndTimeToRange(date: moment.Moment, startTime, endTime): IDateRange {
    const startTimeArray: string[] = startTime.split(':');
    const endTimeArray: string[] = endTime.split(':');

    const dateStart = date.clone();
    const dateEnd = dateStart.clone();

    dateStart.set('hour', +startTimeArray[0]);
    dateStart.set('minute', +startTimeArray[1]);

    dateEnd.set('hour', +endTimeArray[0]);
    dateEnd.set('minute', +endTimeArray[1]);

    return {
      start: dateStart.format(),
      end: dateEnd.format()
    }
  }

  /**
   *
   */
  private _initTimeSelectOptions() {
    this.startTimeOptions = [];
    this.endTimeOptions = [];

    const startTime = moment('07:00', 'HH:mm');
    const endTime = moment('24:00', 'HH:mm');

    while ( ! startTime.isAfter(endTime, 'minute') ) {
      this.startTimeOptions.push({
        label: startTime.format('HH:mm'),
        value: startTime.format('HH:mm'),
        disabled: false
      });
      this.endTimeOptions.push({
        label: startTime.format('HH:mm'),
        value: startTime.format('HH:mm'),
        disabled: false
      });
      startTime.add(30, 'minutes');
    }
  }
  
  sum (x:number,y:string) {
    return x + parseInt(y,10);
  }
}
