<div class="vs-cmp">
  <div class="vs-cmp-header">
    <div class="mdl-grid">
      <div class="mdl-cell mdl-cell--3-col">
        <a class="go-back" [routerLink]="['/search']">{{ 'COMPARE.BACK' | translate }}</a>
        <h1>{{ 'COMPARE.TITLE' | translate }}</h1>
        <div class="compare-input" [formGroup]="fgGuests">
          <span>{{ 'COMPARE.INPUT_PREFIX' | translate }}</span>
          <vs-formfield>
            <input type="text" formControlName="guests" />
          </vs-formfield>
          <span>{{ 'COMPARE.INPUT_POSTFIX' | translate }}</span>
        </div>
      </div>
      <div class="mdl-cell mdl-cell--3-col vs-cmp-venue" *ngFor="let venue of venues">
        <div class="vs-cmp-venue_delete" (click)="removeVenue(venue)"><i class="material-icons">clear</i></div>
        <img [src]="venue.images[0] | imageRatio" style="width: 100%; "/>
        <h3 (click)="navigateToVenue($event, venue)">{{ venue.name }}<small>{{ venue.city }}, {{ venue.province }}</small></h3>
      </div>
      <div *ngIf="venues.length < 3" [routerLink]="['/search']" class="mdl-cell mdl-cell--3-col vs-cmp-add">
        <div>&nbsp;</div>
        <i class="material-icons">add</i>
        <span>{{ 'COMPARE.ADD' | translate }}</span>
      </div>
    </div>
  </div>
  <div class="vs-cmp-tbl-container">
    <div class="mdl-grid" *ngIf="loading">
      <div class="mdl-cell mdl-cell--12-col loading-container">
        <mdl-spinner [active]="true" [single-color]="true"></mdl-spinner>
        <div class="loading-overlay loading-overlay--white"></div>
        <img src="/assets/compare_loading.jpg" />
      </div>
    </div>

    <div class="mdl-grid" *ngIf="!loading && venues.length === 0">
      <div class="mdl-cell mdl-cell--12-col">
      </div>
    </div>

    <div class="mdl-grid" *ngIf="!loading && venues.length > 0">
      <div *ngIf="prices.totalRow.length > 0" class="mdl-cell mdl-cell--12-col vs-cmp-section">
        <h3>{{ 'COMPARE.PRICES_BLOCK' | translate }}</h3>
        <table>
          <tbody>
            <tr>
              <td>{{ 'COMPARE.PRICES_BLOCK_SPACE' | translate }}</td>
              <td *ngFor="let spacePrice of prices.spaceRow">
                <ng-container *ngIf="!spacePrice">
                  &nbsp;
                </ng-container>
                <ng-container *ngIf="spacePrice === -1">
                  {{ 'COMPARE.PRICES_NO_DATA' | translate }}
                </ng-container>
                <ng-container *ngIf="spacePrice === -2">
                  {{ 'COMPARE.PRICES_INCLUDED_PACKAGE' | translate }}
                </ng-container>
                <ng-container *ngIf="spacePrice > 0">
                  {{ spacePrice | currencyCents:'EUR' }}
                </ng-container>
              </td>
            </tr>
            <tr>
              <td>{{ 'COMPARE.PRICES_BLOCK_PACKAGE' | translate }}</td>
              <td *ngFor="let arrangementPrice of prices.arrangementRow">
                <ng-container *ngIf="!arrangementPrice">
                  &nbsp;
                </ng-container>
                <ng-container *ngIf="arrangementPrice === -1">
                  {{ 'COMPARE.PRICES_NO_DATA' | translate }}
                </ng-container>
                <ng-container *ngIf="arrangementPrice > 0">
                  {{ arrangementPrice | currencyCents:'EUR' }}
                </ng-container>
              </td>
            </tr>
            <tr>
              <td>{{ 'COMPARE.PRICES_BLOCK_TOTAL' | translate }}</td>
              <td *ngFor="let totalPrice of prices.totalRow">
                <b *ngIf="totalPrice">
                  {{ totalPrice | currencyCents:'EUR' }}
                </b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="amenities.length > 0" class="mdl-cell mdl-cell--12-col vs-cmp-section">
        <h3>{{ 'COMPARE.FACILITIES_BLOCK' | translate }}</h3>
        <table>
          <tbody>
            <tr *ngFor="let amenity of amenities">
              <td>{{ 'FEATURES.'+slugify(amenity.label) | translate }}</td>
              <td *ngFor="let venueHasAmenity of amenity.venues">
                <i *ngIf="venueHasAmenity" class="material-icons">check_circle</i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="places.length > 0" class="mdl-cell mdl-cell--12-col vs-cmp-section">
        <h3>{{ 'COMPARE.ACCESSIBILITY_BLOCK' | translate }}</h3>
        <table>
          <tbody>
            <tr *ngFor="let place of places">
              <td>{{ 'PLACES.'+place.label.toUpperCase() | translate }}</td>
              <td *ngFor="let venuePlace of place.venues">
                <ng-container *ngIf="venuePlace">
                  {{ venuePlace.distance | metersToKm }} {{ venuePlace.name}}
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

