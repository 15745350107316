import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Venue, BaseProduct } from '@core/models';
import { ITranslation } from '../models/translation';
import { onVenueSwitch } from './venue-switch.service';
import { environment } from '@env/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()
export class UserTranslationService implements onVenueSwitch {
  private venueId: number;
  private http: HttpClient;

  /**
   * 
   */
  constructor(http: HttpClient) {
    this.http = http;
  }

  /**
   * 
   */
  setVenueId(venueId: number) {
    this.venueId = venueId;
  }

  /**
   * 
   */
  getAll(): Observable<ITranslation[]> {
    let requestUrl = `${environment.API_URL}/venues/${this.venueId}/translation`;
    return this.http.get<ITranslation[]>(requestUrl);
  }

  /**
   * 
   */
  getEntity(product: BaseProduct): Observable<ITranslation[]> {
    let urlSearchParams = new HttpParams();

    urlSearchParams = urlSearchParams.set('category', product.category);
    urlSearchParams = urlSearchParams.set('entity', product.id.toString());

    const options = {
      params: urlSearchParams
    };

    let requestUrl = `${environment.API_URL}/venues/${this.venueId}/translation`;
    return this.http.get<ITranslation[]>(requestUrl, options);
  }

  /**
   * 
   */
  upsert(translation: ITranslation): Observable<ITranslation> {
    if (translation.id) {
      return this.update(translation);
    }
    return this.create(translation);
  }

  /**
   * 
   */
  create(translation: ITranslation): Observable<ITranslation> {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(translation);

    return this.http.post<ITranslation>(`${environment.API_URL}/venues/${this.venueId}/translation`,  body, requestOptions);
  }

  /**
   * 
   */
  update(translation: ITranslation): Observable<ITranslation> {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(translation);
    return this.http.put<ITranslation>(`${environment.API_URL}/venues/${this.venueId}/translation/${translation.id}`, body, requestOptions);
  }

}
