import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'substr'
})
export class SubstrPipe implements PipeTransform {
  transform(value: string, start: number = 0, end: number = 250) {
    if ( ! value || !value.length ) {
      return '';
    }

    return (end < value.length) ? value.substr(start, end) + '...' : value;
  }
}
