import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { ArrangementProduct } from '../models';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ArrangementService {
  http: HttpClient;

  static readonly ARRANGEMENT_TYPES = [
    { value: 'all-in', label: 'All-Inclusive' },
    { value: 'catering', label: 'Catering' },
    { value: 'equipment', label: 'Equipment' }
  ];

  constructor(http: HttpClient) {
    this.http = http;
  }

  /**
   * 
   */
  getVenueArrangements(
    venueId: number,
    spaceId?: number,
    eventType?: string,
    period?: {start: string, end: string},
    organizationId?: number,
    guests?: number): Observable<ArrangementProduct[]> {

    let requestUrl = `${environment.API_URL}/venues/${venueId}/arrangements?`;

    if ( spaceId ) {
      requestUrl += '&space_id='+spaceId.toString();
    }

    if ( eventType ) {
      requestUrl += '&type='+eventType;
    }

    if ( period && period.start && period.end ) {
      requestUrl += '&period[start]='+encodeURIComponent(period.start)+'&period[end]='+encodeURIComponent(period.end);
    }

    if ( organizationId ) {
      requestUrl += '&organization_id='+organizationId;
    }

    if ( guests ) {
      requestUrl += '&guests='+guests;
    }

    return this.http.get<ArrangementProduct[]>(requestUrl);
  }

  /**
   * 
   */
  getVenueArrangement(venueId: number, arrangementId: number): Observable<ArrangementProduct> {
    return this.http.get<ArrangementProduct>(`${environment.API_URL}/venues/${venueId}/arrangements/${arrangementId}`);
  }
}
