import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'bookingChangelog'
})
export class BookingChangelogPipe implements PipeTransform {
  translateActionMap: {[label: string]: string};
  translateVariableMap: {[label: string]: string};
  
  private _datePipe: DatePipe = new DatePipe('nl-NL');
  
  constructor(    
    private translateService: TranslateService
  ) {
    this.translateActionMap = {};
    this.translateActionMap['attach'] = 'ATTACH';
    this.translateActionMap['status.request'] = 'STATUS_REQUEST';
    this.translateActionMap['status.required'] = 'STATUS_REQUEST';
    this.translateActionMap['status.reservation'] = 'STATUS_RESERVATION';
    this.translateActionMap['status.booked'] = 'STATUS_BOOKED';
    this.translateActionMap['status.done'] = 'STATUS_DONE';
    this.translateActionMap['status.cancelled'] = 'STATUS_CANCELLED';
    this.translateActionMap['create'] = 'CREATE';
    this.translateActionMap['delete'] = 'STATUS_DEFAULT';
    this.translateActionMap['update'] = 'UPDATE';
    this.translateActionMap['detach'] = 'DETACH';
    this.translateActionMap['create_message'] = 'CREATE_MESSAGE';

    this.translateVariableMap = {};
    this.translateVariableMap['name'] = 'BOOKING_DETAILS.EVENT_NAME';
    this.translateVariableMap['guests'] = 'BOOKING_DETAILS.GUESTS';
    this.translateVariableMap['setup'] = 'BOOKING_DETAILS.SETUP';
    this.translateVariableMap['start'] = 'DATE_TIME.START_TIME';
    this.translateVariableMap['end'] = 'DATE_TIME.END_TIME';
    this.translateVariableMap['catering'] = 'COMMON.CATERING_LABEL';
    this.translateVariableMap['equipment'] = 'COMMON.EQUIPMENT_LABEL';
    this.translateVariableMap['packages'] = 'COMMON.PACKAGES_LABEL';
    this.translateVariableMap['extras'] = 'COMMON.EXTRAS_LABEL';
  }

  transform(value: any, userId: number): string {
    if (!value || !value.action || !value.user) {
      return this.translateService.instant('BOOKING_CHANGELOG.DEFAULT');
    }

    const name = {'name' : value.user.name};
    const user = value.user.id === userId ? this.translateService.instant('BOOKING_CHANGELOG.USER_YOU') : this.translateService.instant('BOOKING_CHANGELOG.USER_OTHER', name);
    let key = value.action;

    if (value.action === 'status') {
      key += "." + value.changes.to;
    }

    if (value.action === 'delete') {
      key += "." + value.changes.entity;
    }

    let trKey = this.translateActionMap[key] || 'DEFAULT';

    let changeFrom, changeTo, trVariable;

    let changesVariables : any[] = [];
    
    if (value.action === 'update') {
      for (let variable in value.changes) {
        changeFrom = changeTo = '';
        if (variable === 'start' || variable === 'end') {
          changeFrom = this._datePipe.transform(value.changes[variable].from.date, 'dd MMM yyyy HH:mm');
          changeTo = this._datePipe.transform(value.changes[variable].to.date, 'dd MMM yyyy HH:mm');
        }
        else if (variable === 'guests' || variable === 'setup' || variable === 'name') {
          changeFrom = value.changes[variable].from;
          changeTo = value.changes[variable].to;
        }
        trVariable = this.translateVariableMap[variable] || variable;
        changesVariables.push({'user': user, 'var': this.translateService.instant(trVariable), 'from' : changeFrom, 'to': changeTo});
      }
    } else if (value.action === 'delete' || value.action === 'detach') {
      changesVariables.push({'name': value.changes.name});

    } else {
      changesVariables.push({'user': user});
    }

    let translatedText = "";
    for (let translation of changesVariables) {
      translatedText += this.translateService.instant('BOOKING_CHANGELOG.' + trKey, translation);
      if (translation.var && translation.from && translation.to) {
        translatedText += this.translateService.instant('BOOKING_CHANGELOG.UPDATE_DIFF', translation);
      }
      trKey = "UPDATE_AND";
    }

    return translatedText;
  }
}
