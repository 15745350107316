import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeNL from '@angular/common/locales/nl';
import * as Dinero from 'dinero.js/build/cjs/dinero.js';

import { APP_DECLARATIONS } from './app.declarations';
import { APP_IMPORTS } from './app.imports';
import { APP_PROVIDERS } from './app.providers';
import { AppComponent } from './app.component';
import { AuthDialogComponent } from './main/containers';
import { ErrorDialogComponent } from './main/components/error-dialog/error-dialog';
import { SpaceDetailModalComponent } from './main/components';
import { initApp } from './initApp';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { VSHttpInterceptor } from '@core/services';

registerLocaleData(localeNL);

Dinero.defaultCurrency = 'EUR';
Dinero.globalLocale = 'nl-NL';
Dinero.globalFormat = '0,0.00';

@NgModule({
  declarations: [
    AppComponent,
    APP_DECLARATIONS
  ],
  imports: [
    APP_IMPORTS
  ],
  entryComponents: [
    AuthDialogComponent,
    ErrorDialogComponent,
    SpaceDetailModalComponent
  ],
  bootstrap: [AppComponent],
  providers: [    
    { provide: APP_INITIALIZER, useFactory: initApp, deps: [Injector], multi: true },
    APP_PROVIDERS,
    { provide: HTTP_INTERCEPTORS, useClass: VSHttpInterceptor , multi: true }

  ]
})
export class AppModule { }
