<mdl-dialog #homeVideoDialog [mdl-dialog-config]="dialogConfig">
  <div class="mdl-dialog__content embed-container">
    <iframe src="https://player.vimeo.com/video/298423522?autoplay=true" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen allow="autoplay; fullscreen"></iframe>
  </div>
</mdl-dialog>

<section class="main-cont">
  <div class="mdl-grid mdl-grid--no-spacing">
    <div class="mdl-cell--no-v-spacing mdl-cell--12-col">
      <div class="jumbotron">
      <div class="jumbotron-images">
        <vs-photo-slider [images]="headerImages" [interval]="4000"></vs-photo-slider>
      </div>

      <div class="overlay"></div>
      <section class="content">
        <div class="filler"></div>
        <div class="content-container">
          <div class="mdl-grid mdl-grid--no-spacing">
            <div class="mdl-cell mdl-cell--12-col cell-middle">
              <div class="search_content">
                <h1 class="display-1" [innerHTML]="'HOME.HEADLINE' | translate"></h1>
                <p class="tagline">{{ 'HOME.TAGLINE' | translate }}</p>
                <form class="form-search" [formGroup]="searchForm">
                  <div class="form-search-inner">
                    <vs-formfield>
                      <div class="autosuggest-field">
                        <div class="autosuggest-prefix">
                          <i class="material-icons">search</i>
                        </div>
                        <div class="autosuggest-infix">
                        <input type="text"
                          class="autosuggest-searchbox"
                          (blur)="onBlur()"
                          formControlName="q"
                          [formControl]="query"
                          [placeholder]="'HOME.SEARCH_PLACEHOLDER' | translate" />
        
                        <ul *ngIf="isOpen" class="autosuggest-list" (click)="onSelect($event)">
                          <li *ngFor="let result of searchResults" class="autosuggest-list-item" [attr.data-key]="result.id">
                            <i *ngIf="result.type" class="icon icon-{{ (result.type === 'city' ? 'profile' : 'venue') }}"></i>
                            {{result.name}}
                        </ul>
                        </div>
                      </div>
                    </vs-formfield>
        
                    <ng-select
                      #eventTypeControl
                      class="eventtype-control"
                      formControlName="eventType"
                      [placeholder]="'HOME.EVENT_TYPE_PLACEHOLDER' | translate"
                      [items]="eventTypeOptions"
                      [clearable]="false"
                      [searchable]="false"
                      (change)="eventTypeControlChanged($event)">
                      <ng-template ng-label-tmp ng-option-tmp let-item="item">
                        {{ ('ACTIVITIES.'+item.toUpperCase()) | translate }}
                      </ng-template>
                    </ng-select>
                  </div>
                  <div class="form-search-btn" role="button" tabindex="0" 
                       (click)="navigateToSearch($event)"
                       (keydown.enter)="navigateToSearch($event)"
                    >
                    {{ 'HOME.SHOW_VENUES' | translate }}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
    </div>
  </div>
</section>

<section class="company_list">
  <div class="mdl-grid mdl-grid--no-spacing">
    <div class="mdl-cell--no-v-spacing mdl-cell--12-col">
      <div class="meetings_div">
        <div class="type_list platform_coloum_normal">
          <div class="type_list_item">
            <div class="type_list_item_title">
              <h3 class="type_list_item_title">{{ 'HOME.COMPANY_LIST_TITLE' | translate }}</h3>
            </div>
          </div>

          <div class="type_list_item">
            <div class="type_list_item_image">
              <img src="../../../../assets/images/companies/rn.svg" alt="rn"/>
            </div>
          </div>

          <div class="type_list_item">
            <div class="type_list_item_image">
              <img src="../../../../assets/images/companies/gk.svg" alt="gk"/>
            </div>
          </div>

          <div class="type_list_item">
            <div class="type_list_item_image">
              <img src="../../../../assets/images/companies/sentia.svg" alt="sentia"/>
            </div>
          </div>

          <div class="type_list_item">
            <div class="type_list_item_image">
              <img src="../../../../assets/images/companies/Vijfhart.svg" alt="Vijfhart"/>
            </div>
          </div>

          <div class="type_list_item">
            <div class="type_list_item_image">
              <img src="../../../../assets/images/companies/Dela.svg" alt="Dela"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section #videoblock class="how-it-works">
  <div class="mdl-grid mdl-grid--no-spacing" *ngIf="showHowItWorks">
    <div class="mdl-cell--no-v-spacing mdl-cell--12-col">
      <div class="hiw">
      <div class="hiw-title">
        <h2 class="display-2">{{ 'HOME.HIW_TITLE' | translate }}</h2>
        <p>{{ 'HOME.HIW_SUBTITLE' | translate }}</p>
      </div>
      <div class="video-div">
        <img class="video-img" src="https://res.cloudinary.com/venuesuite-com/image/upload/v1602766317/homepage/Video_BG.jpg" width="1280" />
        <img class="video-play" src="../../../../assets/images/marketing/play_icon.svg" (click)="homeVideoDialog.show()" />
      </div>
      </div>
    </div>
  </div>
</section>
<section class="section-divider">
  <div class="mdl-grid mdl-grid--no-spacing section-divider" *ngIf="showHowItWorks">
    <div class="mdl-cell--no-v-spacing mdl-cell--12-col mdl-cell--12-col-phone">
      <div class="hiw-list">
        <div class="hiw-list_item">
          <div class="hiw-list_item_title">{{ 'HOME.HIW_1_TITLE' | translate }}</div>
          <div class="hiw-list_item_txt">
            <p>{{ 'HOME.HIW_1_DESC' | translate }}</p>
          </div>
        </div>

        <div class="hiw-list_item">
          <div class="hiw-list_item_title">{{ 'HOME.HIW_2_TITLE' | translate }}</div>
          <div class="hiw-list_item_txt">
            <p>{{ 'HOME.HIW_2_DESC' | translate }}</p>
          </div>
        </div>

        <div class="hiw-list_item">
          <div class="hiw-list_item_title">{{ 'HOME.HIW_3_TITLE' | translate }}</div>
          <div class="hiw-list_item_txt">
            <p>{{ 'HOME.HIW_3_DESC' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="meeting_types">
  <div class="mdl-grid mdl-grid--no-spacing" *ngIf="showCities">
    <div class="mdl-cell--no-v-spacing mdl-cell--12-col">
      <div class="meetings_div">
        <div class="header">
          <h2 class="display-2">{{ 'HOME.MEETING_TYPE_TITLE' | translate }}</h2>
        </div>
        <div class="type_list platform_coloum_normal">
          <div class="type_list_item">
            <a [routerLink]="['/search']" [queryParams]="{eventType:'meeting'}" target="_self" tabindex="-1">
              <img src="https://res.cloudinary.com/venuesuite-com/image/upload/v1602768754/homepage/Vergadering.jpg" width="221" />
            </a>
            <div class="type_list_item_title">
              {{ 'HOME.MEETING_MEETING' | translate }}
            </div>
          </div>
          <div class="type_list_item">
            <a [routerLink]="['/search']" [queryParams]="{eventType:'training'}" target="_self" tabindex="-1">
              <img src="https://res.cloudinary.com/venuesuite-com/image/upload/v1602768749/homepage/Training.jpg" width="221" />
            </a>
            <div class="type_list_item_title">
              {{ 'HOME.MEETING_TRAINING' | translate }}
            </div>
          </div>
          <div class="type_list_item">
            <a [routerLink]="['/search']" [queryParams]="{eventType:'workshop'}" target="_self" tabindex="-1">
              <img src="https://res.cloudinary.com/venuesuite-com/image/upload/v1602768759/homepage/Workshop.jpg" width="221"/>
            </a>
            <div class="type_list_item_title">
              {{ 'HOME.MEETING_WORK' | translate }}
            </div>
          </div>
          <div class="type_list_item">
            <a [routerLink]="['/search']" [queryParams]="{eventType:'event'}" target="_self" tabindex="-1">
              <img src="https://res.cloudinary.com/venuesuite-com/image/upload/v1602768770/homepage/Corporate_event.jpg" width="221" />
            </a>
            <div class="type_list_item_title">
              {{ 'HOME.MEETING_EVENT' | translate }}
            </div>
          </div>
          <div class="type_list_item">
            <a [routerLink]="['/search']" [queryParams]="{eventType:'dinner'}" target="_self" tabindex="-1">
              <img src="https://res.cloudinary.com/venuesuite-com/image/upload/v1602768736/homepage/Diner.jpg" width="221" />
            </a>
            <div class="type_list_item_title">
              {{ 'HOME.MEETING_DINNER' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="booker-platform-advantage platform-advantage"> 
  <div class="mdl-grid mdl-grid--no-spacing">
    <div class="mdl-cell--no-v-spacing mdl-cell--12-col">
      <section class="feature-benefit s-bg-white s-img-align-right ">
        <div class="feature-benefit__text-wrap align-left">
          <div class="feature-benefit__text ">
            <div class="feature-benefit__desc-wrap ">
              <h2 class="display-2 feature-benefit__sub-title">{{ 'HOME.PLATFORM_ADVANT_TITLE_1' | translate }}</h2>              
              <ul>
                <li class="feature-benefit__desc">
                  {{ 'HOME.PLATFORM_ADVANT_DES_1' | translate }}
                </li>
              </ul>
              <a [routerLink]="['/search']" rel="noreferrer" class="mdl-button--xl btn-schedule-demo" mdl-button mdl-button-type="raised" mdl-colored="primary" tabindex="-1">
                {{ 'HOME.VIEW_OFFER' | translate }} 
              </a>
            </div>
          </div>
          <div class="bim_quote-des">
            <blockquote class="review__text">
              <p class="desc">{{ 'HOME.QUOTE_1' | translate }} </p>
              <div class="review__user-box">
                <span class="review__user-name">Kelly Veldkamp</span><span class="review__user-post">&nbsp;|&nbsp;Vijfhart IT-Opleidingen</span>
              </div>
            </blockquote>
          </div>
        </div>
        <div class="feature-benefit__img-wrap s-img-right">
          <div class="feature-benefit__img-wrap-container">
            <img src="{{ 'HOME.PLATFORM_ADVANT_IMAGE_1' | translate }}" width="616" alt="image-1" />
          </div>
        </div>
      </section>
      <section class="feature-benefit s-bg-white s-img-align-left ">
        <div class="feature-benefit__text-wrap align-left">
          <div class="feature-benefit__text ">
            <div class="feature-benefit__desc-wrap ">
              <h2 class="display-2 feature-benefit__sub-title">{{ 'HOME.PLATFORM_ADVANT_TITLE_2' | translate }}</h2>              
              <ul>
                <li>
                  {{ 'HOME.PLATFORM_ADVANT_DES_2' | translate }}
                </li>
              </ul>
              <a href="https://meetings.hubspot.com/niels53" target="_blank" rel="noreferrer" class="mdl-button--xl btn-schedule-demo" mdl-button mdl-button-type="raised" mdl-colored="primary">
                {{ 'HOME.PLAN_DEMO' | translate }} 
              </a>
            </div>
          </div>
          <div class="bim_quote-des">
            <blockquote class="review__text">
              <p class="desc">{{ 'HOME.QUOTE_2' | translate }}</p>
              <div class="review__user-box">
                <span class="review__user-name">Henriëtte Dubois</span><span class="review__user-post">&nbsp;|&nbsp;Reclassering Nederland</span>
              </div>
            </blockquote>
          </div>
        </div>
        <div class="feature-benefit__img-wrap s-img-left">
          <div class="feature-benefit__img-wrap-container">
            <img src="{{ 'HOME.PLATFORM_ADVANT_IMAGE_2' | translate }}" width="616" alt="image-2"/>
          </div>
        </div>
      </section>
      <section class="feature-benefit s-bg-white s-img-align-right ">
        <div class="feature-benefit__text-wrap align-left">
          <div class="feature-benefit__text ">
            <div class="feature-benefit__desc-wrap ">
              <h2 class="display-2">{{ 'HOME.PLATFORM_ADVANT_TITLE_3' | translate }}</h2>
              <ul>
                <li>
                  {{ 'HOME.PLATFORM_ADVANT_DES_3' | translate }}
                </li>
              </ul>
              <a href="https://meetings.hubspot.com/niels53" target="_blank" rel="noreferrer" class="mdl-button--xl btn-schedule-demo" mdl-button mdl-button-type="raised" mdl-colored="primary">
                {{ 'HOME.PLAN_DEMO' | translate }} 
              </a>
            </div>
          </div>
          <div class="bim_quote-des">
            <blockquote class="review__text">
              <p class="desc">{{ 'HOME.QUOTE_3' | translate }}</p>
              <div class="review__user-box">
                <span class="review__user-name">Marian Meppelink</span><span class="review__user-post">&nbsp;|&nbsp;Reclassering Nederland</span>
              </div>
            </blockquote>
          </div>
        </div>
        <div class="feature-benefit__img-wrap s-img-right">
          <div class="feature-benefit__img-wrap-container">
            <img src="{{ 'HOME.PLATFORM_ADVANT_IMAGE_3' | translate }}" width="616" alt="image-3"/>
          </div>
        </div>
      </section>
    </div>
  </div>
</section>

<!-- <section class="quote">
  <div class="mdl-grid mdl-grid--no-spacing">
    <div class="mdl-cell--no-v-spacing mdl-cell--12-col">
      <span class="quote-mark"></span>
      <span class="quote-txt">{{ 'ADD_VENUE.QUOTE' | translate }}</span>
      <div class="quote-person">
        <img src="https://res.cloudinary.com/venuesuite-com/image/upload/v1547481425/marketing/nbc_partner.png" />
        <div class="quote-person__desc">
          <b>Justin van Hooijdonk</b>
          <p>CEO NBC Congrescentrum</p>
        </div>
      </div>
    </div>
  </div>
</section> -->

<section class="new_way">
  <div class="mdl-grid mdl-grid--no-spacing" *ngIf="showMission">
    <div class="mdl-cell--no-v-spacing mdl-cell--12-col">
      <ng-container>
        <div class="new_way_container">
          <div class="new_way_container__wrap">
            <div class="new_way_container__wrap-text ">
              <div class="new_way_container__wrap-text-desc-wrap ">
                <h2 class="display-2">{{ 'HOME.USP_TITLE' | translate }}</h2>
              </div>
            </div>
          </div>
    
          <div class="new_way_container-list">
            <div class="usp_item">
              <div class="usp_item-icon usp_item-icon--quality-check">
                <img src="../../../../assets/images/marketing/instant_price.svg"/>
              </div>
              <h3 class="display-3">{{ 'HOME.USP_1_TITLE' | translate }}</h3>
              <p>{{ 'HOME.USP_1_DESC' | translate }}</p>
            </div>
    
            <div class="usp_item">
              <div class="usp_item-icon usp_item-icon--quality-check">
                <img src="../../../../assets/images/marketing/quick_impression.svg"/>
              </div>
              <h3 class="display-3"> {{ 'HOME.USP_2_TITLE' | translate }}</h3>
              <p>{{ 'HOME.USP_2_DESC' | translate }}</p>
            </div>
    
            <div class="usp_item">
              <div class="usp_item-icon usp_item-icon--quality-check">
                <img src="../../../../assets/images/marketing/quality_guarantee.svg"/>
              </div>
              <h3 class="display-3">{{ 'HOME.USP_3_TITLE' | translate }}</h3>
              <p>{{ 'HOME.USP_3_DESC' | translate }}</p>
            </div>
    
            <div class="usp_item">
              <div class="usp_item-icon usp_item-icon--quality-check">
                <img src="../../../../assets/images/marketing/company_insights.svg"/>
              </div>
              <h3 class="display-3">{{ 'HOME.USP_4_TITLE' | translate }}</h3>
              <p>{{ 'HOME.USP_4_DESC' | translate }}</p>
            </div>
    
            <div class="usp_item">
              <div class="usp_item-icon usp_item-icon--quality-check">
                <img src="../../../../assets/images/marketing/free.svg"/>
              </div>
              <h3 class="display-3">{{ 'HOME.USP_5_TITLE' | translate }}</h3>
              <p>{{ 'HOME.USP_5_DESC' | translate }}</p>
            </div>
            
            <div class="usp_item">
              <div class="usp_item-icon usp_item-icon--quality-check">
                <img src="../../../../assets/images/marketing/direct_contact.svg"/>
              </div>
              <h3 class="display-3">{{ 'HOME.USP_6_TITLE' | translate }}</h3>
              <p>{{ 'HOME.USP_6_DESC' | translate }}</p>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</section>


<section class="get-started">
  <div class="mdl-grid mdl-grid--no-spacing" *ngIf="showGetStarted">
    <div class="mdl-cell--no-v-spacing mdl-cell--12-col">
      <div class="container">
        <h2 class="display-h2">{{ 'HOME.TO_WORK' | translate }}</h2>
        <a [routerLink]="['/search']" rel="noreferrer" class="mdl-button--xl btn-schedule-demo" mdl-button mdl-button-type="raised" mdl-colored="primary">
          {{ 'HOME.DISCOVER_VENUES' | translate }}
        </a>
        <button onclick="window.open('https://meetings.hubspot.com/niels53', '_blank')" rel="noreferrer" target="_blank" class="mdl-button--xl" mdl-button mdl-button-type="raised" mdl-colored="accent">
          {{ 'HOME.REQUEST_DEMO' | translate }}
        </button>
      </div>
    </div>
  </div>
</section>

<vs-footer></vs-footer>

