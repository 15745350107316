import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { PriceRule } from '../models/price-rule';
import { SessionService } from './session.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class PriceRuleService {

  static readonly PRICE_EXCLUDE_DAY = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"]

  venueId: number = 0;

  constructor(private sessionService: SessionService, private http: HttpClient) {
    this.sessionService.user$.subscribe(user => {
      this.venueId = (user !== null ? user.venue_id : null);
    });
  }

  /**
   * 
   */
  setVenueId(val: number) {
    this.venueId = val;
  }

  /**
   * 
   */
  getVenueId(venueId?: number) {
    return venueId ? venueId : this.venueId;
  }

  /**
   * Get All
   */
  getAll(venueId?: number): Observable<PriceRule[]> {
    const auxVenueId = this.getVenueId(venueId);
    let requestUrl = `${environment.API_URL}/venues/${auxVenueId}/pricing_rule`;
    return this.http.get<PriceRule[]>(requestUrl);
  }

  /**
   * Save
   */
  save(priceRule: PriceRule): Observable<PriceRule> {
    if (priceRule.id) {
      return this.update(priceRule);
    }
    return this.create(priceRule);
  }

  /**
   * Create
   */
  create(priceRule: PriceRule): Observable<PriceRule> {
    const auxVenueId = this.getVenueId();
    const requestOptions = { headers: new HttpHeaders() };
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(priceRule);

    return this.http.post<PriceRule>(`${environment.API_URL}/venues/${auxVenueId}/pricing_rule`,  body, requestOptions);
  }

  /**
   * Update
   */
  update(priceRule: PriceRule): Observable<PriceRule> {
    const auxVenueId = this.getVenueId();
    const requestOptions = { headers: new HttpHeaders() };
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(priceRule);

    return this.http.put<PriceRule>(`${environment.API_URL}/venues/${auxVenueId}/pricing_rule/${priceRule.id}`,  body, requestOptions);
  }

  /**
   * Delete
   */
  delete(id: number): Observable<any> {
    const auxVenueId = this.getVenueId();
    const requestOptions = {
      headers: new HttpHeaders()
    };

    return this.http.delete(`${environment.API_URL}/venues/${auxVenueId}/pricing_rule/${id}`, requestOptions);
  }
}