import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'orderBy', pure: false
})
export class OrderByPipe implements PipeTransform {
  output: Array<any> = [];
  sortedOutput: Array<any> = [];
  return: Array<any> = [];

  transform(input: any, col: string, type: any = false, desc: boolean = false) {
    if (!Array.isArray(input) || input.length <= 1) {
      return input;
    }

    for (const [key, value] of Object.entries(input)) {
      if (value.hasOwnProperty(col)) {
        this.output[key] = value[col];
      }
    }

    this.sortedOutput = this.output.sort(function(a, b) {
      if (type === 'datetime') {
        if (a !== b) {
          return desc ? +new Date(a) - +new Date(b) : +new Date(b) - +new Date(a);
        }
      } else {
        return desc ? a - b : b - a;
      }
    })

    for (const [k, v] of Object.entries(this.sortedOutput)){
      for (const [ik, iv] of Object.entries(input)){
        if (iv[col] === v) {
          this.return[k] = iv;
        }
      }
    }

    return this.return.filter(function(elem, index, self) {
      return index === self.indexOf(elem);
    });

  }
}
