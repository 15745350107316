import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'vs-sidenav',
  templateUrl: './sidenav.html',
  styleUrls: ['./sidenav.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SidenavComponent {
  @Input() title: string;
}
