import { Component, InjectionToken, Inject } from '@angular/core';
import { MdlDialogReference } from '@angular-mdl/core';

export interface IErrorInfo {
  hash: string;
  location: string;
  name: string;
  stack?: any;
}

export const ERR_INFO = new InjectionToken<IErrorInfo>('errorInfo');

@Component({
  selector: 'vs-error-dialog',
  templateUrl: './error-dialog.html',
  styleUrls: ['./error-dialog.scss']
})
export class ErrorDialogComponent {
  hash: string;
  location: string;
  message: string;
  stack: any;

  constructor(@Inject(ERR_INFO) errInfo: IErrorInfo, private dialogRef: MdlDialogReference) {
    this.hash = errInfo.hash;
    this.location = errInfo.location;
    this.message = errInfo.name;
    this.stack = errInfo.stack;
  }

  close() {
    this.dialogRef.hide();
  }
}
