import {Venue} from '../models';
import * as favoriteActions from '../actions/favorite.actions';

export interface State {
  venues: Map<number, Venue>;
  loading: boolean;
}

const initialState: State = {
  venues: new Map(),
  loading: false
};

export function reducer(state = initialState, action: favoriteActions.Actions): State {
  switch (action.type) {
    case favoriteActions.ActionTypes.LOAD_VENUES:
      return {
        ...state,
        loading: true
      };
    case favoriteActions.ActionTypes.VENUES_LOADED:
      return {
        venues: new Map(action.payload.map(v => [v.id, v] as [number, Venue])),
        loading: false
      };
    case favoriteActions.ActionTypes.VENUE_ADDED:
      const addedVenue = action.payload;

      return {
        venues: new Map(state.venues.set(addedVenue.id, addedVenue)),
        loading: false
      };
    case favoriteActions.ActionTypes.VENUE_REMOVED:
      state.venues.delete(action.payload.id);
      return {
        venues: new Map(state.venues),
        loading: false
      };
    default:
      return state;
  }
}

export const getLoading = (state: State) => state.loading;
export const getVenues = (state: State) => state.venues;
