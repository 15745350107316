<div class="form-card">
  <h4>{{ 'FORGOT_PASS.HEADER' | translate }}</h4>
  <p class="subtitle">{{ 'FORGOT_PASS.TEXT' | translate }}</p>
  <form [formGroup]="form" (submit)="$event.preventDefault()">
    <vs-formfield [class.is-invalid]="email.invalid && submitAttempt">
      <label>{{ 'LOGIN.USERNAME' | translate }}</label>
      <input type="email" formControlName="email" />
      <span dirErrorMsg>{{ 'LOGIN.USERNAME_ERROR' | translate }}</span>
    </vs-formfield>
    <button (click)="submitForm()" mdl-button mdl-button-type="raised" mdl-colored="accent">
      <ng-template [ngIf]="!loading">{{ 'FORGOT_PASS.SEND' | translate }}</ng-template>
      <ng-template [ngIf]="loading">{{ 'FORGOT_PASS.SENDING' | translate }}</ng-template>
    </button>
  </form>
</div>
