import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  forwardRef, OnDestroy,
} from '@angular/core';

import { environment } from '@env/environment';
import { FileUploader } from 'ng2-file-upload';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ImageTransformPipe } from '../../pipes/image-transform';

const IMAGE_UPLOAD_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ImageUploadComponent),
  multi: true
};

@Component({
  selector: 'vs-file-upload',
  providers: [IMAGE_UPLOAD_VALUE_ACCESSOR],
  templateUrl: './image-upload.html',
  styleUrls: ['./image-upload.scss']
})
export class ImageUploadComponent implements OnDestroy, OnInit, ControlValueAccessor {
  @Input() ngModel: any;
  @Input() fileLimit = 1;
  @Input() min;
  @Input() sortable = true;
  @Input() multiple = false;
  @Input() header = false;
  @Input() isLogo = false;
  @Output() change: EventEmitter<any> = new EventEmitter(true);
  @ViewChild('fileInput', {static: false}) nativeFileInput: ElementRef;
  images: Array<string> = [];
  maxFileSize = 50000000;
  loading: boolean;
  uploaderService: FileUploader;
  enabled = true;

  get image() {
    return this.images[0];
  }

  get hasImage() {
    return !!this.images[0];
  }

  options = {
    draggable: '.image-item',
    onUpdate: (event: any) => {
      this.writeValue(this.images);
    }
  };

  imageTransformPipe: ImageTransformPipe;
  private onChange: any = Function.prototype;
  private onTouched: any = Function.prototype;

  constructor(
    private changeDetectionRef: ChangeDetectorRef,
    private elementRef: ElementRef) {

    this.imageTransformPipe = new ImageTransformPipe();
  }

  ngOnInit() {
    this.images = [];

    this.enabled = true;
    this.uploaderService = new FileUploader({
      url: `${environment.API_URL}/upload/image`,
      autoUpload: true,
      maxFileSize: this.maxFileSize,
      headers: [{
        name: 'Authorization',
        value: 'Bearer ' + localStorage.getItem('vsuite/apiToken')
      }]
    });

    this.uploaderService.onBeforeUploadItem = (item) => {
      item.withCredentials = false;
      this.loading = true;
    };

    this.uploaderService.onErrorItem = (item, response) => {
      alert('Failed to upload ' + item.file.name + '. The file server didn\'t accept it');
    };

    this.uploaderService.onWhenAddingFileFailed = (item, filter, option) => {
      if ( filter.name === 'fileSize' ) {
        alert(`
          File size to big. The maximum permitted file size is ${this.formatBytes(this.maxFileSize)}.
          Your file is ${this.formatBytes(item.size)}.
        `);
      } else {
        alert('The file you selected is either not allowed or it cannot be recognized.');
      }
    };

    this.uploaderService.onSuccessItem = (fileItem, response) => {
      const parsedResponse = JSON.parse(response);
      let imageUrl: string;

      if ( parsedResponse ) {

        imageUrl = parsedResponse.secure_url;

        if ( this.multiple ) {
          this.writeValue([...this.images, imageUrl]);
        } else {
          this.writeValue([imageUrl]);
        }
      } else {
        alert('Failed to upload ' + fileItem.file.name + '. The file server didn\'t accept it');
      }
    };

    this.uploaderService.onCompleteAll = () => {
      this.loading = false;
    };
  }

  ngOnDestroy() {
    this.enabled = false;
  }

  transformImg(img: string) {
    return this.imageTransformPipe.transform(img, 112, 112, true);
  }

  formatBytes(bytes: number, decimals = 2): string {
    if ( bytes === 0 ) {
      return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals || 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    const s = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

   return s + ' ' + sizes[i];
  }

  hasError(): boolean {
    const nativeElement: HTMLElement = this.elementRef.nativeElement;
    return nativeElement.classList.contains('is-invalid');
  }

  writeValue(value: Array<string>) {
    value = !value ? [] : value;

    if ( value && this.enabled ) {
      this.ngModel = value;
      this.onChange(this.ngModel);
      this.renderValue(this.ngModel);
    }
  }

  private renderValue(value: Array<string>) {
    this.images = value;
    this.changeDetectionRef.detectChanges();
  }

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  deleteImg(index: number) {
    this.images.splice(index, 1);
    this.writeValue(this.images);
  }

  openFileInputDialog() {
    this.nativeFileInput.nativeElement.click();
  }
}
