<div class="vs-datetime-picker" #container>
  <div class="vs-datetime-picker_calendar-section">
    <div class="vs-datetime-picker_actions" *ngIf="allowMultipleDates">
      <div class="vs-datetime-picker_actions-button"
        role="button"
        tabindex="8" 
        [class.vs-datetime-picker_actions-button--active]="activeView === 'single'"
        (click)="showActiveView('single')"
        (keydown.enter)="showActiveView('single')">
        {{ 'DATE_TIME.SINGLE_DATE' | translate }}
      </div>
      <div class="vs-datetime-picker_actions-button"
        role="button"
        tabindex="9" 
        [class.vs-datetime-picker_actions-button--active]="activeView === 'multiple'"
        (click)="showActiveView('multiple')"
        (keydown.enter)="showActiveView('multiple')">
        {{ 'DATE_TIME.REPEATING_DATES' | translate }}
      </div>
    </div>

    <div 
       class="vs-datetime-picker_mini-cal_navigator" 
       id="datetimepickerMonthSelector"
       tabindex="10" 
       (keydown.arrowleft)="prevMonth()" 
       (keydown.arrowright)="nextMonth()" 
       (keydown.shift.tab)="setFocus('datetimepickerCancelButton')">
      <span 
        tabindex="11" 
        class="material-icons" 
        (click)="prevMonth()" 
        (keydown.enter)="prevMonth()" 
        role="button" 
        title="Previous Month">arrow_back</span>
      {{ activeMonth }}, {{ activeYear }}
      <span 
        tabindex="12"
        class="material-icons"
        (click)="nextMonth()"
        (keydown.enter)="nextMonth()" 
        role="button"
        title="Next Month">arrow_forward</span>
    </div>

    <div class="vs-datetime-picker_mini-cal_data">
      <table>
        <thead>
          <tr>
            <th>{{ 'COMMON.DAY_OF_WEEK_SHORT.MO' | translate }}</th>
            <th>{{ 'COMMON.DAY_OF_WEEK_SHORT.TU' | translate }}</th>
            <th>{{ 'COMMON.DAY_OF_WEEK_SHORT.WE' | translate }}</th>
            <th>{{ 'COMMON.DAY_OF_WEEK_SHORT.TH' | translate }}</th>
            <th>{{ 'COMMON.DAY_OF_WEEK_SHORT.FR' | translate }}</th>
            <th>{{ 'COMMON.DAY_OF_WEEK_SHORT.SA' | translate }}</th>
            <th>{{ 'COMMON.DAY_OF_WEEK_SHORT.SU' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let w of calendarData">
            <td *ngFor="let d of w.days"
                [class.today]="d.isToday && d.isCurrentMonth"
                [class.selected]="d.isSelected && d.isCurrentMonth"
                [class.is-past]="d.isPast || !d.isAvailable"
                [class.not-selectable]="d.isPast || d.isToday || !d.isAvailable || !d.isCurrentMonth"
                [class.outside-current]="!d.isCurrentMonth"
                [attr.tabindex]="(d.isPast || d.isToday || !d.isAvailable || !d.isCurrentMonth) ? null : sum(13, d.dayOfMonth)"
                (click)="selectDate($event, d)"
                (keydown.enter)="selectDate($event, d)"
                data-iso="d.dateIso"
                role="button"
                >
                <span>{{ d.isCurrentMonth ? d.dayOfMonth : '' }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div  class="vs-datetime-picker_additional-section" *ngIf="!calendarOnly">
    <ng-container *ngIf="allowTimeSelection">
      <h3 class="vs-datetime-picker_time-header">{{ 'COMMON.TIME' | translate }}</h3>
      <div class="vs-datetime-picker_time">
        <vs-formfield>
          <ng-select
            placeholder="Start time"
            [(ngModel)]="startTime"
            [searchable]="false"
            [clearable]="false"
            [items]="startTimeOptions"
            (change)="onStartTimeChange($event)"
            bindLabel="value"
            bindValue="value"
            tabindex="50">
            <ng-template ng-header-tmp>{{ 'DATE_TIME.START_TIME' | translate }}</ng-template>
            <ng-template ng-label-tmp let-item="item">{{ item.value }}</ng-template>
            <ng-template ng-option-tmp let-item="item">{{ item.label }}</ng-template>
          </ng-select>
        </vs-formfield>

        <vs-formfield>
          <ng-select
            placeholder="End time"
            [(ngModel)]="endTime"
            [searchable]="false"
            [clearable]="false"
            bindLabel="value"
            bindValue="value"
            [items]="endTimeOptions"
            tabindex="51">
            <ng-template ng-header-tmp>{{ 'DATE_TIME.END_TIME' | translate }}</ng-template>
            <ng-template ng-label-tmp let-item="item">{{ item.value }}</ng-template>
            <ng-template ng-option-tmp let-item="item">{{ item.label }}</ng-template>
          </ng-select>
        </vs-formfield>
      </div>

      <div class="vs-helptext" *ngIf="startTime !== '' && endTime !== ''">
        <div class="vs-helptext-text">{{ 'DATE_TIME.PRICE_NOTE' | translate }}</div>
      </div>
    </ng-container>
    <ng-container *ngIf="activeView === 'single'">
      <h3 class="vs-datetime-picker_selected-dates-header">{{ 'DATE_TIME.SELECTED_DATE' | translate }}</h3>
      <div class="vs-datetime-picker_selected-dates" *ngIf="selectedDates.length > 0">
        <ol>
          <li>
            <span (click)="navigateCalendarTo(selectedDate)">{{ selectedDate.format('dd, D MMM YYYY')}}</span>
          </li>
        </ol>
      </div>
    </ng-container>
    <ng-container *ngIf="activeView === 'multiple'">
      <h3 class="vs-datetime-picker_selected-dates-header">{{ 'DATE_TIME.SELECTED_DATES' | translate }} ({{ selectedDates.length }})</h3>
      <div class="vs-datetime-picker_selected-dates">
        <ol>
          <li *ngFor="let day of selectedDates; let i = index">
            <strong>{{ i + 1 }}</strong>
            <span (click)="navigateCalendarTo(day)">{{ day.format('dd, D MMM YYYY') }}</span>
            <i (click)="deselectDay($event, i)" class="icon icon-delete"></i>
          </li>
        </ol>
      </div>
    </ng-container>
    <div class="vs-datetime-picker_buttons">
      <button
        type="button"
        id="datetimepickerCancelButton"
        tabindex="60"
        autofocus
        mdl-button
        (keydown.tab)="setFocus('datetimepickerConfirmButton', 'datetimepickerMonthSelector')"
        (click)="hide($event)">
        {{ 'COMMON.CANCEL' | translate }}
      </button>
      <button
        type="button"
        id="datetimepickerConfirmButton"
        tabindex="61"
        (click)="confirm($event)"
        (keydown.tab)="setFocus('datetimepickerMonthSelector')"
        [disabled]="!isValid"
        mdl-button
        mdl-button-type="raised"
        mdl-colored="accent">
        {{ 'COMMON.SELECT' | translate }}
      </button>
    </div>
    <div class="vs-datetime-picker_buttons-placeholder"></div>
  </div>
</div>
