import { Observable, BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import {environment as env} from "@env/environment";

export enum DAY_PART {
  MORNING = 'morning',
  AFTERNOON = 'afternoon',
  EVENING = 'evening'
}

export interface ICalendarAvailabilityHour {
  hour: number;
  availability: number;
  isAvailable: boolean;
  isSelected: boolean;
}

export interface ICalendarAvailabilityDayPart {
  dayPart: string;
  availability: number;
  isAvailable: boolean;
  isSelected: boolean;
}

export interface ICalendarAvailabilityDay {
  dateIso: string;
  dayShortCode: string;
  dayOfMonth?: string;
  isToday: boolean;
  isPast: boolean;
  isCurrentMonth: boolean;
  isAvailable: boolean;
  dayParts: ICalendarAvailabilityDayPart[];
  hours: ICalendarAvailabilityHour[];
}

export interface ICalendarAvailabilityWeek {
  weekNr: string;
  days: ICalendarAvailabilityDay[];
}

export interface ICalendarSelectedDates {
  date: moment.Moment;
  dayparts: string[];
  hours: number[];
}

export class CalendarAvailabilityService {
  calendarDataChange$: Observable<ICalendarAvailabilityWeek[]>;

  private _calendarData: BehaviorSubject<ICalendarAvailabilityWeek[]>;
  private _selectedDates: ICalendarSelectedDates[] = [];
  private _currentDate: moment.Moment;
  private _datePointer: moment.Moment;
  private _allowMultiple: boolean = true;

  /**
   *
   */
  get selectedDates() {
    return this._selectedDates;
  }

  /**
   *
   */
  get datePointer() {
    return this._datePointer;
  }

  /**
   *
   */
  set datePointer(date: moment.Moment) {
    if (!date.isValid()) {
        return;
    }
    this._datePointer = date;
    this._generateCalendarData();
  }

  /**
   *
   */
  constructor() {
    this._currentDate = moment();
    this._datePointer = moment();

    this._calendarData = new BehaviorSubject(null);
    this.calendarDataChange$ = this._calendarData.asObservable();

    this._generateCalendarData();
  }

  /**
   * 
   */
  allowMultiple(allow :boolean = true) {
    this._allowMultiple = allow;
    return this;
  }

  /**
   *
   */
  add(amount: any, unit: string = 'month') {
    this._datePointer.add(amount, unit);
    this._generateCalendarData();
  }

  /**
   *
   */
  substract(amount: any, unit: string = 'month') {
    this._datePointer.subtract(amount, unit);
    this._generateCalendarData();
  }

  /**
   *
   */
  selectDate(day: string, dayPart: string) {
    const momentSelectedDate = moment(day);
    this._mergeSelectedDate(momentSelectedDate, dayPart);
    this._generateCalendarData();
  }

  /**
   *
   */
  selectHour(day: string, hour: number) {
    const momentSelectedDate = moment(day);
    this._mergeSelectedHour(momentSelectedDate, hour);
    this._generateCalendarData();
  }

  /**
   *
   */
  deselectDate(day: string) {
    const removeDate = moment(day);
    const dateIndex = this._selectedDates.findIndex(dateItem => removeDate.isSame(dateItem.date, 'day'));
    if (dateIndex >= 0) {
      this._selectedDates.splice(dateIndex, 1);
    }
    this._generateCalendarData();
  }

  /**
   *
   */
  navigateToDate(date: moment.Moment) {
    this._datePointer = date.clone();
    this._generateCalendarData();
  }

  /**
   *
   */
  removeSelectedDates() {
    this._selectedDates = [];
  }

  /**
   *
   */
  refresh() {
    this._generateCalendarData();
  }

  /**
   *
   */
  dayPartsFromDateRange(primaryDate): string[] {
    let startHour = moment(primaryDate.start).hour();
    let endHour = moment(primaryDate.end).hour();

    let dayParts: string[] = [];
    if (startHour == env.DAY_PART_START_HOUR.MORNING) {
      dayParts.push(DAY_PART.MORNING);
    } else if (startHour == env.DAY_PART_START_HOUR.AFTERNOON) {
      dayParts.push(DAY_PART.AFTERNOON);
    } else if (startHour == env.DAY_PART_START_HOUR.EVENING) {
      dayParts.push(DAY_PART.EVENING);
    }
    if (endHour == env.DAY_PART_END_HOUR.AFTERNOON) {
      if (startHour == env.DAY_PART_START_HOUR.MORNING) {
        dayParts.push(DAY_PART.AFTERNOON);
      }
    } else if (endHour == env.DAY_PART_END_HOUR.EVENING) {
      if (startHour == env.DAY_PART_START_HOUR.MORNING) {
        dayParts.push(DAY_PART.AFTERNOON, DAY_PART.EVENING);
      } else if (startHour == env.DAY_PART_START_HOUR.AFTERNOON) {
        dayParts.push(DAY_PART.EVENING);
      }
    }

    return dayParts;
  }

  /**
   *
   */
  hoursFromDateRange(primaryDate): number[] {
    let startHour = moment(primaryDate.start).hour();
    let endHour = moment(primaryDate.end).hour();

    let hours: number[] = [];

    for (let i = startHour; i <= endHour; i++) {
      hours.push(i);
    }

    return hours;
  }

  /**
   *
   */
  private _generateCalendarData() {
    let firstWeekOfMonth = this._datePointer.clone().startOf('month');
    let lastWeekOfMonth = this._datePointer.clone().endOf('month');

    let calendarData = [];

    let week: moment.Moment = firstWeekOfMonth.clone();

    const startHours = env.DAY_PART_START_HOUR.MORNING.valueOf();
    const endHours = (env.DAY_PART_END_HOUR.EVENING.valueOf() == 0) ? 24 : env.DAY_PART_END_HOUR.EVENING.valueOf();

    while ( ! week.isAfter(lastWeekOfMonth, 'isoWeek') ) {

      let weekObject = {
        weekNr: week.format('W'),
        days: []
      };

      let weekDay = week.clone().startOf('isoWeek');
      let endOfWeek = week.clone().endOf('isoWeek');

      while ( ! weekDay.isAfter(endOfWeek, 'day') ) {

        let hours = [];
        for (let i = startHours; i <= endHours; i++) {
          hours.push({hour: i, isAvailable: true, availability: 1, isSelected: this._isHourSelected(weekDay, i)});
        }

        let day: ICalendarAvailabilityDay = {
          dateIso: weekDay.toISOString(true),
          dayShortCode: weekDay.format('dd'),
          dayOfMonth: weekDay.format('D'),
          isToday: weekDay.isSame(this._currentDate, 'day'),
          isCurrentMonth: weekDay.isSame(this._datePointer, 'month'),
          isPast: weekDay.isBefore(this._currentDate, 'day'),
          isAvailable: true,
          dayParts: [
            {dayPart: DAY_PART.MORNING, isAvailable: true, availability: 1, isSelected: this._isDayPartSelected(weekDay, DAY_PART.MORNING)},
            {dayPart: DAY_PART.AFTERNOON, isAvailable: true, availability: 1, isSelected: this._isDayPartSelected(weekDay, DAY_PART.AFTERNOON)},
            {dayPart: DAY_PART.EVENING, isAvailable: true, availability: 1, isSelected: this._isDayPartSelected(weekDay, DAY_PART.EVENING)}
          ],
          hours: hours
        };

        weekObject.days.push(day);

        weekDay.add(1, 'day');
      }

      calendarData.push(weekObject);

      week.add(1, 'week');
    }

    this._calendarData.next(calendarData);
  }

 /**
   *
   */
  private _mergeSelectedDate(weekDay: moment.Moment, daypart: string) {
    const dateIndex = this._selectedDates.findIndex(dateItem => weekDay.isSame(dateItem.date, 'day'));
    if (dateIndex >= 0) {
      // check continuity
      if ((daypart === DAY_PART.MORNING || daypart === DAY_PART.EVENING) &&
          this._selectedDates[dateIndex].dayparts.length === 1 &&
          this._selectedDates[dateIndex].dayparts.indexOf(daypart === DAY_PART.MORNING ? DAY_PART.EVENING : DAY_PART.MORNING) !== -1
        ) {
        this._selectedDates[dateIndex].dayparts = [];
      }
      if (daypart === DAY_PART.AFTERNOON &&
          this._selectedDates[dateIndex].dayparts.length === 3
        ) {
        return;
      }
      // end check continuity

      const daypartIndex = this._selectedDates[dateIndex].dayparts.indexOf(daypart);
      if (daypartIndex === -1) {
        this._selectedDates[dateIndex].dayparts.push(daypart);
      } else {
        this._selectedDates[dateIndex].dayparts.splice(daypartIndex, 1);
        if (this._selectedDates[dateIndex].dayparts.length === 0) {
          this._selectedDates.splice(dateIndex, 1);
        }
      }
    } else {
      let selectedDate: ICalendarSelectedDates = {
        date: weekDay,
        dayparts: [daypart],
        hours: []
      };
      if (!this._allowMultiple) {
        this.removeSelectedDates();
      }
      this._selectedDates.push(selectedDate);
    }
  }

  /**
   *
   */
  private _mergeSelectedHour(weekDay: moment.Moment, hour: number) {
    const dateIndex = this._selectedDates.findIndex(dateItem => weekDay.isSame(dateItem.date, 'day'));
    if (dateIndex >= 0) {
      const daypartIndex = this._selectedDates[dateIndex].hours.indexOf(hour);
      const startHour = Math.min.apply(Math, this._selectedDates[dateIndex].hours);
      const endHour = Math.max.apply(Math, this._selectedDates[dateIndex].hours);
      if (daypartIndex === -1) {

        if (hour > startHour && this._selectedDates[dateIndex].hours.length === 1) {
          this._selectedDates[dateIndex].hours = [];
          for (let i = startHour; i <= hour; i++) {
            this._selectedDates[dateIndex].hours.push(i);
          }
        } else if (hour < startHour && this._selectedDates[dateIndex].hours.length === 1) {
          this._selectedDates[dateIndex].hours = [];
          for (let i = hour; i <= startHour; i++) {
            this._selectedDates[dateIndex].hours.push(i);
          }
        } else if (hour - endHour === 1) {
          this._selectedDates[dateIndex].hours.push(hour);
        } else if (startHour - hour === 1) {
          this._selectedDates[dateIndex].hours.unshift(hour);
        } else {
          this._selectedDates[dateIndex].hours = [];
          this._selectedDates[dateIndex].hours.push(hour);
        }

      } else {

        // do not deselect hours in between selection
        if (hour <= startHour || hour >= endHour) {
          this._selectedDates[dateIndex].hours.splice(daypartIndex, 1);
          if (this._selectedDates[dateIndex].hours.length === 0) {
            this._selectedDates.splice(dateIndex, 1);
          }
        } // Deselect ranges
        else {
          // close to end date
          if (endHour - hour < hour - startHour) {
            this._selectedDates[dateIndex].hours.splice(daypartIndex + 1, endHour - hour);
          } else {
            // close to start date
            this._selectedDates[dateIndex].hours.splice(0, hour - startHour);
          }
        }
      }
    } else {
      let selectedDate: ICalendarSelectedDates = {
        date: weekDay,
        dayparts: [],
        hours: [hour]
      };
      if (!this._allowMultiple) {
        this.removeSelectedDates();
      }
      this._selectedDates.push(selectedDate);
    }
  }

  /**
   *
   */
  private _isDayPartSelected(weekDay: moment.Moment, daypart: string) {
    let newWeekDay = weekDay.clone();
    const dateIndex = this._selectedDates.findIndex(dateItem => newWeekDay.isSame(dateItem.date, 'day'));
    if (dateIndex >= 0) {
      return this._selectedDates[dateIndex].dayparts.indexOf(daypart) !== -1;
    }
    return false;
  }

  /**
   *
   */
  private _isHourSelected(weekDay: moment.Moment, hour: number) {
    let newWeekDay = weekDay.clone();
    const dateIndex = this._selectedDates.findIndex(dateItem => newWeekDay.isSame(dateItem.date, 'day'));
    if (dateIndex >= 0) {
      return this._selectedDates[dateIndex].hours.indexOf(hour) !== -1;
    }
    return false;
  }
}
