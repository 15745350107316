<div class="vs-carousel" [class.coming-soon]="comingSoon" tabindex="0">
    <div class="coming-soon-overlay" *ngIf="comingSoon">
       <h4>{{ 'SEARCH.COMING_SOON' | translate }}</h4>
    </div>
    <div
      *ngIf="showCarouselNav"
      class="mdl-shadow--2dp carousel-nav carousel-nav--left"
      (click)="nextImageEvent($event, 'left')">
      <i class="icon icon-arrow-left-short"></i>
    </div>
    <img [src]="image | imageRatio:transformation:width" alt="Gallery visuals" />
    <div
      *ngIf="showCarouselNav"
      class="mdl-shadow--2dp carousel-nav carousel-nav--right"
      (click)="nextImageEvent($event, 'right')">
      <i class="icon icon-arrow-right-short"></i>
    </div>
</div>
