import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DocumentService {
  constructor(private http: HttpClient) { }

  /**
   * 
   */
  getBookingDocuments(bookingId: number): Observable<any> {
    const requestUrl = `${environment.API_URL}/bookings/${bookingId}/documents`;
    return this.http.get(requestUrl);
  }

  /**
   * 
   */
  delete(bookingId: number, documentId: number): Observable<any> {
    const requestUrl = `${environment.API_URL}/bookings/${bookingId}/documents/${documentId}`;
    return this.http.delete(requestUrl);
  }

  /**
   * 
   */
  update(documentId: number, document: any): Observable<any> {
    const requestUrl = `${environment.API_URL}/documents/${documentId}`;
    const requestBody = JSON.stringify(document);

    return this.http.patch(requestUrl, requestBody);
  }
}
