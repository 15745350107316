import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { maskCurrency } from '@core/util';

@Directive({
  selector: '[vsCurrencyMask]'
})
export class CurrencyMaskDirective {

  private regex: RegExp = new RegExp(/^[0-9.,]+$/);
  private allowedKeyCodes = [37, 39, 9, 8, 13, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
  private allowedCombinationCodes = [90, 65, 67, 88, 86];

  constructor(private control: NgControl) { }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    return this.regex.test(event.key) ||
      this.allowedKeyCodes.includes(event.keyCode) ||
      this.allowedCombinationCodes.includes(event.keyCode) && (event.ctrlKey || event.metaKey);
  }

  @HostListener('blur', ['$event']) onBlur(event: FocusEvent) {
    const value = +this.control.control.value;

    if ( value ) {
      this.control.control.setValue(maskCurrency(value));
    }
  }
}
