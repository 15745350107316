import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '@env/environment';

@Injectable()
export class HubSpotService {
  private isVisible = false;
  private isLoaded = false;
  private checkOnNav = true;

  urlWhiteList: {[url: string]: boolean} = {};

  /**
   * 
   */
  constructor(@Inject(DOCUMENT) private document: any) {}

  /**
   * 
   */
  setManual() {
    this.checkOnNav = false;
  }

  /**
   * 
   */
  register(url: string) {
    this.checkOnNav = true;
    if ( ! this.urlWhiteList[url] ) {
      this.urlWhiteList[url] = true;
    }
  }

  /**
   * 
   */
  onAppNav(url: string) {
    if (!this.checkOnNav) {
        return;
    }
    if (this.urlWhiteList[url]) {
      if (!this.isVisible) {
        setTimeout(() => {
          this.show();
        }, environment.HUBSPOT_SHOW_DELAY_SECONDS * 1000);
      }
    } else {
      this.hide();
    }
  }

  /**
   * 
   */
  load() {
    if (!environment.HUBSPOT_ENABLED || this.isLoaded) {
      return;
    }
    if (this.document.getElementById('hs-script-loader') === null) {
      let script = this.document.createElement('script');
      script.id = 'hs-script-loader';
      script.src = '//js.hs-scripts.com/'+environment.HUBSPOT_INTEGRATION_KEY+'.js';
      script.async = true;
      script.defer = true;
      this.document.getElementsByTagName('body')[0].appendChild(script);
      this.isLoaded = true;
    }
    return;
  }

  /**
   * 
   */
  show() {
    if (!environment.HUBSPOT_ENABLED) {
      return;
    }
    this.load();
    const element: HTMLElement = this.document.getElementById('hubspot-messages-iframe-container');
    if (element) {
      element.classList.remove('invisible');
      this.isVisible = true;
    }
  }

  /**
   * 
   */
  hide() {
    if (!environment.HUBSPOT_ENABLED) {
      return;
    }
    const element: HTMLElement = this.document.getElementById('hubspot-messages-iframe-container');
    if (element) {
      element.classList.add('invisible');
      this.isVisible = false;
    }
  }
}
