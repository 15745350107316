import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { of, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AppRoles } from '../roles.enum';
import * as fromRoot from '../reducers';
import { HasRole } from './has-role';

@Injectable()
export class HasOrganizationManagerRoleGuard extends HasRole implements CanActivate {
  constructor(store: Store<fromRoot.State>, private router: Router) {
    super(store);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.hasRole(AppRoles.ORGANIZATION_MANAGER).pipe(switchMap(canAccess => {
      if ( canAccess ) {
        return of(true);
      }

      this.router.navigate(['/']);
      return of(false);
    }));
  }
}
