import { Component } from '@angular/core';

@Component({
  selector: 'vs-layout',
  template: `
    <ng-content select="vs-sidenav"></ng-content>
    <div class="vsp-main-content">
      <ng-content></ng-content>
    </div>
    <div class="clearfix"></div>
  `
})
export class LayoutComponent { }
