import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { environment } from '@env/environment';

import * as Sentry from "@sentry/browser";

if ( environment.LOGGING && environment.LOGGING_SENTRY_URL ) {
    Sentry.init({
      dsn: environment.LOGGING_SENTRY_URL
    });
}

@Injectable()
export class GlobalErrorHandlerService implements ErrorHandler {
  dialogOpened: boolean;

  constructor() { }

  handleError(error): void {
    if ( environment.LOGGING ) {

      const eventId = Sentry.captureException(error.originalError || error);

      if (! environment.PRODUCTION ) {
        Sentry.showReportDialog({ eventId });
      }

    } else {
      console.error(error);
      throw error;
    }
  }
}
