import {Component, OnDestroy, OnInit, ViewEncapsulation} from "@angular/core";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {debounceTime, mergeMap, take, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";

import {CompareVenuesFacade} from "@core/facades/compare-venues.facade";
import {ArrangementProduct, ProductPriceUnit, Space, Venue} from "@core/models";
import {ArrangementService, SearchCriteriaService, SpaceService, VenueService} from "@core/services";
import {VenuePlaces} from "@core/models/venue";
import {ISearchCriteria} from "@core/services/search-criteria.service";

import * as moment from 'moment';
import { slugify } from '@core/util';

@Component({
    selector: 'vs-compare-venue-page',
    templateUrl: './compare-venue-page.html',
    styleUrls: ['./compare-venue-page.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CompareVenuePageComponent implements OnInit, OnDestroy {
  readonly DEFAULT_GUESTS = 20;
  readonly SPACES_NULL = -1;
  readonly SPACES_PRICE_PACKAGE = -2;

  venues: Venue[] = [];
  fgGuests: FormGroup;
  loading = false;

  prices = {
    spaceRow: [],
    arrangementRow: [],
    totalRow: []
  };
  amenities: {label: string, venues: boolean[]}[] = [];
  places: {label: string, venues: VenuePlaces[]}[] = [];

  /**
   * 
   */
  get guests(): FormControl {
    return this.fgGuests.get('guests') as FormControl;
  }

  private destroyed$ = new Subject();

  /**
   * 
   */
  constructor(
    private fb: FormBuilder,
    private compareVenuesFacade: CompareVenuesFacade,
    private spaceService: SpaceService,
    private arrangementService: ArrangementService,
    private searchCriteriaService: SearchCriteriaService,
    private router: Router) {}

  /**
   * 
   */
  ngOnInit() {
    this.fgGuests = this.fb.group({'guests': []});

    // Check if guests already filled in
    this.searchCriteriaService.searchCriteria$.pipe(take(1)).subscribe((searchCriteria: ISearchCriteria) => {
      if (searchCriteria.guests) {
        this.fgGuests.patchValue({guests: searchCriteria.guests});
      }
    });

    this.fgGuests.get('guests').valueChanges
      .pipe(debounceTime(600))
      .subscribe(guests => this.buildComparisonTable(this.venues, guests));

    this.compareVenuesFacade.venues$.pipe(takeUntil(this.destroyed$)).subscribe(venues => {
      this.venues = Array.from(venues.values());

      if (this.guests.value === null) {
        this.fgGuests.patchValue({guests: this.DEFAULT_GUESTS});
      } else {
        this.buildComparisonTable(this.venues, this.guests.value);
      }
    });
  }

  /**
   * 
   */
  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  /**
   * 
   */
  navigateToVenue($event, venue: Venue) {
    $event.preventDefault();
    this.router.navigate([VenueService.buildVenueLink({id: venue.id, name: venue.name})]);
  }

  /**
   * 
   */
  buildComparisonTable(venues: Venue[], guests: number) {
    if (venues && venues.length > 0) {
      this.buildPricesTable(venues, guests);
      this.buildAmenitiesTable(venues);
      this.buildPlacesTable(venues);
    }
  }

  /**
   * 
   */
  removeVenue(venue: Venue) {
    this.compareVenuesFacade.removeVenue(venue);
  }

  /**
   * 
   */
  private buildPricesTable(venues: Venue[], nrOfGuests: number) {
    // Reset prices rows
    this.prices.spaceRow = [null, null, null];
    this.prices.arrangementRow = [null, null, null];
    this.prices.totalRow = [null, null, null];

    let done = 0;
    this.loading = true;

    let meetingDate = {
      start: moment().add(1, 'weeks').isoWeekday(1).hour(9).minute(0).format("Y-MM-DDTHH:mm"),
      end: moment().add(1, 'weeks').isoWeekday(1).hour(17).minute(0).format("Y-MM-DDTHH:mm")
    };

    for (let i = 0; i < venues.length; i++) {
      this.spaceService.getSpacesQuote(venues[i].id, nrOfGuests, meetingDate).pipe(
        mergeMap((spaces: Space[]) => {

          let cheapestSpaceId = null;

          if (!spaces.length) {
            this.prices.spaceRow[i] = this.SPACES_NULL;
          } else {

            const spacesSorted = this.sortSpacesByPrice(spaces);
            if (spacesSorted.length > 0) {
              cheapestSpaceId = spacesSorted[0].id;
              this.prices.spaceRow[i] = spacesSorted[0].quote.base_price;
            } else {
              this.prices.spaceRow[i] = 0;
            }
          }

          return this.arrangementService.getVenueArrangements(
            venues[i].id,
            cheapestSpaceId,
            null,
            meetingDate
          );
        })
      ).subscribe((arrangements: ArrangementProduct[]) => {
        if (arrangements.length > 0 && this.prices.spaceRow[i] != -1) {
          const cheapestArrangement = arrangements[0];
          if (cheapestArrangement.pricing.unit === ProductPriceUnit.PER_UNIT) {
            this.prices.arrangementRow[i] = arrangements[0].pricing.excluded * nrOfGuests;
          } else {
            this.prices.arrangementRow[i] = arrangements[0].pricing.excluded;
          }

          // Override space price if the cheapest package includes the space
          if (cheapestArrangement.space_always_included) {
            this.prices.spaceRow[i] = this.SPACES_PRICE_PACKAGE; // -2 = Space price included with package
          }
        } else {
          this.prices.arrangementRow[i] = -1;
        }

        // Calculate totals
        const auxSpacePrice = this.prices.spaceRow[i] > 0 ? this.prices.spaceRow[i] : 0;
        const auxArrangementPrice = this.prices.arrangementRow[i] > 0 ? this.prices.arrangementRow[i] : 0;
        this.prices.totalRow[i] = auxSpacePrice + auxArrangementPrice;

        // Finish loading
        done++;
        if (done === venues.length) {
          this.loading = false;
        }
      });
    }
  }

  /**
   * 
   */
  private buildAmenitiesTable(venues: Venue[]) {
    this.amenities = [];
    venues.forEach((venue, venueIndex) => {
      venue.amenities.forEach((amenity) => {
        const amenityIndex = this.amenities.findIndex(v => v.label === amenity.label);

        if (amenityIndex !== -1) {
          this.amenities[amenityIndex].venues[venueIndex] = true;
        } else {
          const amenityEntry = {
            label: amenity.label,
            venues: [false,false,false]
          };
          amenityEntry.venues[venueIndex] = true;
          this.amenities.push(amenityEntry);
        }
      });
    });
  }

  /**
   * 
   */
  private buildPlacesTable(venues: Venue[]) {
    this.places = [];
    venues.forEach((venue, venueIndex) => {
      venue.places.forEach((place) => {
        const placeIndex = this.places.findIndex(v => v.label === place.type);

        if (placeIndex !== -1) {
          this.places[placeIndex].venues[venueIndex] = place;
        } else {
          const placeEntry = {
            label: place.type,
            venues: [null,null,null]
          };
          placeEntry.venues[venueIndex] = place;
          this.places.push(placeEntry);
        }
      });
    });
  }

  /**
   * 
   */
  private sortSpacesByPrice(spaces: Space[]) {

    if (spaces.length === 1) return spaces;

    return spaces.filter((venue) => {
          return venue.quote.base_price > 0;
      }).sort((a, b) => {
      const fromPriceA = a.quote.base_price;
      const fromPriceB = b.quote.base_price;

      if (fromPriceA < fromPriceB) {
        return -1;
      } else if (fromPriceA > fromPriceB) {
        return 1;
      }
      return 0;
    });
  }
  
  slugify(aux: string) {
    return slugify(aux).toUpperCase();
  }
}
