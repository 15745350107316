import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { withLatestFrom, switchMap, startWith } from 'rxjs/operators';

import * as fromBooking from './booking.actions';
import * as fromRoot from '@core/reducers/index';
import { INewBooking } from '../models/booking-form-model';

@Injectable()
export class BookingEffects {

  /**
   * When a draft is saved, updated or deleted. We want to sync all the drafts
   * with the session storage. We do this by listening to actions of the type
   * save or update and then getting the all the entities
   */
  @Effect() draftSave$: Observable<Action> = this.actions$
    .pipe (
      ofType(
        fromBooking.BookingActionTypes.CREATE_DRAFT,
        fromBooking.BookingActionTypes.UPDATE_DRAFT,
        fromBooking.BookingActionTypes.DELETE_DRAFT
      )
    )
    .pipe(
      withLatestFrom(this.store$.select(fromRoot.selectDraftEntities)),
      switchMap(([action, drafts]) => {
        let obj = {};
        (<INewBooking[]>drafts).forEach((value, index) => {
          obj[index] = value;
        });
        sessionStorage.setItem('vsuite/drafts', JSON.stringify(obj));
        return of({type: 'NO_OPT'});
      })
    );

  /**
   * On app load, we trigger the load drafts actions which checks the session storage
   * and inserts all the drafts in there.
   */
  @Effect() loadDrafts$: Observable<Action> = this.actions$
    .pipe (
      ofType(fromBooking.BookingActionTypes.LOAD_DRAFTS)
    )
    .pipe(
      startWith(new fromBooking.LoadDraftsAction()),
      switchMap(() => {
        if ( sessionStorage.getItem('vsuite/drafts') ) {
          const draftsFromStore: Object = JSON.parse(sessionStorage.getItem('vsuite/drafts'));
          let drafts: { [spaceId:number]: INewBooking } = [];
          Object.keys(draftsFromStore).forEach(key => {
            drafts[key] = draftsFromStore[key];
          });
          return of(new fromBooking.InsertManyAction(drafts));
        }

        return of({type: 'NO_OPT'});
      })
    )

  constructor(
    private actions$: Actions,
    private store$: Store<fromRoot.State>
  ) {}
}
