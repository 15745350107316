import * as fromUser from '../actions/user';

import { User } from '../models/user';
import { ISwitchableVenue } from '../services';

export interface State {
  isLoggedIn: boolean;
  hasLoginLessToken: boolean;
  loading: boolean;
  user: User | null;
  venues: ISwitchableVenue[];
  showAuthDialog: boolean;
  authDialogTab: 'login'|'signup';
  auth2FAToken?: string | null;
}

const initialState: State = {
  isLoggedIn:  false,
  hasLoginLessToken: false,
  loading: false,
  user: null,
  venues: [],
  showAuthDialog: false,
  authDialogTab: 'login',
  auth2FAToken: null
};

export function reducer(state = initialState, action: fromUser.Actions): State {
  switch (action.type) {
    case fromUser.ActionTypes.LOGIN: {

      return Object.assign({}, state, {
        loading: true
      });
    }
    case fromUser.ActionTypes.TOKEN_LOGIN: {

      return Object.assign({}, state, {
        loading: true
      });
    }
    case fromUser.ActionTypes.USER_VENUE_LOADED: {
      const user = state.user;
      user.venue = action.payload;

      return Object.assign({}, state, {
        user: user
      });
    }
    case fromUser.ActionTypes.USER_VENUES_LOADED: {
      return Object.assign({}, state, {
        venues: [...action.payload]
      });
    }
    case fromUser.ActionTypes.LOGIN_SUCCESS: {
      const user = action.payload;
      return {
        isLoggedIn: true,
        hasLoginLessToken: false,
        loading: false,
        user: user,
        venues: [],
        showAuthDialog: false,
        authDialogTab: 'login'
      };
    }
    case fromUser.ActionTypes.LOGIN_LESS_TOKEN_SUCCESS: {
      const user = action.payload;
      return {
        isLoggedIn: false,
        hasLoginLessToken: true,
        loading: false,
        user: user,
        venues: [],
        showAuthDialog: false,
        authDialogTab: 'login'
      };
    }
    case fromUser.ActionTypes.LOGIN_FAILED: {
      return Object.assign({}, state, {
        loading: false
      });
    }
    case fromUser.ActionTypes.LOGOUT_SUCCESS: {
      return Object.assign({}, state, {
        isLoggedIn: false,
        user: new User(null)
      });
    }
    case fromUser.ActionTypes.UPDATE_USER: {
      // const user = state.user.mergeWith((oldVal, newVal) => newVal == null ? oldVal : newVal, action.payload);
      const user = new User(Object.assign({}, state.user, action.payload));

      return Object.assign({}, state, {
        user: user
      });
    }
    case fromUser.ActionTypes.SHOW_AUTH_DIALOG: {
      return Object.assign({}, state, {
        showAuthDialog: true,
        authDialogTab: action.authType
      });
    }
    case fromUser.ActionTypes.LOGIN_REQUEST_2FA: {
      return Object.assign({}, state, {
        auth2FAToken: action.payload
      });
    }
    case fromUser.ActionTypes.HIDE_AUTH_DIALOG: {
      return Object.assign({}, state, {
        showAuthDialog: false
      });
    }
    default: {
      return state;
    }
  }
}

export const getLoading = (state: State) => state.loading;

export const getIsLoggedIn = (state: State) => state.isLoggedIn;

export const getHasLoginLessToken = (state: State) => state.hasLoginLessToken;

export const getUser = (state: State) => state.user;

export const getUserVenues = (state: State) => state.venues;

export const getShowAuthDialog = (state: State) => state.showAuthDialog;

export const getAuthDialogTab = (state: State) => state.authDialogTab;

export const getAuthRequest2FA = (state: State) => state.auth2FAToken;
