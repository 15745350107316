import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'vs-venue-host-vcard',
  templateUrl: './venue-host-vcard.html',
  styleUrls: ['./venue-host-vcard.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VenueHostVcardComponent {
  @Input() contactInfo: any;
  @Input() venueName;
  @Input() direction: 'row'|'column' = 'column';
  @Input() avatarTransform: string = '1:1'
  @Input() avatarWidth: string = '64'

  constructor() { }
}
