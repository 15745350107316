import { Component, Input, HostBinding, OnInit, ChangeDetectorRef, HostListener, ElementRef, ChangeDetectionStrategy, ViewChild, Output, EventEmitter, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

/** Event object that is emitted when an autocomplete option is selected. */
export class AutocompleteSelectedEvent {
  constructor(public option: AutocompleteResult) { }
}

export interface AutocompleteResult {
  key: string;
  value: string;
  res?: any;
}

enum KEYCODES {
  ARROW_DOWN = 40,
  ARROW_UP = 38,
  ENTER = 13,
  ESC = 27
};

@Component({
  selector: 'vs-autocomplete',
  templateUrl: './autocomplete.html',
  styleUrls: ['./autocomplete.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: AutoCompleteComponent,
    multi: true
  }],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoCompleteComponent implements OnInit, ControlValueAccessor, OnDestroy {
  @Input() @HostBinding('class') classList = '';
  @Input() placeholder = '';
  @Input() resultLimit = 5;
  @Input() isDisabled = false;
  @Input() searchOnFocus = false;
  @Input() filterFn: (q: string, limit?: number) => Observable<any>;
  @Output() readonly selectedItemChange: EventEmitter<AutocompleteSelectedEvent> = new EventEmitter();
  @ViewChild('input', {static: false}) inputElement: ElementRef;
  @ViewChild('panel', {static: false}) panelElement: ElementRef;
  @HostBinding('class.vs-autocomplete') defaultClass = true;
  searchSubject: Subject<string> = new Subject<string>();
  isFocused: boolean;
  inputValue: string = '';
  selectedItemIndex: number = -1;
  showResults = false;
  results: AutocompleteResult[] = [];
  initialSearchFlag = false;
  _onChange: (value: any) => void = () => {};
  _onTouched = () => {};
  _destroyed$: Subject<void> = new Subject();
  destroyed = false;

  constructor(
    private cref: ChangeDetectorRef,
    private elementRef: ElementRef) { }

  ngOnInit() {
    this.searchSubject.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      takeUntil(this._destroyed$),
      switchMap(searchQuery => this.filterFn(searchQuery, this.resultLimit)))
      .subscribe(results => {
        this.selectedItemIndex = -1;
        this.results = results;

        if ( ! this.destroyed ) {
          this.cref.detectChanges();
        }
      }, err => {
        this.results = [{key: '', value: 'Server unreachable... :('}];
      });
  }

  ngOnDestroy() {
    this.destroyed = true;
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  @HostListener('document:click', ['$event']) documentClick($event) {
    if ( this.panelElement && ! this.isFocused ) {
      if ( this.showResults && ! this.panelElement.nativeElement.contains(<Node>$event.target) ) {
        this.showResults = false;
      }
    }
  }

  inputValueChange($event) {
    this.showResults = true;
    this.inputValue = $event as string || '';
    this.inputValue = this.inputValue.trim();
    this._onChange(this.inputValue);
    this.searchSubject.next(this.inputValue);
  }

  handleKeydown($event: KeyboardEvent) {
    if ( $event.keyCode === KEYCODES.ARROW_UP && this.selectedItemIndex > 0 ) {
      this.selectedItemIndex--;
    } else if ( $event.keyCode === KEYCODES.ARROW_DOWN && this.selectedItemIndex < this.results.length - 1 ) {
      this.selectedItemIndex++;
    } else if ( $event.keyCode === KEYCODES.ENTER && this.inputValue && this.inputValue.length > 0 ) {
      this.selectResultItem(this.selectedItemIndex);
    } else if ( $event.keyCode === KEYCODES.ESC ) {
      this.showResults = false;
    }
  }

  selectResultItem(index?: number) {
    if ( index === -1 || ! this.results[index] ) {
      this.selectedItemChange.emit(new AutocompleteSelectedEvent({
        key: this.inputValue,
        value: this.inputValue
      }));
    } else {
      this.inputValueChange(this.results[index].value);
      this.selectedItemChange.emit(new AutocompleteSelectedEvent(this.results[index]));
    }

    this.showResults = false;
  }

  handleFocus($event) {
    if ( this.searchOnFocus && ! this.initialSearchFlag ) {
      this.searchSubject.next('');
      this.initialSearchFlag = true;
    }

    this.isFocused = true;
    this.showResults = true;
  }

  handleBlur($event) {
    this.isFocused = false;
  }

  writeValue(value: any) {
    if ( value ) {
      this.initialSearchFlag = true;
      this.inputValue = value;
    } else {
      this.searchSubject.next('');
      this.inputValue = '';
    }

    this.cref.detectChanges();
  }

  registerOnChange(fn: any) {
    this._onChange = fn;
  }

  registerOnTouched(fn: any) {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
}
