import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChartistModule } from 'ng-chartist';
import { FileUploadModule } from 'ng2-file-upload';
import { SortablejsModule } from 'angular-sortablejs';
import { NgPipesModule } from 'ngx-pipes';
import { AgmCoreModule } from '@agm/core';
import { MdlModule } from '@angular-mdl/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { SHARED_DECLARATIONS } from '@shared/shared.declarations';
import { DatetimePickerComponent, DatetimeAvailabilityPickerComponent, VideoDialogComponent } from './components';

import { environment } from '@env/environment';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.TRANSLATION_FILES);
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ChartistModule,
    ReactiveFormsModule,
    NgPipesModule,
    MdlModule,
    RouterModule,
    FileUploadModule,
    SortablejsModule,
    HttpClientModule,
    TranslateModule.forChild({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        },
        isolate: false
    }),
    AgmCoreModule,
    NgSelectModule
  ],
  declarations: [
    SHARED_DECLARATIONS
  ],
  entryComponents: [
    DatetimePickerComponent,
    DatetimeAvailabilityPickerComponent,
    VideoDialogComponent
  ],
  exports: [
    SHARED_DECLARATIONS,
    NgPipesModule,
    CommonModule,
    FormsModule,
    ChartistModule,
    ReactiveFormsModule,
    MdlModule,
    TranslateModule,
    AgmCoreModule,
    NgSelectModule
  ]
})
export class SharedModule { }
