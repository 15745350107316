import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { VenueService } from '@core/services';

@Component({
  selector: 'vs-signup-venue',
  templateUrl: './signup-venue.html',
  styleUrls: ['./signup-venue.scss']
})
export class SignUpVenueComponent implements OnInit {
  signUpForm: FormGroup;
  submitAttempt = false; 
  isSubmitting = false;
  signUpComplete = false;

  signUpFailed = false;
  signUpError = '';

  /**
   * 
   */
  constructor(
    private formBuilder: FormBuilder,
    private venueService: VenueService) { }

  /**
   * 
   */
  ngOnInit() {
    this.signUpForm = this._createForm();
  }

  /**
   * 
   */
  signUp($event) {
    $event.stopPropagation();

    this.submitAttempt = true;
    this.signUpFailed = false;
    this.signUpComplete = false;

    if (this.signUpForm.valid) {

      const signUpData = this.signUpForm.value;

      this.isSubmitting = true;

      this.venueService.signUp(signUpData).subscribe(result => {
        this.isSubmitting = false;
        this.signUpComplete = true;
      }, error => {
        this.isSubmitting = false;
        this.signUpFailed = true;
        this.signUpError = error.error.message || 'An unknown error occured. Please try again!';
      });
    }
  }

  /**
   * 
   */
  formControlIsInvalid(identifier): boolean {
    return this.submitAttempt && !this.signUpForm.get(identifier).valid;
  }

  /**
   * 
   */
  private _createForm(): FormGroup {
    return this.formBuilder.group({
      fullname: ['', Validators.required],
      username: ['', [Validators.required, Validators.email]],
      venue: ['', Validators.required]
    });
  }
}
