import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bookingStatus'
})
export class BookingStatusPipe implements PipeTransform {
  statusMap: {[label: string]: string};

  constructor() {
    this.statusMap = {};
    this.statusMap['request'] = 'Request';
    this.statusMap['reservation'] = 'Optional';
    this.statusMap['booked'] = 'Confirmed';
    this.statusMap['done'] = 'Done';
    this.statusMap['cancelled'] = 'Cancelled';
  }

  transform(value: string): string {
    if ( ! this.statusMap[value] ) {
      return value;
    }

    return this.statusMap[value];
  }
}
