import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Venue } from '../models/venue';
import { environment } from '@env/environment';
import { SessionService } from './session.service';
import { VenueService } from './venue.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class UserVenueService extends VenueService {
  venueId: number;

  constructor(http: HttpClient, private sessionService: SessionService) {
    super(http);

    // Get the venue id from the currently authenticated user
    this.sessionService.user$.subscribe(user => {
      this.venueId = (user !== null ? user.venue_id : null);
    });
  }

  /**
   * 
   */
  getUserVenue(): Observable<Venue> {
    if ( ! this.venueId ) {
      throw new Error('Attempting to get venue but no venue id set');
    }

    return this.getVenue(this.venueId);
  }

  /**
   * 
   */
  getVenue(venueId: number): Observable<Venue> {
    return this.http.get<Venue>(`${environment.API_URL}/venues/${venueId}/admin`);
  }

  /**
   * 
   */
  updateVenue(venue: Venue): Observable<any> {
    if ( ! this.venueId ) {
      throw new Error('Attempting to update venue info but no venue id set');
    }

    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(venue);

    return this.http.put(`${environment.API_URL}/venues/${this.venueId}/admin`,  body, requestOptions);
  }

  /**
   * 
   */
  setVenueId(val: number) {
    this.venueId = val;
  }
}
