import * as moment from 'moment';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'dateTransform'})
export class DateTransformPipe implements PipeTransform {

  transform(
    value: string,
    outputFormat: string,
    format: string = 'YYYY-MM-DD HH:mm:ss+-HHmm'): string {
    if ( typeof(value) === undefined || value == null ) return 'INVALID DATE';
    const momentObject = moment(value);

    return momentObject.format(outputFormat) as string;
  }
}
