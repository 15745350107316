import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { IMessage } from '../models';
import { HttpClient, HttpParams } from '@angular/common/http';


@Injectable()
export class MessageService {

  http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  /**
   * 
   */
  fetch(bookingId: number, limit?: number, after?: number, before?: number): Observable<IMessage[]> {
    const url = `${environment.API_URL}/bookings/${bookingId}/messages`;

    let urlSearchParams = new HttpParams();;

    if ( before ) {
      urlSearchParams = urlSearchParams.set('before', before.toString());
    }

    if ( after ) {
      urlSearchParams = urlSearchParams.set('after', after.toString());
    }

    if ( limit ) {
      urlSearchParams = urlSearchParams.set('limit', limit.toString());
    }

    const options = {
      params: urlSearchParams
    };

    return this.http.get<IMessage[]>(url, options);
  }

  /**
   * 
   */
  send(bookingId: number, message: string) {
    const requestUrl = `${environment.API_URL}/bookings/${bookingId}/messages`;
    const requestBody = JSON.stringify({
      content: message
    });

    return this.http.post(requestUrl, requestBody);
  }

  /**
   * 
   */
  markAsRead(messageId: number) {
    const requestUrl = `${environment.API_URL}/messages/read/${messageId}`;
    const requestBody = JSON.stringify({
      read: true
    });

    return this.http.post(requestUrl, requestBody);
  }
}
