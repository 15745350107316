import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env/environment';

@Pipe({
  name: 'priceSelector'
})
export class PriceSelectorPipe implements PipeTransform {
  transform(pricing: Object) {

    const priceToShow = environment.hasOwnProperty('PRICE_TO_SHOW') ? environment['PRICE_TO_SHOW'] : 'net';

    if (priceToShow == 'gross') {
      if (pricing.hasOwnProperty('total_price')) {
        return pricing['total_price'];
      }
      if (pricing.hasOwnProperty('included')) {
        return pricing['included'];
      }
    }
    if (priceToShow == 'net') {
      if (pricing.hasOwnProperty('price')) {
        return pricing['price'];
      }
      if (pricing.hasOwnProperty('excluded')) {
        return pricing['excluded'];
      }
    }
    return 0;
  }
}
