import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import * as fromRoot from '../reducers';
import * as userActions from '../actions/user';

/**
 * Make sure the user is logged out before entering the route
 */
@Injectable()
export class LogoutGuard implements CanActivate {
  constructor(private store: Store<fromRoot.State>) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.store.dispatch(new userActions.LogoutAction(false));
    return of(true);
  }
}
