import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

import { environment } from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class OrganizationService {
  constructor(
    private http: HttpClient,
    private transService: TranslateService) { }

  /**
   *
   */
  findById(id: number): Observable<any> {
    return this.http.get(environment.API_URL+'/organizations/'+id);
  }

  /**
   * Create a new organization
   *
   * @param account
   */
  register(account, options: object = null): Observable<any> {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');

    let reqBody = {...account, ...options};

    reqBody['lang'] = this.transService.currentLang || this.transService.defaultLang;

    const body = JSON.stringify(reqBody);

    return this.http.post(`${environment.API_URL}/organizations`, body, requestOptions);
  }

  /**
   *
   */
  update(id: number, details: any): Observable<any> {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(details);
    return this.http.put(environment.API_URL+'/organizations/'+id, body, requestOptions);
  }

  /**
   *
   */
  getExistingCoupon(venueId: number): Observable<any> {
    return this.http.get(`${environment.API_URL}/coupon/${venueId}`);
  }

  /**
   *
   */
  deleteExistingCoupon(venueId: number): Observable<any> {
    return this.http.delete(`${environment.API_URL}/coupon/${venueId}`);
  }

}
