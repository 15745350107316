import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';

@Pipe({
  name: 'priceTypeSelector'
})
export class PriceTypeSelectorPipe implements PipeTransform {
    constructor(    
    private translateService: TranslateService
  ) {}
  
  transform(fromPrice: Object) : string {

    const priceToShow = environment.hasOwnProperty('PRICE_TO_SHOW') ? environment['PRICE_TO_SHOW'] : 'net';
    const fromPriceType = fromPrice.hasOwnProperty('type') ? fromPrice['type'] : 'daypart';

    const priceToShowTr = this.translateService.instant(priceToShow == 'gross' ? 'COMMON.INCL_TAX' : 'COMMON.EX_TAX');
    const fromPriceTypeTr = this.translateService.instant('COMMON.PRICES_FROM.'+fromPriceType );

    return fromPriceTypeTr + " " + priceToShowTr.toLowerCase();
  }
}
