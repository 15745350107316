import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'vs-forgot-password',
  templateUrl: './forgot-password.html',
  styleUrls: ['./forgot-password.scss']
})
export class ForgotPasswordComponent {
  @Input() loading = false;
  @Output() onSend: EventEmitter<string> = new EventEmitter<string>();
  form: FormGroup;
  email: FormControl = new FormControl('', [
    Validators.required,
    Validators.email
  ]);
  submitAttempt = false;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      'email': this.email
    });
  }

  submitForm(): void {
    this.submitAttempt = true;

    if ( this.form.valid ) {
      this.onSend.emit(this.form.get('email').value);
    }
  }
}
