import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

declare let ga: Function;
declare let gtag: Function;

@Injectable()
export class EventTrackerService {

  /**
   * 
   */
  constructor() {}

  /**
   * 
   */
  public trackEvent(
    category: string,
    action: string,
    label: string = null,
    value: number = null) {

    this.analityc('send','event', 
      {
        'eventCategory': category,
        'eventAction': action,
        'eventLabel': label,
        'eventValue': value
      }
    );
  }

  /**
   * 
   */
  public trackPageView(url: string) {
    this.analityc('send','pageview', url);
  }

  /**
   * 
   */
  private analityc(func: string, action: string, data: any) {
    if (!environment.ENABLE_GA) {
      return;
    }

    if (window.hasOwnProperty('ga') && typeof window['ga'] === 'function') {
      ga(func, action, data);
    }
    else if (window.hasOwnProperty('gtag') && typeof window['gtag'] === 'function') {
      if (action === 'pageview') {
        gtag('set', 'page_path', data);
        gtag('event', 'page_view');
      }
      else if (action === 'event') {
        gtag('event', data.eventCategory);
      }
    }
    else if (window.hasOwnProperty('dataLayer') && typeof window['dataLayer'] === 'object') {
      if (action === 'pageview') {
        window['dataLayer'].push(['set', 'page_path', data]);
        window['dataLayer'].push(['event', 'page_view']);
      }
      else if (action === 'event') {
        window['dataLayer'].push(['event', data.eventCategory]);
      }
    }

    if (environment.HUBSPOT_ENABLED) {
      if (window.hasOwnProperty('_hsq') && typeof window['_hsq'] === 'object') {
        if (action === 'pageview') {
          window['_hsq'].push(['setPath', data]);
          window['_hsq'].push(['trackPageView']);
        }
        else if (action === 'event') {
          // window['_hsq'].push(['trackCustomBehavioralEvent', {name: data.eventCategory, properties: {action: data.eventAction}}]);
        }
      }
    }
  }
}
