import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AppRoles } from '../roles.enum';
import * as fromRoot from '../reducers';
import * as userActions from '../actions/user';
import { HasRole } from './has-role';

@Injectable()
export class HasVenueManagerRoleGuard extends HasRole implements CanActivate {
  constructor(store: Store<fromRoot.State>, private router: Router) {
    super(store);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.hasRole(AppRoles.VENUE_MANAGER).pipe(switchMap(canAccess => {
      if ( canAccess ) {
        return of(true);
      }

      this.store.dispatch(new userActions.ShowAuthDialogAction('login'));
      this.router.navigate(['/'], { queryParams: {redirectUrl: state.url}});

      return of(false);
    }));
  }
}
