<div #filler class="nav-scroll-filler"></div>
<section class="main-cont">
  <nav
  #navElement
  class="marketing-nav-primary"
  [class.marketing-nav-primary--transparent]="marketingTransparentNav">

  <div class="mdl-grid mdl-grid--no-spacing">
    <div class="mdl-cell--no-v-spacing mdl-cell--12-col">
      <div #navPrimaryElement class="marketing-nav-primary__inner">

        <div class="nav-title">
          <a class="marketing-nav-link--logo" aria-label="Home" routerLink="/">
            <img class="marketing-nav-link--logo" [src]="mobileLogo" alt="logo" class="mobile"/>
            <img [src]="deskTopLogo" alt="logo" class="desktop"/>
            <img [src]="transparentLogo " alt="logo" class="transparent"/>
          </a>
        </div>

        <div class="marketing-nav-primary__mobile" (click)="toggleMobileMenu($event)">
          <img src="../../../../assets/images/marketing/menu_icon.svg" *ngIf="!showMobileMenu" class="material-icons" />
          <img src="../../../../assets/images/marketing/close_icon.svg" *ngIf="showMobileMenu" class="material-icons" />
        </div>

        <div
          class="marketing-nav-links"
          [class.marketing-nav-links--show]="showMobileMenu">
    
          <ng-container *ngIf="isAuthenticated">
            <div class="layout-spacer"></div>
            <a class="marketing-nav-link"
              routerLinkActive="marketing-nav-link--active"
              *ngFor="let navItem of navItems"
              [routerLink]="navItem.link">{{ navItem.label | translate }}</a>
            <vs-venue-switcher></vs-venue-switcher>
            <a class="marketing-nav-link marketing-nav-link--profile" #btnProfile (click)="vsDropdown.toggle($event, btnProfile)">
              <img alt="Profile picture" [src]="userAvatar | imageTransform:45:45:false:true" />
              <div class="greeting"><b>{{ userName }}</b></div>
              <i class="material-icons">arrow_drop_down</i>
            </a>
            <vs-dropdown #vsDropdown>
              <a class="dropdown-item has-icon" routerLink="/user">
                <i class="icon icon-account"></i>
                <div>{{ 'NAV.PROFILE_TITLE' | translate }}<span>{{ 'NAV.PROFILE_DESC' | translate }}</span></div>
              </a>
              <a *ngIf="isOrganizationManager" class="dropdown-item has-icon" routerLink="/company">
                <i class="icon icon-venue"></i>
                <div>{{ 'NAV.COMPANY_TITLE' | translate }}<span>{{ 'NAV.COMPANY_DESC' | translate }}</span></div>
              </a>
              <a class="dropdown-item has-icon dropdown-item--divider" (click)="signOut.emit()">
                <i class="icon icon-close-circle"></i>
                <div>{{ 'NAV.SIGN_OUT' | translate }}</div>
              </a>
            </vs-dropdown>
          </ng-container>
    
          <ng-container *ngIf="!isAuthenticated">
            <a class="marketing-nav-link" id="phone" href="tel:030-6026940">
              <img src="../../../../assets/images/marketing/phone_icon.svg"/>+31 30 602 6940
            </a>
            <span style="border-right: 1px solid #D7D7D7;margin: 0 12px;" class="desktop">
              &nbsp;
            </span>
              <a class="marketing-nav-link" role="button" id="login-link" tabindex="0" (click)="showAuthDialog($event, 'login')" (keydown.enter)="showAuthDialog($event, 'login')">
                {{ 'NAV.LOG_IN' | translate }}
              </a>
            <span style="padding: 0 6px;" class="desktop">
              &nbsp;
	          </span>
            <a *ngIf="latestUrl !== '/'" href="https://share.hsforms.com/1W5kQreImTL-nwOt8u8mr5w2g8h5" rel="noreferrer" target="_blank" class="mdl-button--xl btn-schedule-demo" mdl-button mdl-button-type="raised" mdl-colored="primary">
              {{ 'NAV.DEMO' | translate }}
            </a>
            <a class="marketing-nav-link--button" *ngIf="latestUrl !== '/venues' && latestUrl !== '/fast-booking' && latestUrl !== '/whitelabel'" routerLink="/venues" routerLinkActive="marketing-nav-link--active">
             {{ 'NAV.LIST_VENUE' | translate }}
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</nav>
</section>


