import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Space } from '../models';
import { environment } from '@env/environment';
import { SessionService } from './session.service';
import { SpaceService } from './space.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

/**
 * UserSpaceService automatically uses the venue id
 * of the current user for interacting with the space
 * end point
 */
@Injectable()
export class UserSpaceService extends SpaceService {
  venueId: number;

  constructor(http: HttpClient, private sessionService: SessionService) {
    super(http);

    this.sessionService.user$.subscribe(user => {
      this.venueId = (user !== null ? user.venue_id : null);
    });
  }

  setVenueId(val: number) {
    this.venueId = val;
  }

  /**
   * Get the spaces of the currently authenticated user
   */
  getUserVenueSpaces(): Observable<Space[]> {
    return this.http.get<Space[]>(`${environment.API_URL}/venues/${this.venueId}/admin/spaces`);
  }

  /**
   * Get a specific space of the currently authenticated user
   */
  getUserVenueSpace(spaceId: number): Observable<Space> {
    return this.http.get<Space>(`${environment.API_URL}/venues/${this.venueId}/admin/spaces/${spaceId}`);
  }

  /**
   * Create a new space for the venue of the
   * authenticated user
   */
  createSpace(space: Space) {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(space);

    return this.http.post(`${environment.API_URL}/venues/${this.venueId}/admin/spaces`,  body, requestOptions);
  }

  /**
   * Update space
   */
  updateSpace(space: Space) {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(space);

    return this.http.put(`${environment.API_URL}/venues/${this.venueId}/admin/spaces/${space.id}`,  body, requestOptions);
  }

  /**
   * Delete space
   */
  deleteSpace(spaceId: number) {
    return this.http.delete(`${environment.API_URL}/venues/${this.venueId}/admin/spaces/${spaceId}`);
  }

  /**
   *
   */
  updateSpacesOrder(spaces: Array<any>) {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(spaces);

    return this.http
      .put(
        `${environment.API_URL}/venues/${this.venueId}/admin/spaces/order`,
        body,
        requestOptions);
  }
}
