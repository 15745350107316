import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class AvailabilityService {

  http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  /**
   * Get the spaces with a quote from a venue.
   */
  getSpaceAvailability(
    spaceId: number,
    date: string
  ): Observable<any[]> {

    let params = new HttpParams();;

    params = params.append("date", date);
    params = params.append("view", "month");

    const options = {
      params: params
    }

    return this.http.get<any[]>(`${environment.API_URL}/spaces/${spaceId}/availability/calendar`, options);
  }

  /**
   * Get the spaces with a quote from a venue.
   */
  getVenueAvailability(
    venueId: number,
    date: string
  ): Observable<any[]> {

    let params = new HttpParams();;

    params = params.append("date", date);
    params = params.append("view", "month");

    const options = {
      params: params
    }

    return this.http.get<any[]>(`${environment.API_URL}/venue/${venueId}/availability/calendar`, options);
  }
}
