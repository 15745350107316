import { Component, Input, Output, EventEmitter, OnChanges, ChangeDetectionStrategy, ViewEncapsulation, SimpleChanges } from '@angular/core';
import {SortablejsOptions} from "angular-sortablejs";

export interface IDataTableItem {
  id: number;
  selected: boolean;
  classList?: string; // Add optional class to a row
  [propName: string]: any; // Supports {label:any, value:any} if the sorting value differs from the display value
}

export interface IDataTableColumn {
  key: string;
  name: string;
  sortable?: boolean;
  numeric?: boolean;
}

export interface IDataTableModel {
  columns: IDataTableColumn[];
  data: IDataTableItem[];
}

export class DefaultDataTableModel implements IDataTableModel {
  columns: IDataTableColumn[];
  data: IDataTableItem[] = [];

  constructor(columns: IDataTableColumn[]) {
    this.columns = columns;
  }

  addAll(data: IDataTableItem[]) {
    this.data.push(...data);
  }
}

@Component({
  selector: 'vs-data-table',
  templateUrl: './data-table.html',
  styleUrls: ['./data-table.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class DataTableComponent implements OnChanges {
  @Input() itemsPerPage: number = 0;
  @Input() model: IDataTableModel;
  @Input() selectable: boolean;
  @Input() sortable = true;
  @Input() draggable = false;
  @Input() hidePagination = false;
  @Input() selected: IDataTableItem[];
  @Input() classList: string = '';
  @Output() readonly selectedChange = new EventEmitter<IDataTableItem[]>();
  @Input() activeItem: IDataTableItem;

  @Output() readonly activeItemChange = new EventEmitter<IDataTableItem>();
  @Output() readonly altActiveItemChange = new EventEmitter<IDataTableItem>();
  @Output() readonly dragChange = new EventEmitter<{data: IDataTableItem[], selected: IDataTableItem}>();

  filteredData: IDataTableItem[];
  sortCol: string;
  sortDir: 'asc'|'desc'|null;
  currentPage = 1;
  lastPage = 1;
  paginateEnd: number;
  paginateStart: number;
  options: SortablejsOptions = {
    handle : '.draggable-item',
    animation: 150,
    ghostClass: 'draggable-ghost-item',
    disabled : !this.draggable,
    onUpdate: (event: any) => {
      this.dragChange.emit({data: this.filteredData, selected: this.activeItem});
    }
  };

  /**
   * 
   */
  ngOnChanges(changes: SimpleChanges) {
    if ( changes && (changes.model || changes.itemsPerPage) ) {
      this.currentPage = 1;
      this.lastPage = 1;
      this.sortAndFilterItems();
    }

    this.options.disabled = !this.draggable;
  }

  /**
   * 
   */
  hasData() {
    return this.model ? this.model.data.length > 0 : false;
  }

  /**
   * 
   */
  selectionChange($event) {
    this.updateSelected();
  }

  /**
   * 
   */
  isAllSelected() {
    return this.model.data.every(data => data.selected);
  }

  /**
   * 
   */
  toggleAll() {
    const selected = !this.isAllSelected();
    this.model.data.forEach(data => data.selected = selected);
    this.updateSelected();
  }

  /**
   * 
   */
  updateSelected() {
    this.selected = this.model.data.filter(data => data.selected);
    this.selectedChange.emit(this.selected);
  }

  /**
   * 
   */
  toggleActiveItem(item: IDataTableItem, $event: MouseEvent) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    if (this.activeItem && this.activeItem.id === item.id) {
      this.activeItem = null;
    } else {
      this.activeItem = item;
    }

    if ($event.altKey === true) {
        this.altActiveItemChange.emit(this.activeItem);
    } else {
        this.activeItemChange.emit(this.activeItem);
    }
  }

  /**
   * 
   */
  nextPage($event) {
    this.currentPage++;
    this.sortAndFilterItems();
  }

  /**
   * 
   */
  prevPage($event) {
    this.currentPage--;
    this.sortAndFilterItems();
  }

  /**
   * 
   */
  setSortColumn(item: IDataTableColumn) {
    if ( ! item.sortable ) {
      return;
    }

    if ( item.key === this.sortCol ) {
      this.sortDir = (this.sortDir === 'asc') ? 'desc' : null;

      // Go back to default sorting after traversing through the possible sorting directions
      if ( this.sortDir === null ) {
        this.sortCol = 'id';
        this.sortDir = 'asc';
      }
    } else {
      this.sortCol = item.key;
      this.sortDir = 'asc';
    }

    this.sortAndFilterItems();
  }

  /**
   * 
   */
  sortAndFilterItems() {
    if ( this.model && this.model.hasOwnProperty('data') ) {
      this.filteredData = this.applyPagination(this.model.data, this.applySorting(this.model.data, this.sortCol, this.sortDir));
    }
  }

  /**
   * 
   */
  private applySorting(items: IDataTableItem[], sortCol: string, sortDir: 'asc'|'desc'|null): IDataTableItem[] {
    if ( sortCol && sortDir ) {
      return items.sort((a, b) => {
        const vA = a[sortCol].hasOwnProperty('value') ? a[sortCol].value : a[sortCol];
        const vB = b[sortCol].hasOwnProperty('value') ? b[sortCol].value : b[sortCol];

        if ( sortDir === 'asc' ) {
          return ((vA < vB)) ? -1 : ((vA > vB) ? 1 : 0);
        } else if ( sortDir === 'desc' ) {
          return ((vA > vB)) ? -1 : ((vA < vB) ? 1 : 0);
        }
      });
    } else {
      return items;
    }
  }

  /**
   * 
   */
  private applyPagination(allItems, items: IDataTableItem[]): IDataTableItem[] {
    if ( this.itemsPerPage > 0 && items.length > this.itemsPerPage ) {
      this.paginateStart = (this.currentPage - 1) * this.itemsPerPage;
      this.paginateEnd = this.currentPage * this.itemsPerPage;

      if ( this.paginateEnd >= this.model.data.length ) {
        this.paginateEnd = this.model.data.length;
      }

      this.lastPage = Math.ceil(items.length / this.itemsPerPage);
      return items.slice(this.paginateStart, this.paginateEnd);
    } else {
      this.paginateStart = 0;
      this.paginateEnd = items.length;
    }

    return items;
  }
}
