import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {NavService} from '../services';

@Injectable()
export class TransparentNavGuard implements CanActivate {
  constructor(private navService: NavService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.navService.registerRoute(state.url);
    return true;
  }
}
