import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'metersToKm'
})
export class MetersToKmPipe implements PipeTransform {
  transform(value: any, ...args): any {
    value = !value ? 0 : value;

    if ( value < 1000) {
      return value + ' m';
    }

    return Math.floor(value / 1000) + ' km';
  }
}
