import { Observable, of } from 'rxjs';
import { map, take, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import * as fromRoot from '../reducers';

export abstract class HasRole {

  constructor(public store: Store<fromRoot.State>) { }

  /**
   * Check if the given role is present at the logged in user
   */
  hasRole(roleName: string): Observable<boolean> {
    return this.store.select(fromRoot.getUser)
      .pipe(
        map(user => user && user.role === roleName),
        take(1),
        switchMap(hasRole => hasRole ? of(true) : of(false))
      );
  }
}
