import {Component, Input, Output, EventEmitter, ViewChild, ElementRef} from '@angular/core';
import { trigger, style, transition, animate, keyframes, state } from '@angular/animations';

@Component({
  selector: 'vs-side-panel',
  templateUrl: './side-panel.html',
  styleUrls: ['./side-panel.scss'],
  animations: [
    trigger('slideOutAndIn', [
      transition('inactive => active', [
        animate(300, keyframes([
          style({transform: 'translateX(0)', offset: 0}),
          style({transform: 'translateX(99%)', offset: 0.5}),
          style({transform: 'translateX(0)', offset: 1.0})
        ]))
      ]),
    ]),
    trigger('sidePanelState', [
      state('hidden', style({
        transform: 'translateX(100%)'
      })),
      state('visible',   style({
        transform: 'translateX(0%)'
      })),
      transition('hidden => visible', animate('300ms ease-in')),
      transition('visible => hidden', animate('300ms ease-out'))
    ])
  ]
})
export class SidePanelComponent {
  @ViewChild('sidePanelTop', {static: false}) sidePanelTop: ElementRef;
  @Input()
  set visible(value: boolean) {
    this.sidePanelState = (value) ? 'visible' : 'hidden';
  }

  get visible() {
    return this.sidePanelState === 'visible';
  }

  @Input() startHidden = false;

  @Output() onSlideInComplete: EventEmitter<boolean> = new EventEmitter();
  isFadedOut: boolean;
  slideOutAndInState = 'inactive';
  sidePanelState = 'hidden';

  toggle() {
    this.visible = !this.visible;
  }

  fadeIn() {
    this.isFadedOut = false;
  }

  fadeOut() {
    this.isFadedOut = true;
  }

  slideIn() {
    this.startHidden = false;

    // Activate slide out and back in animation when the panel
    // is already visible and the slideIn() method is called
    if ( this.sidePanelState === 'visible' ) {
      this.slideOutAndInState = 'active';
    }

    this.sidePanelState = 'visible';
    this.sidePanelTop.nativeElement.scrollIntoView({ block: 'start' });
  }

  slideOut() {
    this.sidePanelState = 'hidden';
  }

  sideInAnimationDone($event: AnimationEvent) {
    if ( this.sidePanelState === 'visible' ) {
      this.onSlideInComplete.emit(true);
    }
  }

  slideOutAndInAnimationDone($event: AnimationEvent) {
    this.slideOutAndInState = 'inactive';
    this.onSlideInComplete.emit(true);
  }
}
