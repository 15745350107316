import {Component, Input} from '@angular/core';

@Component({
  selector: 'vs-faq',
  templateUrl: './faq.html',
  styleUrls: ['./faq.scss']
})
export class FaqComponent {
  @Input() translateKey: string;
  isCollapsed = true;

  toggleFaq($event) {
    this.isCollapsed = !this.isCollapsed;
  }
}
