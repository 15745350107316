import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'vs-add-venue-page-component',
  templateUrl: './add-venue-page.html',
  styleUrls: ['./add-venue-page.scss']
})
export class AddVenuePageComponent implements OnInit {
  dialogConfig = {
    classes: 'mdl-dialog_no-padding mdl-dialog_video',
    clickOutsideToClose: true,
    styles: {
      width: '80%',
      height: 'auto'
    },
    isModal: true,
    animate: false,
    enterTransitionDuration: 0,
    leaveTransitionDuration: 0
  };
  headerImages = [
    'https://res.cloudinary.com/venuesuite-com/image/upload/v1603195162/homepage/speaker-conference.jpg'
  ];

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private translate: TranslateService) { }

  ngOnInit() {
    this.setupSEO();
  }

  public setupSEO() {
    this.translate.get(['PAGES.ADD_VENUE.TITLE', 'PAGES.ADD_VENUE.META'])
      .subscribe(translations => {
        this.titleService.setTitle(translations['PAGES.ADD_VENUE.TITLE']);
        this.metaService.updateTag(
          {name: 'description', content: translations['PAGES.ADD_VENUE.META']}
        );
      });
  }

  /**
   *
   */
  navigateToTarget($element: any) {
    $element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
  }
}
