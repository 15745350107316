<div class="err-dialog">
  <div class="err-dialog__header">
    <i class="material-icons">sentiment_dissatisfied</i>
    <h4>Something Went Wrong</h4>
    <i class="material-icons close-icon" (click)="close()">close</i>
  </div>
  <div class="err-dialog__body">
    <p>It looks like you've hit an issue in our client application. Don't worry though! An automatically generated message has been send to our specialists and it's likely we're already looking into this!</p>
    <p>If you're daring, you may want to try the following:</p>
    <ul>
      <li>We detected something AdBlock-like. Try disabling it, as it's known to cause issues.</li>
      <li>Give it a few seconds and reload the page.</li>
    </ul>
    <p>If this problem happens again. Please contact our specialists, providing the information bellow.</p>
    <ul>
      <li>Error ID: {{ hash }}</li>
      <li>Error URL: {{ location }} </li>
    </ul>
    <div class="error-stack-scroll">
      <div class="error-stack">{{ stack }}</div>
    </div>
  </div>
  <div class="err-dialog__actions">
    <button (click)="close()">I have read it. Close it.</button>
  </div>
</div>
