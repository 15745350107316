import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { Space } from './../models';
import { VenueAvailability } from '../../main/containers/venue-detail-page/venue-detail-page';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as moment from 'moment';

@Injectable()
export class SpaceService {

  http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  /**
   * Get the spaces with a quote from a venue.
   */
  getSpacesQuote(
    venueId: number,
    guests: number,
    period?: {start: string, end: string}
  ): Observable<Space[]> {

    const urlSearchParams = new HttpParams();

    if ( guests ) {
      urlSearchParams.set('guests', `${guests}`);
    }

    if ( period && period.start && period.end ) {
      urlSearchParams.set('period[start]', moment(period.start).format("YYYY-MM-DD HH:mm"));
      urlSearchParams.set('period[end]', moment(period.end).format("YYYY-MM-DD HH:mm"));
    }

    const options = {
      params: urlSearchParams
    };

    return this.http.get<Space[]>(`${environment.API_URL}/venues/${venueId}/spaces/quote`, options);
  }

  /**
   * 
   */
  private objToSearchParams(params: any): HttpParams {
    let httpParams: HttpParams = new HttpParams();

    if ( params ) {
      Object.keys(params).forEach(param => {
        if (params[param]) {
          if (params[param] instanceof Array) {
            params[param].forEach(value => {
              httpParams = httpParams.append(param, value);
            });
          } else {
            if (param === 'period' && param) {
              httpParams = httpParams.append('period[start]', moment(params[param].start).format("YYYY-MM-DD HH:mm"));
              httpParams = httpParams.append('period[end]', moment(params[param].end).format("YYYY-MM-DD HH:mm"));
            } else {
              httpParams = httpParams.append(param, params[param]);
            }
          }
        }
      });  
    }

    return httpParams;
  }

  /**
   * Get the spaces of a venue.
   */
  getSpaces(
    venueId: number,
    searchData?: VenueAvailability,
    shareKey?: string): Observable<Space[]> {

    const urlSearchParams = this.objToSearchParams(searchData);

    if ( shareKey ) {
      urlSearchParams.set('share', shareKey);
    }

    const options = {
      params: urlSearchParams
    };
    return this.http.get<Space[]>(`${environment.API_URL}/venues/${venueId}/spaces`, options);
  }

  /**
   * Get a specific space of a venue specified by
   * its *spaceId*.
   */
  getSpace(venueId: number, spaceId: number): Observable<Space> {
    return this.http.get<Space>(`${environment.API_URL}/venues/${venueId}/spaces/${spaceId}`);
  }

  /**
   * Find space through the spaces endpoint
   *
   * @param spaceId
   */
  findSpace(spaceId: number): Observable<Space> {
    return this.http.get<Space>(`${environment.API_URL}/spaces/${spaceId}`);
  }
}
