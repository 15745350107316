import { Component, ChangeDetectionStrategy, OnChanges, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IUserEntry } from '@core/services/user-manage.service';

@Component({
  selector: 'vs-user-form',
  templateUrl: './user-form.html',
  styleUrls: ['./user-form.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserFormComponent implements OnChanges {
  @Input() roles: {key: string, value: string}[] = [];
  @Input() departments: {id: number, name: string}[] = [];
  @Input() user: IUserEntry;
  @Output() readonly saveUser = new EventEmitter();
  @Output() readonly cancel = new EventEmitter();
  @ViewChild('usernameElement', {static: false}) userNameElement: HTMLInputElement;

  form: FormGroup;
  showValidationErrors: boolean;

  constructor(private _formBuilder: FormBuilder) {
    this.form = this._createForm();
  }

  ngOnChanges(changes) {
    if ( changes && changes.user ) {
      if ( this.user === null ) {
        this.form.reset();
        this.form.get('username').enable();
        this.form.get('full_name').enable();

        if ( this.userNameElement ) {
          setTimeout(() => {
            this.userNameElement.focus();
          }, 200);
        }
      } else {
        this.form.reset(this.user);
        this.form.get('username').disable();
        this.form.get('full_name').disable();
      }
    }
  }

  save($event) {
    if ( this.form.valid ) {
      this.showValidationErrors = false;
      this.saveUser.emit(this.form.value);
    } else {
      this.showValidationErrors = true;
    }
  }

  private _createForm(): FormGroup {
    return this._formBuilder.group({
      full_name: ['', Validators.required],
      username: ['', [Validators.required, Validators.email]],
      role: ['', Validators.required],
      department: ['']
    })
  }
}
