import { ActivatedRouteSnapshot, Router, Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, empty } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { VenueService } from '../services';
import { Venue } from '../models';

@Injectable()
export class VenueResolver implements Resolve<Venue|{}> {

  constructor(private venueService: VenueService, private router: Router) { }

  resolve(route: ActivatedRouteSnapshot): Observable<Venue|{}> {
    const venueId: number = +route.params.venue_id;

    let shareKey: string;

    if ( route.params.share_key ) {
      shareKey = route.params.share_key
    }

    return this.venueService
      .getVenue(venueId, shareKey)
      .pipe(
        catchError((err: any) => {
          this.router.navigate(['/404']);
          return empty();
        })
      );
  }
}
