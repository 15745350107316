<div class="vs-alert" role="alert"
     [class.vs-alert_type-info]="type === 'info'"
     [class.vs-alert_type-error]="type === 'error'"
     [class.vs-alert_type-success]="type === 'success'"
     [class.vs-alert_type-warning]="type === 'warning'"
     [class.vs-alert_type-neutral]="type === 'neutral'"
     [class.vs-alert_type-critical]="type === 'critical'">
  <i class="vs-alert_icon icon icon-info-circle" *ngIf="type === 'info'"></i>
  <i class="vs-alert_icon icon icon-close-circle" *ngIf="type === 'error'"></i>
  <i class="vs-alert_icon icon icon-check-circle" *ngIf="type === 'success'"></i>
  <i class="vs-alert_icon icon icon-info-circle" *ngIf="type === 'warning'"></i>
  <p *ngIf="text">{{ text }}</p>
  <p *ngIf="htmlText" [innerHTML]="htmlText | safeHtml"></p>
  <ng-content></ng-content>
  <a href="{{ 'HOME.COVID_19_URL' | translate }}" target="_blank" *ngIf="type === 'critical'"> {{'HOME.READ_MORE' | translate}} </a>

</div>
