import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { environment } from '@env/environment';
import { User } from '../models/user';
import { decodeToken } from '../util';
import { AppRoles } from '../roles.enum';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()
export class UserService {
  readonly localStoreApiKey = 'vsuite/apiToken';
  readonly localStoreRefreshTokenKey = 'vsuite/refreshToken';

  constructor(
    private http: HttpClient,
    public translate: TranslateService
  ) { }

  /**
   * 
   */
  static isVenue(role: AppRoles) {
    return role === AppRoles.VENUE_MANAGER || role === AppRoles.VENUE_USER;
  }

  /**
   * 
   */
  static isOrganization(role: AppRoles) {
    return role === AppRoles.ORGANIZATION_MANAGER || role === AppRoles.ORGANIZATION_USER;
  }

  /**
   * Attempt to get a valid API token for the given credentials.
   * Save the JWT token in the localStorage and return the
   * user object.
   */
  login(username, password): Observable<User> {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    
    const postBody = JSON.stringify({
      username: username,
      password: password
    });

    return this.http
      .post(`${environment.API_URL}/auth`, postBody , requestOptions)
      .pipe(
        filter((res: any) => {
          if ( ! res.token ) {
            throw Error('Failed to login');
          }
          return res;
        }),
        map(res => {

          if (res.msg == '2FA') {
            return this.request2FACode(res)
          }

          return this.onLoginSuccess(res)
        })
      );
  }

  /**
   *
   */
  request2FACode(res) : User {
    const user = new User(null)
    user.auth_2fa_token = res.token
    return user
  }

  /**
   * 
   */
  tokenLogin(token: string): Observable<User> {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify({
        token: token
    });

    return this.http
      .post(`${environment.API_URL}/auth/token`, body, requestOptions)
      .pipe(
        filter((res: any) => {
          if ( ! res.token ) {
            throw Error('Failed to login');
          }
          return res;
        }),
        map(res => this.onTokenLoginSuccess(res))
      );
  }

  /**
   * 
   */
  onTokenLoginSuccess(res) : User {
    localStorage.setItem(this.localStoreApiKey, res.token);
    if ( res.refresh_token ) {
      localStorage.setItem(this.localStoreRefreshTokenKey, res.refresh_token);
    } else {
      localStorage.setItem(this.localStoreRefreshTokenKey, res.token);
    }

    const userConfig = decodeToken(res.token);
    const user = new User(userConfig);
    this.setLang(user)
    return user;
  }

  /**
   * 
   */
  onLoginSuccess(res) : User {
    localStorage.setItem(this.localStoreApiKey, res.token);
    if ( res.refresh_token ) {
      localStorage.setItem(this.localStoreRefreshTokenKey, res.refresh_token);
    }

    const userConfig = decodeToken(res.token);
    const user = new User(userConfig);
    this.setLang(user)
    return user;
  }

  /**
   * 
   */
  setLang(user : User) {
    if (user.lang) {
      this.translate.use(user.lang);
      moment.locale(user.lang);
    }
  }

  /**
   * Update account details. If the update was succesfull, we update the
   * localStorage with the new details
   */
  updateUser(id, value: any): Observable<any> {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(value);

    return this.http
      .put(`${environment.API_URL}/users`, body , requestOptions)
      .pipe(
        map((res: any) => {
          localStorage.setItem(this.localStoreApiKey, res.token);
          const userConfig = decodeToken(res.token);
          return new User(userConfig);
        })
      );
  }

  /**
   * Construct a User object from the given JWT token
   */
  convertTokenToUser(jwt: string): User {
    const userConfig = decodeToken(jwt);
    const now = new Date().getTime();
    const exp = parseInt(userConfig.exp + '000');

    if ( now > exp ) {
      localStorage.removeItem(this.localStoreApiKey);
      location.reload();
    }

    return new User(userConfig);
  }

  /**
   * Get the API key from the local storage
   */
  clientBrowserToken() {
    return localStorage.getItem(this.localStoreApiKey);
  }

  /**
   * Send reset instructions to the given e-mail if it exists
   *
   * @param email
   */
  forgotPassword(email: string): Observable<any> {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify({
      email: email
    });
    return this.http.post(`${environment.API_URL}/password/forgot`, body , requestOptions);
  }

  /**
   * Resets the password for a user that with the given token
   *
   * @param token
   * @param newPassword
   */
  resetPassword(token: string, newPassword: string): Observable<any> {
    localStorage.removeItem(this.localStoreApiKey);
    localStorage.removeItem(this.localStoreRefreshTokenKey);

    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify({
        token: token,
        new_password: newPassword
    });

    return this.http.post(`${environment.API_URL}/password/reset`, body , requestOptions);
  }

  /**
   * Validate email with reset token
   *
   * @param token
   */
  validate(token: string) {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify({token: token});

    this.http.post(`${environment.API_URL}/password/validate`, body , requestOptions).subscribe(data => {});
    return;
  }

  /**
   * Changes the users password with a validation for its current password
   *
   * @param email
   * @param currentPassword
   * @param newPassword
   */
  changePassword(email: string, currentPassword: string, newPassword: string): Observable<any> {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify({
        username: email,
        password: currentPassword,
        new_password: newPassword
    });

    return this.http.post(`${environment.API_URL}/password/change`, body , requestOptions);
  }

  /**
   * Validate email
   *
   * @param email
   */
  checkEmail(value: string): Observable<any> {
    let urlSearchParams = new HttpParams();;
    urlSearchParams = urlSearchParams.set('email', value.toString());

    const options = {
      params: urlSearchParams
    };

    return this.http.get(`${environment.API_URL}/email/check`, options);
  }

  /**
   * Gets the venues of the authenticated user
   */
  getVenues() {
    return this.http.get(`${environment.API_URL}/users/me/venues`);
  }

  /**
   * Destroy token from client local storage
   */
  logout(): void {
    localStorage.removeItem(this.localStoreApiKey);
    sessionStorage.removeItem('lastActiveVenueId');
  }

  /**
   *
   */
  check2FA(code: string, token: string): Observable<any> {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify({
      code:  code,
      token: token
    });

    return this.http
        .post(`${environment.API_URL}/auth/check`, body, requestOptions)
        .pipe(
            filter((res: any) => {
              if ( ! res.token ) {
                throw Error('Failed to login');
              }
              return res;
            }),
            map(res => this.onLoginSuccess(res))
        );
  }
}
