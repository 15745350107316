import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'vs-fast-booking',
  templateUrl: './fast-booking.html',
  styleUrls: ['./fast-booking.scss']
})
export class FastBookingComponent implements OnInit {

  constructor(
    private titleService: Title,
    private metaService: Meta,
    private translate: TranslateService) { }

  ngOnInit() {
    this.setupSEO();
  }

  public setupSEO() {
    this.translate.get(['PAGES.WIDGET.TITLE', 'PAGES.WIDGET.META'])
      .subscribe(translations => {
        this.titleService.setTitle(translations['PAGES.WIDGET.TITLE']);
        this.metaService.updateTag(
          {name: 'description', content: translations['PAGES.WIDGET.META']}
        );
      });
  }

  /**
   *
   */
  navigateToTarget($element : any) {
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

}
