import { BaseProduct } from './base-product';

export interface CateringProduct extends BaseProduct {
  component_weight?: number;
  quantity?: number;
}

export enum CateringOfferCategory {
  FOOD = 'food',
  BEVERAGES = 'beverages'
}

export enum CateringComponent {
  BREAKFAST = 'breakfast',
  LUNCH = 'lunch',
  DINNER = 'dinner',
  SNACKS = 'snacks',
  SOFT_DRINKS = 'softdrinks',
  ALCOHOLIC = 'alcoholic',
  COFFEE_TEA = 'coffee_and_tea',
  WATER = 'water'
}
