import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from '@angular/router';
import { Observable } from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {Injector} from '@angular/core';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  canDeactivate(
    component: ComponentCanDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot): boolean | Observable<boolean> {
    return (nextState.url.indexOf('ipc=1') !== -1 || component.canDeactivate()) ? true :
      confirm(`WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.`);
  }
}
