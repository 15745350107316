import { Injectable } from '@angular/core';
import { Subject, timer } from 'rxjs';

interface IToastConfig {
  message: string;
  type?: 'info'|'success'|'danger'|'warning';
  dismissable?: boolean;
  timeout?: number;
  actions?: {[name: string]: Function};
}

@Injectable()
export class ToasterService {
  showToaster$: Subject<IToastConfig> = new Subject<IToastConfig>();
  closeToaster$: Subject<any> = new Subject<any>();

  // Alias for show() to support old 3th party toaster
  showSnackbar(config) {
    this.show(config);
  }

  /*
   */
  show(config: IToastConfig) {
    const mergeConfig = Object.assign(
      {
        message: 'Success',
        timeout: 2500,
        type: 'success',
        dismissable: true
      },
      config
    );

    this.emit(mergeConfig);
  }

  /*
   */
  close() {
    this.closeToaster$.next();
  }

  /*
   */
  private emit(config: IToastConfig) {
    if (config.timeout > 0) {
      timer(config.timeout).subscribe((e) => this.closeToaster$.next());
    }
    this.showToaster$.next(config);
  }
}
