import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { ISearchCriteria } from '../services/search-criteria.service';
import { Venue } from '../models';
import { environment } from '@env/environment';
import { slugify } from "@core/util";
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable()
export class VenueService {

  static readonly VENUE_SORT_OPTION = [
    'random',
    'recent',
    'priceasc'
  ];

  static readonly VENUE_CANCEL_POLICY_OPTION = [
    'flexible',
    'moderate',
    'strict',
    "custom"
  ];

  static buildVenueLink(venue: {id: number, name: string}, previewToken = '') {
    return `/venues/${slugify(venue.name)}/${venue.id}/details/${previewToken}`;
  }

  http: HttpClient;
  /**
   * 
   */
  constructor(http: HttpClient) {
    this.http = http;
  }

  /**
   * 
   */
  signUp(venueInfo): Observable<any> {
    const requestOptions = {headers: new HttpHeaders()};
    requestOptions.headers.set('Content-Type', 'application/json');
    const body = JSON.stringify(venueInfo);

    return this.http.post(`${environment.API_URL}/venues`,  body, requestOptions);
  }

  /**
   * 
   */
  findVenueMarkers(
    searchCriteria: ISearchCriteria, 
    limit: number, 
    max_lat?: number,
    max_lng?: number,
    min_lat?: number,
    min_lng?: number) {
    let urlSearchParams = this.objToSearchParams(searchCriteria);

    if (limit) {
      urlSearchParams = urlSearchParams.set('limit', limit.toString());
    }

    if (max_lat > min_lat) {
      urlSearchParams = urlSearchParams.set('max_lat', max_lat.toString());
    }

    if (max_lng > min_lng) {
      urlSearchParams = urlSearchParams.set('max_lng', max_lng.toString());
    }

    if (min_lat < max_lat) {
      urlSearchParams = urlSearchParams.set('min_lat', min_lat.toString());
    }

    if (min_lng < max_lng) {
      urlSearchParams = urlSearchParams.set('min_lng', min_lng.toString());
    }
    // hard filter venue ids
    if ( environment.FILTER_VENUE_IDS && environment.FILTER_VENUE_IDS instanceof Array && environment.FILTER_VENUE_IDS.length > 0 ) {
      urlSearchParams = urlSearchParams.set('venue_ids', environment.FILTER_VENUE_IDS.join(','));
    }

    return this.http.get(`${environment.API_URL}/venues/marker`, {
      params: urlSearchParams
    });
  }

  /**
   * 
   */
  findVenues(
    searchCriteria: ISearchCriteria,
    limit?: number,
    page?: number,
    start?: number,
    max_lat?: number,
    max_lng?: number,
    min_lat?: number,
    min_lng?: number) {

    let urlSearchParams = this.objToSearchParams(searchCriteria);

    if (limit) {
      urlSearchParams = urlSearchParams.set('limit', limit.toString());
    }

    if (page) {
      urlSearchParams = urlSearchParams.set('page', page.toString());
    }

    if (start) {
      urlSearchParams = urlSearchParams.set('start', start.toString());
    }

    if (max_lat) {
      urlSearchParams = urlSearchParams.set('max_lat', max_lat.toString());
    }

    if (max_lng) {
      urlSearchParams = urlSearchParams.set('max_lng', max_lng.toString());
    }

    if (min_lat) {
      urlSearchParams = urlSearchParams.set('min_lat', min_lat.toString());
    }

    if (min_lng) {
      urlSearchParams = urlSearchParams.set('min_lng', min_lng.toString());
    }

    // hard filter venue ids
    if ( environment.FILTER_VENUE_IDS && environment.FILTER_VENUE_IDS instanceof Array && environment.FILTER_VENUE_IDS.length > 0 ) {
      urlSearchParams = urlSearchParams.set('venue_ids', environment.FILTER_VENUE_IDS.join(','));
    }

    const options = {
      params: urlSearchParams
    };

    return this.http.get(`${environment.API_URL}/venues`, options);
  }

  /**
   * 
   */
  getVenue(venueId: number, shareKey?: string): Observable<Venue> {
    let options = {};

    if (shareKey) {
      const urlSearchParams = new URLSearchParams();
      urlSearchParams.set('share', shareKey);
      options['params'] = urlSearchParams;
    }

    return this.http.get<Venue>(`${environment.API_URL}/venues/${venueId}`, options)
  }

  /**
   * 
   */
   autocomplete(term: string, limit: number = 5): Observable<Array<any>> {
    if (term.trim().length === 0) {
      return of([]);
    }

    let urlSearchParams = new HttpParams();

    urlSearchParams = urlSearchParams.set('q', term);
    urlSearchParams = urlSearchParams.set('limit', limit.toString());

    // hard filter venue ids
    if ( environment.FILTER_VENUE_IDS && environment.FILTER_VENUE_IDS instanceof Array && environment.FILTER_VENUE_IDS.length > 0 ) {
      urlSearchParams = urlSearchParams.set('venue_ids', environment.FILTER_VENUE_IDS.join(','));
    }

    const options = {
      params: urlSearchParams
    };

    return this.http.get<Array<any>>(`${environment.API_URL}/autocomplete/venuescities`, options);
  }

  /**
   * 
   */
  private objToSearchParams(params: any): HttpParams {
    let httpParams: HttpParams = new HttpParams();
    Object.keys(params).forEach(param => {
      if (params[param]) {
        if (params[param] instanceof Array) {
          params[param].forEach(value => {
              httpParams = httpParams.append(param, value);
          });
        } else {
          if (param === 'period' && param) {
            httpParams = httpParams.append('period[start]', params[param].start);
            httpParams = httpParams.append('period[end]', params[param].end);
          } else {
            httpParams = httpParams.append(param, params[param]);
          }
        }
      }
    });

    return httpParams;
  }
}
