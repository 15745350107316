import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MapsService {
  http: HttpClient;

  constructor(http: HttpClient) {
    this.http = http;
  }

  getInfo(data): Observable<any> {
    const requestUrl = `${environment.API_URL}/map/search?q=${data}`;

    return this.http.get(requestUrl);
  }
}
