import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, QueryList, ContentChildren, Input, HostBinding } from '@angular/core';
import { PostfixDirective, PrefixDirective } from '../../directives';

@Component({
  selector: 'vs-formfield',
  templateUrl: 'formfield.html',
  styleUrls: ['./formfield.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormfieldComponent implements OnInit {
  @Input() direction: 'horizontal'|'vertical' = 'vertical';
  @Input() classList: string;
  @Input() size:'sm'|'lg' = null;
  @HostBinding('class.vs-formfield') hostClass = true;
  @ContentChildren(PrefixDirective) _prefixChildren: QueryList<PrefixDirective>;
  @ContentChildren(PostfixDirective) _postfixChildren: QueryList<PostfixDirective>;

  constructor() { }

  ngOnInit() { }
}
