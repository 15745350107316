import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MdlDialogService } from '@angular-mdl/core';
import { switchMap } from 'rxjs/operators';
import * as moment from 'moment';

import { DatetimePickerComponent, DATETIMEPICKER_CONF, IDatetimePickerConfiguration, DatetimePickerValue, isDateRange } from './datetime-picker';

@Component({
  selector: 'vs-datetime',
  templateUrl: './datetime.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: DatetimeComponent,
    multi: true
  }]
})
export class DatetimeComponent implements ControlValueAccessor {
  @Input() dateOnly = false;
  @Input() selectStartEndTime = true;
  @Input() calendarOnly = false;
  @Input() dateFormat = 'D MMM YYYY';
  @Input() dateTimeSeparator = '/';
  @Input() timeFormat = 'HH:mm';
  @Input() timeSeparator = '-';
  @Input() multipleDates = false;
  @Input() disabled = false;
  @Input() label = '';
  @Input() placeholder = '';
  @Input() spaceId = 0;
  @Input() venueId = 0;

  isDialogVisible: boolean;
  invalidDate: boolean;
  focused = false;

  text = '';

  private value: any;
  private onChange: Function;
  private onTouched: Function;

  /**
   * 
   */
  constructor(
    private changeDetectionRef: ChangeDetectorRef,
    private dialogService: MdlDialogService) {}

  /**
   * 
   */
  writeValue(value: any) {
    this.value = value;
    this.renderValue(this.value);
  }

  /**
   * 
   */
  clearValue($event) {
    this.writeValue(null);
    this.onChange(null);
  }

  /**
   * 
   */
  registerOnChange(fn: Function) {
    this.onChange = fn;
  }

  /**
   * 
   */
  registerOnTouched(fn: Function) {
    this.onTouched = fn;
  }

  /**
   * 
   */
  onBlur($event) {
    this.focused = false;
    this.onTouched();
  }

  /**
   * 
   */
  onFocus($event) {
    // Disabled for now because the animation adds more lag
    // this.focused = true;
  }

  /**
   * 
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /**
   * 
   */
  renderValue(value: any) {
    this.text = this.stringifyValue(value);
    this.changeDetectionRef.detectChanges();
  }

  /**
   * 
   */
  stringifyValue(value: DatetimePickerValue): string {
    if ( value ) {
      if ( value instanceof Array ) {
        const firstDate = value.find(x=>x!==undefined);

        const start = moment(firstDate.start, moment.ISO_8601);
        const end = moment(firstDate.end, moment.ISO_8601);

        return `${value.length} dates selected ${this.dateTimeSeparator} ${start.format(this.timeFormat)} ${this.timeSeparator} ${end.format(this.timeFormat)}`;
      } else if ( isDateRange(value) ) {
        const start = moment(value.start, moment.ISO_8601);
        const end = moment(value.end, moment.ISO_8601);
        const dateFormatted = start.format(this.dateFormat);

        return `${dateFormatted} ${this.dateTimeSeparator} ${start.format(this.timeFormat)} ${this.timeSeparator} ${end.format(this.timeFormat)}`;
      } else if ( value && moment(value, moment.ISO_8601).isValid() ) {
        return moment(value, moment.ISO_8601).format(this.dateFormat);
      }

      this.invalidDate = true;
      return 'INVALID DATE';
    }

    return '';
  }

  /**
   * 
   */
  openDatetimePicker($event: MouseEvent) {
    if ( ! this.disabled && ! this.isDialogVisible ) {
      this.isDialogVisible = true;
      this.onTouched();

      const dateTimePickerConfiguration: IDatetimePickerConfiguration = {
        value: this.invalidDate ? null : <DatetimePickerValue>this.value,
        dateOnly: this.dateOnly,
        allowMultiple: this.multipleDates,
        selectTimeRange: this.selectStartEndTime,
        calendarOnly : this.calendarOnly,
        spaceId: this.spaceId,
        venueId: this.venueId
      };

      const pDialog = this.dialogService.showCustomDialog({
        component: DatetimePickerComponent,
        providers: [{provide: DATETIMEPICKER_CONF, useValue: dateTimePickerConfiguration}],
        isModal: true,
        styles:  this.calendarOnly ? {'width': '40rem', 'max-width': 'none', 'max-height': 'none'} : {},
        classes: 'vs-datepicker-dialog',
        clickOutsideToClose: true,
        enterTransitionDuration: 400,
        leaveTransitionDuration: 400
      });

      pDialog.pipe(switchMap(ref => ref.onHide())).subscribe(data => {
        this.isDialogVisible = false;
        this.dateTimePickerCallback(data)
      });
    }
  }

  /**
   * 
   */
  dateTimePickerCallback(data) {
    if ( data ) {
      this.onChange(data);
      this.writeValue(data);
    }
  }
}
