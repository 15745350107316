import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'activeProduct',
    pure: false
})
export class ActiveProductPipe implements PipeTransform {
    transform(items: any[]): any {
        if (!items) {
            return items;
        }
        return items.filter(item => item.activated);
    }
}