import { Component, OnInit, Output, EventEmitter, Input} from '@angular/core';

@Component({
  selector: 'vs-inner-dialog-host',
  templateUrl: './inner-dialog-host.html',
  styleUrls: ['./inner-dialog-host.scss']
})
export class InnerDialogHostComponent implements OnInit {
  @Output() readonly closeDialogClick = new EventEmitter<void>();
  @Input() scrollBody = false;
  @Input() showActions = true;

  constructor() { }

  ngOnInit() { }
}
