<div
  class="vs-form-field-wrapper"
  [class.vs-form-field-wrapper--lg]="size === 'lg'"
  [class.vs-form-field-wrapper--sm]="size === 'sm'"
  [class.vs-form-field-wrapper--horizontal]="direction === 'horizontal'">
  <ng-content select="label"></ng-content>
  <div class="input-container"
    [class.has-prefix]="_prefixChildren.length"
    [class.has-postfix]="_postfixChildren.length">
    <div class="vs-form-field-prefix" *ngIf="_prefixChildren.length">
      <ng-content select="[dirPrefix]"></ng-content>
    </div>
    <div class="vs-form-field-infix">
      <ng-content></ng-content>
    </div>
    <div class="vs-form-field-postfix" *ngIf="_postfixChildren.length">
      <ng-content select="[dirPostfix]"></ng-content>
    </div>
  </div>
  <ng-content select="[dirErrorMsg]"></ng-content>
</div>
