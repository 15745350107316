import {
  ChangeDetectionStrategy,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2, ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {filter} from 'rxjs/operators';
import {fromEvent} from 'rxjs';

import {User} from '@core/models';
import * as fromRoot from '@core/reducers/index';
import * as userActions from '@core/actions/user';
import {AppRoles} from '@core/roles.enum';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'vs-marketing-nav',
  templateUrl: './marketing-nav.component.html',
  styleUrls: ['./marketing-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class MarketingNavComponent implements OnChanges, OnInit {
  @Input() navItems: Array<any>;
  @Input() marketingTransparentNav: boolean;
  @Input() isAuthenticated: boolean;
  @Input() latestUrl: string;
  @Input() user: User;
  @Output() readonly signOut = new EventEmitter();
  @Output() readonly currentVenue = new EventEmitter();
  @ViewChild('navElement', {static: false}) navElement;
  @ViewChild('filler', {static: false}) fillerElement;
  userName: string;
  userAvatar: string;
  showMobileMenu: boolean;
  isOrganizationManager: boolean;
  isOrganization: boolean;
  transparentLogo = environment.TRANSPARENT_ICON;
  mobileLogo = environment.MOBILE_LOGO;
  deskTopLogo = environment.DESKTOP_LOGO;
  constructor(
    private router: Router,
    private renderer: Renderer2,
    private store: Store<fromRoot.State>) { }

  ngOnInit() {
    this.router
      .events
      .pipe(
        filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationEnd) => {
        this.showMobileMenu = false;
      });

    fromEvent(document, 'scroll').subscribe(event => {
      if ( this.marketingTransparentNav ) {
        const scrollFromTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

        if ( scrollFromTop >= 66 ) {
          this.renderer.setStyle(this.fillerElement.nativeElement, 'display', 'block');
          this.renderer.removeClass(this.navElement.nativeElement, 'marketing-nav-primary--transparent');
          this.renderer.addClass(this.navElement.nativeElement, 'marketing-nav-primary--fixed');

          const top = -66 + (scrollFromTop - 66);
          if ( top <= 0 ) {
            this.renderer.setStyle(this.navElement.nativeElement, 'top', top+'px');
          } else {
            this.renderer.setStyle(this.navElement.nativeElement, 'top', '0px');
          }
        } else {
          this.renderer.setStyle(this.fillerElement.nativeElement, 'display', 'none');
          this.renderer.setStyle(this.navElement.nativeElement, 'top', '0px');
          this.renderer.addClass(this.navElement.nativeElement, 'marketing-nav-primary--transparent');
          this.renderer.removeClass(this.navElement.nativeElement, 'marketing-nav-primary--fixed');
        }
      }
    });
  }

  ngOnChanges(changes) {
    this.userName = this.isAuthenticated ? this.user.first_name : '';
    this.userAvatar = this.isAuthenticated ? this.user.avatar : '';
    this.isOrganizationManager = this.isAuthenticated ? this.user.role == AppRoles.ORGANIZATION_MANAGER : false;
    this.isOrganization = this.user && this.user.organization_id && this.user.organization_id > 0;

    if ( changes.marketingTransparentNav ) {
      if(this.fillerElement !== undefined) {
        this.renderer.setStyle(this.fillerElement.nativeElement, 'display', 'none');
        this.renderer.removeClass(this.navElement.nativeElement, 'marketing-nav-primary--fixed');
      }
    }
  }

  showAuthDialog($event, tab: string) {
    $event.preventDefault();
    this.store.dispatch(new userActions.ShowAuthDialogAction(tab));
  }

  toggleMobileMenu($event) {
    this.showMobileMenu = !this.showMobileMenu;
  }
}
