import { ActivatedRoute, Router } from '@angular/router';
import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UserService } from '@core/services';
import * as fromRoot from '@core/reducers';
import * as userActions from '@core/actions/user';
import { Store } from '@ngrx/store';

@Component({
  selector: 'vs-reset-password-page',
  templateUrl: './reset-password-page.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResetPasswordPageComponent  {
  completeUser: boolean = false;
  loading: boolean = false;
  email: string;
  errorMsg: string;
  private token: string;

  constructor(
    private store: Store<fromRoot.State>,
    private route: ActivatedRoute,
    private router: Router,
    private cref: ChangeDetectorRef,
    private userService: UserService) {

    this.completeUser = (location.href.includes('complete-account')) ? true : false;

    this.token = this.route.snapshot.params['token'];
    this.email = this.route.snapshot.params['email'];

    if (this.completeUser) {
      this.userService.validate(this.token);
    }
  }

  resetPassword(newPassword: string) {
    this.loading = true;
    this.errorMsg = null;

    this.userService
      .resetPassword(this.token, newPassword)
      .subscribe((res) => {
        this.store.select(fromRoot.getUserIsLoggedIn).subscribe(isLoggedIn => {
          if ( isLoggedIn ) {
            this.router.navigate(['/booking']);
          }
        });

        this.store.dispatch(new userActions.LoginAction({
          username: this.email,
          password: newPassword
        }));

        this.loading = false;
      }, (res) => {
        this.loading = false;
        const body = res || { error: {message: 'unknown'}};
        this.errorMsg = 'Sorry but we failed to set your password. Reason: ' + body.error.message;
        this.cref.detectChanges();
      });
  }
}
