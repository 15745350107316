import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {Venue} from '../models';
import {environment} from '@env/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FavoritesService {
  constructor(private jsonHttp: HttpClient) {}

  getFavoriteVenues(): Observable<Venue[]> {
    return this.jsonHttp.get<Venue[]>(environment.API_URL + '/favorites/venues');
  }

  addFavoriteVenue(id: number): Observable<any> {
    return this.jsonHttp.post(environment.API_URL + '/favorites/venues/' + id, null);
  }

  deleteFavoriteVenue(id: number): Observable<any> {
    return this.jsonHttp.delete(environment.API_URL + '/favorites/venues/' + id);
  }
}
