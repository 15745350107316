import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, AfterViewInit, ElementRef, HostListener } from '@angular/core';
import { fromEvent } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'vs-carousel',
  templateUrl: './carousel.html',
  styleUrls: ['./carousel.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CarouselComponent implements AfterViewInit {
  @Input() images: any[];
  @Input() transformation: string = "3:2";
  @Input() width: string = "600";
  @Input() comingSoon: boolean;
  @Output() readonly mouseOver = new EventEmitter<any>();
  @Output() readonly mouseOut = new EventEmitter<any>();
  imageIndex: number;

 /**
  *
  */ 
  get showCarouselNav() {
    return this.images.length > 1 && !this.comingSoon;
  }

 /**
  *
  */ 
  get image() {
    return (this.images.length ) ? this.images[this.imageIndex] : null;
  }

 /**
  *
  */ 
  constructor(private element: ElementRef) {
    this.imageIndex = 0;
  }

 /**
  *
  */ 
  ngAfterViewInit() {
    fromEvent(this.element.nativeElement, 'mouseover')
      .pipe(distinctUntilChanged())
      .subscribe((e: MouseEvent) => this.mouseOver.emit(e));

    fromEvent(this.element.nativeElement, 'mouseleave')
      .pipe(distinctUntilChanged())
      .subscribe((e: MouseEvent) => this.mouseOut.emit(e));
  }

 /**
  *
  */ 
  nextImageEvent($event: UIEvent, direction: 'left'|'right') {
    $event.stopPropagation();
    $event.preventDefault();

    this.nextImage(direction);
  }

 /**
  *
  */  
  nextImage(direction) {
    const imgLength = this.images.length;

    if ( direction === 'left' ) {
      this.imageIndex = (this.imageIndex - 1 !== -1) ? this.imageIndex - 1 : imgLength - 1;
    } else {
      this.imageIndex = (this.imageIndex + 1 >= imgLength ) ? 0 : this.imageIndex + 1;
    }
  }
  
  /**
   * 
   */
  @HostListener('keydown', ['$event']) 
    handleKeyDown($event: KeyboardEvent) {
    
    switch ($event.keyCode) {
      case 39: //ArrowRight
        this.nextImage('right');
        $event.stopImmediatePropagation();
        break;
      case 37: //ArrowLeft
        this.nextImage('left');
        $event.stopImmediatePropagation();
        break;
      default:
        break;
      }
      
   }

}
