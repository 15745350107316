import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'vs-breadcrumbs',
  styleUrls: ['./breadcrumbs.scss'],
  template: `
    <div class="vs-breadcrumbs"><ng-content></ng-content></div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class BreadcrumbsComponent {
  constructor() { }
}
