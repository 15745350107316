import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'vs-complete-account',
  templateUrl: './complete-account.html',
  styleUrls: ['./complete-account.scss']
})
export class CompleteAccountComponent {
  @Input() email: string;
  @Input() loading = false;
  @Input() error: string;
  @Output() readonly resetPassword: EventEmitter<string> = new EventEmitter<string>();
  submitAttempt = false;
  form: FormGroup;
  password: FormControl = new FormControl('', Validators.required);

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      password : this.password
    });
  }

  onSubmit() {
    this.submitAttempt = true;

    if ( this.form.valid ) {
      this.resetPassword.emit(this.form.get('password').value);
    }
  }
}
