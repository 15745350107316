import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    Input,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'vs-dropdown',
  template: `
    <div
      #container
      class="dropdown-container mdl-shadow--8dp"
      [class.visible]="show">
      <ng-content></ng-content>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./dropdown.scss']
})
export class DropdownComponent implements AfterViewInit {
  @Input() position;
  @Input() topCorrection = 20;
  @Input() closeOnClickInside = true;
  show = false;
  viewInitialized = false;
  scrollVal = 0;
  @ViewChild('container', {static: false}) public containerChild: ElementRef;
  private container: HTMLElement;

  toggle(event, ref) {
    event.stopPropagation();

    if ( ! this.viewInitialized ) {
      this.viewInitialized = true;
    }

    this.show = !this.show;

    const forElement  = (ref.element) ? ref.element : ref;
    const rect        = forElement.getBoundingClientRect();

    this.container.style.top = (forElement.offsetTop + forElement.offsetHeight) + 'px';

    let offsetLeft;

    if ( ! this.position ) {
      offsetLeft = (forElement.offsetLeft + rect.width) - this.container.getBoundingClientRect().width;
    } else if ( this.position === 'bottom-left' ) {
      offsetLeft = forElement.offsetLeft;
    }

    this.container.style.left = offsetLeft + 'px';
  }

  close(event, ref) {
    event.stopPropagation();

    if ( this.viewInitialized ) {
      this.viewInitialized = false;
    }

    this.show = false;
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event: Event): void {
    if ( this.container.contains(<Node>event.target) && ! this.closeOnClickInside ) {
      return;
    }

    if ( this.viewInitialized && this.show ) {
      this.show = false;
    }
  }

  @HostListener('window:scroll', ['$event']) onWindowScroll($event) {
    this.scrollVal++;

    if ( this.scrollVal === 25 ) {
      this.viewInitialized = false;
      this.show = false;
      this.scrollVal = 0;
    }
  }

  ngAfterViewInit() {
    this.container = this.containerChild.nativeElement;
    this.container.style.marginTop = this.topCorrection + 'px';
  }
}
