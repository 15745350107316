import {Injectable} from "@angular/core";
import {Actions, Effect, ofType} from "@ngrx/effects";
import {map, startWith, switchMap, withLatestFrom} from "rxjs/operators";
import {of} from "rxjs";
import { Store} from "@ngrx/store";
import {MdlDialogService} from "@angular-mdl/core";
import {TranslateService} from "@ngx-translate/core";

import {Venue} from "@core/models";
import * as fromRoot from "@core/reducers/index";
import * as fromCompare from "@core/actions/compare-venue.actions";

@Injectable()
export class CompareVenueEffects {
  @Effect() loadCompareVenues$ = this.actions$
    .pipe(ofType(fromCompare.ActionTypes.LOAD_VENUES))
    .pipe(
      startWith(new fromCompare.LoadVenuesAction()),
      switchMap(() => {
        // TODO: Get venues from localStorage, trigger VenuesLoadedAction
        return of({type: 'NOOP'});
      })
    );

  @Effect() addVenue$ = this.actions$
    .pipe(ofType(fromCompare.ActionTypes.ADD_VENUE))
    .pipe(
      map((action: any) => action.payload),
      withLatestFrom(this.store$.select(fromRoot.getCompareVenues)),
      switchMap(([venue, venues]) => {
        if(venues.size > 3) {
          return this.dialogService
            .alert(this.transService.instant('COMPARE.NOTIFY'), 'OK', this.transService.instant('COMPARE.NOTIFY_TITLE'))
            .pipe(switchMap(() => of(new fromCompare.RmVenueAction(venue))));
        } else {
          return of({type: 'NOOP'});
        }
      })
    );

  @Effect() rmVenue$ = this.actions$
    .pipe(
      ofType(fromCompare.ActionTypes.RM_VENUE)
    )
    .pipe(
      map((action: any) => action.payload),
      switchMap((venue: Venue) => {
        // TODO: Sync with localStorage/cookie
        return of({type: 'NOOP'});
      })
    );

  constructor(
    private actions$: Actions,
    private store$: Store<fromRoot.State>,
    private dialogService: MdlDialogService,
    private transService: TranslateService) { }
}
