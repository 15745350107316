import { Component, InjectionToken, Inject, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

export const VIDEO_URL = new InjectionToken<string>('videoUrl');

@Component({
  selector: 'vs-video-dialog',
  templateUrl: './video-dialog.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoDialogComponent {
  videoId: string;
  videoUrl: SafeResourceUrl;

  constructor(@Inject(VIDEO_URL) videoUrl: string, private sanitizer: DomSanitizer) {
    let sourceUrl: string;
    if ( videoUrl.indexOf('youtube.com') !== -1 ) {
      this.videoId = this.getYoutubeVideoId(videoUrl);
      sourceUrl = 'https://www.youtube.com/embed/';
    } else if ( videoUrl.indexOf('youtu.be') !== -1 ) {
      this.videoId = videoUrl.split('/').pop();
      sourceUrl = 'https://www.youtube.com/embed/';
    } else if ( videoUrl.indexOf('vimeo.com') !== -1 ) {
      this.videoId = this.getVimeoVideoId(videoUrl);
      sourceUrl = 'https://player.vimeo.com/video/';
    }
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(sourceUrl+this.videoId); 
  }

  getYoutubeVideoId(videoUrl: string) {
    try {
      const matches = videoUrl.match(/(?:[\/]|v=)([a-zA-Z0-9-_]{11})/);
      return matches[1];
    } catch ( err ) {
      console.log(err);
    }
  }

  getVimeoVideoId(url) {
    try {
      const re = /\/\/(?:www\.)?vimeo.com\/([0-9a-z\-_]+)/i;
      const matches = re.exec(url);
      return matches[1];
    } catch( err ) {
      console.log(err);
    }
  }
}
