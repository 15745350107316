import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { environment } from '@env/environment';

@Injectable()
export class NotInProductionGuard implements CanActivate {
  canActivate(route: ActivatedRouteSnapshot): boolean {
    return !environment.PRODUCTION;
  }
}
