import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router/router';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { SpaceService, VenueService } from '../services';
import { Venue, Space } from '../models';

@Injectable()
export class SpaceVenueResolver implements Resolve<[Space, Venue]> {

  constructor(
    private spaceService: SpaceService,
    private venueService: VenueService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<[Space, Venue]> {
    const spaceId: number = +route.params.space_id;

    return this.spaceService
      .findSpace(spaceId)
      .pipe(
        concatMap((space) => {
          return forkJoin(
            of(space),
            this.venueService.getVenue(space.venue.id)
          );
        })
      );
  }
}
