import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'equipmentIcon'
})
export class EquipmentIconPipe implements PipeTransform {
  equipmentIconMap: {[label: string]: string};

  constructor() {
    this.equipmentIconMap = {};
    this.equipmentIconMap['wardrobe'] = 'icon-warderobe';
    this.equipmentIconMap['daylight'] = 'icon-daylight';
    this.equipmentIconMap['tv_screen'] = 'icon-tv';
    this.equipmentIconMap['projector'] = 'icon-projector';
    this.equipmentIconMap['projection_and_screen'] = 'icon-projector';
    this.equipmentIconMap['monitor'] = 'icon-tv';
    this.equipmentIconMap['beamer'] = 'icon-projector';
    this.equipmentIconMap['speakers'] = 'icon-speaker';
    this.equipmentIconMap['sound_system'] = 'icon-sound';
    this.equipmentIconMap['microphone'] = 'icon-microphone';
    this.equipmentIconMap['laser_pointer'] = 'icon-laserpointer';
    this.equipmentIconMap['audio_engineer'] = 'icon-tech-support';
    this.equipmentIconMap['laptop'] = 'icon-computer';
    this.equipmentIconMap['extra_chairs'] = 'icon-chair';
    this.equipmentIconMap['extra_tables'] = 'icon-table';
    this.equipmentIconMap['lighting'] = 'icon-pro-lighting';
    this.equipmentIconMap['speakers_desk'] = 'icon-speakersdesk';
    this.equipmentIconMap['video_camera'] = 'icon-photo';
    this.equipmentIconMap['conference_phone'] = 'icon-phone';
    this.equipmentIconMap['pens_and_paper'] = 'icon-paper-pen';
    this.equipmentIconMap['whiteboard'] = 'icon-whiteboard';
    this.equipmentIconMap['flipchart'] = 'icon-flipchart';
    this.equipmentIconMap['headset'] = 'icon-headset';
  }

  transform(value: string) {
    return this.equipmentIconMap[value] || 'icon-spaces';
  }
}
